import axios from "axios";

const api = axios.create({
    baseURL: process.env.REACT_APP_SERVER_BASE_URL,
});

api.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('accessToken');
        if (token) {
            config.headers.Authorization = token;
        }

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);


export default api;