import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { FaUserGraduate, FaChalkboardTeacher } from "react-icons/fa";
import { AiFillNotification, AiOutlineFileSearch } from "react-icons/ai";
import { ImBooks } from "react-icons/im";
import { MdExpandLess, MdExpandMore, MdDashboard, MdLibraryBooks } from "react-icons/md";
import { Collapse, Stack, Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import { useEffect, useState } from "react";
import SidebarMenu from "./SidebarMenu";
import useWindowDimensions from "../hooks/useWindowDimensions";
import { useLocation } from "react-router-dom";
import { SiGoogleclassroom } from "react-icons/si";
import { BsCalendar2CheckFill, BsFillChatSquareQuoteFill, BsFillFileEarmarkMedicalFill, BsNewspaper } from "react-icons/bs";
import { FaPencilRuler, FaBookReader } from "react-icons/fa";
import { GoSettings } from "react-icons/go";
import { useAuth } from "../hooks/useAuth";
import { IoIosHelpBuoy } from "react-icons/io";
import { TbCertificate } from "react-icons/tb";
import usePermissions from "../hooks/permission/usePermissions";
import Loading from "./Loading";
import { ROUTES } from "../Pages/Settings/permission_data";


const Sidebar = ({ open, onClose }) => {
    const location = useLocation()
    const [submenuControl, setSubmenuControl] = useState({
        students: false,
        teachers: false,
        courses: false,
        batch: false,
        classes: false,
        attendance: false,
        exam: false,
        results: false,
        applications: false,
        notices: false,
        news: false,
        photoGallery: false,
        videoGallery: false,
        publications: false,
        settings: false,
        evaluation: false,
        avassignments: false,
        subject: false,
    })

    const [drawerWidth, setDrawerWidth] = useState(240)

    const handleClick = (key) => {
        let newValue = { ...submenuControl }
        const currentValue = newValue[key];
        Object.keys(newValue).forEach(keyItem => {
            newValue[keyItem] = false;
        });
        newValue[key] = !currentValue
        setSubmenuControl(newValue)
    }

    const { width } = useWindowDimensions();

    useEffect(() => {
        if (width > 960) {
            setDrawerWidth(280)
        } else {
            setDrawerWidth(320)
        }
    }, [width])

    useEffect(() => {
        if (location.pathname.includes("students")) {
            let newValue = { ...submenuControl }
            newValue.clients = !newValue.clients
            setSubmenuControl(newValue)
        } else if (location.pathname.includes("teachers")) {
            let newValue = { ...submenuControl }
            newValue.clients = !newValue.clients
            setSubmenuControl(newValue)
        } else if (location.pathname.includes("courses")) {
            let newValue = { ...submenuControl }
            newValue.clients = !newValue.clients
            setSubmenuControl(newValue)
        } else if (location.pathname.includes("classes")) {
            let newValue = { ...submenuControl }
            newValue.clients = !newValue.clients
            setSubmenuControl(newValue)
        } else if (location.pathname.includes("attendance")) {
            let newValue = { ...submenuControl }
            newValue.clients = !newValue.clients
            setSubmenuControl(newValue)
        } else if (location.pathname.includes("exam")) {
            let newValue = { ...submenuControl }
            newValue.clients = !newValue.clients
            setSubmenuControl(newValue)
        } else if (location.pathname.includes("results")) {
            let newValue = { ...submenuControl }
            newValue.clients = !newValue.clients
            setSubmenuControl(newValue)
        } else if (location.pathname.includes("applications")) {
            let newValue = { ...submenuControl }
            newValue.clients = !newValue.clients
            setSubmenuControl(newValue)
        } else if (location.pathname.includes("notices")) {
            let newValue = { ...submenuControl }
            newValue.clients = !newValue.clients
            setSubmenuControl(newValue)
        } else if (location.pathname.includes("news")) {
            let newValue = { ...submenuControl }
            newValue.clients = !newValue.clients
            setSubmenuControl(newValue)
        } else if (location.pathname.includes("settings")) {
            let newValue = { ...submenuControl }
            newValue.clients = !newValue.clients
            setSubmenuControl(newValue)
        }
    }, [])
    const handleClose = () => {
        if (width <= 960) {
            onClose()
        }
    }

    const { user, lang } = useAuth();
    const { check, isPermiting } = usePermissions(user?.id);

    return (
        <Drawer
            open={open}
            onClose={onClose}
            variant={width > 960 ? "persistent" : "temporary"}
            sx={{
                width: drawerWidth,
                flexShrink: 0,
                [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
            }} >
            <Toolbar />
            {isPermiting ? <Loading size={25} /> : <Box className={`sidebarContainer`} sx={{ overflow: 'auto' }}>
                <List sx={{ pt: 0 }}>
                    <Stack alignItems={"center"} justifyContent={"center"} gap={1.2} py={3} bgcolor={"#edf4fa"}>
                        <img src="/favicon.png" alt="GDN ERP Logo" style={{ maxWidth: "70px" }} />
                        <Typography variant={"body2"} textAlign={"center"} fontWeight={"bold"} color={"green"}>Training Information</Typography>
                        <Typography variant={"body1"} textAlign={"center"} fontWeight={"bold"} color={"#428df5"}>Bangladesh Film and Television Institue</Typography>
                    </Stack>
                    <SidebarMenu onClose={handleClose} directory="/dashboard/home" icon={<MdDashboard size={20} />}
                        menuTitle={lang === "en" ? "Dashboard" : "ড্যাশবোর্ড"} />


                    {check(ROUTES.STUDENT.MANAGE.M, ROUTES.STUDENT.MANAGE.S, ROUTES.STUDENT.ADD, ROUTES.STUDENT.COURSE.MANAGE.M, ROUTES.STUDENT.COURSE.MANAGE.S, ROUTES.STUDENT.SEMESTER.MANAGE.M, ROUTES.STUDENT.SEMESTER.MANAGE.S, ROUTES.STUDENT.SEMESTER.ADD) &&
                        <>
                            <ListItemButton onClick={() => handleClick("students")}>
                                <ListItemIcon>
                                    <FaUserGraduate size={20} />
                                </ListItemIcon>
                                <ListItemText primary={lang === "en" ? "Students" : "শিক্ষার্থী"} />
                                {submenuControl.students ? <MdExpandMore /> : <MdExpandLess />}
                            </ListItemButton>
                            <Collapse in={submenuControl.students} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    {check(ROUTES.STUDENT.MANAGE.M, ROUTES.STUDENT.MANAGE.S) && <SidebarMenu onClose={handleClose} icon={""} menuTitle={lang === "en" ? "Manage Students" : "শিক্ষার্থী ব্যবস্থাপনা"}
                                        directory="/students/manage" />}
                                    {check(ROUTES.STUDENT.ADD) && <SidebarMenu onClose={handleClose} icon={""} menuTitle={lang === "en" ? "Add Student" : "শিক্ষার্থী সংযুক্তি"}
                                        directory="/students/add" />}
                                    {/* <SidebarMenu onClose={handleClose} icon={""} menuTitle={lang === "en" ? "Bulk Import" : "বাল্ক ইমপোর্ট"}
                                                directory="/students/bulk-import" /> */}
                                    {check(ROUTES.STUDENT.COURSE.MANAGE.M, ROUTES.STUDENT.COURSE.MANAGE.S) && <SidebarMenu onClose={handleClose} icon={""} menuTitle={lang === "en" ? "Manage Course Enrollment" : "কোর্স তালিকাভুক্তি ব্যবস্থাপনা"}
                                        directory="/students/manage-enrollment" />}
                                    {check(ROUTES.STUDENT.SEMESTER.MANAGE.M, ROUTES.STUDENT.SEMESTER.MANAGE.S) && <SidebarMenu onClose={handleClose} icon={""} menuTitle={lang === "en" ? "Manage Semester Enrollment" : "সেমিস্টার বরাদ্দকরণ ব্যবস্থাপনা"}
                                        directory="/students/semester-enrollment" />}
                                    {check(ROUTES.STUDENT.SEMESTER.ADD) && <SidebarMenu onClose={handleClose} icon={""} menuTitle={lang === "en" ? "Semester Enrollment" : "সেমিস্টার বরাদ্দকরণ"}
                                        directory="/students/semester-enrollment/enroll" />}
                                    <Divider />
                                </List>
                            </Collapse>
                        </>
                    }


                    {check(ROUTES.TEACHER.MANAGE.M, ROUTES.TEACHER.MANAGE.S, ROUTES.TEACHER.ADD, ROUTES.TEACHER.COURSE.MANAGE.M, ROUTES.TEACHER.COURSE.MANAGE.S, ROUTES.TEACHER.COURSE.ASSIGN) &&
                        <>
                            <ListItemButton onClick={() => handleClick("teachers")}>
                                <ListItemIcon>
                                    <FaChalkboardTeacher size={20} />
                                </ListItemIcon>
                                <ListItemText primary={lang === "en" ? "Teachers" : "শিক্ষকবৃন্দ"} />
                                {submenuControl.teachers ? <MdExpandMore /> : <MdExpandLess />}
                            </ListItemButton>
                            <Collapse in={submenuControl.teachers} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    {check(ROUTES.TEACHER.MANAGE.M, ROUTES.TEACHER.MANAGE.S) && <SidebarMenu onClose={handleClose} icon={""} menuTitle={lang === "en" ? "Manage Teachers" : "শিক্ষক ব্যবস্থাপনা"}
                                        directory="/teachers/manage" />}
                                    {check(ROUTES.TEACHER.ADD) && <SidebarMenu onClose={handleClose} icon={""} menuTitle={lang === "en" ? "Add Teacher" : "শিক্ষক সংযুক্তি"}
                                        directory="/teachers/add" />}
                                    {check(ROUTES.TEACHER.COURSE.MANAGE.M, ROUTES.TEACHER.COURSE.MANAGE.S) && <SidebarMenu onClose={handleClose} icon={""} menuTitle={lang === "en" ? "Manage Assigned Course" : "বরাদ্দকৃত কোর্স ব্যবস্থাপনা"}
                                        directory="/teachers/assigned-course-manage" />}
                                    {check(ROUTES.TEACHER.COURSE.ASSIGN) && <SidebarMenu onClose={handleClose} icon={""} menuTitle={lang === "en" ? "Assign Course" : "কোর্স বরাদ্দকরণ"}
                                        directory="/teachers/assign-course" />}
                                    <Divider />
                                </List>
                            </Collapse>
                        </>
                    }


                    {check(ROUTES.SUBJECT.MANAGE.M, ROUTES.SUBJECT.ADD) &&
                        <>
                            <ListItemButton onClick={() => handleClick("subject")}>
                                <ListItemIcon>
                                    <MdLibraryBooks size={20} />
                                </ListItemIcon>
                                <ListItemText primary={lang === "en" ? "Subjects" : "বিষয়সমূহ"} />
                                {submenuControl.subject ? <MdExpandMore /> : <MdExpandLess />}
                            </ListItemButton>
                            <Collapse in={submenuControl.subject} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    {check(ROUTES.SUBJECT.MANAGE.M) && <SidebarMenu onClose={handleClose} icon={""} menuTitle={lang === "en" ? "Manage Subjects" : "বিষয়সমূহ ব্যবস্থাপনা"}
                                        directory="/subject/manage" />}
                                    {check(ROUTES.SUBJECT.ADD) && <SidebarMenu onClose={handleClose} icon={""} menuTitle={lang === "en" ? "Add Subject" : "বিষয় সংযুক্তি"}
                                        directory="/subject/add" />}
                                    <Divider />
                                </List>
                            </Collapse>
                        </>
                    }


                    {check(ROUTES.COURSE.MANAGE.M, ROUTES.COURSE.ADD) &&
                        <>
                            <ListItemButton onClick={() => handleClick("courses")}>
                                <ListItemIcon>
                                    <ImBooks size={20} />
                                </ListItemIcon>
                                <ListItemText primary={lang === "en" ? "Courses" : "কোর্সসমূহ"} />
                                {submenuControl.courses ? <MdExpandMore /> : <MdExpandLess />}
                            </ListItemButton>
                            <Collapse in={submenuControl.courses} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    {check(ROUTES.COURSE.MANAGE.M) && <SidebarMenu onClose={handleClose} icon={""} menuTitle={lang === "en" ? "Manage Courses" : "কোর্স ব্যবস্থাপনা"}
                                        directory="/courses/manage" />}
                                    {check(ROUTES.COURSE.ADD) && <SidebarMenu onClose={handleClose} icon={""} menuTitle={lang === "en" ? "Add Course" : "কোর্স সংযুক্তি"}
                                        directory="/courses/add" />}
                                    {check(ROUTES.COURSE.MYCOURSE.M) &&
                                        <SidebarMenu onClose={handleClose} icon={""} menuTitle={lang === "en" ? "My Courses" : "আমার কোর্সসমূহ"}
                                            directory="/courses/my-courses" />
                                    }
                                    <Divider />
                                </List>
                            </Collapse>
                        </>
                    }

                    {check(ROUTES.BATCH.MANAGE.M, ROUTES.BATCH.ADD) &&
                        <>
                            <ListItemButton onClick={() => handleClick("batch")}>
                                <ListItemIcon>
                                    <AiOutlineFileSearch size={20} />
                                </ListItemIcon>
                                <ListItemText primary={lang === "en" ? "Batch" : "ব্যাচ"} />
                                {submenuControl.batch ? <MdExpandMore /> : <MdExpandLess />}
                            </ListItemButton>
                            <Collapse in={submenuControl.batch} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    {check(ROUTES.BATCH.MANAGE.M) && <SidebarMenu onClose={handleClose} icon={""} menuTitle={lang === "en" ? "Manage Batch" : "ব্যাচ ব্যবস্থাপনা"}
                                        directory="/batch/manage" />}
                                    {check(ROUTES.BATCH.ADD) && <SidebarMenu onClose={handleClose} icon={""} menuTitle={lang === "en" ? "Add Batch" : "ব্যাচ সংযুক্তি"}
                                        directory="/batch/add-batch" />}
                                </List>
                            </Collapse>
                        </>
                    }


                    <ListItemButton onClick={() => handleClick("classes")}>
                        <ListItemIcon>
                            <SiGoogleclassroom size={20} />
                        </ListItemIcon>
                        <ListItemText primary={lang === "en" ? "Classes" : "ক্লাসসমূহ"} />
                        {submenuControl.classes ? <MdExpandMore /> : <MdExpandLess />}
                    </ListItemButton>
                    <Collapse in={submenuControl.classes} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            {check(ROUTES.CLASS.MANAGE.M, ROUTES.CLASS.MANAGE.S) &&
                                <SidebarMenu onClose={handleClose} icon={""} menuTitle={lang === "en" ? "Manage Classes" : "ক্লাশ ব্যবস্থাপনা"}
                                    directory="/classes/manage" />}
                            {check(ROUTES.CLASS.ADD) && <SidebarMenu onClose={handleClose} icon={""} menuTitle={lang === "en" ? "Add Class" : "ক্লাস সংযুক্তি"}
                                directory="/classes/add" />}
                            {check(ROUTES.CLASS.ROUTINE) && <SidebarMenu onClose={handleClose} icon={""} menuTitle={lang === "en" ? "Class Routine" : "ক্লাশ রুটিন"}
                                directory="/classes/routine" />}
                            <Divider />
                        </List>
                    </Collapse>


                    {check(ROUTES.ATTENDANCE.MANAGE.M, ROUTES.ATTENDANCE.MANAGE.S, ROUTES.ATTENDANCE.ADD, ROUTES.ATTENDANCE.REPORT.MANAGE.M, ROUTES.ATTENDANCE.REPORT.MANAGE.S) &&
                        <>
                            <ListItemButton onClick={() => handleClick("attendance")}>
                                <ListItemIcon>
                                    <BsCalendar2CheckFill size={20} />
                                </ListItemIcon>
                                <ListItemText primary={lang === "en" ? "Attendance" : "উপস্থিতি"} />
                                {submenuControl.attendance ? <MdExpandMore /> : <MdExpandLess />}
                            </ListItemButton>
                            <Collapse in={submenuControl.attendance} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    {check(ROUTES.ATTENDANCE.MANAGE.M, ROUTES.ATTENDANCE.MANAGE.S) && <SidebarMenu onClose={handleClose} icon={""} menuTitle={lang === "en" ? "Manage Attendance" : "উপস্থিতি ব্যবস্থাপনা"}
                                        directory="/attendance/manage" />}
                                    {/* {check(ROUTES.ATTENDANCE.ADD) && <SidebarMenu onClose={handleClose} icon={""} menuTitle={lang === "en" ? "Student Wise Attendance" : "শিক্ষার্থী ভিত্তিক উপস্থিতি"}
                                        directory="/attendance/student-attendance" />} */}
                                    {check(ROUTES.ATTENDANCE.REPORT.MANAGE.M, ROUTES.ATTENDANCE.REPORT.MANAGE.S) && <SidebarMenu onClose={handleClose} icon={""} menuTitle={lang === "en" ? "Attendance Summary Report" : "উপস্থিতি রিপোর্টের সারসংক্ষেপ"}
                                        directory="/attendance/attendance-report" />}
                                    <Divider />
                                </List>
                            </Collapse>
                        </>
                    }


                    <ListItemButton onClick={() => handleClick("exam")}>
                        <ListItemIcon>
                            <FaPencilRuler size={20} />
                        </ListItemIcon>
                        <ListItemText primary={lang === "en" ? "Exams" : "পরীক্ষা"} />
                        {submenuControl.exam ? <MdExpandMore /> : <MdExpandLess />}
                    </ListItemButton>
                    <Collapse in={submenuControl.exam} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            {check(ROUTES.EXAM.MANAGE.M, ROUTES.EXAM.MANAGE.S) && <SidebarMenu onClose={handleClose} icon={""} menuTitle={lang === "en" ? "Manage Exams" : "পরীক্ষা ব্যবস্থাপনা"}
                                directory="/exam/manage" />}
                            {check(ROUTES.EXAM.ADD) && <SidebarMenu onClose={handleClose} icon={""} menuTitle={lang === "en" ? "Create Exam" : "পরীক্ষা তৈরি"}
                                directory="/exam/add" />}
                            {check(ROUTES.EXAM.ROUTINE) &&
                                <SidebarMenu onClose={handleClose} icon={""} menuTitle={lang === "en" ? "Exam Routine" : "পরীক্ষার রুটিন"}
                                    directory="/exam/routine" />}
                            <Divider />
                        </List>
                    </Collapse>


                    {/* <ListItemButton onClick={() => handleClick("avassignments")}>
                        <ListItemIcon>
                            <MdAssignment size={20} />
                        </ListItemIcon>
                        <ListItemText primary={lang === "en" ? "A/V Assignments" : "A/V অ্যাসাইনমেন্ট"} />
                        {submenuControl.avassignments ? <MdExpandMore /> : <MdExpandLess />}
                    </ListItemButton>
                    <Collapse in={submenuControl.avassignments} timeout="auto" unmountOnExit>
                        <List component="div" disabl2ePadding>
                            {user?.type !== 'student' &&
                                <>
                                    <SidebarMenu onClose={handleClose} icon={""} menuTitle={lang === "en" ? "A/V Assignments" : "অ্যাসাইনমেন্ট ব্যবস্থাপনা"}
                                        directory="/avassignments/manage" />
                                    <SidebarMenu onClose={handleClose} icon={""} menuTitle={lang === "en" ? "Assignment List" : "অ্যাসাইনমেন্ট তালিকা"}
                                        directory="/avassignments/assignment-list" />
                                    <SidebarMenu onClose={handleClose} icon={""} menuTitle={lang === "en" ? "Add Assignment" : "অ্যাসাইনমেন্ট যুক্ত করুন"}
                                        directory="/avassignments/add" />
                                </>}
                            {user?.type === 'student' &&
                                <SidebarMenu onClose={handleClose} icon={""} menuTitle={lang === "en" ? "Submit Assignment" : "অ্যাসাইনমেন্ট সাবমিট"}
                                    directory="/avassignments/assignment-submit" />}

                            <Divider />
                        </List>
                    </Collapse> */}


                    <ListItemButton onClick={() => handleClick("results")}>
                        <ListItemIcon>
                            <BsFillFileEarmarkMedicalFill size={20} />
                        </ListItemIcon>
                        <ListItemText primary={lang === "en" ? "Results" : "ফলাফল"} />
                        {submenuControl.results ? <MdExpandMore /> : <MdExpandLess />}
                    </ListItemButton>
                    <Collapse in={submenuControl.results} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            {check(ROUTES.RESULT.MANAGE.M, ROUTES.RESULT.MANAGE.S) && <SidebarMenu onClose={handleClose} icon={""} menuTitle={lang === "en" ? "Manage Results" : "ফলাফল ব্যবস্থাপনা"}
                                directory="/results/manage" />}
                            {check(ROUTES.RESULT.FINAL.M, ROUTES.RESULT.FINAL.S) && <SidebarMenu onClose={handleClose} icon={""} menuTitle={lang === "en" ? "Manage Final Result" : "চূড়ান্ত ফলাফল ব্যবস্থাপনা"}
                                directory="/results/final/manage" />}
                            {check(ROUTES.RESULT.CHECK_RESULT) && <SidebarMenu onClose={handleClose} icon={""} menuTitle={lang === "en" ? "Check Result" : "ফলাফল অনুসন্ধান"}
                                directory="/results/check-result" />
                            }
                            {check(ROUTES.RESULT.CGPA_CONFIG.M) && <SidebarMenu onClose={handleClose} icon={""} menuTitle={lang === "en" ? "CGPA Configuration" : "সিজিপিএ কনফিগারেশন"}
                                directory="/results/cgpa-config" />}
                            <Divider />
                        </List>
                    </Collapse>

                    {check(ROUTES.REQUEST.MANAGE.M, ROUTES.REQUEST.MANAGE.S, ROUTES.REQUEST.ADD) &&
                        <>
                            <ListItemButton onClick={() => handleClick("applications")}>
                                <ListItemIcon>
                                    <BsFillChatSquareQuoteFill size={20} />
                                </ListItemIcon>
                                <ListItemText primary={lang === "en" ? "Application Requests" : "আবেদন রিকুয়েস্টসমূহ"} />
                                {submenuControl.applications ? <MdExpandMore /> : <MdExpandLess />}
                            </ListItemButton>
                            <Collapse in={submenuControl.applications} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    {check(ROUTES.REQUEST.MANAGE.M, ROUTES.REQUEST.MANAGE.S) &&
                                        <SidebarMenu onClose={handleClose} icon={""}
                                            menuTitle={lang === "en" ? "Manage Requests" : "রিকুয়েস্ট ব্যবস্থাপনা"}
                                            directory="/applications/manage" />}
                                    {check(ROUTES.REQUEST.ADD) &&
                                        <SidebarMenu onClose={handleClose} icon={""}
                                            menuTitle={lang === "en" ? "New Request" : "নতুন রিকুয়েস্ট"}
                                            directory="/applications/new-request" />}

                                    <Divider />
                                </List>
                            </Collapse>
                        </>
                    }

                    {check(ROUTES.NOTICE.MANAGE.M, ROUTES.NOTICE.MANAGE.S, ROUTES.NOTICE.ADD) &&
                        <>
                            <ListItemButton onClick={() => handleClick("notices")}>
                                <ListItemIcon>
                                    <AiFillNotification size={20} />
                                </ListItemIcon>
                                <ListItemText primary={lang === "en" ? "Notices" : "নোটিশসমূহ"} />
                                {submenuControl.notices ? <MdExpandMore /> : <MdExpandLess />}
                            </ListItemButton>
                            <Collapse in={submenuControl.notices} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    {check(ROUTES.NOTICE.MANAGE.M, ROUTES.NOTICE.MANAGE.S) && <SidebarMenu onClose={handleClose} icon={""} menuTitle={lang === "en" ? "Manage Notice" : "নোটিশ ব্যবস্থাপনা"}
                                        directory="/notices/manage" />}
                                    {check(ROUTES.NOTICE.ADD) && <SidebarMenu onClose={handleClose} icon={""} menuTitle={lang === "en" ? "Create Notice" : "নোটিশ তৈরি"}
                                        directory="/notices/create" />}
                                    <Divider />
                                </List>
                            </Collapse>
                        </>
                    }


                    {/* {user?.type === "admin" &&
                        <>
                            <ListItemButton onClick={() => handleClick("news")}>
                                <ListItemIcon>
                                    <BsNewspaper size={20} />
                                </ListItemIcon>
                                <ListItemText primary={lang === "en" ? "News" : "নিউজ"} />
                                {submenuControl.news ? <MdExpandMore /> : <MdExpandLess />}
                            </ListItemButton>
                            <Collapse in={submenuControl.news} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    <SidebarMenu onClose={handleClose} icon={""} menuTitle={lang === "en" ? "Manage News" : "নিউজ ব্যবস্থাপনা"}
                                        directory="/news/manage-news" />
                                    <SidebarMenu onClose={handleClose} icon={""} menuTitle={lang === "en" ? "Add News" : "নিউজ সংযুক্তি"}
                                        directory="/news/add-news" />
                                    <Divider />
                                </List>
                            </Collapse>
                        </>
                    } */}

                    {/* {user?.type === "admin" &&
                        <>
                            <ListItemButton onClick={() => handleClick("publications")}>
                                <ListItemIcon>
                                    <FaBookReader size={20} />
                                </ListItemIcon>
                                <ListItemText primary={lang === "en" ? "Publications" : "প্রকাশনাসমুহ"} />
                                {submenuControl.publications ? <MdExpandMore /> : <MdExpandLess />}
                            </ListItemButton>
                            <Collapse in={submenuControl.publications} timeout="auto" unmountOnExit>
                                <List component="div" disabl2ePadding>
                                    <SidebarMenu onClose={handleClose} icon={""} menuTitle={lang === "en" ? "Manage Publications" : "প্রকাশনা ব্যবস্থাপনা"}
                                        directory="/publications/manage-publications" />
                                    <SidebarMenu onClose={handleClose} icon={""} menuTitle={lang === "en" ? "Add Publication" : "প্রকাশনা সংযুক্তি"}
                                        directory="/publications/add-publication" />
                                    <Divider />
                                </List>
                            </Collapse>
                        </>
                    } */}

                    {/* {user?.type === "admin" &&
                        <>
                            <ListItemButton onClick={() => handleClick("photoGallery")}>
                                <ListItemIcon>
                                    <MdPhotoLibrary size={20} />
                                </ListItemIcon>
                                <ListItemText primary={lang === "en" ? "Photos Gallery" : "ফটোগ্যালারি"} />
                                {submenuControl.photoGallery ? <MdExpandMore /> : <MdExpandLess />}
                            </ListItemButton>
                            <Collapse in={submenuControl.photoGallery} timeout="auto" unmountOnExit>
                                <List component="div" disabl2ePadding>
                                    <SidebarMenu onClose={handleClose} icon={""} menuTitle={lang === "en" ? "Albums" : "অ্যালবাম"}
                                        directory="/photo-gallery/albums" />
                                    <SidebarMenu onClose={handleClose} icon={""} menuTitle={lang === "en" ? "Add Album" : "অ্যালবাম যুক্ত করুন"}
                                        directory="/photo-gallery/add-album" />
                                    <SidebarMenu onClose={handleClose} icon={""} menuTitle={lang === "en" ? "Manage Photos" : "ফটো ব্যবস্থাপনা"}
                                        directory="/photo-gallery/manage-photos" />
                                    <SidebarMenu onClose={handleClose} icon={""} menuTitle={lang === "en" ? "Add Photo" : "ফটো যুক্ত করুন"}
                                        directory="/photo-gallery/add-photos" />
                                    <Divider />
                                </List>
                            </Collapse>
                        </>
                    } */}

                    {/* {user?.type === "admin" &&
                        <>
                            <ListItemButton onClick={() => handleClick("videoGallery")}>
                                <ListItemIcon>
                                    <MdVideoLibrary size={20} />
                                </ListItemIcon>
                                <ListItemText primary={lang === "en" ? "Videos Gallery" : "ভিডিওগ্যালারি"} />
                                {submenuControl.videoGallery ? <MdExpandMore /> : <MdExpandLess />}
                            </ListItemButton>
                            <Collapse in={submenuControl.videoGallery} timeout="auto" unmountOnExit>
                                <List component="div" disabl2ePadding>
                                    <SidebarMenu onClose={handleClose} icon={""} menuTitle={lang === "en" ? "Albums" : "অ্যালবাম"}
                                        directory="/video-gallery/albums" />
                                    <SidebarMenu onClose={handleClose} icon={""} menuTitle={lang === "en" ? "Add Album" : "অ্যালবাম যুক্ত করুন"}
                                        directory="/video-gallery/add-album" />
                                    <SidebarMenu onClose={handleClose} icon={""} menuTitle={lang === "en" ? "Manage Videos" : "ভিডিও ব্যবস্থাপনা"}
                                        directory="/video-gallery/manage-videos" />
                                    <SidebarMenu onClose={handleClose} icon={""} menuTitle={lang === "en" ? "Add Video" : "ভিডিও যুক্ত করুন"}
                                        directory="/video-gallery/add-video" />
                                    <Divider />
                                </List>
                            </Collapse>
                        </>
                    } */}

                    {/* <ListItemButton onClick={() => handleClick("evaluation")}>
                        <ListItemIcon>
                            <MdOutlineRateReview size={20} />
                        </ListItemIcon>
                        <ListItemText primary={lang === "en" ? "Evaluation" : "মূল্যায়ন"} />
                        {submenuControl.evaluation ? <MdExpandMore /> : <MdExpandLess />}
                    </ListItemButton>
                    <Collapse in={submenuControl.evaluation} timeout="auto" unmountOnExit>
                        <List component="div" disabl2ePadding>
                            {user?.type !== 'student' &&
                                <SidebarMenu onClose={handleClose} icon={""} menuTitle={lang === "en" ? "Manage Evaluation" : "মূল্যায়ন ব্যবস্থাপনা"}
                                    directory="/evaluation/manage" />}
                            {user?.type === 'admin' &&
                                <>
                                    <SidebarMenu onClose={handleClose} icon={""} menuTitle={lang === "en" ? "Evaluation Activation" : "মূল্যায়ন সক্রিয়করণ"}
                                        directory="/evaluation/activation" />
                                    <SidebarMenu onClose={handleClose} icon={""} menuTitle={lang === "en" ? "Manage Evaluation Questions" : "মূল্যায়নের প্রশ্ন ব্যবস্থাপনা"}
                                        directory="/evaluation/evaluation-questions" />
                                    <SidebarMenu onClose={handleClose} icon={""} menuTitle={lang === "en" ? "Add Evaluation Question" : "মূল্যায়নের প্রশ্ন যুক্ত করুন"}
                                        directory="/evaluation/question" />
                                </>}
                            {user?.type === 'student' &&
                                <SidebarMenu onClose={handleClose} icon={""} menuTitle={lang === "en" ? "Evaluate Teacher" : "শিক্ষকের মূল্যায়ন"}
                                    directory="/evaluation/course-evaluation" />}

                            <Divider />
                        </List>
                    </Collapse> */}

                    {check(ROUTES.DOCUMENT.MANAGE.M, ROUTES.DOCUMENT.MANAGE.S) &&
                        <SidebarMenu onClose={handleClose} directory="/documents/manage"
                            icon={<TbCertificate size={20} />}
                            menuTitle={lang === "en" ? "Documents" : "নথিপত্র"} />
                    }


                    {check(ROUTES.SETTINGS.STAFF.MANAGE.M, ROUTES.SETTINGS.STAFF.ADD, ROUTES.SETTINGS.ROLE.MANAGE.M, ROUTES.SETTINGS.ROLE.ADD, ROUTES.SETTINGS.PERMISSION.MANAGE.M) &&
                        <>
                            <ListItemButton onClick={() => handleClick("settings")}>
                                <ListItemIcon>
                                    <GoSettings size={20} />
                                </ListItemIcon>
                                <ListItemText primary={lang === "en" ? "Settings" : "সেটিংস"} />
                                {submenuControl.settings ? <MdExpandMore /> : <MdExpandLess />}
                            </ListItemButton>
                            <Collapse in={submenuControl.settings} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    {check(ROUTES.SETTINGS.STAFF.MANAGE.M) && <SidebarMenu onClose={handleClose} icon={""} menuTitle={lang === "en" ? "Manage Staffs" : "কর্মী ব্যবস্থাপনা"}
                                        directory="/settings/manage-staff" />}
                                    {check(ROUTES.SETTINGS.STAFF.ADD) && <SidebarMenu onClose={handleClose} icon={""} menuTitle={lang === "en" ? "Add Staff" : "নতুন কর্মী"}
                                        directory="/settings/add-staff" />}
                                    {check(ROUTES.SETTINGS.ROLE.MANAGE.M) && <SidebarMenu onClose={handleClose} icon={""} menuTitle={lang === "en" ? "Manage Roles" : "ভূমিকা ব্যবস্থাপনা"}
                                        directory="/settings/manage-role" />}
                                    {check(ROUTES.SETTINGS.ROLE.ADD) && <SidebarMenu onClose={handleClose} icon={""} menuTitle={lang === "en" ? "Add Role" : "নতুন ভূমিকা"}
                                        directory="/settings/add-role" />}
                                    {check(ROUTES.SETTINGS.PERMISSION.MANAGE.M) && <SidebarMenu onClose={handleClose} icon={""} menuTitle={lang === "en" ? "Permissions" : "অনুমতি"}
                                        directory="/settings/permissions" />}
                                    <Divider />
                                </List>
                            </Collapse>
                        </>
                    }

                    {check(ROUTES.NOTICEBOARD) && <SidebarMenu onClose={handleClose} directory="/noticeboard"
                        icon={<AiFillNotification size={20} />}
                        menuTitle={lang === "en" ? "Noticeboard" : "নোটিশবোর্ড"} />}
                    {check(ROUTES.HELP) && <SidebarMenu onClose={handleClose} directory="/help"
                        icon={<IoIosHelpBuoy size={20} />}
                        menuTitle={lang === "en" ? "Help" : "হেল্প"} />}

                </List>
            </Box>}
        </Drawer>
    )
}

export default Sidebar;