import Page from "../../Layout/Page";
import {
    IconButton,
    Paper, Table, TableBody, TableContainer, TableHead, TablePagination, TableRow,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { grey } from "@mui/material/colors";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { useState } from "react";
import * as Yup from "yup";
import { useAuth } from "../../hooks/useAuth";
import PageHeader from "../../Layout/PageHeader";
import FilterForm from "../../components/Filter/FilterForm";
import SelectField from "../../components/Fields/SelectField";
import TCell from "../../components/Table/TCell";
import ActionMenu from "../../components/Menus/ActionMenu";
import { BiDotsVerticalRounded } from "react-icons/bi";
import useGetAllCourse from "../../hooks/course/useGetAllCourse";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { deleteStudentEnroll, getStudentEnrollments, getStudentEnrollmentsSelf } from "../../api/fetcherFn/student";
import DeleteDialog from "../../components/HelperComponents/DeleteDialog";
import Loading from "../../Layout/Loading";
import usePermissions from "../../hooks/permission/usePermissions";
import { ROUTES } from "../Settings/permission_data";
import useGetCourseEnrolledStudents from "../../hooks/student/useGetCourseEnrolledStudents";

const validationSchema = Yup.object().shape({
    course_id: Yup.number().nullable(),
    batch_id: Yup.number().nullable(),
});

export default function ManageCourseEnrollment() {
    const { check } = usePermissions();
    const queryClient = useQueryClient();
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const [data, setData] = useState({});
    const [anchorEl, setAnchorEl] = useState(null);
    const [deleteDialog, setDeleteDialog] = useState(false);
    const open = Boolean(anchorEl);
    const { lang } = useAuth();

    const { control, handleSubmit, watch, setValue } = useForm({ resolver: yupResolver(validationSchema), mode: "onChange", });
    const course = watch('course_id');
    const batch = watch('batch_id');

    const allCourse = useGetAllCourse();
    const { data: studentCourses, isLoading } = useGetCourseEnrolledStudents(page, limit, course, batch);
    const { mutateAsync } = useMutation(() => deleteStudentEnroll(data.id), { onSuccess: () => { queryClient.invalidateQueries(['student-courses']); } });


    const handleClick = (event, row) => {
        setAnchorEl(event.currentTarget);
        setData(row)
    };

    const onSubmit = (data) => { };

    const courses = allCourse?.data?.response_data;

    return (
        <Page title={"Manage Course Enrollment"}>
            <PageHeader titleEn="Manage Course Enrollment" titleBn="কোর্স তালিকাভুক্তি ব্যবস্থাপনা"
                link={{ to: '/students/manage', textEn: "Manage Student", textBn: "শিক্ষার্থী ব্যবস্থাপনা" }} />

            <Paper elevation={0} sx={{ p: 2, minHeight: '75vh' }} className="rad-grad">
                <FilterForm handleSubmit={handleSubmit(onSubmit)} button={check(ROUTES.STUDENT.COURSE.ADD)} btnText={{ en: "Enroll Course", bn: "কোর্স তালিকাভুক্তি" }} navigate="/students/student-enrollment">
                    <SelectField
                        name="course_id"
                        control={control}
                        label={{ en: "Select Course", bn: "কোর্স নির্বাচন" }}
                        data={courses}
                        handelChange={() => {
                            setValue('batch_id', null);
                        }} />
                    <SelectField
                        name="batch_id"
                        control={control}
                        uniqueKey={course}
                        label={{ en: "Select Batch", bn: "ব্যাচ নির্বাচন" }}
                        data={courses && courses?.find(c => c.id === course)?.batches} />

                </FilterForm>


                {isLoading ? <Loading /> :
                    <>
                        <TableContainer sx={{ mt: 2, borderRadius: 1, border: `1px solid ${grey[100]}` }}>
                            <Table sx={{ minWidth: 650 }} size={"medium"}>
                                <TableHead sx={{ bgcolor: grey[100] }}>
                                    <TableRow>
                                        <TCell align="center" bold>#</TCell>
                                        <TCell bold>{lang === "en" ? "Course Name" : "কোর্সের নাম"}</TCell>
                                        <TCell bold>{lang === "en" ? "Batch" : "ব্যাচ"}</TCell>
                                        <TCell bold align="center">{lang === "en" ? "Registered Students" : "নিবন্ধিত ছাত্র সংখ্যা"}</TCell>
                                        <TCell bold>{lang === "en" ? "Enrollment Date & Time" : "তালিকাভুক্তির সময় ও তারিখ"}</TCell>
                                        <TCell align="center" bold>{lang === "en" ? "Action" : "অ্যাকশন"}</TCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {!studentCourses.success || studentCourses.response_data.data.length === 0 ?
                                        <TableRow sx={{ textAlign: "center" }}><TCell align="center" bold colSpan={7}>No record found.</TCell></TableRow> :
                                        studentCourses.response_data.data?.map((row, index) => (
                                            <TableRow key={index} hover>
                                                <TCell align="center">{index + 1}</TCell>
                                                <TCell>{row.course.name}</TCell>
                                                <TCell>{row.batch.name}</TCell>
                                                <TCell align="center">{row.students.length}</TCell>
                                                <TCell>{row.enrolled_at}</TCell>
                                                <TCell align="center"><IconButton
                                                    onClick={(e) => handleClick(e, row)}><BiDotsVerticalRounded /></IconButton></TCell>
                                            </TableRow>
                                        ))}

                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            component="div"
                            count={studentCourses?.response_data?.meta.data_found}
                            page={page}
                            onPageChange={(e, newPage) => {
                                setPage(newPage);
                            }}
                            rowsPerPage={limit}
                            onRowsPerPageChange={(e) => {
                                setLimit(parseInt(e.target.value, 10));
                                setPage(0);
                            }}
                        />
                    </>
                }
            </Paper>

            <ActionMenu
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
                open={open}
                viewUrl={check(ROUTES.STUDENT.COURSE.MANAGE.V) && `/students/student-enrollment/details/${data.id}`}
                editUrl={check(ROUTES.STUDENT.COURSE.MANAGE.E) && `/students/student-enrollment/edit/${data.id}`}
                handleDeleteDialog={check(ROUTES.STUDENT.COURSE.MANAGE.D) && (() => setDeleteDialog(true))} />

            {deleteDialog && <DeleteDialog handleDelete={async () => await mutateAsync(data.id)} dialog={deleteDialog} setDialog={setDeleteDialog} />}

        </Page>
    )
}