import Page from "../../Layout/Page";
import {
    IconButton,
    Paper,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
} from "@mui/material";
import { grey, } from "@mui/material/colors";
import { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as Yup from "yup";
import PageHeader from "../../Layout/PageHeader";
import Translate from "../../components/Translate/Translate";
import FilterForm from "../../components/Filter/FilterForm";
import TCell from "../../components/Table/TCell";
import { BiDotsVerticalRounded } from "react-icons/bi";
import ActionMenu from "../../components/Menus/ActionMenu";
import TextInput from "../../components/Fields/TextInput";
import { useForm } from "react-hook-form";
import Loading from "../../Layout/Loading";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deleteRole } from "../../api/fetcherFn/settings";
import DeleteDialog from "../../components/HelperComponents/DeleteDialog";
import useAllRoles from "../../hooks/settings/useAllRoles";
import usePermissions from "../../hooks/permission/usePermissions";
import { ROUTES } from "./permission_data";
import { toast } from "react-hot-toast";


const validationSchema = Yup.object().shape({
    search: Yup.string(),
});

export default function RoleManage() {
    const { check } = usePermissions();
    const queryClient = useQueryClient();
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const [openDialog, setOpenDialog] = useState(false);
    const [data, setData] = useState({});
    const { control, handleSubmit, watch } = useForm({ resolver: yupResolver(validationSchema), mode: "onChange", });
    const name = watch('search');
    const { roles, roleLoading } = useAllRoles(page, limit, name);

    const { mutateAsync } = useMutation(() => deleteRole(data.id), {
        onSuccess: (data) => {
            if (data.success) {
                queryClient.invalidateQueries(['roles']);
                toast.success(data.message);
            } else {
                toast.error(data.message);
            }
        }
    });
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (e, data) => {
        setAnchorEl(e.currentTarget);
        setData(data);
    };


    const onSubmit = (data) => {
        // console.log(data)
    };

    return (
        <Page title={"Role Manage"}>
            <PageHeader titleEn="Role Management" titleBn="ভূমিকা ব্যবস্থাপনা" />

            <Paper elevation={0} sx={{ p: 2, minHeight: '75vh' }} className="rad-grad">
                <FilterForm handleSubmit={handleSubmit(onSubmit)} navigate="/settings/add-role">
                    <TextInput
                        name="search"
                        control={control}
                        // label={{ en: "Search", bn: "খুজুন" }}
                        placeholder="Search by name..." />
                </FilterForm>

                {roleLoading ? <Loading /> :
                    <>
                        <TableContainer sx={{ mt: 2, borderRadius: 1, border: `1px solid ${grey[100]}` }}>
                            <Table sx={{ minWidth: 650 }} size={"medium"}>
                                <TableHead sx={{ bgcolor: grey[100] }}>
                                    <TableRow>
                                        <TCell bold align="center">#</TCell>
                                        <TCell bold><Translate en="Role" bn="ভূমিকা" /></TCell>
                                        <TCell bold><Translate en="Brief Description" bn="সংক্ষিপ্ত বিবরণ" /></TCell>
                                        <TCell bold align="center"><Translate en="Total Users" bn="সর্বমোট ইউজার" /></TCell>
                                        <TCell bold align="center"><Translate en="Action" bn="অ্যাকশন" /></TCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {!roles.success || roles.response_data.data.length === 0 ?
                                        <TableRow sx={{ textAlign: "center" }}><TCell align="center" bold colSpan={7}>Nothing found.</TCell></TableRow> :
                                        roles.response_data.data.map((row, i) => (
                                            <TableRow key={i} hover>
                                                <TCell align="center">{i + 1}</TCell>
                                                <TCell>{row?.name}</TCell>
                                                <TCell>{row.brief_description}</TCell>
                                                <TCell align="center">{row.users?.length}</TCell>
                                                <TCell align="center">
                                                    <IconButton onClick={(e) => handleClick(e, row)}><BiDotsVerticalRounded /></IconButton></TCell>
                                            </TableRow>
                                        ))}

                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            component="div"
                            count={roles?.response_data?.meta.data_found}
                            page={page}
                            onPageChange={(_, newPage) => {
                                setPage(newPage);
                            }}
                            rowsPerPage={limit}
                            onRowsPerPageChange={(e) => {
                                setLimit(parseInt(e.target.value, 10));
                                setPage(0);
                            }}
                        />
                    </>
                }
            </Paper>

            <ActionMenu
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
                open={open}
                editUrl={check(ROUTES.SETTINGS.ROLE.MANAGE.E) && `/settings/add-role/${data.id}`}
                handleDeleteDialog={!(['admin', 'teacher', 'student'].includes(data?.name?.toLowerCase())) && check(ROUTES.SETTINGS.ROLE.MANAGE.D) && (() => setOpenDialog(true))} />

            {openDialog && <DeleteDialog handleDelete={async () => await mutateAsync(data.id)} dialog={openDialog} setDialog={setOpenDialog} />}
        </Page>
    );
}