export const ROUTES = {
    STUDENT: {
        MANAGE: {
            M: '/api/student/manage', V: '/api/student/get', E: '/api/student/update', D: '/api/student/delete', S: '/api/student/manage/self'
        },
        ADD: '/api/student/add',
        COURSE: {
            MANAGE: {
                M: '/api/student-course/manage', V: '/api/student-course/get', E: '/api/student-course/update', D: '/api/student-course/delete', S: '/api/student-course/manage/self'
            },
            ADD: '/api/student-course/enroll'
        },
        SEMESTER: {
            MANAGE: {
                M: '/api/semester-enrollment/manage', V: '/api/semester-enrollment/get', E: '/api/semester-enrollment/update', D: '/api/semester-enrollment/delete',
                S: '/api/semester-enrollment/manage/self'
            },
            ADD: '/api/semester-enrollment/enroll',
        }
    },
    TEACHER: {
        MANAGE: {
            M: '/api/teacher/manage', V: '/api/teacher/get', E: '/api/teacher/update', D: '/api/teacher/delete', S: '/api/teacher/manage/self'
        },
        ADD: '/api/teacher/add',
        COURSE: {
            MANAGE: {
                M: '/api/teacher-course/manage', V: '/api/teacher-course/get', E: '/api/teacher-course/update', S: '/api/teacher-course/manage/self',
                D: '/api/teacher-course/delete',
            },
            ASSIGN: '/api/teacher-course/assign'
        }
    },
    SUBJECT: {
        MANAGE: {
            M: '/api/subject/manage', V: '/api/subject/get', E: '/api/subject/update', D: '/api/subject/delete'
        },
        ADD: '/api/subject/add'
    },
    COURSE: {
        MANAGE: {
            M: '/api/course/manage', V: '/api/course/get', E: '/api/course/update', D: '/api/course/delete'
        },
        ADD: '/api/course/add',
        MYCOURSE: {
            M: '/api/course/my-course', V: '/api/course/my-course/get'
        },
    },
    BATCH: {
        MANAGE: {
            M: '/api/batch/manage', V: '/api/batch/get', E: '/api/batch/update', D: '/api/batch/delete'
        },
        ADD: '/api/batch/add'
    },
    CLASS: {
        MANAGE: {
            M: '/api/class/manage', V: '/api/class/get', E: '/api/class/update', D: '/api/class/delete', N: '/api/class/notification', S: '/api/class/manage/self',
        },
        ADD: '/api/class/create',
        ROUTINE: '/api/class/routine',
    },
    ATTENDANCE: {
        MANAGE: {
            M: '/api/attendance/manage', E: '/api/attendance/update', S: '/api/attendance/manage/self'
        },
        ADD: '/api/attendance/create',
        UPDATE: '/api/attendance/update-many',
        REPORT: {
            MANAGE: {
                M: '/api/attendance/report/manage', S: '/api/attendance/report/manage/self'
            }
        }
    },
    EXAM: {
        MANAGE: {
            M: '/api/exam/manage', V: '/api/exam/get', E: '/api/exam/update', D: '/api/exam/delete', N: '/api/exam/notification',
            S: '/api/exam/manage/self'
        },
        ADD: '/api/exam/create',
        ROUTINE: '/api/exam/routine',
    },
    RESULT: {
        MANAGE: {
            M: '/api/result/manage', V: '/api/result/get', E: '/api/result/update', D: '/api/result/delete', S: '/api/result/manage/self'
        },
        ADD: '/api/result/create',
        FINAL: {
            M: '/api/final-result/manage', V: '/api/final-result/get', E: '/api/final-result/update', D: '/api/final-result/delete',
            S: '/api/final-result/manage/self'
        },
        FINAL_PUB: '/api/final-result/publish',
        CHECK_RESULT: '/api/result/check',
        CGPA_CONFIG: {
            M: '/api/result/cgpa-config/manage', E: '/api/result/cgpa-config/update'
        },
    },
    REQUEST: {
        MANAGE: {
            M: '/api/request/manage', V: '/api/request/get', A: '/api/request/approve', C: '/api/request/cancel',
            S: '/api/request/manage/self', D: '/api/request/delete'
        },
        ADD: '/api/request/new',
    },
    NOTICE: {
        MANAGE: {
            M: '/api/notice/manage', V: '/api/notice/get', E: '/api/notice/update', D: '/api/notice/delete', S: '/api/notice/manage/self'
        },
        ADD: '/api/notice/create',
    },
    DOCUMENT: {
        MANAGE: {
            M: '/api/request/documents/manage', S: '/api/request/documents/manage/self'
        }
    },
    SETTINGS: {
        STAFF: {
            MANAGE: {
                M: '/api/user/manage', V: '/api/user/get', E: '/api/user/update', D: '/api/user/delete',
                STATUS: '/api/user/update-status'
            },
            ADD: '/api/user/add',
        },
        ROLE: {
            MANAGE: {
                M: '/api/role/manage', V: '/api/role/get', E: '/api/role/update', D: '/api/role/delete'
            },
            ADD: '/api/role/add',
        },
        PERMISSION: {
            MANAGE: {
                M: '/api/permission/manage'
            },
            USER_SPECIFIC: '/api/permission/get'
        },

    },
    NOTICEBOARD: '/api/notice/noticeboard',
    HELP: '/api/help'
}





export const permission_data = [
    {
        module: 'Students',
        menus: [
            {
                name: 'Manage Students', action: [
                    { name: "Manage(Self)", route: ROUTES.STUDENT.MANAGE.S },
                    { name: "Manage(Global)", route: ROUTES.STUDENT.MANAGE.M },
                    { name: "View", route: ROUTES.STUDENT.MANAGE.V },
                    { name: "Edit", route: ROUTES.STUDENT.MANAGE.E },
                    { name: "Delete", route: ROUTES.STUDENT.MANAGE.D },
                ]
            },
            { name: 'Add Student', route: ROUTES.STUDENT.ADD },
            {
                name: 'Manage Course Enrollment', action: [
                    { name: "Manage(Self)", route: ROUTES.STUDENT.COURSE.MANAGE.S, },
                    { name: "Manage(Global)", route: ROUTES.STUDENT.COURSE.MANAGE.M, },
                    { name: "View", route: ROUTES.STUDENT.COURSE.MANAGE.V },
                    { name: "Edit", route: ROUTES.STUDENT.COURSE.MANAGE.E },
                    { name: "Delete", route: ROUTES.STUDENT.COURSE.MANAGE.D },
                ],
            },
            { name: 'Student Enrollment', route: ROUTES.STUDENT.COURSE.ADD },
            {
                name: 'Manage Semester Enrollment', action: [
                    { name: "Manage(Self)", route: ROUTES.STUDENT.SEMESTER.MANAGE.S },
                    { name: "Manage(Global)", route: ROUTES.STUDENT.SEMESTER.MANAGE.M },
                    { name: "View", route: ROUTES.STUDENT.SEMESTER.MANAGE.V },
                    { name: "Edit", route: ROUTES.STUDENT.SEMESTER.MANAGE.E },
                    { name: "Delete", route: ROUTES.STUDENT.SEMESTER.MANAGE.D },
                ],
            },
            { name: 'Semester Enrollment', route: ROUTES.STUDENT.SEMESTER.ADD },
        ]
    },
    {
        module: 'Teachers',
        menus: [
            {
                name: 'Manage Teachers', action: [
                    { name: "Manage(Self)", route: ROUTES.TEACHER.MANAGE.S },
                    { name: "Manage(Global)", route: ROUTES.TEACHER.MANAGE.M },
                    { name: "View", route: ROUTES.TEACHER.MANAGE.V },
                    { name: "Edit", route: ROUTES.TEACHER.MANAGE.E },
                    { name: "Delete", route: ROUTES.TEACHER.MANAGE.D },
                ]
            },
            { name: 'Add Teacher', route: ROUTES.TEACHER.ADD },
            {
                name: 'Manage Assigned Course', action: [
                    { name: "Manage(Self)", route: ROUTES.TEACHER.COURSE.MANAGE.S },
                    { name: "Manage(Global)", route: ROUTES.TEACHER.COURSE.MANAGE.M },
                    { name: "View", route: ROUTES.TEACHER.COURSE.MANAGE.V },
                    { name: "Edit", route: ROUTES.TEACHER.COURSE.MANAGE.E },
                    { name: "Delete", route: ROUTES.TEACHER.COURSE.MANAGE.D },
                ]
            },
            { name: 'Assign Course', route: ROUTES.TEACHER.COURSE.ASSIGN },
        ]
    },
    {
        module: 'Subjects',
        menus: [
            {
                name: 'Manage Subjects', action: [
                    { name: "Manage", route: ROUTES.SUBJECT.MANAGE.M },
                    { name: "View", route: ROUTES.SUBJECT.MANAGE.V },
                    { name: "Edit", route: ROUTES.SUBJECT.MANAGE.E },
                    { name: "Delete", route: ROUTES.SUBJECT.MANAGE.D },
                ]
            },
            { name: 'Add Subject', route: ROUTES.SUBJECT.ADD },
        ]
    },
    {
        module: 'Courses',
        menus: [
            {
                name: 'Manage Courses', action: [
                    { name: "Manage", route: ROUTES.COURSE.MANAGE.M },
                    { name: "View", route: ROUTES.COURSE.MANAGE.V },
                    { name: "Edit", route: ROUTES.COURSE.MANAGE.E },
                    { name: "Delete", route: ROUTES.COURSE.MANAGE.D },
                ]
            },
            { name: 'Add Course', route: ROUTES.COURSE.ADD },
        ]
    },
    {
        module: 'Batch',
        menus: [
            {
                name: 'Manage Batch', action: [
                    { name: "Manage", route: ROUTES.BATCH.MANAGE.M },
                    { name: "View", route: ROUTES.BATCH.MANAGE.V },
                    { name: "Edit", route: ROUTES.BATCH.MANAGE.E },
                    { name: "Delete", route: ROUTES.BATCH.MANAGE.D },
                ]
            },
            { name: 'Add Batch', route: ROUTES.BATCH.ADD },
        ]
    },
    {
        module: 'Classes',
        menus: [
            {
                name: 'Manage Classes', action: [
                    { name: "Manage(Self)", route: ROUTES.CLASS.MANAGE.S },
                    { name: "Manage(Global)", route: ROUTES.CLASS.MANAGE.M },
                    { name: "View", route: ROUTES.CLASS.MANAGE.V },
                    { name: "Edit", route: ROUTES.CLASS.MANAGE.E },
                    { name: "Delete", route: ROUTES.CLASS.MANAGE.D },
                    { name: "Notify", route: ROUTES.CLASS.MANAGE.N },
                ]
            },
            { name: 'Add Class', route: ROUTES.CLASS.ADD },
            { name: 'Class Routine', route: ROUTES.CLASS.ROUTINE },
        ]
    },
    {
        module: 'Attendance',
        menus: [
            {
                name: 'Manage Attendance', action: [
                    { name: "Manage(Self)", route: ROUTES.ATTENDANCE.MANAGE.S },
                    { name: "Manage(Global)", route: ROUTES.ATTENDANCE.MANAGE.M },
                    { name: "Edit", route: ROUTES.ATTENDANCE.MANAGE.E },
                    { name: "Edit All", route: ROUTES.ATTENDANCE.UPDATE },
                ]
            },
            { name: 'Create Attendance', route: ROUTES.ATTENDANCE.ADD },
            {
                name: 'Attendance Summary Report', action: [
                    { name: "Manage(Self)", route: ROUTES.ATTENDANCE.REPORT.MANAGE.S },
                    { name: "Manage(Global)", route: ROUTES.ATTENDANCE.REPORT.MANAGE.M },
                ]
            },
        ]
    },
    {
        module: 'Exams',
        menus: [
            {
                name: 'Manage Exam', action: [
                    { name: "Manage(Self)", route: ROUTES.EXAM.MANAGE.S },
                    { name: "Manage(Global)", route: ROUTES.EXAM.MANAGE.M },
                    { name: "View", route: ROUTES.EXAM.MANAGE.V },
                    { name: "Edit", route: ROUTES.EXAM.MANAGE.E },
                    { name: "Delete", route: ROUTES.EXAM.MANAGE.D },
                    { name: "Notify", route: ROUTES.EXAM.MANAGE.N },
                ]
            },
            { name: 'Create Exam', route: ROUTES.EXAM.ADD },
            { name: 'Exam Routine', route: ROUTES.EXAM.ROUTINE },
        ]
    },
    {
        module: 'Results',
        menus: [
            {
                name: 'Manage Results', action: [
                    { name: "Manage(Self)", route: ROUTES.RESULT.MANAGE.S },
                    { name: "Manage(Global)", route: ROUTES.RESULT.MANAGE.M },
                    { name: "View", route: ROUTES.RESULT.MANAGE.V },
                    { name: "Edit", route: ROUTES.RESULT.MANAGE.E },
                    { name: "Delete", route: ROUTES.RESULT.MANAGE.D },
                ]
            },
            { name: 'Add Result', route: ROUTES.RESULT.ADD },
            {
                name: 'Manage Final Result', action: [
                    { name: "Manage(Self)", route: ROUTES.RESULT.FINAL.S },
                    { name: "Manage(Global)", route: ROUTES.RESULT.FINAL.M },
                    { name: "View", route: ROUTES.RESULT.FINAL.V },
                    { name: "Edit", route: ROUTES.RESULT.FINAL.E },
                    { name: "Delete", route: ROUTES.RESULT.FINAL.D },
                ]
            },
            { name: 'Publish Final Result', route: ROUTES.RESULT.FINAL_PUB },
            {
                name: 'CGPA Configuation', action: [
                    { name: "Manage", route: ROUTES.RESULT.CGPA_CONFIG.M },
                    { name: "Edit", route: ROUTES.RESULT.CGPA_CONFIG.E },
                ]
            },
            { name: 'Check Result', route: ROUTES.RESULT.CHECK_RESULT },
        ]
    },
    {
        module: 'Appilcation Requests',
        menus: [
            {
                name: 'Manage Requests', action: [
                    { name: "Manage(Self)", route: ROUTES.REQUEST.MANAGE.S },
                    { name: "Manage(Global)", route: ROUTES.REQUEST.MANAGE.M },
                    { name: "View", route: ROUTES.REQUEST.MANAGE.V },
                    { name: "Approve", route: ROUTES.REQUEST.MANAGE.A },
                    { name: "cancel", route: ROUTES.REQUEST.MANAGE.C },
                    { name: "Delete", route: ROUTES.REQUEST.MANAGE.D },
                ]
            },
            { name: 'New Request', route: ROUTES.REQUEST.ADD },
        ]
    },
    {
        module: 'Notices',
        menus: [
            {
                name: 'Manage Notices', action: [
                    { name: "Manage(Self)", route: ROUTES.NOTICE.MANAGE.S },
                    { name: "Manage(Global)", route: ROUTES.NOTICE.MANAGE.M },
                    { name: "View", route: ROUTES.NOTICE.MANAGE.V },
                    { name: "Edit", route: ROUTES.NOTICE.MANAGE.E },
                    { name: "Delete", route: ROUTES.NOTICE.MANAGE.D },
                ]
            },
            { name: 'Create Notice', route: ROUTES.NOTICE.ADD }
        ]
    },
    {
        module: 'Documents',
        menus: [
            {
                name: 'Manage Documents', action: [
                    { name: "Manage(Self)", route: ROUTES.DOCUMENT.MANAGE.S },
                    { name: "Manage(Global)", route: ROUTES.DOCUMENT.MANAGE.M },
                ]
            }
        ]
    },
    {
        module: 'Settings',
        menus: [
            {
                name: 'Manage Staffs', action: [
                    { name: "Manage", route: ROUTES.SETTINGS.STAFF.MANAGE.M },
                    { name: "View", route: ROUTES.SETTINGS.STAFF.MANAGE.V },
                    { name: "Edit", route: ROUTES.SETTINGS.STAFF.MANAGE.E },
                    { name: "Account Status", route: ROUTES.SETTINGS.STAFF.MANAGE.STATUS },
                    { name: "Delete", route: ROUTES.SETTINGS.STAFF.MANAGE.D },
                ]
            },
            { name: 'Add Staff', route: ROUTES.SETTINGS.STAFF.ADD },
            {
                name: 'Manage Roles', action: [
                    { name: "Manage", route: ROUTES.SETTINGS.ROLE.MANAGE.M },
                    { name: "View", route: ROUTES.SETTINGS.ROLE.MANAGE.V },
                    { name: "Edit", route: ROUTES.SETTINGS.ROLE.MANAGE.E },
                    { name: "Delete", route: ROUTES.SETTINGS.ROLE.MANAGE.D },
                ]
            },
            { name: 'Add Role', route: ROUTES.SETTINGS.ROLE.ADD },
            { name: 'Permissions', route: ROUTES.SETTINGS.PERMISSION.MANAGE.M },
            { name: "User Specific Permissions", route: ROUTES.SETTINGS.PERMISSION.USER_SPECIFIC },
        ]
    },
    {
        module: 'Extras',
        menus: [
            { name: 'Noticeboard', route: ROUTES.NOTICEBOARD },
            { name: 'Help', route: ROUTES.HELP },
        ]
    },
];


