import Page from "../../Layout/Page";
import { useParams } from "react-router-dom";
import PageHeader from "../../Layout/PageHeader";
import TRow from "../../components/Table/TRow";
import Translate from "../../components/Translate/Translate";
import React, { useRef } from 'react';
import Loading from "../../Layout/Loading";
import { Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import TCell from "../../components/Table/TCell";
import { grey, } from "@mui/material/colors";
import { IoMdPrint } from "react-icons/io";
import useGetAStudentEnroll from "../../hooks/student/useGetAStudentEnroll";
import { useReactToPrint } from "react-to-print";


const EnrollmentDetails = () => {
  const { id } = useParams();
  const { data, isLoading } = useGetAStudentEnroll(id);
  const enrolment = data?.response_data;
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Course Enrollment Details ${enrolment?.course?.name}`,
  });


  return (
    <Page title="Course Enrollment Details">
      <PageHeader titleEn="Course Enrollment Details" titleBn="শিক্ষার্থীর তালিকাভুক্তির বিস্তারিত"
        link={{ to: '/students/manage-enrollment', textEn: "Manage Enrollment", textBn: "তালিকাভুক্তি ব্যবস্থাপনা" }} />

      {isLoading ? <Loading /> :
        <Stack component={Paper} px={{ md: 4 }} sx={{ py: 2, maxWidth: 900, margin: '0 auto' }} className="rad-grad">
          <div style={{ width: '100%', display: 'inline-flex', justifyContent: 'flex-end' }}>
            <IoMdPrint onClick={handlePrint} size={25} color="gray" cursor="pointer" />
          </div>

          <Stack ref={componentRef} p={{ md: 4, xs: 2 }} gap={4}>
            <TRow data={[
              { label: { en: "Course ID", bn: "কোর্সের আইডি" }, value: enrolment.course.id },
              { label: { en: "Course Name", bn: "কোর্সের নাম" }, value: enrolment.course.name },
              { label: { en: "Batch Name", bn: "ব্যাচের নাম" }, value: enrolment.batch.name },
              { label: { en: "Enrolled at", bn: "তালিকাভুক্তির সময়" }, value: enrolment.enrolled_at },
            ]} />



            {enrolment?.students && <TableContainer sx={{ mt: 2, borderRadius: 1, border: `1px solid ${grey[100]}` }}>
              <TableCell sx={{ fontSize: '1.2rem', fontWeight: 'bold', border: 0, px: 0, py: 1 }}><Translate en="Enrolled Students" bn="এনরোল্ড শিক্ষার্থীগণ" /></TableCell>
              <Table sx={{ minWidth: 650, borderRadius: 1, border: `1px solid ${grey[100]}` }} size="medium">
                <TableHead sx={{ bgcolor: grey[100] }}>
                  <TableRow>
                    <TCell bold align="center">#</TCell>
                    <TCell bold><Translate en={"Student ID"} bn={"শিক্ষার্থীর আইডি"} /></TCell>
                    <TCell bold> <Translate en={"Student Name"} bn={"শিক্ষার্থীর নাম"} /> </TCell>
                    <TCell bold><Translate en={"Email Address"} bn={"ই-মেইল"} /> </TCell>
                    <TCell bold><Translate en={"Phone"} bn={"ফোন নাম্বার"} /></TCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {enrolment?.students.map((data, i) => (
                    <TableRow key={i}>
                      <TCell align="center">{i + 1}</TCell>
                      <TCell>{data._id}</TCell>
                      <TCell>{data.name}</TCell>
                      <TCell>{data.email}</TCell>
                      <TCell>{data.phone}</TCell>
                    </TableRow>
                  ))}


                </TableBody>
              </Table>
            </TableContainer>}


          </Stack>
        </Stack>
      }


    </Page>
  );
}

export default EnrollmentDetails;