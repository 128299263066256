import { useQuery } from '@tanstack/react-query';
import { getExams, getExamsSelf } from '../../api/fetcherFn/exam';
import usePermissions from '../permission/usePermissions';
import { ROUTES } from '../../Pages/Settings/permission_data';

const useExams = (page = 0, limit = 0, course, batch, exam = null, semester, subject = null) => {
    const { check } = usePermissions();
    return useQuery(['exams', page + 1, limit, course, batch, exam, semester, subject],
        check(ROUTES.EXAM.MANAGE.M) ? () => getExams(page + 1, limit, course, batch, exam, semester, subject) : () => getExamsSelf(page + 1, limit, course, batch, exam, semester, subject), {
        keepPreviousData: true,
    });
};

export default useExams;