import Page from "../../Layout/Page";
import {
    Paper,
    Stack,
    Typography,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as Yup from "yup";
import PageHeader from "../../Layout/PageHeader";
import AddForm from "../../components/Filter/AddForm";
import TextInput from "../../components/Fields/TextInput";
import MultilineInput from "../../components/Fields/MultilineInput";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-hot-toast";
import useCourse from "../../hooks/course/useCourse";
import { addCourse, updateCourse } from "../../api/fetcherFn/course";
import Loading from "../../Layout/Loading";
import SelectField from "../../components/Fields/SelectField";
import MultipleSelectField from "../../components/Fields/MultipleSelectField";
import Translate from "../../components/Translate/Translate";
import DropdownField from "../../components/Fields/DropdownField";
import useSubjects from "../../hooks/notice-subject/useSubjects";

const validationSchema = Yup.object().shape({
    type: Yup.string().nullable().required("Required"),
    total_semester: Yup.number().positive().transform(v => !isNaN(v) ? v : 0).when('type', {
        is: 'long',
        then: Yup.number().positive().min(1, "Should At least 1 Semester.").transform(v => !isNaN(v) ? v : 0),
        otherwise: Yup.number().positive().transform(v => !isNaN(v) ? v : 0),
    }),
    name: Yup.string().required("Course Name is required"),
    total_credit: Yup.number()
        .nullable()
        .transform(v => !isNaN(v) ? v : null)
        .required("Required"),
    duration: Yup.number()
        .nullable()
        .transform(v => !isNaN(v) ? v : null)
        .required("Required"),
    description: Yup.string(),
    course_fee: Yup.number().nullable().transform(v => !isNaN(v) ? v : null).required("Required"),
    syllabus: Yup.string().required("Syllabus Description is required"),
    subjects: Yup.array().when('type', {
        is: 'short',
        then: Yup.array().required("Required"),
        otherwise: Yup.array(),
    }),
    semester_duration: Yup.number().nullable().transform(v => !isNaN(v) ? v : null).when('type', {
        is: 'long',
        then: Yup.number().nullable().transform(v => !isNaN(v) ? v : null).required("Required"),
        otherwise: Yup.number().nullable().transform(v => !isNaN(v) ? v : null),
    }),
    per_semester_fee: Yup.number().nullable().transform(v => !isNaN(v) ? v : null).when('type', {
        is: 'long',
        then: Yup.number().nullable().transform(v => !isNaN(v) ? v : null).required("Required"),
        otherwise: Yup.number().nullable().transform(v => !isNaN(v) ? v : null),
    }),
    ...Array.from({ length: 2 }, (_, i) => i + 1).reduce((rules, semester) => {
        const field = `semester_${semester}_subjects`;
        rules[field] = Yup.array().when(["type", "total_semester"], {
            is: (type, total_semester) =>
                type === "long" && semester <= total_semester,
            then: Yup.array().min(1, "Select at least one subject").required("Required."),
            otherwise: Yup.array(),
        });
        return rules;
    }, {}),
});


const courseType = [
    { id: 'short', name: "Short Term Course" },
    { id: 'long', name: "Long Term Course" },
];

export default function EditCourse() {
    const queryClient = useQueryClient();
    const params = useParams();
    const id = parseInt(params.id, 10);
    const navigate = useNavigate();
    const subjects = useSubjects();

    const { data: course, isLoading } = useCourse(id);
    const { control, handleSubmit, watch, reset, setValue } = useForm({ resolver: yupResolver(validationSchema), mode: "onChange", });
    const type = watch('type');
    const totalSemester = parseInt(watch('total_semester'), 10) || course?.response_data?.total_semester;


    const add = useMutation(data => addCourse(data), {
        onSuccess: (data) => {
            if (data.success) {
                toast.success(data.message);
                navigate('/courses/manage');
            } else {
                toast.error(data.message);
            }
        },
        onError: (err) => {
            toast.error(err.message);
        }
    });
    const update = useMutation(data => updateCourse(id, data), {
        onSuccess: (data) => {
            if (data.success) {
                toast.success(data.message);
                queryClient.invalidateQueries('course');
                navigate('/courses/manage');
            } else {
                toast.error(data.message);
            }
        },
        onError: (err) => {
            toast.error(err.message);
        }
    });



    const onSubmit = async (data) => {
        let { type, total_semester, name, course_fee, description, duration, syllabus, subjects, total_credit, per_semester_fee, semester_duration, ...rest } = data
        let semester_wise_subjects = [];
        for (const key in rest) {
            const index = parseInt(key.split('_')[1], 10);
            const semester = {
                semester: index,
                subjects: rest[key]
            }
            semester_wise_subjects.push(semester)
        }
        if (type === 'short') {
            total_semester = undefined;
            per_semester_fee = undefined;
            semester_duration = undefined;
            semester_wise_subjects = null;
            per_semester_fee = undefined;
            subjects = subjects?.map(subject => ({ id: subject }));
        } else {
            subjects = undefined;
        }
        const _semester_wise_subjects = semester_wise_subjects && semester_wise_subjects.sort((a, b) => a.semester - b.semester);
        const _data = { type, total_semester, name, course_fee, description, duration, syllabus, subjects, total_credit, per_semester_fee, semester_duration, semester_wise_subjects: _semester_wise_subjects };

        if (id) {
            await update.mutateAsync(_data);
            return;
        }
        await add.mutateAsync(_data);
    }


    return (
        <Page title={`Edit Course`}>
            <PageHeader titleEn={`Edit Course`} titleBn={`কোর্স তথ্য পরিবর্তন`}
                link={{ to: '/courses/manage', textEn: "Manage Courses", textBn: "কোর্স ব্যবস্থাপনা" }} />


            {isLoading ? <Loading /> : <AddForm handleSubmit={handleSubmit(onSubmit)} loading={add.isLoading || update.isLoading} reset={reset}>
                <SelectField
                    name="type"
                    control={control}
                    subLabel={false}
                    defaultValue={course?.response_data?.type}
                    data={courseType}
                    handelChange={() => {
                        setValue('total_semester', undefined);
                        setValue('semester_duration', undefined);
                        setValue('per_semester_fee', undefined);
                    }}
                    label={{ en: "Select Course Type", bn: "কোর্সের ধরন নির্বাচন" }}
                    errorMsg fontBold={false} required />


                {(type === 'long') && <Stack direction="row" gap={1} >
                    <DropdownField
                        name="total_semester"
                        control={control}
                        totalValue={12}
                        value={course?.response_data?.total_semester}
                        label={{ en: "Total Semester", bn: "সর্বমোট সেমিস্টার" }}
                        errorMsg fontBold={false} required />

                    <TextInput
                        name="semester_duration"
                        type="number"
                        min={1}
                        control={control}
                        value={course?.response_data?.semester_duration}
                        label={{ en: "Semester Duration(In months)", bn: "সেমিস্টারের মেয়াদকাল (মাসে)" }}
                        placeholder="e.g. 4"
                        errorMsg fontBold={false} required />
                </Stack>}


                <TextInput
                    name="name"
                    control={control}
                    value={course?.response_data?.name}
                    label={{ en: "Course Name", bn: "কোর্সের নাম" }}
                    placeholder="Course name..."
                    errorMsg fontBold={false} required />

                <Stack direction="row" gap={1}>
                    <TextInput
                        name="duration"
                        type="number"
                        min={1}
                        control={control}
                        value={course?.response_data?.duration}
                        label={{ en: "Duration (In months)", bn: "মেয়াদকাল (মাসে)" }}
                        placeholder="e.g. 12"
                        errorMsg fontBold={false} required />
                    <TextInput
                        name="total_credit"
                        type="number"
                        min={1}
                        control={control}
                        value={course?.response_data?.total_credit}
                        label={{ en: "Total Credit", bn: "সর্বমোট ক্রেডিট" }}
                        placeholder="e.g. 80"
                        errorMsg fontBold={false} required />
                </Stack>

                <MultilineInput
                    name="description"
                    control={control}
                    value={course?.response_data?.description}
                    label={{ en: "Course Description", bn: "কোর্সের বিস্তারিত" }}
                    errorMsg />

                <TextInput
                    name="course_fee"
                    type="number"
                    min={0}
                    control={control}
                    value={course?.response_data?.course_fee}
                    label={{ en: "Total Course Fee", bn: "সর্বমোট কোর্স ফি" }}
                    placeholder="e.g. 10000"
                    errorMsg fontBold={false} required />

                {watch('type') === 'long' && < TextInput
                    name="per_semester_fee"
                    type="number"
                    min={0}
                    control={control}
                    value={course?.response_data?.per_semester_fee}
                    label={{ en: "Per Semester Fee", bn: "সেমিস্টারের ভিত্তিক ফি" }}
                    placeholder="e.g. 2000"
                    errorMsg fontBold={false} required />}


                <MultilineInput
                    name="syllabus"
                    control={control}
                    value={course?.response_data?.syllabus}
                    label={{ en: "Course Syllabus", bn: "কোর্সের সিলেবাস" }}
                    errorMsg required />

                {type === 'short' &&
                    <MultipleSelectField
                        name="subjects"
                        control={control}
                        subLabel={false}
                        subValue={'subject_code'}
                        defaultValue={course?.response_data?.subjects}
                        data={subjects?.data?.response_data?.data}
                        label={{ en: 'Select Subjects', bn: 'বিষয় নির্বাচন' }}
                        errorMsg fontBold={false} required />
                }

                {(type === 'long') && totalSemester > 0 &&
                    <>
                        <Typography fontWeight="bold" mt={4} mb={1.2}><Translate en="Semester Wise Subject Selection:" bn="সেমিস্টার ভিত্তিক বিষয় নির্বাচনঃ" /></Typography>
                        {[...Array(totalSemester).keys()].map((d, i) => <Stack key={i} gap={1} mb={1}>
                            <MultipleSelectField
                                name={`semester_${i + 1}_subjects`}
                                control={control}
                                defaultValue={course?.response_data?.semester_wise_subjects?.find(item => item.semester === i + 1)?.subjects}
                                data={subjects?.data?.response_data?.data}
                                label={{ en: `${i + 1}. Semester ${i + 1} (Select Subject)`, bn: `${i + 1}. সেমিস্টার ${i + 1} (বিষয় নির্বাচন)` }}
                                errorMsg fontBold={false} required />
                        </Stack>)}
                    </>
                }

            </AddForm>}

        </Page>
    )
}