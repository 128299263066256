import Page from "../../Layout/Page";
import {
    Stack, Typography,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as Yup from "yup";
import PageHeader from "../../Layout/PageHeader";
import AddForm from "../../components/Filter/AddForm";
import TextInput from "../../components/Fields/TextInput";
import SelectField from "../../components/Fields/SelectField";
import { exam_type } from "../../_mockData/exams";
import MultipleSelectField from "../../components/Fields/MultipleSelectField";
import MultilineInput from "../../components/Fields/MultilineInput";
import { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import useGetAllCourse from "../../hooks/course/useGetAllCourse";
import useExam from "../../hooks/exam/useExam";
import { toast } from "react-hot-toast";
import { updateExam } from "../../api/fetcherFn/exam";
import Loading from "../../Layout/Loading";
import { getSemesterEnrollments } from "../../api/fetcherFn/student";
import useTeachers from "../../hooks/teacher/useTeachers";
import { getTodaysDate } from "../../utils/constants";
import usePermissions from "../../hooks/permission/usePermissions";
import { ROUTES } from "../Settings/permission_data";
import { getSemesterEnrollmentsSelf } from "../../api/fetcherFn/student";



export default function ExamEdit() {
    const { check } = usePermissions();
    const queryClient = useQueryClient();
    const params = useParams();
    const id = parseInt(params.id, 10);
    const navigate = useNavigate();
    const [selectDefaultValue, setSelectDefaultValue] = useState("Not Null");

    const allCourse = useGetAllCourse();
    const validationSchema = Yup.object().shape({
        course_id: Yup.number()
            .nullable()
            .transform(v => !isNaN(v) ? v : null).required("Required"),
        batch_id: Yup.number()
            .nullable()
            .transform(v => !isNaN(v) ? v : null).required("Required"),
        // Semester would be optional on Condition => if there is semester_wise_subjects in course 
        semester_number: Yup.number().when('course_id', (courseId, schema) => {
            const course = allCourse.data?.response_data?.find(c => c.id === courseId);
            return course?.semester_wise_subjects?.length > 0
                ? schema.nullable().transform(v => !isNaN(v) ? v : null).required('Required.')
                : schema.nullable().transform(v => !isNaN(v) ? v : null);
        }),
        subject_id: Yup.number()
            .nullable()
            .transform(v => !isNaN(v) ? v : null).required("Required"),
        title: Yup.string().required("Required"),
        type: Yup.string()
            .nullable().required("Required"),
        exam_date: Yup.string()
            .required("Required"),
        total_mark: Yup.number()
            .nullable()
            .transform(v => !isNaN(v) ? v : null)
            .required("Required"),
        total_time: Yup.number()
            .nullable()
            .transform(v => !isNaN(v) ? v : null)
            .required("Required"),
        start_time: Yup.string().required("Required"),
        end_time: Yup.string().required("Required"),
        place: Yup.string().required("Required"),
        description: Yup.string(),
        participants: Yup.array().min(1, "Required"),
        examiners: Yup.array(),
    });

    const { control, handleSubmit, watch, setValue } = useForm({ resolver: yupResolver(validationSchema), mode: "onChange", });

    const cId = watch('course_id');
    const bId = watch('batch_id');
    const semesterNum = watch('semester_number');

    const { data, isLoading } = useExam(id);
    const exam = data?.response_data;

    const { data: studentSemesterEnrolled, isLoading: studentLoading } = useQuery(['semester-enrollments', 1, 0, cId, bId, semesterNum],
        check(ROUTES.STUDENT.SEMESTER.MANAGE.M) ? () => getSemesterEnrollments(1, 0, cId, bId, semesterNum) : () => getSemesterEnrollmentsSelf(1, 0, cId, bId, semesterNum), {
        enabled: !!cId && !!bId
    });

    const teachersData = useTeachers();

    const update = useMutation(data => updateExam(id, data), {
        onSuccess: (data) => {
            if (data.success) {
                toast.success(data.message);
                queryClient.invalidateQueries('exam');
                navigate('/exam/manage');
            } else {
                toast.error(data.message);
            }
        },
        onError: (err) => {
            toast.error(err.message);
        }
    });

    const onSubmit = async (data) => {
        data.participants = data.participants?.map(id => ({ id: id }));
        data.examiners = data.examiners?.map(id => ({ id: id }));
        await update.mutateAsync(data);
    }


    const courses = allCourse?.data?.response_data;
    const course = exam?.course.id;
    const batches = courses && courses?.find(c => c.id === (cId || course))?.batches;
    const semesters = courses && courses?.find(c => c.id === (cId || course))?.semester_wise_subjects;
    let subjects = courses && (courses?.find(c => c.id === (cId || course))?.semester_wise_subjects?.find(s => s.semester === (semesterNum || exam?.semester_number))?.subjects
        || courses?.find(c => c.id === (cId || course))?.subjects);


    const students = studentSemesterEnrolled?.response_data?.data?.flatMap(d => d.students);
    const uniqueStudents = students?.filter((student, index, self) => index === self.findIndex((t) => t.id === student.id));
    const teachers = teachersData?.data?.response_data?.data?.flatMap(d => d.user);



    useEffect(() => {
        if (id) {
            setValue('course_id', course);
        }
    }, [id, setValue, course,])



    return (
        <Page title={"Edit Exam"}>
            <PageHeader titleEn="Edit Exam" titleBn="পরীক্ষার তথ্য পরিবর্তন"
                link={{ to: '/exam/manage', textEn: "Manage Exams", textBn: "পরীক্ষা ব্যবস্থাপনা" }} />


            {isLoading ? <Loading /> : <AddForm handleSubmit={handleSubmit(onSubmit)} loading={update.isLoading}>

                <SelectField
                    name="course_id"
                    control={control}
                    isLoading={allCourse.isLoading}
                    defaultValue={exam?.course?.id}
                    data={courses}
                    handelChange={() => {
                        setValue('batch_id', null);
                        setValue('subject_id', null);
                        setValue('semester_number', null);
                        setSelectDefaultValue(null);
                    }}
                    label={{ en: "Select Course", bn: "কোর্স নির্বাচন" }}
                    errorMsg fontBold={false} required />

                <Stack direction="row" gap={1}>
                    <SelectField
                        name="batch_id"
                        control={control}
                        defaultValue={exam?.batch?.id}
                        data={batches}
                        uniqueKey={cId}
                        label={{ en: "Select Batch", bn: "ব্যাচ নির্বাচন" }}
                        errorMsg fontBold={false} required />
                    {semesters && <SelectField
                        name="semester_number"
                        control={control}
                        subLabel={false}
                        uniqueKey={cId}
                        defaultValue={selectDefaultValue && exam?.semester_number}
                        data={semesters?.map(option => ({ id: option.semester, name: `Semester ${option.semester}` }))}
                        handelChange={() => {
                            setValue('subject_id', null);
                        }}
                        label={{ en: "Select Semester", bn: "সেমিস্টার নির্বাচন" }}
                        errorMsg fontBold={false} required />}
                </Stack>

                {subjects && <SelectField
                    name="subject_id"
                    control={control}
                    uniqueKey={cId}
                    defaultValue={selectDefaultValue && exam?.subject.id}
                    subLabel={false}
                    subValue={'subject_code'}
                    data={subjects && subjects}
                    label={{ en: "Select Subject", bn: "বিষয় নির্বাচন" }}
                    errorMsg fontBold={false} required />}


                <TextInput
                    name="title"
                    control={control}
                    value={exam?.title}
                    label={{ en: "Exam Title", bn: "পরীক্ষার টাইটেল" }}
                    errorMsg fontBold={false} required />

                <Stack direction="row" gap={2}>
                    <SelectField
                        name="type"
                        control={control}
                        subLabel={false}
                        defaultValue={exam?.type}
                        data={exam_type}
                        label={{ en: "Exam Type", bn: "পরীক্ষার ধরন" }}
                        errorMsg fontBold={false} required />
                    <TextInput
                        name="exam_date"
                        type="date"
                        control={control}
                        min={getTodaysDate}
                        value={exam?.exam_date}
                        label={{ en: "Exam Date", bn: "পরীক্ষার তারিখ" }}
                        errorMsg fontBold={false} required />
                </Stack>
                <Stack direction="row" gap={2}>
                    <TextInput
                        name="total_mark"
                        type="number"
                        control={control}
                        value={exam?.total_mark}
                        label={{ en: "Exam Total Marks", bn: "পরীক্ষার মোট নম্বর" }}
                        placeholder="e.g. 100"
                        errorMsg fontBold={false} required />
                    <TextInput
                        name="total_time"
                        type="number"
                        control={control}
                        value={exam?.total_time}
                        label={{ en: "Exam Total Time (Minutes)", bn: "পরীক্ষার মোট সময় (মিনিট)" }}
                        placeholder="e.g. 60"
                        errorMsg fontBold={false} required />
                </Stack>
                <Stack direction="row" gap={2}>
                    <TextInput
                        name="start_time"
                        type="time"
                        control={control}
                        value={exam?.start_time}
                        label={{ en: "Start Time", bn: "শুরুর সময়" }}
                        errorMsg fontBold={false} required />
                    <TextInput
                        name="end_time"
                        type="time"
                        control={control}
                        value={exam?.end_time}
                        label={{ en: "End Time", bn: "শেষের সময়" }}
                        errorMsg fontBold={false} required />
                </Stack>
                <TextInput
                    name="place"
                    control={control}
                    value={exam?.place}
                    label={{ en: "Place", bn: "স্থান" }}
                    errorMsg fontBold={false} required />
                <MultilineInput
                    name="description"
                    control={control}
                    value={exam?.description}
                    label={{ en: "Brief Description", bn: "বিস্তারিত বিবরণ" }}
                    errorMsg fontBold={false} />

                {(cId && bId && studentLoading) ? <Loading /> : uniqueStudents ? <MultipleSelectField
                    name="participants"
                    control={control}
                    defaultValue={exam?.participants}
                    data={uniqueStudents}
                    label={{ en: "Exam Participants", bn: "পরীক্ষায় অংশগ্রহণকারী" }}
                    errorMsg fontBold={false} required />
                    : (cId && bId) &&
                    <Typography variant="body1" my={4} textAlign="center" sx={{ display: 'flex', flexDirection: "column", justifyContent: 'center', alignItems: "center" }}>
                        There is no students enrolled in this course/batch!
                        <NavLink target="_blank" to={'/students/semester-enrollment/enroll'} style={{ fontSize: '16px', color: 'blue', marginTop: 1, textAlign: 'right', fontWeight: "bold" }}>Enroll Students now</NavLink>
                    </Typography>
                }

                <Stack>
                    {teachers && <MultipleSelectField
                        name="examiners"
                        control={control}
                        subLabel={false}
                        subValue={"_id"}
                        defaultValue={exam?.examiners}
                        data={teachers}
                        label={{ en: "Examiners", bn: "পরীক্ষক" }}
                        errorMsg fontBold={false} />}
                </Stack>

            </AddForm>}

        </Page>
    )
}