import Page from "../../Layout/Page";
import { useParams } from "react-router-dom";
import { Divider, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import PageHeader from "../../Layout/PageHeader";
import TRow from "../../components/Table/TRow";
import Translate from "../../components/Translate/Translate";
import Loading from "../../Layout/Loading";
import { Stack, } from "@mui/material";
import { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import TCell from "../../components/Table/TCell";
import { blueGrey, grey } from "@mui/material/colors";
import { IoMdPrint } from "react-icons/io";
import useResult from "../../hooks/result/useResult";


const ResultDetails = () => {
  const params = useParams();
  const id = parseInt(params.id, 10);
  const { data, isLoading } = useResult(id);
  const result = data?.response_data;
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Result Details of ${result?.student?.name}`,
  });


  return (
    <Page title={"Result Details"}>
      <PageHeader titleEn="Result Details" titleBn="ফলাফলের বিস্তারিত"
        link={{ to: '/results/manage', textEn: "Manage Results", textBn: "ফলাফল ব্যবস্থাপনা" }} />

      {isLoading ? <Loading /> :
        <Stack component={Paper} px={{ md: 4 }} sx={{ py: 2, maxWidth: 900, margin: '0 auto' }} className="rad-grad">
          <div style={{ width: '100%', display: 'inline-flex', justifyContent: 'flex-end' }}>
            <IoMdPrint onClick={handlePrint} size={25} color="gray" cursor="pointer" />
          </div>

          <Stack ref={componentRef} p={{ md: 4, xs: 2 }} gap={4}>

            <Stack direction={{ xs: "column", md: "row" }} justifyContent={"space-between"} gap={2}>
              <TRow data={[
                { label: { en: "Student ID", bn: "শিক্ষার্থীর আইডি" }, value: result?.student?._id },
                { label: { en: "Student Name", bn: "শিক্ষার্থীর নাম" }, value: result?.student?.name },
                { label: { en: "Subject Name", bn: "বিষয়ের নাম" }, value: result?.exam?.subject.name },
                { label: { en: "Student Code", bn: "বিষয় কোড" }, value: result?.exam?.subject.subject_code },
                { label: { en: "Total Marks", bn: "প্রাপ্ত মার্ক" }, value: result.total_marks },
                { label: { en: "Examiner Comment", bn: "পরীক্ষকের মন্তব্য" }, value: result.examiner_comment },
                { label: { en: "Status", bn: "স্ট্যাটাস" }, value: result.status },
                { label: { en: "Published at", bn: "প্রকাশের তারিখ" }, value: result.published_at },
              ]} />

              <Divider sx={{ mt: 2, border: `1px solid ${grey[300]}` }} />
              <TRow data={[
                { label: { en: "Course ID", bn: "কোর্স নাম" }, value: result.course.id },
                { label: { en: "Course Name", bn: "কোর্স নাম" }, value: result.course.name },
                { label: { en: "Batch Name", bn: "ব্যাচের নাম" }, value: result.batch.name },
                { label: { en: "Semester", bn: "সেমিস্টার" }, value: `${result.semester_number ? `Semester ${result.semester_number}` : 'None'}` }
              ]} />
            </Stack>

            <Stack>
              <Typography variant="body1" fontSize={'1.1rem'} fontWeight={"bold"}><Translate en="Exam Related" bn="পরীক্ষা সম্পর্কিত" /></Typography>
              <Divider sx={{ borderBottom: `1px solid ${grey[300]}`, maxWidth: '50%' }} />
              <TRow mt={1} data={[
                { label: { en: "Exam ID", bn: "পরীক্ষার আইডি" }, value: result.exam.id },
                { label: { en: "Exam Title", bn: "পরীক্ষার টাইটেল" }, value: result.exam.title },
                { label: { en: "Exam Date", bn: "পরীক্ষার তারিখ" }, value: result.exam.exam_date },
                { label: { en: "Exam Total Marks", bn: "পরীক্ষার সর্বমোট মার্কস" }, value: result.exam.total_mark },
              ]} />
            </Stack>


            {result?.preparedBy && <Typography textAlign="right">Result prepared by <span style={{ color: 'green', fontWeight: 'bold' }}>{result?.preparedBy?.name}</span></Typography>}

          </Stack>
        </Stack>}
    </Page>
  );
}

export default ResultDetails;