export const batches = [
    {
        id: 100,
        label: 'B001',
        name: 'B001',
        creation_date: '12/10/22 11:25:22 PM',
        no_of_students: 40,
        brief: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Commodi, nulla! Lorem, ipsum dolor sit amet consectetur adipisicing elit. Commodi, nulla! Lorem, ipsum dolor sit amet consectetur adipisicing elit. Commodi, nulla! "
    },
    {
        id: 101,
        label: 'B002',
        name: 'B002',
        creation_date: '12/12/22 11:25:22 PM',
        no_of_students: 25,
        brief: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Commodi, nulla! Lorem, ipsum dolor sit amet consectetur adipisicing elit. Commodi, nulla! Lorem, ipsum dolor sit amet consectetur adipisicing elit. Commodi, nulla! "
    },
    {
        id: 102,
        label: 'B003',
        name: 'B003',
        creation_date: '10/01/23 11:25:22 PM',
        no_of_students: 44,
        brief: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Commodi, nulla! Lorem, ipsum dolor sit amet consectetur adipisicing elit. Commodi, nulla! Lorem, ipsum dolor sit amet consectetur adipisicing elit. Commodi, nulla! "
    },
    {
        id: 103,
        label: 'B004',
        name: 'B004',
        creation_date: '20/01/23 11:25:22 PM',
        no_of_students: 30,
        brief: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Commodi, nulla! Lorem, ipsum dolor sit amet consectetur adipisicing elit. Commodi, nulla! Lorem, ipsum dolor sit amet consectetur adipisicing elit. Commodi, nulla! "
    },
];

export const batches2 = [
    {
        id: 100,
        label: 'B001',
        creation_date: '12/10/22 11:25:22 PM',
        no_of_students: 40,
        brief: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Commodi, nulla! Lorem, ipsum dolor sit amet consectetur adipisicing elit. Commodi, nulla! Lorem, ipsum dolor sit amet consectetur adipisicing elit. Commodi, nulla! "
    },
    {
        id: 101,
        label: 'B002',
        creation_date: '12/12/22 11:25:22 PM',
        no_of_students: 25,
        brief: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Commodi, nulla! Lorem, ipsum dolor sit amet consectetur adipisicing elit. Commodi, nulla! Lorem, ipsum dolor sit amet consectetur adipisicing elit. Commodi, nulla! "
    },
    {
        id: 102,
        label: 'B003',
        creation_date: '10/01/23 11:25:22 PM',
        no_of_students: 44,
        brief: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Commodi, nulla! Lorem, ipsum dolor sit amet consectetur adipisicing elit. Commodi, nulla! Lorem, ipsum dolor sit amet consectetur adipisicing elit. Commodi, nulla! "
    },
    {
        id: 103,
        label: 'B004',
        creation_date: '20/01/23 11:25:22 PM',
        no_of_students: 30,
        brief: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Commodi, nulla! Lorem, ipsum dolor sit amet consectetur adipisicing elit. Commodi, nulla! Lorem, ipsum dolor sit amet consectetur adipisicing elit. Commodi, nulla! "
    },
];