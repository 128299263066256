import React, { Suspense } from "react";
import { useRoutes, Navigate } from "react-router-dom"
import { ROUTES } from "./Pages/Settings/permission_data";
import Layout from "./Layout/Layout";
import HomePage from "./Pages/HomePage/HomePage";
import StudentsManage from "./Pages/ManageStudent/StudentsManage";
import StudentsAdd from "./Pages/ManageStudent/StudentsAdd";
import TeachersManage from "./Pages/ManageTeacher/TeachersManage";
import TeachersAdd from "./Pages/ManageTeacher/TeachersAdd";
import ClassManage from "./Pages/ManageClass/ClassManage";
import ClassAdd from "./Pages/ManageClass/ClassAdd";
import ClassRoutineManage from "./Pages/ManageClass/ClassRoutineManage";
import AttendanceManage from "./Pages/ManageAttendance/AttendanceManage";
import ResultManage from "./Pages/ManageResult/ResultManage";
import NoticeManage from "./Pages/ManageNotice/NoticeManage";
import NoticeAdd from "./Pages/ManageNotice/NoticeAdd";
import StaffManage from "./Pages/Settings/StaffManage";
import StaffAdd from "./Pages/Settings/StaffAdd";
import RoleManage from "./Pages/Settings/RoleManage";
import RoleAdd from "./Pages/Settings/RoleAdd";
import AuthGuard from "./components/authGuard/authGuard";
import ExamManage from "./Pages/ManageExam/ExamManage";
import ExamAdd from "./Pages/ManageExam/ExamAdd";
import CoursesManage from "./Pages/ManageCourse/CoursesManage";
import CoursesAdd from "./Pages/ManageCourse/CoursesAdd";
import CertificateManage from "./Pages/ManageCertificates/CertificateManage";
import AllNotice from "./Pages/ManageNotice/AllNotice";
import StudentDetails from "./Pages/ManageStudent/StudentDetails";
import GetHelp from "./Pages/GetHelp";
import MyProfile from "./Pages/Profile/MyProfile";
import AccountSettings from "./Pages/AccountSettings/AccountSettings";
import TeacherDetails from "./Pages/ManageTeacher/TeacherDetails";
import CourseDetails from "./Pages/ManageCourse/CourseDetails";
import ExamDetails from "./Pages/ManageExam/ExamDetails";
import ApplicationDetails from "./Pages/ManageApplications/ApplicationDetails";
import ManageBatch from "./Pages/Batch/ManageBatch";
import AddBatch from "./Pages/Batch/AddBatch";
import BatchDetails from "./Pages/Batch/BatchDetails";
import ExamRoutine from "./Pages/ManageExam/ExamRoutine";
import Permissions from "./Pages/Settings/Permissions";
import MyCourses from "./Pages/ManageCourse/MyCourses";
import StudentEnrollment from "./Pages/ManageStudent/StudentEnrollment";
import NewRequest from "./Pages/ManageApplications/NewRequest";
import ManageRequests from "./Pages/ManageApplications/ManageRequests";
import PrepareResult from "./Pages/ManageResult/PrepareResult";
import ManageCourseEnrollment from "./Pages/ManageStudent/ManageCourseEnrollment";
import CreateAttendance from "./Pages/ManageAttendance/CreateAttendance";
import AssignCourse from "./Pages/ManageTeacher/AssignCourse";
import AssignedCourseManage from "./Pages/ManageTeacher/AssignedCourseManage";
import EnrollmentDetails from "./Pages/ManageStudent/EnrollmentDetails";
import AssignedCourseDetails from "./Pages/ManageTeacher/AssignedCourseDetails";
import StaffEdit from "./Pages/Settings/StaffEdit";
import NoticeEdit from "./Pages/ManageNotice/NoticeEdit";
import TeacherEdit from "./Pages/ManageTeacher/TeacherEdit";
import StudentsEdit from "./Pages/ManageStudent/StudentEdit";
import ManageSubjects from "./Pages/Subjects/ManageSubjects";
import SubjectDetails from "./Pages/Subjects/SubjectDetails";
import AddSubject from "./Pages/Subjects/AddSubject";
import NoticeDetails from "./Pages/ManageNotice/NoticeDetails";
import SemesterEnrollment from "./Pages/ManageStudent/SemesterEnrollment";
import ManageSemesterEnrollment from "./Pages/ManageStudent/ManageSemesterEnrollment";
import SemesterEnrollmentDetails from "./Pages/ManageStudent/SemesterEnrollmentDetails";
import AttendanceReport from "./Pages/ManageAttendance/AttendanceReport";
import ClassDetails from "./Pages/ManageClass/ClassDetails";
import ResultEdit from "./Pages/ManageResult/ResultEdit";
import EditProfile from "./Pages/Profile/EditProfile";
import CheckResult from "./Pages/ManageResult/CheckResult";
import CGPACalculator from "./Pages/ManageResult/CGPACalculator";
import ManageFinalResult from "./Pages/ManageResult/ManageFinalResult";
import FinalResult from "./Pages/ManageResult/PrepareFinalResult";
import EditFinalResult from "./Pages/ManageResult/EditFinalResult";
import ResultDetails from "./Pages/ManageResult/ResultDetails";
import ExamEdit from "./Pages/ManageExam/ExamEdit";
import Loading from "./Layout/Loading";
import EditAssignCourse from "./Pages/ManageTeacher/EditAssignCourse";
import EditSemesterEnrollment from "./Pages/ManageStudent/EditSemesterEnrollment";
import EditStudentEnrollment from "./Pages/ManageStudent/EditStudentEnrollment";
import EditCourse from "./Pages/ManageCourse/EditCourse";
const LoginPage = React.lazy(() => import("./Pages/LoginPage/LoginPage"));
const ResetPassword = React.lazy(() => import('./Pages/ForgotPassword/ResetPassword'));
const VerifyOTP = React.lazy(() => import('./Pages/ForgotPassword/VerifyOTP'));
const ForgotPassword = React.lazy(() => import('./Pages/ForgotPassword/ForgotPassword'));
const NotFoundPage = React.lazy(() => import("./Pages/NotFoundPage/NotFoundPage"));

const Routes = () => {
    return (
        useRoutes([
            // {
            //     path: "/unauthorized",
            //     element: <Layout />,
            //     children: [
            //         { path: "", element: <AuthGuard  ><UnauthorizedPage /></AuthGuard> },
            //     ]
            // },
            {
                path: "/", element: <AuthGuard hasPermission={"all"} ><Navigate to={"/dashboard/home"} /></AuthGuard>
            },
            {
                path: "/dashboard",
                element: <Layout />,
                children: [
                    { path: "", element: <AuthGuard hasPermission={"all"} ><Navigate to={"/dashboard/home"} /></AuthGuard> },
                    { path: "home", element: <AuthGuard hasPermission={"all"}  ><HomePage /></AuthGuard> },
                ]
            },
            {
                path: "/students",
                element: <Layout />,
                children: [
                    { path: "", element: <AuthGuard hasPermission={[ROUTES.STUDENT.MANAGE.M, ROUTES.STUDENT.MANAGE.S]} ><Navigate to={"/students/manage"} /> </AuthGuard> },
                    { path: "manage", element: <AuthGuard hasPermission={[ROUTES.STUDENT.MANAGE.M, ROUTES.STUDENT.MANAGE.S]} ><StudentsManage /></AuthGuard> },
                    { path: "add", element: <AuthGuard hasPermission={[ROUTES.STUDENT.ADD]} ><StudentsAdd /></AuthGuard> },
                    { path: "edit/:id", element: <AuthGuard hasPermission={[ROUTES.STUDENT.MANAGE.E]} ><StudentsEdit /></AuthGuard> },
                    { path: "details/:id", element: <AuthGuard hasPermission={[ROUTES.STUDENT.MANAGE.V]} ><StudentDetails /></AuthGuard> },
                    // { path: "bulk-import", element: <AuthGuard  hasPermission={ROUTES.STUDENT.MANAGE} ><StudentsBulkImport /></AuthGuard> },
                    { path: "manage-enrollment", element: <AuthGuard hasPermission={[ROUTES.STUDENT.COURSE.MANAGE.M, ROUTES.STUDENT.COURSE.MANAGE.S]} ><ManageCourseEnrollment /></AuthGuard> },
                    { path: "student-enrollment", element: <AuthGuard hasPermission={[ROUTES.STUDENT.COURSE.ADD]} ><StudentEnrollment /></AuthGuard> },
                    { path: "student-enrollment/edit/:id", element: <AuthGuard hasPermission={[ROUTES.STUDENT.COURSE.MANAGE.E]} ><EditStudentEnrollment /></AuthGuard> },
                    { path: "student-enrollment/details/:id", element: <AuthGuard hasPermission={[ROUTES.STUDENT.COURSE.MANAGE.V]} ><EnrollmentDetails /></AuthGuard> },
                    { path: "semester-enrollment", element: <AuthGuard hasPermission={[ROUTES.STUDENT.SEMESTER.MANAGE.M, ROUTES.STUDENT.SEMESTER.MANAGE.S]} ><ManageSemesterEnrollment /></AuthGuard> },
                    { path: "semester-enrollment/view/:id", element: <AuthGuard hasPermission={[ROUTES.STUDENT.SEMESTER.MANAGE.V]} ><SemesterEnrollmentDetails /></AuthGuard> },
                    { path: "semester-enrollment/enroll", element: <AuthGuard hasPermission={[ROUTES.STUDENT.SEMESTER.ADD]} ><SemesterEnrollment /></AuthGuard> },
                    { path: "semester-enrollment/edit/:id", element: <AuthGuard hasPermission={[ROUTES.STUDENT.SEMESTER.MANAGE.E]} ><EditSemesterEnrollment /></AuthGuard> },
                ]
            },
            {
                path: "/teachers",
                element: <Layout />,
                children: [
                    { path: "", element: <AuthGuard hasPermission={[ROUTES.TEACHER.MANAGE.M, ROUTES.TEACHER.MANAGE.S]} ><Navigate to={"/teachers/manage"} /> </AuthGuard> },
                    { path: "manage", element: <AuthGuard hasPermission={[ROUTES.TEACHER.MANAGE.M, ROUTES.TEACHER.MANAGE.S]} ><TeachersManage /></AuthGuard> },
                    { path: "add", element: <AuthGuard hasPermission={[ROUTES.TEACHER.ADD]} ><TeachersAdd /></AuthGuard> },
                    { path: "edit/:id", element: <AuthGuard hasPermission={[ROUTES.TEACHER.MANAGE.E]} ><TeacherEdit /></AuthGuard> },
                    { path: "details/:id", element: <AuthGuard hasPermission={[ROUTES.TEACHER.MANAGE.V]} ><TeacherDetails /></AuthGuard> },
                    { path: "assigned-course-manage", element: <AuthGuard hasPermission={[ROUTES.TEACHER.COURSE.MANAGE.M, ROUTES.TEACHER.COURSE.MANAGE.S]} ><AssignedCourseManage /></AuthGuard> },
                    { path: "assigned-course-manage/details/:id", element: <AuthGuard hasPermission={[ROUTES.TEACHER.COURSE.MANAGE.V]} ><AssignedCourseDetails /></AuthGuard> },
                    { path: "assign-course", element: <AuthGuard hasPermission={[ROUTES.TEACHER.COURSE.ASSIGN]} ><AssignCourse /></AuthGuard> },
                    { path: "assign-course/edit/:id", element: <AuthGuard hasPermission={[ROUTES.TEACHER.COURSE.MANAGE.E]} ><EditAssignCourse /></AuthGuard> },
                ]
            },
            {
                path: "/subject",
                element: <Layout />,
                children: [
                    { path: "", element: <AuthGuard hasPermission={[ROUTES.SUBJECT.MANAGE.M]} ><Navigate to={"/subject/manage"} /> </AuthGuard> },
                    { path: "manage", element: <AuthGuard hasPermission={[ROUTES.SUBJECT.MANAGE.M]} ><ManageSubjects /></AuthGuard> },
                    { path: "details/:id", element: <AuthGuard hasPermission={[ROUTES.SUBJECT.MANAGE.V]} ><SubjectDetails /></AuthGuard> },
                    { path: "add", element: <AuthGuard hasPermission={[ROUTES.SUBJECT.ADD]} ><AddSubject /></AuthGuard> },
                    { path: "add/:id", element: <AuthGuard hasPermission={[ROUTES.SUBJECT.MANAGE.E]} ><AddSubject /></AuthGuard> },
                ]
            },
            {
                path: "/courses",
                element: <Layout />,
                children: [
                    { path: "", element: <AuthGuard hasPermission={[ROUTES.COURSE.MANAGE.M]} ><Navigate to={"/courses/manage"} /> </AuthGuard> },
                    { path: "manage", element: <AuthGuard hasPermission={[ROUTES.COURSE.MANAGE.M]} ><CoursesManage /></AuthGuard> },
                    { path: "details/:id", element: <AuthGuard hasPermission={[ROUTES.COURSE.MANAGE.V]} ><CourseDetails /></AuthGuard> },
                    { path: "my-courses", element: <AuthGuard hasPermission={[ROUTES.COURSE.MYCOURSE.M]} ><MyCourses /></AuthGuard> },
                    { path: "add", element: <AuthGuard hasPermission={[ROUTES.COURSE.ADD]} ><CoursesAdd /></AuthGuard> },
                    { path: "edit/:id", element: <AuthGuard hasPermission={[ROUTES.COURSE.MANAGE.E]} ><EditCourse /></AuthGuard> },
                ]
            },
            {
                path: "/batch",
                element: <Layout />,
                children: [
                    { path: "", element: <AuthGuard hasPermission={[ROUTES.BATCH.MANAGE.M]} ><Navigate to={"/batch/manage"} /> </AuthGuard> },
                    { path: "manage", element: <AuthGuard hasPermission={[ROUTES.BATCH.MANAGE.M]} ><ManageBatch /></AuthGuard> },
                    { path: "add-batch", element: <AuthGuard hasPermission={[ROUTES.BATCH.ADD]} ><AddBatch /></AuthGuard> },
                    { path: "add-batch/:id", element: <AuthGuard hasPermission={[ROUTES.BATCH.MANAGE.E]} ><AddBatch /></AuthGuard> },
                    { path: "details/:id", element: <AuthGuard hasPermission={[ROUTES.BATCH.MANAGE.V]} ><BatchDetails /></AuthGuard> }
                ]
            },
            {
                path: "/classes",
                element: <Layout />,
                children: [
                    { path: "", element: <AuthGuard hasPermission={[ROUTES.CLASS.MANAGE.M, ROUTES.CLASS.MANAGE.S]} ><Navigate to={"/classes/manage"} /> </AuthGuard> },
                    { path: "manage", element: <AuthGuard hasPermission={[ROUTES.CLASS.MANAGE.M, ROUTES.CLASS.MANAGE.S]} ><ClassManage /></AuthGuard> },
                    { path: "add", element: <AuthGuard hasPermission={[ROUTES.CLASS.ADD]} ><ClassAdd /></AuthGuard> },
                    { path: "add/:id", element: <AuthGuard hasPermission={[ROUTES.CLASS.MANAGE.E]} ><ClassAdd /></AuthGuard> },
                    { path: "view/:id", element: <AuthGuard hasPermission={[ROUTES.CLASS.MANAGE.V]} ><ClassDetails /></AuthGuard> },
                    { path: "routine", element: <AuthGuard hasPermission={[ROUTES.CLASS.ROUTINE]} ><ClassRoutineManage /></AuthGuard> },
                ]
            },
            {
                path: "/attendance",
                element: <Layout />,
                children: [
                    { path: "", element: <AuthGuard hasPermission={[ROUTES.ATTENDANCE.MANAGE.M, ROUTES.ATTENDANCE.MANAGE.S]} ><Navigate to={"/attendance/manage"} /> </AuthGuard> },
                    { path: "manage", element: <AuthGuard hasPermission={[ROUTES.ATTENDANCE.MANAGE.M, ROUTES.ATTENDANCE.MANAGE.S]} ><AttendanceManage /></AuthGuard> },
                    { path: "create-attendance", element: <AuthGuard hasPermission={[ROUTES.ATTENDANCE.ADD]} ><CreateAttendance /></AuthGuard> },
                    // { path: "student-attendance", element: <AuthGuard hasPermission={[ROUTES.ATTENDANCE.ADD]} ><StudentWiseAttendance /></AuthGuard> },
                    { path: "attendance-report", element: <AuthGuard hasPermission={[ROUTES.ATTENDANCE.REPORT.MANAGE.M, ROUTES.ATTENDANCE.REPORT.MANAGE.S]} ><AttendanceReport /></AuthGuard> },
                ]
            },
            {
                path: "/exam",
                element: <Layout />,
                children: [
                    { path: "", element: <AuthGuard hasPermission={[ROUTES.EXAM.MANAGE.M, ROUTES.EXAM.MANAGE.S]} ><Navigate to={"/exam/manage"} /> </AuthGuard> },
                    { path: "manage", element: <AuthGuard hasPermission={[ROUTES.EXAM.MANAGE.M, ROUTES.EXAM.MANAGE.S]} ><ExamManage /></AuthGuard> },
                    { path: "details/:id", element: <AuthGuard hasPermission={[ROUTES.EXAM.MANAGE.V]} ><ExamDetails /></AuthGuard> },
                    { path: "add", element: <AuthGuard hasPermission={[ROUTES.EXAM.ADD]} ><ExamAdd /></AuthGuard> },
                    { path: "edit/:id", element: <AuthGuard hasPermission={[ROUTES.EXAM.ADD]} ><ExamEdit /></AuthGuard> },
                    { path: "routine", element: <AuthGuard hasPermission={[ROUTES.EXAM.ROUTINE]} ><ExamRoutine /></AuthGuard> },
                ]
            },
            {
                path: "/results",
                element: <Layout />,
                children: [
                    { path: "", element: <AuthGuard hasPermission={[ROUTES.RESULT.MANAGE.M, ROUTES.RESULT.MANAGE.S]} ><Navigate to={"/results/manage"} /> </AuthGuard> },
                    { path: "manage", element: <AuthGuard hasPermission={[ROUTES.RESULT.MANAGE.M, ROUTES.RESULT.MANAGE.S]} ><ResultManage /></AuthGuard> },
                    { path: "view/:id", element: <AuthGuard hasPermission={[ROUTES.RESULT.MANAGE.V]} ><ResultDetails /></AuthGuard> },
                    { path: "prepare-result", element: <AuthGuard hasPermission={[ROUTES.RESULT.ADD]} ><PrepareResult /></AuthGuard> },
                    { path: "final/manage", element: <AuthGuard hasPermission={[ROUTES.RESULT.FINAL.M, ROUTES.RESULT.FINAL.S]} ><ManageFinalResult /></AuthGuard> },
                    { path: "final/publish", element: <AuthGuard hasPermission={[ROUTES.RESULT.FINAL_PUB]} ><FinalResult /></AuthGuard> },
                    { path: "final/publish/:id", element: <AuthGuard hasPermission={[ROUTES.RESULT.FINAL.E]} ><EditFinalResult /></AuthGuard> },
                    { path: "edit-result/:id", element: <AuthGuard hasPermission={[ROUTES.RESULT.MANAGE.E]} ><ResultEdit /></AuthGuard> },
                    { path: "check-result", element: <AuthGuard hasPermission={[ROUTES.RESULT.CHECK_RESULT]}  ><CheckResult /></AuthGuard> },
                    { path: "cgpa-config", element: <AuthGuard hasPermission={[ROUTES.RESULT.CGPA_CONFIG.M]}  ><CGPACalculator /></AuthGuard> },
                ]
            },
            {
                path: "/applications",
                element: <Layout />,
                children: [
                    { path: "", element: <AuthGuard hasPermission={[ROUTES.REQUEST.MANAGE.M, ROUTES.REQUEST.MANAGE.S]} ><Navigate to={"/applications/manage"} /> </AuthGuard> },
                    { path: "manage", element: <AuthGuard hasPermission={[ROUTES.REQUEST.MANAGE.M, ROUTES.REQUEST.MANAGE.S]} ><ManageRequests /></AuthGuard> },
                    { path: "details/:id", element: <AuthGuard hasPermission={[ROUTES.REQUEST.MANAGE.V]} ><ApplicationDetails /></AuthGuard> },
                    { path: "new-request", element: <AuthGuard hasPermission={[ROUTES.REQUEST.ADD]} ><NewRequest /></AuthGuard> },
                    { path: "new-request/:id", element: <AuthGuard hasPermission={[ROUTES.REQUEST.ADD]} ><NewRequest /></AuthGuard> }
                ]
            },
            {
                path: "/notices",
                element: <Layout />,
                children: [
                    { path: "", element: <AuthGuard hasPermission={[ROUTES.NOTICE.MANAGE.M, ROUTES.NOTICE.MANAGE.S]} ><Navigate to={"/notices/manage"} /> </AuthGuard> },
                    { path: "manage", element: <AuthGuard hasPermission={[ROUTES.NOTICE.MANAGE.M, ROUTES.NOTICE.MANAGE.S]} ><NoticeManage /></AuthGuard> },
                    { path: "create", element: <AuthGuard hasPermission={[ROUTES.NOTICE.ADD]} ><NoticeAdd /></AuthGuard> },
                    { path: "edit/:id", element: <AuthGuard hasPermission={[ROUTES.NOTICE.MANAGE.E]} ><NoticeEdit /></AuthGuard> },
                    { path: "details/:id", element: <AuthGuard hasPermission={[ROUTES.NOTICE.MANAGE.V]} ><NoticeDetails /></AuthGuard> },
                ]
            },
            {
                path: "/noticeboard",
                element: <Layout />,
                children: [
                    { path: "", element: <AuthGuard hasPermission={[ROUTES.NOTICEBOARD]}><AllNotice /></AuthGuard> }
                ]
            },
            // {
            //     path: "/news",
            //     element: <Layout />,
            //     children: [
            //         { path: "", element: <AuthGuard ><Navigate to={"/news/manage-news"} /> </AuthGuard> },
            //         { path: "manage-news", element: <AuthGuard ><ManageNews /></AuthGuard> },
            //         { path: "add-news", element: <AuthGuard ><AddNews /></AuthGuard> },
            //     ]
            // },
            // {
            //     path: "/publications",
            //     element: <Layout />,
            //     children: [
            //         { path: "", element: <AuthGuard ><Navigate to={"publications/manage-publications"} /> </AuthGuard> },
            //         { path: "manage-publications", element: <AuthGuard ><ManagePublications /></AuthGuard> },
            //         { path: "add-publication", element: <AuthGuard ><AddPublication /></AuthGuard> },
            //     ]
            // },
            // {
            //     path: "/photo-gallery",
            //     element: <Layout />,
            //     children: [
            //         { path: "", element: <AuthGuard ><Navigate to={"photo-gallery/manage-photos"} /> </AuthGuard> },
            //         { path: "manage-photos", element: <AuthGuard ><ManagePhotos /></AuthGuard> },
            //         { path: "add-photos", element: <AuthGuard ><AddPhotos /></AuthGuard> },
            //         { path: "albums", element: <AuthGuard ><Albums /></AuthGuard> },
            //         { path: "add-album", element: <AuthGuard ><AddAlbum /></AuthGuard> },
            //     ]
            // },
            // {
            //     path: "/video-gallery",
            //     element: <Layout />,
            //     children: [
            //         { path: "", element: <AuthGuard ><Navigate to={"video-gallery/manage-videos"} /> </AuthGuard> },
            //         { path: "manage-videos", element: <AuthGuard ><ManageVideos /></AuthGuard> },
            //         { path: "add-video", element: <AuthGuard ><AddVideo /></AuthGuard> },
            //         { path: "albums", element: <AuthGuard ><AlbumsVideo /></AuthGuard> },
            //         { path: "add-album", element: <AuthGuard ><AddAlbumVideo /></AuthGuard> },
            //     ]
            // },
            // {
            //     path: "/avassignments",
            //     element: <Layout />,
            //     children: [
            //         { path: "", element: <AuthGuard ><Navigate to={"dashboard"} /> </AuthGuard> },
            //         { path: "manage", element: <AuthGuard ><AVAssignment /></AuthGuard> },
            //         { path: "details", element: <AuthGuard ><StudentAssignmentDetails /></AuthGuard> },
            //         { path: "add", element: <AuthGuard ><AddAsignment /></AuthGuard> },
            //         { path: "assignment-list", element: <AuthGuard ><AssignmentList /></AuthGuard> },
            //         { path: "assignment-list/details/:id", element: <AuthGuard ><AssignmentDetails /></AuthGuard> },
            //         { path: "assignment-submit", element: <AuthGuard hasType={["student"]}><SubmitAssignment /></AuthGuard> },
            //     ]
            // },
            // {
            //     path: "/evaluation",
            //     element: <Layout />,
            //     children: [
            //         { path: "", element: <AuthGuard ><Navigate to={"dashboard"} /> </AuthGuard> },
            //         { path: "manage", element: <AuthGuard ><ManageEvaluation /></AuthGuard> },
            //         { path: "activation", element: <AuthGuard ><EvaluationActivation /></AuthGuard> },
            //         { path: "course-teachers", element: <AuthGuard ><TeacherEvaluationDetails /></AuthGuard> },
            //         { path: "details", element: <AuthGuard ><EvaluationDetails /></AuthGuard> },
            //         { path: "my-evaluation", element: <AuthGuard hasType={["teacher"]}><MyEvaluation /></AuthGuard> },
            //         { path: "course-evaluation", element: <AuthGuard hasType={["student"]}><CourseEvaluation /></AuthGuard> },
            //         { path: "evaluate", element: <AuthGuard hasType={["student"]}><Evaluate /></AuthGuard> },
            //         { path: "evaluation-questions", element: <AuthGuard ><EvaluationQuestions /></AuthGuard> },
            //         { path: "question", element: <AuthGuard ><AddQuestion /></AuthGuard> }
            //     ]
            // },
            {
                path: "/settings",
                element: <Layout />,
                children: [
                    { path: "", element: <AuthGuard hasPermission={[ROUTES.SETTINGS.STAFF.MANAGE.M]} ><Navigate to={"/settings/manage-staff"} /> </AuthGuard> },
                    { path: "manage-staff", element: <AuthGuard hasPermission={[ROUTES.SETTINGS.STAFF.MANAGE.M]} ><StaffManage /></AuthGuard> },
                    { path: "add-staff", element: <AuthGuard hasPermission={[ROUTES.SETTINGS.STAFF.ADD]} ><StaffAdd /></AuthGuard> },
                    { path: "edit-staff/:id", element: <AuthGuard hasPermission={[ROUTES.SETTINGS.STAFF.MANAGE.E]} ><StaffEdit /></AuthGuard> },
                    { path: "manage-role", element: <AuthGuard hasPermission={[ROUTES.SETTINGS.ROLE.MANAGE.M]} ><RoleManage /></AuthGuard> },
                    { path: "add-role", element: <AuthGuard hasPermission={[ROUTES.SETTINGS.ROLE.ADD]} ><RoleAdd /></AuthGuard> },
                    { path: "add-role/:id", element: <AuthGuard hasPermission={[ROUTES.SETTINGS.ROLE.MANAGE.E]} ><RoleAdd /></AuthGuard> },
                    { path: "permissions", element: <AuthGuard hasPermission={[ROUTES.SETTINGS.PERMISSION.MANAGE.M]} ><Permissions /></AuthGuard> },
                ]
            },
            {
                path: "/documents",
                element: <Layout />,
                children: [
                    { path: "", element: <AuthGuard hasPermission={[ROUTES.DOCUMENT.MANAGE.M, ROUTES.DOCUMENT.MANAGE.S]} ><Navigate to={"/documents"} /> </AuthGuard> },
                    { path: "manage", element: <AuthGuard hasPermission={[ROUTES.DOCUMENT.MANAGE.M, ROUTES.DOCUMENT.MANAGE.S]} ><CertificateManage /></AuthGuard> },
                    // { path: "my-documents", element: <AuthGuard hasType={["student"]}><MyDocuments /></AuthGuard> },
                ]
            },
            {
                path: "/help",
                element: <Layout />,
                children: [
                    { path: "", element: <AuthGuard hasPermission={[ROUTES.HELP]} ><Navigate to={"/help/get-help"} /></AuthGuard> },
                    { path: "get-help", element: <AuthGuard hasPermission={[ROUTES.HELP]}><GetHelp /></AuthGuard> },
                ]
            },
            {
                path: "/help",
                element: <Layout />,
                children: [
                    { path: "", element: <AuthGuard hasPermission={[ROUTES.HELP]} ><Navigate to={"/help/get-help"} /></AuthGuard> },
                    { path: "get-help", element: <AuthGuard hasPermission={[ROUTES.HELP]}><GetHelp /></AuthGuard> },
                ]
            },
            {
                path: "/profile",
                element: <Layout />,
                children: [
                    { path: "", element: <AuthGuard  ><MyProfile /></AuthGuard> },
                    { path: "edit-profile", element: <AuthGuard  ><EditProfile /></AuthGuard> },
                ]
            },
            {
                path: "/account-settings",
                element: <Layout />,
                children: [
                    { path: "", element: <AuthGuard ><AccountSettings /></AuthGuard> },
                ]
            },
            {
                path: "/login",
                element: <Suspense fallback={<Loading size={50} minHeight="80vh" />}><LoginPage /></Suspense>
            },
            {
                path: "/forgot-password",
                element: <Suspense fallback={<Loading size={50} minHeight="80vh" />}><ForgotPassword /></Suspense>
            },
            {
                path: "/verify-otp",
                element: <Suspense fallback={<Loading size={50} minHeight="80vh" />}><VerifyOTP /></Suspense>
            },
            {
                path: "/reset-password",
                element: <Suspense fallback={<Loading size={50} minHeight="80vh" />}><ResetPassword /></Suspense>
            },
            {
                path: "*",
                element: <Suspense fallback={<Loading size={50} minHeight="80vh" />}><NotFoundPage /></Suspense>
            }

        ])
    )
}

export default Routes;