import Page from "../../Layout/Page";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as Yup from "yup";
import PageHeader from "../../Layout/PageHeader";
import AddForm from "../../components/Filter/AddForm";
import TextInput from "../../components/Fields/TextInput";
import MultilineInput from "../../components/Fields/MultilineInput";
import SelectField from "../../components/Fields/SelectField";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-hot-toast";
import useBatch from "../../hooks/batch/useBatch";
import { addBatch, updateBatch } from "../../api/fetcherFn/batch";
import Loading from "../../Layout/Loading";
import useGetAllCourse from "../../hooks/course/useGetAllCourse";
import { Stack } from "@mui/material";
import { getTodaysDate } from "../../utils/constants";

const validationSchema = Yup.object().shape({
    course_id: Yup.number().nullable().required("Required"),
    name: Yup.string()
        .required("Batch Name is required"),
    start_date: Yup.string(),
    end_date: Yup.string(),
    description: Yup.string(),
});

const AddBatch = () => {
    const queryClient = useQueryClient();
    const params = useParams();
    const id = parseInt(params.id, 10);
    const navigate = useNavigate();
    const allCourse = useGetAllCourse();
    const { data: batch, isLoading } = useBatch(id);
    const { control, handleSubmit, reset } = useForm({ resolver: yupResolver(validationSchema), mode: "onChange", });
    const add = useMutation(data => addBatch(data), {
        onSuccess: (data) => {
            if (data.success) {
                toast.success(data.message);
                queryClient.invalidateQueries('batch');
                navigate('/batch/manage');
            } else {
                toast.error(data.message);
            }
        },
        onError: (err) => {
            toast.error(err.message);
        }
    });
    const update = useMutation(data => updateBatch(id, data), {
        onSuccess: (data) => {
            if (data.success) {
                toast.success(data.message);
                queryClient.invalidateQueries('batch');
                navigate('/batch/manage');
            } else {
                toast.error(data.message);
            }
        },
        onError: (err) => {
            toast.error(err.message);
        }
    });



    const onSubmit = async (data) => {
        if (id) {
            await update.mutateAsync(data);
        } else {
            await add.mutateAsync(data);
        }
    }
    console.log(getTodaysDate)
    return (
        <Page title={`${id ? 'Edit' : 'Add'} Batch`}>
            <PageHeader titleEn={`${id ? 'Edit' : 'Add'} Batch`} titleBn={`ব্যাচ ${id ? 'তথ্য পরিবর্তন' : 'সংযুক্তিকরণ'}`}
                link={{ to: '/batch/manage', textEn: "Manage Batch", textBn: "ব্যাচ ব্যবস্থাপনা" }} />


            {(id && isLoading) ? <Loading /> : <AddForm handleSubmit={handleSubmit(onSubmit)} loading={add.isLoading || update.isLoading} reset={reset}>
                <SelectField
                    name="course_id"
                    control={control}
                    defaultValue={batch?.response_data?.course.id}
                    isLoading={allCourse.isLoading}
                    data={allCourse?.data?.response_data}
                    label={{ en: "Select Course Name", bn: "কোর্স নির্বাচন" }}
                    errorMsg fontBold={false} required />
                <TextInput
                    name="name"
                    control={control}
                    value={batch?.response_data?.name}
                    label={{ en: "Batch Name", bn: "ব্যাচ নির্বাচন" }}
                    placeholder="B001"
                    errorMsg fontBold={false} required />

                <Stack direction="row" gap={1}>
                    <TextInput
                        name="start_date"
                        type="date"
                        control={control}
                        min={getTodaysDate}
                        value={batch?.response_data?.start_date}
                        label={{ en: "Start Date", bn: "শুরুর তারিখ" }}
                        errorMsg fontBold={false} />
                    <TextInput
                        name="end_date"
                        type="date"
                        control={control}
                        min={getTodaysDate}
                        value={batch?.response_data?.end_date}
                        label={{ en: "End Date", bn: "শেষের তারিখ" }}
                        errorMsg fontBold={false} />
                </Stack>

                <MultilineInput
                    name="description"
                    control={control}
                    value={batch?.response_data?.description}
                    label={{ en: "Brief About Batch", bn: "ব্যাচের সংক্ষিপ্ত বিবরণ" }}
                    errorMsg fontBold={false} />

            </AddForm>}
        </Page>
    )
}

export default AddBatch;