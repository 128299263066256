import { Button, Dialog, DialogContent, DialogTitle, Stack } from '@mui/material';
import React from 'react';
import SelectField from '../Fields/SelectField';
import Translate from '../Translate/Translate';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import * as Yup from "yup";
import { useNavigate } from 'react-router-dom';
import useGetAllCourse from '../../hooks/course/useGetAllCourse';
import useGetExams from '../../hooks/exam/useGetExams';


const ResultDialog = ({ openDialog, setOpenDialog, }) => {
    const navigate = useNavigate();
    const allCourse = useGetAllCourse();

    const validationSchema = Yup.object().shape({
        course_id: Yup.number().nullable().transform(v => !isNaN(v) ? v : null).required("Required"),
        batch_id: Yup.number().nullable().transform(v => !isNaN(v) ? v : null).required("Required"),
        semester_number: Yup.number().when('course_id', (courseId, schema) => {
            const course = allCourse.data?.response_data?.find(c => c.id === courseId);
            return course?.semester_wise_subjects?.length > 0
                ? schema.nullable().transform(v => !isNaN(v) ? v : null).required('Required.')
                : schema.nullable().transform(v => !isNaN(v) ? v : null);
        }),
        exam_id: Yup.number().nullable().transform(v => !isNaN(v) ? v : null).required("Required"),
    });

    const { control, handleSubmit, watch, setValue } = useForm({ resolver: yupResolver(validationSchema), mode: "onChange" });

    const course = watch('course_id');
    const batch = watch('batch_id');
    const semesterNum = watch('semester_number');

    const { data: examData } = useGetExams(course, batch, null, semesterNum ? semesterNum : null);

    const onSubmit = (data) => {
        const { course_id, batch_id, semester_number, exam_id } = data;
        setOpenDialog(!openDialog);
        if (semester_number) {
            navigate(`/results/prepare-result?course=${course_id}&batch=${batch_id}&semester=${semester_number}&exam=${exam_id}`);
        } else {
            navigate(`/results/prepare-result?course=${course_id}&batch=${batch_id}&exam=${exam_id}`);
        }
    };


    const courses = allCourse?.data?.response_data;
    const batches = courses && courses?.find(c => c.id === course)?.batches;
    const semesters = courses && courses?.find(c => c.id === course)?.semester_wise_subjects;


    return (
        <Dialog maxWidth="md" onClose={() => setOpenDialog(!openDialog)} open={openDialog} scroll="body" >
            <DialogTitle fontWeight="bold"><Translate en='Create a new result' bn='ফলাফল যুক্তকরণ' /></DialogTitle>
            <DialogContent sx={{ paddingBottom: 8 }}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Stack minWidth={{ md: 400 }} gap={2}    >
                        <SelectField
                            name="course_id"
                            control={control}
                            data={courses} handelChange={() => {
                                setValue('batch_id', null);
                                setValue('semester_number', null);
                                setValue('class_id', null);
                            }}
                            label={{ en: "Select Course", bn: "কোর্স নির্বাচন" }}
                            errorMsg fontBold={false} required />
                        <SelectField
                            name="batch_id"
                            control={control}
                            data={batches}
                            uniqueKey={course}
                            label={{ en: "Select Batch", bn: "ব্যাচ নির্বাচন" }}
                            errorMsg fontBold={false} required />
                        {semesters && <SelectField
                            name="semester_number"
                            control={control}
                            subLabel={false}
                            uniqueKey={course}
                            data={semesters?.map(option => ({ id: option.semester, name: `Semester ${option.semester}` }))}
                            label={{ en: "Select Semester", bn: "সেমিস্টার নির্বাচন" }}
                            errorMsg fontBold={false} />}

                        <SelectField
                            name="exam_id"
                            control={control}
                            uniqueKey={course}
                            data={examData?.response_data?.data?.map(exam => ({ id: exam.id, name: exam.title }))}
                            label={{ en: "Select Exam", bn: "পরীক্ষা নির্বাচন" }}
                            errorMsg fontBold={false} required />


                        <Button type='submit' variant="contained" sx={{ mt: 4, textTransform: 'capitalize' }}>Proceed to next</Button>
                    </Stack>
                </form>
            </DialogContent>
        </Dialog>
    );
};

export default ResultDialog;