import Page from "../../Layout/Page";
import { useParams } from "react-router-dom";
import { Divider, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, } from "@mui/material";
import PageHeader from "../../Layout/PageHeader";
import TRow from "../../components/Table/TRow";
import Loading from "../../Layout/Loading";
import useClass from "../../hooks/class/useClass";
import Translate from "../../components/Translate/Translate";
import TCell from "../../components/Table/TCell";
import { grey, } from "@mui/material/colors";
import { IoMdPrint } from "react-icons/io";
import { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { formatTime } from "../../utils/constants";

const ClassDetails = () => {
  const { id } = useParams();
  const { data, isLoading } = useClass(id);
  const _class = data?.response_data;
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Class Details of ${_class?.name}`,
  });

  return (
    <Page title="Class Details">
      <PageHeader titleEn="Class Details" titleBn="ক্লাসের বিস্তারিত"
        link={{ to: '/classes/manage', textEn: "Manage Classes", textBn: "ক্লাস ব্যবস্থাপনা" }} />

      {isLoading ? <Loading /> :
        <Stack component={Paper} px={{ md: 4 }} sx={{ py: 2, maxWidth: 900, margin: '0 auto' }} className="rad-grad">
          <div style={{ width: '100%', display: 'inline-flex', justifyContent: 'flex-end' }}>
            <IoMdPrint onClick={handlePrint} size={25} color="gray" cursor="pointer" />
          </div>

          <Stack ref={componentRef} p={{ md: 4, xs: 2 }} gap={4}>


            <Stack direction={{ xs: "column", md: "row" }} justifyContent={"space-between"} gap={2}>
              <TRow data={[
                { label: { en: "Class ID", bn: "ক্লাসের আইডি" }, value: _class.id },
                { label: { en: "Class Name", bn: "ক্লাসের নাম" }, value: _class.name },
                { label: { en: "Class Subject", bn: "বিষয়ের নাম" }, value: _class.subject.name },
                { label: { en: "Subject Code", bn: "বিষয় কোড" }, value: _class.subject.subject_code },
                { label: { en: "Location", bn: "ক্লাসে লোকেশন" }, value: _class.place },
                { label: { en: "Class Date", bn: "ক্লাসের তারিখ" }, value: _class.class_date },
                { label: { en: "Start Time", bn: "ক্লাস শুরু" }, value: formatTime(_class.start_time) },
                { label: { en: "End Time", bn: "ক্লাস শেষ" }, value: formatTime(_class.end_time) },
              ]} />

              <Divider sx={{ mt: 2, border: `1px solid ${grey[300]}` }} />
              <TRow data={[
                { label: { en: "Course ID", bn: "কোর্সের আইডি" }, value: _class.course.id },
                { label: { en: "Course Name", bn: "কোর্সের নাম" }, value: _class.course.name },
                { label: { en: "Batch Name", bn: "ব্যাচের নাম" }, value: _class.batch.name },
                { label: { en: "Semester", bn: "সেমিস্টার" }, value: `${_class.semester_number ? `Semester ${_class.semester_number}` : 'None'}` }
              ]} />
            </Stack>


            {_class?.teacher && <TableContainer sx={{ mt: 2, borderRadius: 1, }}>
              <TableCell sx={{ fontSize: '1.2rem', fontWeight: 'bold', border: 0, px: 0, py: 1 }}><Translate en="Class Teacher" bn="ক্লাসের শিক্ষক" /></TableCell>
              <Table sx={{ minWidth: 650, borderRadius: 1, border: `1px solid ${grey[100]}` }} size="medium">
                <TableHead sx={{ bgcolor: grey[100] }}>
                  <TableRow>
                    <TCell bold><Translate en={"Teacher ID"} bn={"শিক্ষকের আইডি"} /></TCell>
                    <TCell bold><Translate en={"Teacher Name"} bn={"শিক্ষকের নাম"} /></TCell>
                    <TCell bold> <Translate en={"Email"} bn={"ই-মেইল"} /> </TCell>
                    <TCell bold><Translate en={"Phone"} bn={"ফোন"} /> </TCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow  >
                    <TCell>{_class.teacher._id}</TCell>
                    <TCell>{_class.teacher.name}</TCell>
                    <TCell>{_class.teacher.email}</TCell>
                    <TCell>{_class.teacher.phone}</TCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>}


          </Stack>
        </Stack>
      }

    </Page>
  );
}

export default ClassDetails;