import Page from "../../Layout/Page";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as Yup from "yup";
import PageHeader from "../../Layout/PageHeader";
import AddForm from "../../components/Filter/AddForm";
import SelectField from "../../components/Fields/SelectField";
import TextInput from "../../components/Fields/TextInput";
import MultilineInput from "../../components/Fields/MultilineInput";
import FileInput from "../../components/Fields/FileInput";
import { PDF_DOC_DOCX_PNG_JPG_JPEG } from "../../Constants/FileTypes";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-hot-toast";
import { updateNotice } from "../../api/fetcherFn/notice";
import Loading from "../../Layout/Loading";
import useNotice from "../../hooks/notice-subject/useNotice";
import useRoles from "../../hooks/settings/useRoles";
import { BsFileEarmarkText } from "react-icons/bs";
import { Stack } from "@mui/material";


const validationSchema = Yup.object().shape({
    title: Yup.string().required('Notice Title is required'),
    description: Yup.string(),
    notice_for: Yup.string().required("Select for whoom to notify."),
    attachment: Yup.array()
        // .min(1, "Please select at least one file")
        .max(1, "Maximum 1 file.")
        .test("fileSize", "File size must be less than 5MB", (value) =>
            value.every((file) => file.size <= 5000000)
        )
        .test("fileType", "Invalid file type", (value) =>
            value.every((file) => PDF_DOC_DOCX_PNG_JPG_JPEG.includes(file.type))
        ),
});

export default function NoticeEdit() {
    const queryClient = useQueryClient();
    const params = useParams();
    const id = parseInt(params.id, 10);
    const navigate = useNavigate();

    const { roles, roleLoading } = useRoles();

    const { data: notice, isLoading } = useNotice(id);
    const { control, handleSubmit, reset, watch } = useForm({ resolver: yupResolver(validationSchema), mode: "onChange", });

    const update = useMutation(data => updateNotice(id, data), {
        onSuccess: (data) => {
            if (data.success) {
                toast.success(data.message);
                queryClient.invalidateQueries('notice');
                navigate('/notices/manage');
            } else {
                toast.error(data.message);
            }
        },
        onError: (err) => {
            toast.error(err.message);
        }
    });

    const onSubmit = async (data) => {
        const { attachment, ...rest } = data;
        const formData = new FormData();
        formData.append('attachment', attachment[0]);
        for (const key in rest) {
            formData.append(key, rest[key]);
        }

        await update.mutateAsync(formData);
    }
    console.log(roles?.response_data)
    return (
        <Page title={`Edit Notice`}>
            <PageHeader titleEn={`Edit Notice`} titleBn={`নোটিস পরিবর্তন`}
                link={{ to: '/notices/manage', textEn: "Manage Notice", textBn: "নোটিস ব্যবস্থাপনা" }} />



            {(id && isLoading) ? <Loading /> : <AddForm handleSubmit={handleSubmit(onSubmit)} loading={update.isLoading} reset={reset}>
                <TextInput
                    name="title"
                    control={control}
                    value={notice?.response_data?.title}
                    label={{ en: "Notice Title", bn: "নোটিস টাইটেল" }}
                    errorMsg fontBold={false} required />
                <MultilineInput
                    name="description"
                    control={control}
                    value={notice?.response_data?.description}
                    label={{ en: "Deccription", bn: "বিস্তারিত" }}
                    errorMsg />
                <FileInput
                    name="attachment"
                    control={control}
                    fileTypes={PDF_DOC_DOCX_PNG_JPG_JPEG}
                    placeholder=".pdf, .doc, .png, .jpg, .jpeg" required />

                {notice?.response_data?.attachment && !watch('attachment') && <Stack direction="row" alignItems="center" gap={1} justifyContent="flex-end">
                    <BsFileEarmarkText size={18} />
                    <a href={notice?.response_data?.attachment} target="_blank" rel="noreferrer">Attachment File</a>
                </Stack>}

                <SelectField
                    name="notice_for"
                    control={control}
                    subLabel={false}
                    defaultValue={notice?.response_data?.noticeFor?.id}
                    isLoading={roleLoading}
                    data={roles?.response_data}
                    label={{ en: "Notice For?", bn: "কার জন্য?" }}
                    errorMsg fontBold={false} required />

            </AddForm>}

        </Page>
    )
}