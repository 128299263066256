import Page from "../../Layout/Page";
import {
    Button,
    Checkbox,
    Divider,
    FormControlLabel,
    Paper, Radio, RadioGroup,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from "@mui/material";
import { grey, } from "@mui/material/colors";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as Yup from "yup";
import Translate from "../../components/Translate/Translate";
import PageHeader from "../../Layout/PageHeader";
import TCell from "../../components/Table/TCell";
import { HiSave } from "react-icons/hi";
import CheckboxRadio from "../../components/Fields/CheckboxRadio";
import { useState } from "react";
import TRow from "../../components/Table/TRow";
import useClasses from "../../hooks/class/useClasses";
import Loading from "../../Layout/Loading";
import { useMutation, useQuery } from "@tanstack/react-query";
import { getSemesterEnrollments, getSemesterEnrollmentsSelf } from "../../api/fetcherFn/student";
import { createAttendance } from "../../api/fetcherFn/attendance";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import usePermissions from "../../hooks/permission/usePermissions";
import { ROUTES } from "../Settings/permission_data";

const schema = Yup.object().shape({
    attendance: Yup
        .array()
        .of(
            Yup.object().shape({
                student_id: Yup.number(),
                attendance: Yup.string().required("Required.").oneOf(['present', 'absent']),
                remarks: Yup.string()
            })
        ),
});

const CreateAttendance = () => {
    const { check } = usePermissions();
    const queries = new URLSearchParams(window.location.search);
    const courseId = parseInt(queries.get('course'), 10);
    const batchId = parseInt(queries.get('batch'), 10);
    const classId = parseInt(queries.get('class'), 10);
    const semesterNum = parseInt(queries.get('semester'), 10);

    const navigate = useNavigate();

    const [selectAllPresent, setSelectAllPresent] = useState(false);
    const [selectAllAbsent, setSelectAllAbsent] = useState(false);


    const { data: classesData, isLoading } = useClasses(0, 0, courseId, batchId);
    const _class = classesData?.response_data?.data?.find(c => c.id === classId);
    const subjectId = _class?.subject?.id;

    const { data: enrollmentData, isLoading: studentLoading } = useQuery(['semester-enrollments-data', courseId, batchId, semesterNum, subjectId],
        check(ROUTES.STUDENT.SEMESTER.MANAGE.M) ? () => getSemesterEnrollments(1, 0, courseId, batchId, semesterNum, subjectId) : () => getSemesterEnrollmentsSelf(1, 0, courseId, batchId, semesterNum, subjectId), {
        enabled: !!courseId && !!batchId && !!subjectId
    });

    const students = enrollmentData?.response_data?.data?.flatMap(d => d.students);

    const create = useMutation((data) => createAttendance(data), {
        onSuccess: (data) => {
            if (data.success) {
                toast.success(data.message);
                navigate('/attendance/manage');
            } else {
                toast.error(data.message);
            }
        },
        onError: (err) => {
            toast.error(err.message);
        }
    });

    const { control, handleSubmit, watch, setValue } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            attendance: students?.map((student) => ({
                student_id: student.id,
                attendance: '',
                remarks: ''
            })),
        },
    });


    const handleSelectAllStatus = (status) => {
        setValue('attendance', students.map((student) => ({
            student_id: student.id,
            attendance: status,
        })));
        if (status === 'present') {
            setSelectAllPresent(true);
            setSelectAllAbsent(false);
        } else {
            setSelectAllPresent(false);
            setSelectAllAbsent(true);
        }
    };

    const handleRadioChange = (e, i) => {
        const updatedAttendance = [...watch('attendance')];
        updatedAttendance[i].student_id = students[i].id;
        updatedAttendance[i].attendance = e.target.value;
        setValue('attendance', updatedAttendance);
        setSelectAllPresent(false);
        setSelectAllAbsent(false);
    }

    const handleSaveAttendance = async (data) => {
        const _data = {
            course_id: courseId,
            batch_id: batchId,
            class_id: classId,
            semester_number: semesterNum || undefined,
            subject_id: _class?.subject.id,
            attendances: data.attendance
        };

        await create.mutateAsync(_data);
    }


    return (
        <Page title={"Create Attendance"}>
            <PageHeader titleEn="Create Attendance" titleBn="উপস্থিতি তৈরি করুন"
                link={{ to: '/attendance/manage', textEn: "Manage Attendance", textBn: "উপস্থিতি ব্যবস্থাপনা" }} />

            {isLoading ? <Loading /> :
                <Paper elevation={0} sx={{ px: 3, pt: 2, pb: 4, mt: 2, minHeight: '75vh' }} className="rad-grad">
                    <TRow mt={0} data={[
                        { label: { en: "Course Name", bn: "কোর্সের নাম" }, value: _class?.course.name },
                        { label: { en: "Batch Name", bn: "ব্যাচের নাম" }, value: _class?.batch.name },
                        { label: { en: "Semester", bn: "সেমিস্টার" }, value: `${_class.semester_number ? `Semester ${_class.semester_number}` : 'None'}` },
                        { label: { en: "Subject", bn: "বিষয়" }, value: _class?.subject.name },
                        { label: { en: "Class Name", bn: "ক্লাসের নাম" }, value: _class.name },
                        { label: { en: "Class Date", bn: "ক্লাসের তারিখ" }, value: _class.class_date }
                    ]} />

                    <Divider sx={{ my: 2.5 }} />



                    {studentLoading ? <Loading size={40} /> :
                        <form onSubmit={handleSubmit(handleSaveAttendance)}>
                            <TableContainer sx={{ mt: 2, borderRadius: 1, border: `1px solid ${grey[100]}` }}>
                                <Table sx={{ minWidth: 650 }} size={"medium"}>
                                    <TableHead sx={{ bgcolor: grey[100] }}>
                                        <TableRow>
                                            <TCell bold align="center">#</TCell>
                                            <TCell bold><Translate en="Student ID" bn="শিক্ষার্থীর আইডি" /></TCell>
                                            <TCell bold><Translate en="Student Name" bn="শিক্ষার্থীর নাম" /></TCell>
                                            <TCell bold align="center"><Translate en="Attendance Status" bn="উপস্থিতি স্ট্যাটাস" /> <br />
                                                <RadioGroup row sx={{ justifyContent: 'center' }} >
                                                    <FormControlLabel
                                                        value="present"
                                                        control={
                                                            <CheckboxRadio
                                                                checked={selectAllPresent}
                                                                onChange={() => handleSelectAllStatus('present')}
                                                            // disabled={selectAllAbsent}
                                                            />}
                                                        label="Present"
                                                    />
                                                    <FormControlLabel
                                                        value="absent"
                                                        control={<CheckboxRadio
                                                            checked={selectAllAbsent}
                                                            onChange={() => handleSelectAllStatus('absent')}
                                                        // disabled={selectAllPresent}
                                                        />} label="Absent" />
                                                </RadioGroup>
                                            </TCell>
                                            <TCell bold align="center"><Translate en="Remarks" bn="মন্তব্য" /></TCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {!enrollmentData.success || enrollmentData.response_data.data.length === 0 ?
                                            <TableRow sx={{ textAlign: "center" }}><TCell align="center" bold colSpan={10}>No records found.</TCell></TableRow> :
                                            students?.map((student, i) => (
                                                <TableRow key={i} hover>
                                                    <TCell align="center">{i + 1}</TCell>
                                                    <TCell>{student._id}</TCell>
                                                    <TCell>{student.name}</TCell>
                                                    <TCell align="center">
                                                        <Controller
                                                            name={`attendance[${i}].attendance`}
                                                            control={control}
                                                            render={({ field }) => (
                                                                <RadioGroup {...field} row sx={{ justifyContent: 'center' }}>
                                                                    <FormControlLabel
                                                                        value="present"
                                                                        control={
                                                                            <CheckboxRadio
                                                                                checked={watch(`attendance.${i}.attendance`) === 'present'}
                                                                                onChange={(e) => handleRadioChange(e, i)}
                                                                            // disabled={selectAllAbsent}
                                                                            />}
                                                                        label="Present"
                                                                    />
                                                                    <FormControlLabel
                                                                        value="absent"
                                                                        control={
                                                                            <CheckboxRadio
                                                                                checked={watch(`attendance.${i}.attendance`) === 'absent'}
                                                                                onChange={(e) => handleRadioChange(e, i)}
                                                                            // disabled={selectAllPresent}
                                                                            />}
                                                                        label="Absent"
                                                                    />
                                                                </RadioGroup>
                                                            )}
                                                        />
                                                    </TCell>
                                                    <TCell align="center">
                                                        <Controller
                                                            name={`attendance[${i}].remarks`}
                                                            control={control}
                                                            render={({ field }) => (
                                                                <TextField {...field} size="small" />
                                                            )}
                                                        />
                                                    </TCell>
                                                </TableRow>
                                            ))}

                                    </TableBody>
                                </Table>
                            </TableContainer>

                            <Stack justifyContent="flex-end" direction={{ xs: 'column', md: 'row' }} mt={2}>
                                <Button type="submit" variant={"contained"} size="small" >
                                    <Stack direction={"row"} gap={1} alignItems={"center"}>
                                        <HiSave size={20} style={{ margin: 4 }} />
                                        <Typography textTransform={"capitalize"}><Translate en="Save Attendance" bn="সংরক্ষণ করুন" /></Typography>
                                    </Stack>
                                </Button>
                            </Stack>
                        </form>}


                </Paper>}
        </Page>
    );
}


export default CreateAttendance;