import { toast } from "react-hot-toast";
import api from "../setup";




export const getTotalCourse = async () => {
    try {
        const response = await api.get('/api/course/total-course');
        if (response.data.success) return response.data;
        return null;
    } catch (err) {
        toast.error(err.message);
    }
}
export const getTotalStudent = async () => {
    try {
        const response = await api.get('/api/student/total-student');
        if (response.data.success) return response.data;
        return null;
    } catch (err) {
        toast.error(err.message);
    }
}

export const getTotalExam = async () => {
    try {
        const response = await api.get('/api/exam/total-exam');
        if (response.data.success) return response.data;
        return null;
    } catch (err) {
        toast.error(err.message);
    }
}
export const getTotalCourseStudent = async () => {
    try {
        const response = await api.get('/api/semester-enrollment/total-enrolled');
        if (response.data.success) return response.data;
        return null;
    } catch (err) {
        toast.error(err.message);
    }
}
export const getNoticeboard = async () => {
    try {
        const response = await api.get('/api/notice/noticeboard');
        if (response.data.success) return response.data;
        return null;
    } catch (err) {
        toast.error(err.message);
    }
}
export const getPermissions = async (userId) => {
    try {
        const response = await api.get(`/api/permission/get/${userId}`);
        return response.data;
    } catch (err) {
        toast.error(err.message);
    }
}

export const getTotalClass = async () => {
    try {
        const response = await api.get('/api/class/total-class');
        if (response.data.success) return response.data;
        return null;
    } catch (err) {
        toast.error(err.message);
    }
}

