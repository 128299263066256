import { Button, Divider, Paper, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useAuth } from '../../hooks/useAuth';
import Page from '../../Layout/Page';
import ChangePassword from './ChangePassword';
import ChangeEmail from './ChangeEmail';
import Translate from '../../components/Translate/Translate';
import { grey } from '@mui/material/colors';
import img from '../../assets/security-with-login-pass.jpg';
import usePermissions from '../../hooks/permission/usePermissions';
import { ROUTES } from '../Settings/permission_data';

const AccountSettings = () => {
    const { check } = usePermissions();
    const { lang, user } = useAuth();
    const [changePass, setChangePass] = useState(false);
    const [changeEmail, setChangeEmail] = useState(false);

    return (
        <Page title='Account Settings'>
            <Paper elevation={0} sx={{ p: 4, minHeight: '85vh' }} className='rad-grad'>
                <Typography variant='h5' fontWeight={"bold"}>{lang === 'en' ? 'Account Settings' : 'অ্যাকাউন্ট সেটিংস'}</Typography>
                <Divider sx={{ mt: 1, mb: 3 }} />

                <Stack direction={{ xs: 'column', md: 'row' }} gap={4} minHeight="45vh">
                    <Stack mt={3} gap={0.5}>
                        <Button variant='outlined' disabled={!new RegExp(user?.role, "i").test('admin')}
                            sx={{ textTransform: 'capitalize', fontWeight: 'bold' }}
                            onClick={() => {
                                setChangeEmail(!changeEmail);
                                setChangePass(false);
                            }}><Translate en="Change Email" bn="ইমেইল পরিবর্তন" /></Button> <br />
                        <Button variant='outlined'
                            sx={{ textTransform: 'capitalize', fontWeight: 'bold' }}
                            onClick={() => {
                                setChangePass(!changePass);
                                setChangeEmail(false);
                            }}><Translate en="Change Password" bn="পাসওয়ার্ড পরিবর্তন" /> </Button>
                    </Stack>

                    <Divider sx={{ border: `1px solid ${grey[200]}` }} />
                    {!changeEmail && !changePass && <img src={img} width={'40%'} height={'40%'} alt="img" />}


                    {changePass && <ChangePassword />}
                    {check(ROUTES.SETTINGS.STAFF.MANAGE.M) && check(ROUTES.SETTINGS.STAFF.MANAGE.E) && changeEmail && <ChangeEmail />}
                </Stack>

            </Paper>
        </Page>
    );
};

export default AccountSettings;