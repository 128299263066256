export const accordionData = [
    { title: 'What courses are currently being offered?', details: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Ex saepe, voluptas assumenda est aliquid accusantium cupiditate officiis rerum repellendus corporis!' },
    { title: 'How do I register for a course?', details: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Ex saepe, voluptas assumenda est aliquid accusantium cupiditate officiis rerum repellendus corporis!' },
    { title: 'What are the prerequisites for a course?', details: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Ex saepe, voluptas assumenda est aliquid accusantium cupiditate officiis rerum repellendus corporis!' },
    { title: 'Is there a limit to the number of courses I can take?', details: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Ex saepe, voluptas assumenda est aliquid accusantium cupiditate officiis rerum repellendus corporis!' },
    { title: 'Are there any scholarships or financial aid options available for courses?', details: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Ex saepe, voluptas assumenda est aliquid accusantium cupiditate officiis rerum repellendus corporis!' },
    { title: 'Are there any online or distance learning options for courses?', details: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Ex saepe, voluptas assumenda est aliquid accusantium cupiditate officiis rerum repellendus corporis!' },
];






