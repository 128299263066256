import { Avatar, Divider, Paper, Stack, Typography } from '@mui/material';
import React from 'react';
import { BiEdit } from 'react-icons/bi';
import Translate from '../../components/Translate/Translate';
import Page from '../../Layout/Page';
import { AiFillFacebook, AiOutlineGithub, AiOutlineInstagram, AiOutlineTwitter } from 'react-icons/ai'
import { blueGrey } from '@mui/material/colors';
import { NavLink } from 'react-router-dom';
import useGetUserInfo from '../../hooks/account/useGetUserInfo';
import Loading from '../../Layout/Loading';

const MyProfile = () => {
    const { data: userData, isLoading } = useGetUserInfo();

    if (isLoading) return <Loading />;
    const user = userData?.response_data;

    return (
        <Page title='Profile'>
            <Paper elevation={0} sx={{ p: 4, minHeight: '85vh', }} className='rad-grad' >
                <Typography variant='h5' fontWeight={"bold"} ><Translate en='Profile' bn='প্রোফাইল' /> <NavLink to={`/profile/edit-profile`}><BiEdit size={21} style={{ color: 'blueviolet', marginLeft: '0.5rem' }} /></NavLink> </Typography>
                <Divider sx={{ my: 1 }} />
                <Stack direction={"row"} alignItems={"center"} sx={{ my: 4 }} spacing={2}>
                    {user?.photo_url ?
                        <Avatar
                            src={user.photo_url}
                            alt="Profile"
                            sx={{ width: '5rem', height: '5rem' }}
                        /> :
                        <Avatar sx={{ width: '5rem', height: '5rem', bgcolor: blueGrey[500], fontSize: '2.5rem', fontWeight: 'bold' }}>{user.name.charAt(0)}</Avatar>}
                    <div>
                        <Typography variant='h5' fontWeight={'bold'}>{user.name} </Typography>
                        <Typography variant='subtitle2' color={'gray'}>Role / {user.role.name}</Typography>
                    </div>
                </Stack>
                <Stack sx={{ color: "#4B4B4B" }} gap={0.4}>

                    <Stack direction={"row"} alignItems={"center"} gap={1}>
                        <Typography variant='subtitle1' fontWeight={'bold'}>ID:</Typography>
                        <Typography variant='subtitle1'>{user._id}</Typography>
                    </Stack>

                    <Stack direction={"row"} alignItems={"center"} gap={1}>
                        <Typography variant='subtitle1' fontWeight={'bold'}>Full Name:</Typography>
                        <Typography variant='subtitle1'>{user.name}</Typography>
                    </Stack>
                    <Stack direction={"row"} alignItems={"center"} gap={1}>
                        <Typography variant='subtitle1' fontWeight={'bold'}>Email address:</Typography>
                        <Typography variant='subtitle1'>{user.email}</Typography>
                    </Stack>
                    <Stack direction={"row"} alignItems={"center"} gap={1}>
                        <Typography variant='subtitle1' fontWeight={'bold'}>Phone number:</Typography>
                        <Typography variant='subtitle1'>{user.phone}</Typography>
                    </Stack>
                    <Stack direction={"row"} alignItems={"center"} gap={1} marginTop={2}>
                        <Typography variant='subtitle1' fontWeight={'bold'}>Social connections:</Typography>
                        <Stack direction={"row"} gap={1}>
                            <AiFillFacebook />
                            <AiOutlineTwitter />
                            <AiOutlineInstagram />
                            <AiOutlineGithub />
                        </Stack>
                    </Stack>
                </Stack>
            </Paper>
        </Page>
    );
};

export default MyProfile;