import React, { useState } from "react";
import { Controller } from "react-hook-form";
import { FormControl, FormHelperText, OutlinedInput, Typography } from "@mui/material";
import { red } from "@mui/material/colors";
import Translate from "../Translate/Translate";

function FileInput({ name = "attachments", control, label = { en: "Attachments", bn: "সংযুক্তি" }, multiple = false, fileTypes, placeholder = "", required = false }) {
    const [selectedFiles, setSelectedFiles] = useState([]);

    return (
        <>
            <Controller
                name={name}
                control={control}
                defaultValue={[]}
                render={({ field, formState }) => (
                    <>
                        <FormControl fullWidth variant="outlined" size="small">
                            <Typography color={!!formState.errors?.[name] && red[700]}>
                                <Translate en={`${label.en} (accepts only ${placeholder})`} bn={`${label.bn} (ফাইল ফরম্যাট ${placeholder})`} /><span>{required && "*"}</span>
                            </Typography>

                            <OutlinedInput
                                type="file"
                                error={!!formState.errors?.[name]}
                                inputProps={{
                                    multiple,
                                    accept: fileTypes.join(", "),
                                    onChange: (event) => {
                                        setSelectedFiles([...event.target.files]);
                                        field.onChange([...event.target.files]);
                                    },
                                }}
                            />
                            {!!formState.errors?.[name] && (
                                <FormHelperText error>
                                    {formState?.errors?.[name]?.message}
                                </FormHelperText>)}
                        </FormControl>
                        {!formState.errors?.[name] && selectedFiles.length > 0 && (
                            <ol >
                                {selectedFiles.map((file, index) => (
                                    <li key={index}>{file.name}</li>
                                ))}
                            </ol>
                        )}
                    </>
                )}
            />
        </>
    );
}

export default FileInput;
