import Page from "../../Layout/Page";
import { Grid, Paper, Stack, Typography } from "@mui/material";
import {
    Bar,
    Legend,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
    PieChart,
    Pie,
    Cell,
    BarChart,
    CartesianGrid
} from "recharts";
import DashboardStatecard from "../../components/homepage/DashboardStatecard";
import NoticeBoard from "../ManageNotice/NoticeBoard";
import { useQuery } from "@tanstack/react-query";
import { getNoticeboard, getTotalClass, getTotalCourseStudent, getTotalExam, getTotalStudent } from "../../api/fetcherFn/dashboard";
import Translate from "../../components/Translate/Translate";
import { useAuth } from "../../hooks/useAuth";
import { ROUTES } from "../Settings/permission_data";
import usePermissions from "../../hooks/permission/usePermissions";
import { getAllCourse } from "../../api/fetcherFn/course";


const COLORS = ["#42cbf5", "#428df5"];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
        <text
            x={x}
            y={y}
            fill="white"
            textAnchor={x > cx ? "start" : "end"}
            dominantBaseline="central">
            {`${(percent * 100).toFixed(0)}%`}
        </text>
    );
};


const HomePage = () => {
    const { check } = usePermissions();
    const { lang } = useAuth();
    const { data: course } = useQuery(['tc'], getAllCourse);
    const { data: student } = useQuery(['ts'], getTotalStudent);
    const { data: exam } = useQuery(['te'], getTotalExam);
    const { data: cs } = useQuery(['cs'], getTotalCourseStudent);
    const { data: nb } = useQuery(['nb'], getNoticeboard);
    const { data: tc } = useQuery(['totalClasses'], getTotalClass);


    return (
        <Page title={"Dashboard"}>
            <Grid container spacing={3}>
                <Grid item xs={12} md={3} lg={3}>
                    <DashboardStatecard cardTitle={<Translate en="Courses" bn="কোর্সসমূহ" />} cardData={course?.response_data?.length || 0}
                        img="/assets/images/book.png" bgcolor="#E4D00A" />
                </Grid>
                {check(ROUTES.STUDENT.MANAGE.M) &&
                    <Grid item xs={12} md={3} lg={3}>
                        <DashboardStatecard cardTitle={<Translate en="Students" bn="শিক্ষার্থীসমূহ" />} cardData={student?.response_data?.total || 0}
                            img="/assets/images/student.png" bgcolor="#42cbf5" />
                    </Grid>
                }
                <Grid item xs={12} md={3} lg={3}>
                    <DashboardStatecard cardTitle={<Translate en="Classes" bn="ক্লাসসমূহ" />} cardData={tc?.response_data || 0}
                        img="/assets/images/online-education.png" bgcolor="#f0d57f" />
                </Grid>
                <Grid item xs={12} md={3} lg={3}>
                    <DashboardStatecard cardTitle={<Translate en="Exams" bn="পরীক্ষাসমূহ" />} cardData={exam?.response_data || 0}
                        img="/assets/images/exam.png" bgcolor="#428df5" />
                </Grid>
            </Grid>
            {check(ROUTES.COURSE.MANAGE.M) && <Grid container spacing={3} sx={{ mt: 0 }}>
                {cs &&
                    <Grid item xs={12} md={12} lg={6}>
                        <Paper elevation={0} sx={{ p: 2 }}>
                            <Stack direction={"row"} justifyContent={"space-between"} sx={{ mb: 2 }}>
                                <Typography variant="h6" fontWeight="bold" color="#0F3F62"
                                    sx={{ mt: 2 }}><Translate en="Courses/No. of Students" bn="কোর্সসমূহ / শিক্ষার্থী সংখ্যা" /></Typography>
                            </Stack>
                            <Stack justifyContent="center" alignItems="center" sx={{ width: "100%", paddingTop: "2rem" }}>
                                <ResponsiveContainer height={400}>
                                    <BarChart
                                        width={150}
                                        height={40}
                                        data={cs?.response_data}>
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="course" display="none" label={{ value: lang === 'en' ? "List of Courses" : "কোর্সসমূহ", position: 'insideBottom' }} />
                                        <YAxis label={{ value: lang === 'en' ? "Number of Students" : "ছাত্র/ছাত্রীর সংখ্যা", angle: -90, position: 'insideLeft', textAnchor: 'middle' }} />
                                        <Tooltip />
                                        <Bar dataKey="student" barSize={50} fill="#428df5" />
                                    </BarChart>
                                </ResponsiveContainer>

                            </Stack>
                        </Paper>
                    </Grid>
                }
                {student &&
                    <Grid item xs={12} md={12} lg={6}>
                        <Paper elevation={0} sx={{ p: 2 }}>
                            <Stack direction={"row"} justifyContent={"space-between"} sx={{ mb: 2 }}>
                                <Typography variant="h6" fontWeight="bold" color="#0F3F62"
                                    sx={{ mt: 2 }}><Translate en="Male / Female" bn="ছাত্র / ছাত্রী" /></Typography>
                            </Stack>
                            <Stack direction={"row"} justifyContent="center" alignItems="center"
                                sx={{ height: "100%", width: "100%" }}>
                                <PieChart width={400} height={400}>
                                    <Pie
                                        data={student?.response_data?.genderWiseData}
                                        cx={200}
                                        cy={200}
                                        labelLine={false}
                                        label={renderCustomizedLabel}
                                        outerRadius={130}
                                        fill="#8884d8"
                                        dataKey="value">
                                        {student?.response_data?.genderWiseData.map((entry, index) => (
                                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                        ))}
                                    </Pie>
                                    <Tooltip />
                                    <Legend />
                                </PieChart>
                            </Stack>
                        </Paper>
                    </Grid>}

            </Grid>}
            {check(ROUTES.NOTICEBOARD) && nb &&
                <NoticeBoard />}
        </Page >
    )
}
export default HomePage;