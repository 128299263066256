import Page from "../../Layout/Page";
import {
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as Yup from "yup";
import Translate from "../../components/Translate/Translate";
import PageHeader from "../../Layout/PageHeader";
import FilterForm from "../../components/Filter/FilterForm";
import PermissionDialog from "../../components/Dialogs/PermissionDialog";
import ViewDialog from "../../components/Dialogs/ViewDialog";
import SelectField from "../../components/Fields/SelectField";
import TextInput from "../../components/Fields/TextInput";
import ActionMenu from "../../components/Menus/ActionMenu";
import { BiDotsVerticalRounded } from "react-icons/bi";
import TCell from "../../components/Table/TCell";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  changeStatus,
  deleteUser,
  getUsers,
} from "../../api/fetcherFn/settings";
import Loading from "../../Layout/Loading";
import useRoles from "../../hooks/settings/useRoles";
import DeleteDialog from "../../components/HelperComponents/DeleteDialog";
import usePermissions from "../../hooks/permission/usePermissions";
import { ROUTES } from "./permission_data";
import { IosSwitch } from "../../components/Buttons/IosSwitch";
import { useAuth } from "../../hooks/useAuth";
import { toast } from "react-hot-toast";

const validationSchema = Yup.object().shape({
  role_id: Yup.number()
    .nullable()
    .transform((v) => (!isNaN(v) ? v : null)),
  search_name: Yup.string(),
});

export default function StaffManage() {
  const { user } = useAuth();
  const { check } = usePermissions();
  const queryClient = useQueryClient();
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [openDialog, setOpenDialog] = useState(false);
  const [viewDialog, setViewDialog] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [data, setData] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event, data) => {
    setAnchorEl(event.currentTarget);
    setData(data);
  };
  const { control, handleSubmit, watch } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
  });
  const roleId = watch("role_id");
  const name = watch("search_name");
  const { data: users, isLoading } = useQuery(
    ["users", page + 1, limit, roleId, name],
    () => getUsers(page + 1, limit, roleId, name),
    {
      keepPreviousData: true,
    }
  );
  const { roles, roleLoading } = useRoles();
  const status = useMutation(data => changeStatus(data), {
    onSuccess: (data) => {
      if (data.success) {
        queryClient.invalidateQueries(["users"]);
        toast.success(data.message);
      } else {
        toast.error(data.message)
      }
    },
  });
  const { mutateAsync } = useMutation(() => deleteUser(data.id), {
    onSuccess: (data) => {
      if (data.success) {
        queryClient.invalidateQueries(["users"]);
        toast.success(data.message);
      }
    },
    onError: err => {
      toast.error(err.message);
    }
  });

  const onSubmit = (data) => { };

  const handleChangeStatus = async (e, id) => {
    if (e.currentTarget.checked) {
      await status.mutateAsync({ id, status: "active" });
    } else {
      await status.mutateAsync({ id, status: "inactive" });
    }
  };

  return (
    <Page title={"Staff Manage"}>
      <PageHeader titleEn="Manage Staff" titleBn="কর্মী ব্যবস্থাপনা" />

      <Paper elevation={0} sx={{ px: 3, py: 2, minHeight: '75vh' }} className="rad-grad">
        <FilterForm
          handleSubmit={handleSubmit(onSubmit)}
          navigate="/settings/add-staff"
        >
          <SelectField
            name="role_id"
            control={control}
            subLabel={false}
            isLoading={roleLoading}
            data={roles?.response_data}
            label={{ en: "Select Role", bn: "ভূমিকা নির্বাচন" }}
          />

          <TextInput
            name="search_name"
            control={control}
            label={{ en: "Search", bn: "খুজুন" }}
            placeholder="Search by name..."
          />
        </FilterForm>

        {isLoading ? (
          <Loading />
        ) : (
          <>
            <TableContainer
              sx={{ mt: 2, borderRadius: 1, border: `1px solid ${grey[100]}` }}
            >
              <Table sx={{ minWidth: 650 }} size={"medium"}>
                <TableHead sx={{ bgcolor: grey[100] }}>
                  <TableRow>
                    <TCell bold align="center">
                      #
                    </TCell>
                    <TCell bold>
                      <Translate en="Staff name" bn="কর্মীর নাম" />
                    </TCell>
                    <TCell bold>
                      <Translate en="Email address" bn="ই-মেইল" />
                    </TCell>
                    <TCell bold>
                      <Translate en="Phone" bn="ফোন" />
                    </TCell>
                    <TCell bold>
                      <Translate en="Role" bn="ভূমিকা" />
                    </TCell>
                    <TCell bold align="center">
                      <Translate en="Status" bn="স্ট্যাটাস" />
                    </TCell>
                    <TCell bold align="center">
                      <Translate en="Action" bn="অ্যাকশন" />
                    </TCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!users.success || users.response_data.data.length === 0 ? (
                    <TableRow sx={{ textAlign: "center" }}>
                      <TCell align="center" bold colSpan={7}>
                        Nothing found.
                      </TCell>
                    </TableRow>
                  ) : (
                    users.response_data.data.map((row, index) => (
                      <TableRow key={index} hover>
                        <TCell align="center">{index + 1}</TCell>
                        <TCell>{row?.name}</TCell>
                        <TCell>{row?.email}</TCell>
                        <TCell>{row?.phone}</TCell>
                        <TCell>{row?.role.name}</TCell>
                        <TCell align="center">
                          <IosSwitch
                            size="small"
                            sx={{ mr: 1 }}
                            onChange={(e) => handleChangeStatus(e, row.id)}
                            defaultChecked={row?.status === "active"}
                          />
                          {row?.status === "active" ? "Active" : "Inactive"}
                        </TCell>
                        <TCell align="center">
                          <IconButton onClick={(e) => handleClick(e, row)}>
                            <BiDotsVerticalRounded />
                          </IconButton>
                        </TCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>

            <TablePagination
              component="div"
              count={users?.response_data?.meta.data_found}
              page={page}
              onPageChange={(e, newPage) => {
                setPage(newPage);
              }}
              rowsPerPage={limit}
              onRowsPerPageChange={(e) => {
                setLimit(parseInt(e.target.value, 10));
                setPage(0);
              }}
            />
          </>
        )}
      </Paper>

      <ActionMenu
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        open={open}
        handleView={check(ROUTES.SETTINGS.STAFF.MANAGE.V) && (() => setViewDialog(true))}
        editUrl={check(ROUTES.SETTINGS.STAFF.MANAGE.E) && `/settings/edit-staff/${data.id}`}
        handleDeleteDialog={check(ROUTES.SETTINGS.STAFF.MANAGE.D) && data.id !== user.id && (() => setDeleteDialog(true))}
        setPermissionDialog={check(ROUTES.SETTINGS.PERMISSION.USER_SPECIFIC) && (() => setOpenDialog(true))} />

      {deleteDialog && <DeleteDialog
        handleDelete={async () => await mutateAsync(data.id)}
        dialog={deleteDialog}
        setDialog={setDeleteDialog}
      />}


      {viewDialog && <ViewDialog openDialog={viewDialog} setOpenDialog={setViewDialog}
        metadata={{
          title: {
            en: `Staff Details of "${data.name}"`,
            bn: `স্টাফের বিস্তারিতঃ "${data.name}"`,
          }, data
        }} />}





      {openDialog && <PermissionDialog openDialog={openDialog} setOpenDialog={setOpenDialog}
        metadata={{
          title: {
            en: `Permission: "${data.name}" Role: "${data.role.name}"`,
            bn: `অনুমতি প্রদানঃ "${data.name}" ভূমিকাঃ "${data.role.name}"`,
          }, data,
        }} />}

    </Page>
  );
}
