import { Breadcrumbs, Paper, Stack, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import Translate from '../components/Translate/Translate';

const PageHeader = ({ titleEn = "Title", titleBn = "টাইটেল", link, mb = 3 }) => {

    let links = [];
    if (!link || !link.length) {
        links = [link];
    } else {
        links = [...link];
    }


    return (
        <Paper elevation={0} sx={{ mb: mb, bgcolor: 'transparent' }}>
            <Stack spacing={2} sx={{ py: 2 }} direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                <Typography variant={"h6"} fontWeight={"bold"}><Translate en={titleEn} bn={titleBn} /></Typography>
                <Breadcrumbs separator={" / "} aria-label="breadcrumb">
                    <Link to={"/dashboard/home"} style={{ textDecoration: 'none', color: 'green' }}>
                        <Translate en="Dashboard" bn="ড্যাশবোর্ড" />
                    </Link>
                    {link &&
                        links?.map((l, i) =>
                            <Link to={l.to} style={{ textDecoration: 'none', color: "green" }} key={i + 555}>
                                <Translate en={l.textEn} bn={l.textBn} />
                            </Link>
                        )
                    }
                    <Typography color="text.primary"><Translate en={titleEn} bn={titleBn} /></Typography>
                </Breadcrumbs>
            </Stack>
        </Paper >
    );
};

export default PageHeader;