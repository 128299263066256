import Page from "../../Layout/Page";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as Yup from "yup";
import PageHeader from "../../Layout/PageHeader";
import TextInput from "../../components/Fields/TextInput";
import AddForm from "../../components/Filter/AddForm";
import FormSection from "../../components/Filter/FormSection";
import SelectField from "../../components/Fields/SelectField";
import MultilineInput from "../../components/Fields/MultilineInput";
import PasswordField from "../../components/Fields/PasswordField";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-hot-toast";
import { updateTeacher } from "../../api/fetcherFn/teacher";
import Loading from "../../Layout/Loading";
import useTeacher from "../../hooks/teacher/useTeacher";
import PhotoAvatarUpload from "../../components/Fields/PhotoAvatarUpload";
import ToggleSwitch from "../../components/Fields/ToggleSwitch";
import { PNG_JPG_JPEG_GIF } from "../../Constants/FileTypes";
import { getEighteenYearsPrevDate } from "../../utils/constants";

const validationSchema = Yup.object().shape({
    name_en: Yup.string().required("Teacher's name is required"),
    name_bn: Yup.string().required("Teacher's name is required"),
    gender: Yup.string().nullable().required('Gender is required'),
    dob: Yup
        .string()
        .required("Date of birth is required"),
    nid: Yup.string(),
    phone: Yup.string()
        .required('Mobile number is required').max(12, "Invalid!"),
    designation: Yup.string().required("Required."),
    bio: Yup.string(),
    present_address: Yup.string().required('Present address is required'),
    permanent_address: Yup.string().required('Permanent address is required'),
    email: Yup.string().email("Invalid Email").when('toggle', {
        is: true,
        then: Yup.string().required("Required."),
        otherwise: Yup.string(),
    }),
    password: Yup.string().when('toggle', {
        is: true,
        then: Yup.string().required("Required.").min(6, "Must be at least 6 characters."),
        otherwise: Yup.string(),
    }),
    photo: Yup
        .mixed()
        .test("fileSize", "File size is too large", (value) => {
            if (!value) return true;
            return value && value.size <= 1024000; //1 MB
        })
        .test("fileType", "Unsupported file format", (value) => {
            if (!value) return true;
            return value && ["image/jpeg", "image/png"].includes(value.type);
        })
});

export default function TeacherEdit() {
    const queryClient = useQueryClient();
    const params = useParams();
    const id = parseInt(params.id, 10);
    const navigate = useNavigate();
    const { data: teacher, isLoading } = useTeacher(id);
    const { control, handleSubmit, reset, watch, unregister } = useForm({ resolver: yupResolver(validationSchema), mode: "onChange", });
    const toggle = watch('toggle');

    const update = useMutation(data => updateTeacher(id, data), {
        onSuccess: (data) => {
            if (data.success) {
                toast.success(data.message);
                queryClient.invalidateQueries('teacher');
                navigate('/teachers/manage');
            } else {
                toast.error(data.message);
            }
        },
        onError: (err) => {
            toast.error(err.message);
        }
    });



    const onSubmit = async (data) => {
        const { toggle, email, photo, password, ...rest } = data;
        const formData = new FormData();
        formData.append('photo', photo);
        if (!toggle) {
            for (const key in { password: undefined, email, ...rest }) {
                formData.append(key, rest[key]);
            }
        } else {
            const _data = { email, password, ...rest };
            for (const key in _data) {
                formData.append(key, _data[key]);
            }
        }
        if (id) {
            await update.mutateAsync(formData);
        }
    }

    return (
        <Page title={`${id ? 'Edit' : 'Add'} Teacher`}>
            <PageHeader titleEn={`${id ? 'Edit' : 'Add'} Teacher`} titleBn={`শিক্ষক ${id ? 'তথ্য পরিবর্তন' : 'সংযুক্তিকরণ'}`}
                link={{ to: '/teachers/manage', textEn: "Manage Teachers", textBn: "শিক্ষক ব্যবস্থাপনা" }} />

            {(id && isLoading) ? <Loading /> : <AddForm handleSubmit={handleSubmit(onSubmit)} loading={update.isLoading} reset={reset} maxWidth={700}>
                <PhotoAvatarUpload
                    name="photo"
                    control={control}
                    imageUrl={teacher?.response_data?.user.photo_url}
                    fileType={PNG_JPG_JPEG_GIF} />

                <FormSection title={{ en: "Personal Information", bn: "ব্যাক্তিগত তথ্য" }}>

                    <TextInput
                        name="name_en"
                        control={control}
                        value={teacher?.response_data?.name_en}
                        label={{ en: "Teacher Name (English)", bn: "শিক্ষকের নাম (ইংরেজীতে)" }}
                        errorMsg fontBold={false} required />
                    <TextInput
                        name="name_bn"
                        control={control}
                        value={teacher?.response_data?.name_bn}
                        label={{ en: "Teacher Name (Bangla)", bn: "শিক্ষকের নাম (বাংলায়)" }}
                        errorMsg fontBold={false} required />
                    <SelectField
                        name="gender"
                        control={control}
                        subLabel={false}
                        defaultValue={teacher?.response_data?.gender}
                        data={[{ id: "male", name: "Male" }, { id: "female", name: "Female" }, { id: "other", name: "Other" }]}
                        label={{ en: "Gender", bn: "লিঙ্গ" }}
                        errorMsg fontBold={false} required />
                    <TextInput
                        name="dob"
                        type="date"
                        control={control}
                        min={"1970-01-01"}
                        max={getEighteenYearsPrevDate}
                        value={teacher?.response_data?.dob}
                        label={{ en: "Date of Birth", bn: "জন্ম তারিখ" }}
                        errorMsg fontBold={false} required />
                    <TextInput
                        name="nid"
                        type="number"
                        control={control}
                        value={teacher?.response_data?.nid}
                        label={{ en: "NID Number", bn: "এনআইডি নাম্বার" }}
                        placeholder="e.g. 8263215417"
                        errorMsg fontBold={false} />
                    <TextInput
                        name="phone"
                        type="tel"
                        control={control}
                        value={teacher?.response_data?.user.phone}
                        label={{ en: "Mobile Number", bn: "মোবাইল নাম্বার" }}
                        placeholder="e.g. 01710000000"
                        errorMsg fontBold={false} required />
                    <TextInput
                        name="designation"
                        control={control}
                        value={teacher?.response_data?.designation}
                        label={{ en: "Designation", bn: "উপাধি" }}
                        errorMsg fontBold={false} required />

                    <MultilineInput
                        name="bio"
                        control={control}
                        value={teacher?.response_data?.bio}
                        label={{ en: "Bio (Short Description)", bn: "বায়ো (সংক্ষিপ্ত বিবরণ)" }}
                        rows={3} errorMsg />
                    <MultilineInput
                        name="present_address"
                        control={control}
                        value={teacher?.response_data?.present_address}
                        label={{ en: "Present Address", bn: "বর্তমান ঠিকানা" }}
                        rows={4} errorMsg required />
                    <MultilineInput
                        name="permanent_address"
                        control={control}
                        value={teacher?.response_data?.permanent_address}
                        label={{ en: "Permanent Address", bn: "স্থায়ী ঠিকানা" }}
                        rows={4} errorMsg required />

                </FormSection>

                <ToggleSwitch
                    name='toggle'
                    control={control}
                    label={{ en: "Update Login Information!", bn: "লগইন তথ্য আপডেট করুন!" }}
                    unregister={unregister}
                    unregisterFields={['email', 'password']} />

                {toggle &&
                    <FormSection title={{ en: "Login Informations", bn: "লগইন করার তথ্যাবলী" }}>
                        <TextInput
                            name="email"
                            type="email"
                            control={control}
                            value={toggle && teacher?.response_data?.user.email}
                            label={{ en: "Email", bn: "ইমেইল" }}
                            errorMsg fontBold={false} />
                        <PasswordField
                            name="password"
                            type="password"
                            control={control}
                            label={{ en: "Password", bn: "পাসওয়ার্ড" }}
                            errorMsg fontBold={false} />
                    </FormSection>}
            </AddForm>
            }
        </Page>
    )
}