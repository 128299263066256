import Page from "../../Layout/Page";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as Yup from "yup";
import PageHeader from "../../Layout/PageHeader";
import SelectField from "../../components/Fields/SelectField";
import AddForm from "../../components/Filter/AddForm";
import MultipleSelectField from "../../components/Fields/MultipleSelectField";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";
import useGetAllCourse from "../../hooks/course/useGetAllCourse";
import useTeachers from "../../hooks/teacher/useTeachers";
import useGetATeacher from "../../hooks/teacher/useGetATeacher";
import { updateTeacherAssign } from "../../api/fetcherFn/teacher";
import { toast } from "react-hot-toast";
import Loading from "../../Layout/Loading";
import { useState } from "react";



const EditAssignCourse = () => {
    const queryClient = useQueryClient();
    const params = useParams();
    const id = parseInt(params.id, 10);
    const navigate = useNavigate();
    const [selectDefaultValue, setSelectDefaultValue] = useState("Not Null");

    const allCourse = useGetAllCourse();

    const validationSchema = Yup.object().shape({
        course_id: Yup.number().nullable().transform(v => !isNaN(v) ? v : null).required("Required."),
        batch_id: Yup.number().nullable().transform(v => !isNaN(v) ? v : null).required("Required."),
        semester_number: Yup.number().nullable().transform(v => !isNaN(v) ? v : null).when('course_id', (courseId, schema) => {
            const course = allCourse.data?.response_data?.find(c => c.id === courseId);
            return course?.semester_wise_subjects?.length > 0
                ? schema.nullable().transform(v => !isNaN(v) ? v : null).required('Required.')
                : schema.nullable().transform(v => !isNaN(v) ? v : null);
        }),
        teachers: Yup.array()
            .nullable()
            .required()
            .min(1, "Required"),
    });

    const { control, handleSubmit, watch, setValue } = useForm({ resolver: yupResolver(validationSchema), mode: "onChange", });
    const courseId = watch('course_id');


    const { data, isLoading: teacherLoading } = useTeachers();
    const { data: teacherAssign, isLoading } = useGetATeacher(id);

    const update = useMutation(data => updateTeacherAssign(id, data), {
        onSuccess: (data) => {
            if (data.success) {
                toast.success(data.message);
                queryClient.invalidateQueries(['teacher-assign', id])
                navigate('/teachers/assigned-course-manage');
            } else {
                toast.error(data.message);
            }
        },
        onError: (err) => {
            toast.error(err.message);
        }
    });



    const onSubmit = async (data) => {
        data.teachers = data.teachers?.map(id => ({ id: id }));
        await update.mutateAsync(data);
    }

    const courses = allCourse?.data?.response_data;
    const course = teacherAssign?.response_data?.course.id;
    const semester = teacherAssign?.response_data?.semester_number;
    const semesters = courses && courses?.find(c => c.id === (courseId || course))?.semester_wise_subjects;
    const batches = courses && courses?.find(c => c.id === (courseId || course))?.batches;
    const teachers = data?.response_data?.data.map(d => d.user);


    return (
        <Page title={"Update Assign Course"}>
            <PageHeader titleEn="Update Assign Course" titleBn="কোর্স বরাদ্দকরণ আপডেট"
                link={{ to: '/teachers/assigned-course-manage', textEn: "Assigned Course Management", textBn: "বরাদ্দকৃত কোর্স ব্যবস্থাপনা" }} />


            {isLoading ? <Loading /> : <AddForm gradient={false} handleSubmit={handleSubmit(onSubmit)} loading={update.isLoading}>

                <SelectField
                    name="course_id"
                    control={control}
                    defaultValue={course}
                    data={courses}
                    handelChange={() => {
                        setValue('batch_id', null);
                        setValue('subject_id', null);
                        setValue('semester_number', null);
                        setSelectDefaultValue(false);
                    }}
                    label={{ en: "Select Course", bn: "কোর্স নির্বাচন" }}
                    errorMsg fontBold={false} required
                    link={{ label: "Not found Course? Create one", to: "/courses/add" }} />
                <SelectField
                    name="batch_id"
                    control={control}
                    uniqueKey={courseId}
                    defaultValue={selectDefaultValue && teacherAssign?.response_data?.batch.id}
                    data={batches}
                    label={{ en: "Select Batch", bn: "ব্যাচ নির্বাচন" }}
                    errorMsg fontBold={false} required
                    link={{ label: "Not found Batch? Create one", to: "/batch/add-batch" }} />

                {semesters && <SelectField
                    name="semester_number"
                    control={control}
                    subLabel={false}
                    uniqueKey={courseId}
                    defaultValue={selectDefaultValue && semester}
                    data={semesters?.map(option => ({ id: option.semester, name: `Semester ${option.semester}` }))}
                    label={{ en: "Select Semester", bn: "সেমিস্টার নির্বাচন" }}
                    errorMsg fontBold={false} required={semesters?.length > 0} />}


                {teacherLoading ? <Loading size={30} /> : teachers && <MultipleSelectField
                    name="teachers"
                    control={control}
                    defaultValue={teacherAssign?.response_data?.teachers}
                    data={teachers}
                    subLabel={false}
                    subValue={"_id"}
                    label={{ en: "Teachers List", bn: "শিক্ষকবৃন্দ" }}
                    errorMsg fontBold={false} required />}
            </AddForm>}
        </Page>
    )
}

export default EditAssignCourse;