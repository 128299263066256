import { toast } from "react-hot-toast";
import api from "../setup";

export const manageCGPA = async (id) => {
    try {
        const response = await api.get(`/api/cgpa-config/manage`);
        return response.data;
    } catch (err) {
        toast.error(err.message);
    }
}

export const updateCGPA = async (id, data) => {
    try {
        const response = await api.patch(`/api/cgpa-config/update/${id}`, data);
        return response.data;
    } catch (err) {
        toast.error(err.message);
    }
}

export const getCGPA = async (id) => {
    try {
        const response = await api.get(`/api/cgpa-config/get/${id}`);
        return response.data;
    } catch (err) {
        toast.error(err.message);
    }
}