import { toast } from "react-hot-toast";
import api from "../setup";

export const addBatch = async (data) => {
    try {
        const response = await api.post('/api/batch/add', data);
        return response.data;
    } catch (err) {
        toast.error(err.message);
    }
}
export const updateBatch = async (id, data) => {
    try {
        const response = await api.patch(`/api/batch/update/${id}`, data);
        return response.data;
    } catch (err) {
        toast.error(err.message);
    }
}

export const getBatch = async (id) => {
    try {
        const response = await api.get(`/api/batch/get/${id}`);
        return response.data;
    } catch (err) {
        toast.error(err.message);
    }
}
export const getBatches = async (page, limit, course, name) => {
    try {
        let response;
        if (course) { response = await api.get(`/api/batch/manage?page=${page}&limit=${limit}&course=${course}`); }
        if (name) { response = await api.get(`/api/batch/manage?page=${page}&limit=${limit}&name=${name}`); }
        if (course && name) { response = await api.get(`/api/batch/manage?page=${page}&limit=${limit}&course=${course}&name=${name}`); }
        if (!course && !name) {
            response = await api.get(`/api/batch/manage?page=${page}&limit=${limit}`);
        }
        return response.data;
    } catch (err) {
        toast.error(err.message);
    }
}
export const deleteBatch = async (id) => {
    try {
        const response = await api.delete(`/api/batch/delete/${id}`);
        return response.data;
    } catch (err) {
        toast.error(err.message);
    }
}

export const getAllBatch = async (id) => {
    try {
        const response = await api.get(`/api/batch/all-batch/${id}`);
        return response.data;
    } catch (err) {
        toast.error(err.message);
    }
}