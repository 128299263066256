import Page from "../../Layout/Page";
import {
    Button,
    IconButton,
    Paper,
    Stack,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
} from "@mui/material";
import { grey, } from "@mui/material/colors";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as Yup from "yup";
import Translate from "../../components/Translate/Translate";
import PageHeader from "../../Layout/PageHeader";
import FilterForm from "../../components/Filter/FilterForm";
import SelectField from "../../components/Fields/SelectField";
import TextInput from "../../components/Fields/TextInput";
import TCell from "../../components/Table/TCell";
import ActionMenu from "../../components/Menus/ActionMenu";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import Loading from "../../Layout/Loading";
import { approveRequest, cancelRequest, deleteRequest, exportRequestsToCSV, exportRequestsToExcel, getRequests, getRequestsSelf } from "../../api/fetcherFn/requests";
import { toast } from "react-hot-toast";
import useGetAllCourse from "../../hooks/course/useGetAllCourse";
import usePermissions from "../../hooks/permission/usePermissions";
import { ROUTES } from "../Settings/permission_data";
import DeleteDialog from "../../components/HelperComponents/DeleteDialog";
import { saveAs } from 'file-saver';

const validationSchema = Yup.object().shape({
    batch_id: Yup.number(),
    course_id: Yup.number(),
    student_id: Yup.string(),
});

export default function ManageRequests() {
    const { check } = usePermissions();
    const queryClient = useQueryClient();
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const [data, setData] = useState({});
    const [anchorEl, setAnchorEl] = useState(null);
    const [deleteDialog, setDeleteDialog] = useState(false);
    const open = Boolean(anchorEl);

    const { control, handleSubmit, watch, setValue } = useForm({ resolver: yupResolver(validationSchema), mode: "onChange", });
    const course = watch('course_id');
    const batch = watch('batch_id');
    const studentId = watch('student_id');

    const allCourse = useGetAllCourse();

    const { data: requests, isLoading } = useQuery(['requests', page + 1, limit, course, batch, studentId],
        check(ROUTES.REQUEST.MANAGE.M) ? () => getRequests(page + 1, limit, course, batch, studentId) : () => getRequestsSelf(page + 1, limit, course, batch, studentId), {
        keepPreviousData: true
    });

    const { mutateAsync } = useMutation(() => deleteRequest(data.id), {
        onSuccess: (data) => {
            if (data.success) {
                toast.success(data.message);
                queryClient.invalidateQueries(['requests']);
            } else if (!data.success) {
                toast.error(data.message);
            }
        },
        onError: err => {
            toast.error(err.message);
        }
    });



    const approve = useMutation(() => approveRequest(data.id), {
        onSuccess: (data) => {
            if (data.success) {
                toast.success(data.message);
                queryClient.invalidateQueries(['requests']);
            } else {
                toast.error(data.message);
            }
        },
        onError: (err) => {
            toast.error(err.message);
        }
    });
    const cancel = useMutation(() => cancelRequest(data.id), {
        onSuccess: (data) => {
            if (data.success) {
                toast.success(data.message);
                queryClient.invalidateQueries(['requests']);
            } else if (!data.success) {
                toast.error(data.message);
            }
        },
        onError: err => {
            toast.error(err.message);
        }
    });


    const handleClick = (event, row) => {
        setAnchorEl(event.currentTarget);
        setData(row)
    };

    const onSubmit = (data) => { };

    const exportToExcelFormat = async () => {
        try {
            const responseData = await exportRequestsToExcel();
            const filename = 'application_request_details.xlsx';
            saveAs(responseData, filename);
        } catch (err) {
            toast.error('Error downloading file:', err.message);
        }
    }
    const exportToCSVFormat = async () => {
        try {
            const responseData = await exportRequestsToCSV();
            const filename = 'application_request_details.csv';
            saveAs(responseData, filename);
        } catch (err) {
            toast.error('Error downloading file:', err.message);
        }
    }


    const courses = allCourse?.data?.response_data;
    const batches = courses && courses?.find(c => c.id === course)?.batches;

    return (
        <Page title={"Manage Requests"}>
            <PageHeader titleEn="Manage Requests" titleBn="রিকুয়েস্ট ব্যবস্থাপনা" />

            <Stack direction={"row"} justifyContent={"flex-end"} alignItems={"center"} mb={1} gap={1.5}>
                <Button onClick={exportToExcelFormat} variant="outlined" size="small" sx={{ textTransform: 'unset', fontWeight: "bold" }}>Export to Excel</Button>
                <Button onClick={exportToCSVFormat} variant="outlined" size="small" sx={{ textTransform: 'unset', fontWeight: "bold" }} color="warning">Export to CSV</Button>
            </Stack>
            <Paper elevation={0} sx={{ px: 3, py: 2, minHeight: '75vh' }} className="rad-grad">
                <FilterForm handleSubmit={handleSubmit(onSubmit)} button={check(ROUTES.REQUEST.ADD)} navigate="/applications/new-request">
                    <SelectField
                        name="course_id"
                        control={control}
                        isLoading={allCourse.isLoading}
                        data={courses}
                        handelChange={() => {
                            setValue('batch_id', null);
                        }}
                        label={{ en: "Select Course", bn: "কোর্স নির্বাচন" }} />
                    <SelectField
                        name="batch_id"
                        control={control}
                        data={batches}
                        uniqueKey={course}
                        label={{ en: "Select Batch", bn: "ব্যাচ নির্বাচন" }} />

                    {check(ROUTES.REQUEST.MANAGE.M) && <TextInput
                        name="student_id"
                        control={control}
                        label={{ en: "Student ID", bn: "শিক্ষার্থীর আইডি" }}
                        placeholder="Search by ID..." />}


                </FilterForm>


                {isLoading ? <Loading /> :
                    <>
                        <TableContainer sx={{ mt: 2, borderRadius: 1, border: `1px solid ${grey[100]}` }}>
                            <Table sx={{ minWidth: 650 }} size={"medium"}>
                                <TableHead sx={{ bgcolor: `${grey[100]}` }}>
                                    <TableRow>
                                        <TCell bold align="center">#</TCell>
                                        {check(ROUTES.REQUEST.MANAGE.M) &&
                                            <>
                                                <TCell bold><Translate en="Student ID" bn="শিক্ষার্থীর আইডি" /></TCell>
                                                <TCell bold><Translate en="Name" bn="নাম" /></TCell>
                                            </>}
                                        <TCell bold><Translate en="Course Name" bn="কোর্সের নাম" /></TCell>
                                        <TCell bold><Translate en="Batch" bn="ব্যাচ" /></TCell>
                                        <TCell bold align="center"><Translate en="Semester" bn="সেমিস্টার" /></TCell>
                                        <TCell bold align="center"><Translate en="Request Subject" bn="আবেদনের বিষয়" /></TCell>
                                        <TCell bold align="center"><Translate en="Type" bn="ধরন" /></TCell>
                                        <TCell bold align="center" ><Translate en="Date & Time" bn="সময় ও তারিখ" /></TCell>
                                        <TCell bold align="center"><Translate en="Status" bn="অবস্থা" /></TCell>
                                        <TCell bold align="center"><Translate en="Action" bn="অ্যাকশন" /></TCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {!requests.success || requests.response_data.data.length === 0 ?
                                        <TableRow sx={{ textAlign: "center" }}><TCell align="center" bold colSpan={10}>No record found.</TCell></TableRow> :
                                        requests.response_data.data.map((row, index) => (
                                            <TableRow key={index} hover>
                                                <TCell align="center">{index + 1}</TCell>
                                                {check(ROUTES.REQUEST.MANAGE.M) &&
                                                    <>
                                                        <TCell>{row?.requestBy?._id}</TCell>
                                                        <TCell>{row?.requestBy?.name}</TCell>
                                                    </>}
                                                <TCell>{row.course.name}</TCell>
                                                <TCell>{row?.batch?.name}</TCell>
                                                <TCell>{row?.semester_number ? `Semester ${row?.semester_number}` : "None"}</TCell>
                                                <TCell align="center">{row.document_type}</TCell>
                                                <TCell align="center">{row.transcript_type || "None"}</TCell>
                                                <TCell align="center">{row.created_at}</TCell>
                                                <TCell align="center">{row.request_status}</TCell>
                                                <TCell align="center"><IconButton
                                                    onClick={(e) => handleClick(e, row)}><BiDotsVerticalRounded /></IconButton></TCell>
                                            </TableRow>
                                        ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            component="div"
                            count={requests?.response_data?.meta.data_found}
                            page={page}
                            onPageChange={(e, newPage) => {
                                setPage(newPage);
                            }}
                            rowsPerPage={limit}
                            onRowsPerPageChange={(e) => {
                                setLimit(parseInt(e.target.value, 10));
                                setPage(0);
                            }}
                        />
                    </>}
            </Paper>

            <ActionMenu
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
                open={open}
                viewUrl={check(ROUTES.REQUEST.MANAGE.V) && `/applications/details/${data.id}`}
                handleApprove={check(ROUTES.REQUEST.MANAGE.A) && data.request_status !== 'approved' && (async () => await approve.mutateAsync())}
                handleCancelDialog={check(ROUTES.REQUEST.MANAGE.C) && data.request_status !== 'approved' && data.request_status !== 'cancelled' && (async () => await cancel.mutateAsync())}
                handleDeleteDialog={check(ROUTES.REQUEST.MANAGE.D) && (async () => await mutateAsync())} />



            {deleteDialog && <DeleteDialog handleDelete={async () => await mutateAsync(data.id)} dialog={deleteDialog} setDialog={setDeleteDialog} />}

        </Page>

    );
}