import Page from "../../Layout/Page";
import {
    Paper,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { useState } from "react";
import PageHeader from "../../Layout/PageHeader";
import TCell from "../../components/Table/TCell";
import Translate from "../../components/Translate/Translate";
import Loading from "../../Layout/Loading";
import usePermissions from "../../hooks/permission/usePermissions";
import { ROUTES } from "../Settings/permission_data";
import useNoticeboard from "../../hooks/notice-subject/useNoticeboard";

export default function AllNotice() {
    const { check } = usePermissions();
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const { data: notices, isLoading } = useNoticeboard(page, limit);


    return (
        <Page title={"Noticeboard"}>
            <PageHeader titleEn="Noticeboard" titleBn="নোটিসবোর্ড" />

            <Paper elevation={0} sx={{ px: 3, py: 4, minHeight: '75vh' }} className="rad-grad">
                {isLoading ? <Loading /> :
                    <>
                        <TableContainer sx={{ mt: 2, borderRadius: 1, border: `1px solid ${grey[100]}` }}>
                            <Table sx={{ minWidth: 650 }} size={"medium"}>
                                <TableHead sx={{ bgcolor: `${grey[100]}` }}>
                                    <TableRow>
                                        <TCell bold align="center">#</TCell>
                                        <TCell bold><Translate en="Notice Title" bn="নোটিস টাইটেল" /></TCell>
                                        <TCell bold><Translate en="Download" bn="ডাউনলোড" /></TCell>
                                        <TCell bold><Translate en="Date & Time" bn="সময় ও তারিখ" /></TCell>
                                        {check(ROUTES.NOTICE.MANAGE.M) && <TCell bold><Translate en="Notice For" bn="কার জন্য?" /></TCell>}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {!notices.success || notices.response_data.data.length === 0 ?
                                        <TableRow sx={{ textAlign: "center" }}><TCell align="center" bold colSpan={7}>No record found.</TCell></TableRow> :
                                        notices.response_data.data.map((row, index) => (
                                            <TableRow key={index} hover>
                                                <TCell align="center">{index + 1}</TCell>
                                                <TCell>{row.title}</TCell>
                                                <TCell>{row.attachment ? <a href={row.attachment} target="_blank" rel="noreferrer">Download File</a> : 'No attachment'}</TCell>
                                                <TCell>{row.date}</TCell>
                                                {check(ROUTES.NOTICE.MANAGE.M) && <TCell>{row?.notice_for || "All"}</TCell>}
                                            </TableRow>
                                        ))}

                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            component="div"
                            count={notices?.response_data?.meta.data_found}
                            page={page}
                            onPageChange={(e, newPage) => {
                                setPage(newPage);
                            }}
                            rowsPerPage={limit}
                            onRowsPerPageChange={(e) => {
                                setLimit(parseInt(e.target.value, 10));
                                setPage(0);
                            }}
                        />
                    </>}
            </Paper>
        </Page>
    );
}