import Page from "../../Layout/Page";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as Yup from "yup";
import PageHeader from "../../Layout/PageHeader";
import AddForm from "../../components/Filter/AddForm";
import TextInput from "../../components/Fields/TextInput";
import SelectField from "../../components/Fields/SelectField";
import { useNavigate, } from "react-router-dom";
import useRoles from "../../hooks/settings/useRoles";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { addUser, } from "../../api/fetcherFn/settings";
import { toast } from "react-hot-toast";
import PasswordField from "../../components/Fields/PasswordField";
import PhotoAvatarUpload from "../../components/Fields/PhotoAvatarUpload";
import { PNG_JPG_JPEG_GIF } from "../../Constants/FileTypes";


const validationSchema = Yup.object().shape({
    name: Yup.string().required('Required'),
    phone: Yup.string().required('Required').max(12, "Invalid"),
    email: Yup.string().email('Invalid email').required('Required'),
    password: Yup.string().required('Required').min(6, 'Password must be atleast 6 digits'),
    confirm_password: Yup.string().required('Required'),
    role_id: Yup.number().nullable().required('Required'),
    photo: Yup
        .mixed()
        .test("fileSize", "File size is too large", (value) => {
            if (!value) return true;
            return value && value.size <= 1024000; //1 MB
        })
        .test("fileType", "Unsupported file format", (value) => {
            if (!value) return true;
            return value && ["image/jpeg", "image/png"].includes(value.type);
        })
});


export default function StaffAdd() {
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const { control, handleSubmit, reset } = useForm({ resolver: yupResolver(validationSchema), mode: "onChange", });
    const { roles, roleLoading } = useRoles();
    const add = useMutation(data => addUser(data), {
        onSuccess: (data) => {
            if (data.success) {
                toast.success(data.message);
                queryClient.invalidateQueries('user');
                navigate('/settings/manage-staff');
            } else {
                toast.error(data.message);
            }
        },
        onError: (err) => {
            toast.error(err.message);
        }
    });



    const onSubmit = async (data) => {
        const { confirm_password, ...restData } = data;
        if (data.password !== confirm_password) return toast.error("Password Not Match!");
        const formData = new FormData();
        for (const key in restData) {
            formData.append(key, restData[key]);
        }
        await add.mutateAsync(formData);

    }

    const filteredRoles = roles?.response_data?.filter(role => !/^student$|^teacher$/i.test(role.name));

    return (
        <Page title={`Add Staff`}>
            <PageHeader titleEn={`Add Staff`} titleBn={`কর্মী সংযুক্তি`}
                link={{ to: '/settings/manage-staff', textEn: 'Manage Staff', textBn: 'কর্মী ব্যবস্থাপনা' }} />


            <AddForm handleSubmit={handleSubmit(onSubmit)} loading={add.isLoading} reset={reset}>
                <PhotoAvatarUpload
                    name="photo"
                    control={control}
                    fileType={PNG_JPG_JPEG_GIF} />

                <TextInput
                    name="name"
                    control={control}
                    label={{ en: "Staff Name", bn: "কর্মীর নাম" }}
                    errorMsg fontBold={false} required autoFocus />
                <TextInput
                    name="phone"
                    type="tel"
                    control={control}
                    label={{ en: "Mobile Number", bn: "মোবাইল নাম্বার" }}
                    errorMsg fontBold={false} required />
                <TextInput
                    name="email"
                    type="email"
                    control={control}
                    label={{ en: "Email", bn: "ইমেইল" }}
                    errorMsg fontBold={false} required />

                <PasswordField
                    name="password"
                    type="password"
                    control={control}
                    label={{ en: "Password", bn: "পাসওয়ার্ড" }}
                    errorMsg fontBold={false} required />
                <PasswordField
                    name="confirm_password"
                    type="password"
                    control={control}
                    label={{ en: "Confirm Password", bn: "কনফার্ম পাসওয়ার্ড" }}
                    errorMsg fontBold={false} required />


                <SelectField
                    name="role_id"
                    control={control}
                    subLabel={false}
                    isLoading={roleLoading}
                    data={filteredRoles}
                    label={{ en: "Select Role", bn: "ভূমিকা নির্বাচন" }}
                    errorMsg fontBold={false} required />

            </AddForm>


        </Page>
    )
}