import { Button, CircularProgress, Grid, Stack } from '@mui/material';
import React from 'react';
import { HiPlus } from 'react-icons/hi';
import { BsSearch } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import Translate from '../Translate/Translate';

const FilterForm = ({ handleSubmit, isLoading = false, search = false, button = true, btnText = { en: "Add New", bn: "নতুন সংযুক্তি" }, navigate = null, handleBtn, children }) => {
    const ng = useNavigate();
    let childrenProps = [];
    if (!children.length) {
        childrenProps = [children];
    } else {
        childrenProps = children.filter(child => child !== false);
    }

    return (
        <form onSubmit={handleSubmit && handleSubmit}>
            <Grid container alignItems="flex-end" justifyContent="flex-end" flexWrap={'wrap'} spacing={1}>

                {childrenProps?.map((item, i) =>
                    <Grid item xs={12} md={childrenProps?.length === 4 ? 2.5 : childrenProps?.length > 4 ? 2 : 3.33} key={i}>
                        {item}
                    </Grid>)}

                {search && <Grid item xs={12} sm={6} md={1}>
                    <Button type="submit" variant="contained" color='info' fullWidth>
                        {isLoading ? <CircularProgress size={18} style={{ margin: 4, color: '#fff' }} /> : <BsSearch size={20} style={{ margin: 4 }} />}
                    </Button>
                </Grid>}
                {button &&
                    <Grid item xs={12} sm={6} md={2} >
                        <Button onClick={navigate ? (() => ng(navigate)) : (() => handleBtn())} color="info" variant="contained" fullWidth>
                            <Stack direction={"row"} alignItems={"center"} gap={1} textTransform="capitalize" fontWeight="bold">
                                <HiPlus size={20} style={{ margin: 4 }} /><Translate en={btnText.en} bn={btnText.bn} />
                            </Stack>
                        </Button>
                    </Grid>}
            </Grid>

        </form >
    );
};

export default FilterForm;