import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Page from "../Layout/Page";
import { Grid, Paper, Stack } from '@mui/material';
import AccordionElement from '../components/HelpPage.js/AccordionElement';
import { accordionData } from '../_mockData/accordionData';
import { useAuth } from '../hooks/useAuth';
import { Link } from 'react-router-dom';


const GetHelp = () => {
    const { lang } = useAuth();

    return (
        <Page title={"Help"}>
            <Paper elevation={0} sx={{ px: 4, py: 3, minHeight: '100vh' }}>
                <Typography variant='h5' sx={{ mb: 1, fontWeight: 'bold' }}>{lang === "en" ? "Help Page" : "হেল্প পেইজ"}</Typography>
                <Typography variant='subtitle2' sx={{ mb: 5, color: 'gray' }}>Welcome to our help page! Here you will find answers to frequently asked questions, as well as resources to assist you with any issues you may be experiencing. If you can't find what you're looking for, please don't hesitate to contact our customer support team. </Typography>
                <Grid container alignItems={"center"}>
                    <Grid item xs={12} md={6}>
                        <img src="/assets/images/faq.jpg" alt="FAQ" style={{ width: '100%' }} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <div>
                            {
                                accordionData?.map((accordion, i) => <AccordionElement
                                    key={i}
                                    title={accordion.title}
                                    details={accordion.details} />)
                            }
                        </div>
                    </Grid>
                </Grid>

                <Stack direction={"row"} justifyContent={"center"} marginTop={5}>
                    <Typography variant='h6'>Still have a question? <Link to=''>Contact us</Link></Typography>
                </Stack>
            </Paper>
        </Page >
    )
};

export default GetHelp;