import Page from "../../Layout/Page";
import {
    Button,
    IconButton,
    Paper,
    Stack,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as Yup from "yup";
import { useAuth } from "../../hooks/useAuth";
import PageHeader from "../../Layout/PageHeader";
import FilterForm from "../../components/Filter/FilterForm";
import PermissionDialog from "../../components/Dialogs/PermissionDialog";
import TextInput from "../../components/Fields/TextInput";
import { BiDotsVerticalRounded } from "react-icons/bi";
import TCell from "../../components/Table/TCell";
import ActionMenu from "../../components/Menus/ActionMenu";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import DeleteDialog from "../../components/HelperComponents/DeleteDialog";
import { deleteTeacher, exportToCSV, exportToExcel, } from "../../api/fetcherFn/teacher";
import Loading from "../../Layout/Loading";
import useTeachers from "../../hooks/teacher/useTeachers";
import usePermissions from "../../hooks/permission/usePermissions";
import { ROUTES } from "../Settings/permission_data";
import { toast } from "react-hot-toast";
import { saveAs } from 'file-saver';

const validationSchema = Yup.object().shape({
    search: Yup.string(),
});

export default function TeachersManage() {
    const { check } = usePermissions();
    const queryClient = useQueryClient();
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const [data, setData] = useState({});
    const [anchorEl, setAnchorEl] = useState(null);
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [permissionDialog, setPermissionDialog] = useState(false);
    const open = Boolean(anchorEl);
    const { lang } = useAuth();

    const { control, handleSubmit, watch } = useForm({ resolver: yupResolver(validationSchema), mode: "onChange", });
    const name = watch('search');
    const { data: teachers, isLoading } = useTeachers(page, limit, name);
    const { mutateAsync } = useMutation(() => deleteTeacher(data.id), { onSuccess: () => { queryClient.invalidateQueries(['teachers']); } });


    const handleClick = (event, data) => {
        setAnchorEl(event.currentTarget);
        setData(data)
    };

    const onSubmit = (data) => { };

    const exportToExcelFormat = async () => {
        try {
            const responseData = await exportToExcel();
            const filename = 'teacher_details.xlsx';
            saveAs(responseData, filename);
        } catch (err) {
            toast.error('Error downloading file:', err.message);
        }
    }
    const exportToCSVFormat = async () => {
        try {
            const responseData = await exportToCSV();
            const filename = 'teacher_details.csv';
            saveAs(responseData, filename);
        } catch (err) {
            toast.error('Error downloading file:', err.message);
        }
    }


    return (
        <Page title={"Teacher Manage"}>
            <PageHeader titleEn="Manage Teachers" titleBn="শিক্ষক ব্যবস্থাপনা" />

            <Stack direction={"row"} justifyContent={"flex-end"} alignItems={"center"} mb={1} gap={1.5}>
                <Button onClick={exportToExcelFormat} variant="outlined" size="small" sx={{ textTransform: 'unset', fontWeight: "bold" }}>Export to Excel</Button>
                <Button onClick={exportToCSVFormat} variant="outlined" size="small" sx={{ textTransform: 'unset', fontWeight: "bold" }} color="warning">Export to CSV</Button>
            </Stack>
            <Paper elevation={0} sx={{ px: 3, py: 2, minHeight: '75vh' }} className="rad-grad">
                <FilterForm handleSubmit={handleSubmit(onSubmit)} button={check(ROUTES.TEACHER.ADD)} navigate="/teachers/add">
                    <TextInput
                        name="search"
                        control={control}
                        // label={{ en: "Search", bn: "খুজুন" }}
                        placeholder="Search by Name..." />
                </FilterForm>

                {isLoading ? <Loading /> :
                    <>
                        <TableContainer sx={{ mt: 2, borderRadius: 1, border: `1px solid ${grey[100]}` }}>
                            <Table sx={{ minWidth: 650 }} size={"medium"}>
                                <TableHead sx={{ bgcolor: grey[100] }}>
                                    <TableRow>
                                        <TCell align="center" bold>#</TCell>
                                        <TCell bold>{lang === "en" ? "Teachers ID" : "শিক্ষকের আইডি"}</TCell>
                                        <TCell bold>{lang === "en" ? "Teachers Name" : "শিক্ষকের নাম"}</TCell>
                                        <TCell bold>{lang === "en" ? "Designation" : "পদবী"}</TCell>
                                        <TCell bold>{lang === "en" ? "Phone" : "ফোন"}</TCell>
                                        <TCell bold>{lang === "en" ? "Email" : "ই-মেইল"}</TCell>
                                        <TCell align="center" bold>{lang === "en" ? "Action" : "অ্যাকশন"}</TCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {!teachers.success || teachers.response_data.data.length === 0 ?
                                        <TableRow sx={{ textAlign: "center" }}><TCell align="center" bold colSpan={7}>No record found.</TCell></TableRow> :
                                        teachers.response_data.data.map((row, index) => (
                                            <TableRow key={index} hover>
                                                <TCell align="center">{index + 1}</TCell>
                                                <TCell>{row.user._id}</TCell>
                                                <TCell>{row.name_en}</TCell>
                                                <TCell>{row.designation}</TCell>
                                                <TCell>{row.user.phone}</TCell>
                                                <TCell>{row.user.email}</TCell>
                                                <TCell align="center"><IconButton
                                                    onClick={(e) => handleClick(e, row)}><BiDotsVerticalRounded /></IconButton></TCell>
                                            </TableRow>
                                        ))}

                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            component="div"
                            count={teachers?.response_data?.meta.data_found}
                            page={page}
                            onPageChange={(e, newPage) => {
                                setPage(newPage);
                            }}
                            rowsPerPage={limit}
                            onRowsPerPageChange={(e) => {
                                setLimit(parseInt(e.target.value, 10));
                                setPage(0);
                            }}
                        />
                    </>
                }
            </Paper>

            <ActionMenu
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
                open={open}
                viewUrl={check(ROUTES.TEACHER.MANAGE.V) && `/teachers/details/${data.id}`}
                editUrl={check(ROUTES.TEACHER.MANAGE.E) && `/teachers/edit/${data.id}`}
                handleDeleteDialog={check(ROUTES.TEACHER.MANAGE.D) && (() => setDeleteDialog(true))}
                setPermissionDialog={check(ROUTES.SETTINGS.PERMISSION.USER_SPECIFIC) && (() => setPermissionDialog(true))} />

            {deleteDialog && <DeleteDialog handleDelete={async () => await mutateAsync(data.id)} dialog={deleteDialog} setDialog={setDeleteDialog} />}
            {permissionDialog && <PermissionDialog openDialog={permissionDialog} setOpenDialog={setPermissionDialog} metadata={{
                title: {
                    en: `Permission access: ${data.user.name}`,
                    bn: `অনুমতি প্রদানঃ ${data.user.role.name}`,
                }, data: data.user,
            }} />}
        </Page>
    );
}