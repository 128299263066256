import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Dialog, DialogContent, DialogTitle, Stack } from "@mui/material";
import Translate from "../Translate/Translate";
import { AiFillCloseCircle, AiFillPrinter } from "react-icons/ai";

const localizer = momentLocalizer(moment, { timezone: 'Asia/Dhaka' });

const CalenderDialog = ({ openDialog, setOpenDialog, events, title = { en: "Calender View", bn: "ক্যালেন্ডার ভিউ" } }) => {

    return (
        <Dialog fullWidth fullScreen onClose={() => setOpenDialog(!openDialog)} open={openDialog} scroll="body" >
            <DialogTitle fontWeight="bold">
                <Stack direction="row" alignItems="center" justifyContent="space-between" gap={2}>
                    <Translate en={title.en} bn={title.bn} />
                    <Stack direction="row" alignItems="center" gap={2}>
                        <AiFillPrinter size={25} color="gray" cursor="pointer" title="Close" onClick={() => window.print()} />
                        <AiFillCloseCircle size={30} cursor="pointer" title="Close" onClick={() => setOpenDialog(!openDialog)} />
                    </Stack>
                </Stack>
            </DialogTitle>
            <DialogContent sx={{ paddingY: 4 }}>
                <Calendar
                    localizer={localizer}
                    events={events}
                    startAccessor="start"
                    endAccessor="end"
                    views={["month", "work_week", "week", "day"]}
                    defaultView="month"
                    style={{ height: "100vh" }}
                    showMultiDayTimes
                />
            </DialogContent>
        </Dialog>
    );
};

export default CalenderDialog;
