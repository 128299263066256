import { toast } from "react-hot-toast";
import api from "../setup";

export const addCourse = async (data) => {
    try {
        const response = await api.post('/api/course/add', data);
        return response.data;
    } catch (err) {
        toast.error(err.message);
    }
}
export const updateCourse = async (id, data) => {
    try {
        const response = await api.patch(`/api/course/update/${id}`, data);
        return response.data;
    } catch (err) {
        toast.error(err.message);
    }
}

export const getCourse = async (id) => {
    try {
        const response = await api.get(`/api/course/get/${id}`);
        return response.data;
    } catch (err) {
        toast.error(err.message);
    }
}
export const getCourses = async (page, limit, name) => {
    try {
        let response;
        if (name) { response = await api.get(`/api/course/manage?page=${page}&limit=${limit}&name=${name}`); }
        else {
            response = await api.get(`/api/course/manage?page=${page}&limit=${limit}`);
        }
        return response.data;
    } catch (err) {
        toast.error(err.message);
    }
}
export const deleteCourse = async (id) => {
    try {
        const response = await api.delete(`/api/course/delete/${id}`);
        return response.data;
    } catch (err) {
        toast.error(err.message);
    }
}


export const getAllCourse = async () => {
    try {
        const response = await api.get(`/api/course/all-course`);
        return response.data;
    } catch (err) {
        toast.error(err.message);
    }
}