import Page from "../../Layout/Page";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as Yup from "yup";
import PageHeader from "../../Layout/PageHeader";
import AddForm from "../../components/Filter/AddForm";
import SelectField from "../../components/Fields/SelectField";
import MultipleSelectField from "../../components/Fields/MultipleSelectField";
import useGetAllCourse from "../../hooks/course/useGetAllCourse";
import { NavLink, useNavigate, } from "react-router-dom";
import { useMutation, } from "@tanstack/react-query";
import { toast } from "react-hot-toast";
import Loading from "../../Layout/Loading";
import { semesterEnroll, } from "../../api/fetcherFn/student";
import { useState } from "react";
import { Paper, Stack, Typography } from "@mui/material";
import { MdWarning } from "react-icons/md";
import useGetCourseEnrolledStudents from "../../hooks/student/useGetCourseEnrolledStudents";


const SemesterEnrollment = () => {
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState(false);

    const allCourse = useGetAllCourse();

    const validationSchema = Yup.object().shape({
        course_id: Yup.number().nullable().transform(v => !isNaN(v) ? v : null).required("Required"),
        batch_id: Yup.number().nullable().transform(v => !isNaN(v) ? v : null).required("Required"),
        semester_number: Yup.number().nullable().transform(v => !isNaN(v) ? v : null).when('course_id', (courseId, schema) => {
            const course = allCourse.data?.response_data?.find(c => c.id === courseId);
            return course?.semester_wise_subjects?.length > 0
                ? schema.nullable().transform(v => !isNaN(v) ? v : null).required('Required.')
                : schema.nullable().transform(v => !isNaN(v) ? v : null);
        }),
        subjects: Yup.array()
            .nullable().required(),
        students: Yup.array()
            .nullable().required()
            .min(1, "Required"),
    });


    const { control, handleSubmit, watch, setValue } = useForm({ resolver: yupResolver(validationSchema), mode: "onChange", });
    const courseId = watch('course_id');
    const batchId = watch('batch_id');
    const semesterNum = watch('semester_number');

    const { data: studentCourses, isLoading: studentLoading } = useGetCourseEnrolledStudents(0, 0, courseId, batchId, false, false);


    const add = useMutation(data => semesterEnroll(data), {
        onSuccess: (data) => {
            if (data.success) {
                toast.success(data.message);
                navigate('/students/semester-enrollment');
            } else if (!data.success) {
                setErrorMessage(data.message);
                // toast.error(data.message);
            }
        },
        onError: (err) => {
            toast.error(err.message);
        }
    });


    const courses = allCourse?.data?.response_data;
    const batches = courses && courses?.find(c => c.id === courseId)?.batches;
    const semesters = courses && courses?.find(c => c.id === courseId)?.semester_wise_subjects;
    const subjects = courses && (courses?.find(c => c.id === courseId)?.semester_wise_subjects?.find(s => s.semester === semesterNum)?.subjects
        || courses?.find(c => c.id === courseId)?.subjects);

    const students = studentCourses?.response_data?.data?.flatMap(d => d.students);
    const uniqueStudents = students?.filter((student, index, self) => index === self.findIndex((t) => t.id === student.id));

    const onSubmit = async (data) => {
        data.students = data.students?.map(id => ({ id: id }))
        data.subjects = subjects?.map(subject => ({ id: subject.id }));
        await add.mutateAsync(data);
        window.scrollTo(0, 0);
    }



    return (
        <Page title={'Semester Enrollment'}>
            <PageHeader titleEn={'Semester Enrollment'} titleBn={`সেমিস্টার বরাদ্দকরণ`}
                link={{ to: '/students/semester-enrollment', textEn: "Manage Semester Enrollment", textBn: "সেমিস্টার বরাদ্দকরণ ব্যবস্থাপনা" }} />

            <Paper elevation={0} sx={{ minHeight: '75vh' }} className="rad-grad">
                {errorMessage &&
                    <Stack direction={"row"} py={3} color="orangered" alignItems={"center"} gap={1} justifyContent={"center"} ><MdWarning size={25} /> {errorMessage}
                        <NavLink to={`/students/semester-enrollment/edit/${add.data?.response_data?.enrolled_id}`} style={{ color: 'blue', fontWeight: "bold", textDecoration: "underline" }}>Update Existing</NavLink>
                    </Stack>
                }
                <AddForm handleSubmit={handleSubmit(onSubmit)} loading={add.isLoading}>
                    <SelectField
                        name="course_id"
                        control={control}
                        data={courses}
                        handelChange={() => {
                            setValue('batch_id', null);
                            setValue('semester_number', null);
                        }}
                        label={{ en: "Select Course", bn: "কোর্স নির্বাচন" }}
                        errorMsg fontBold={false} required
                        link={{ label: "Not found Course? Create one", to: "/courses/add" }} />
                    <SelectField
                        name="batch_id"
                        control={control}
                        uniqueKey={courseId}
                        data={batches}
                        label={{ en: "Select Batch", bn: "ব্যাচ নির্বাচন" }}
                        errorMsg fontBold={false} required
                        link={{ label: "Not found Batch? Create one", to: "/batch/add-batch" }} />

                    {semesters && <SelectField
                        name="semester_number"
                        control={control}
                        subLabel={false}
                        uniqueKey={courseId}
                        data={semesters?.map(option => ({ id: option.semester, name: `Semester ${option.semester}` }))}
                        label={{ en: "Select Semester", bn: "সেমিস্টার নির্বাচন" }}
                        errorMsg fontBold={false} required={semesters?.length > 0} />}

                    {subjects?.length > 0 && <MultipleSelectField
                        name="subjects"
                        control={control}
                        data={subjects}
                        label={{ en: "Subjects", bn: "বিষয়সমূহ" }}
                        errorMsg fontBold={false} required disabled />}

                    {(courseId && batchId && studentLoading) ? <Loading /> : uniqueStudents?.length > 0 ? <MultipleSelectField
                        name="students"
                        control={control}
                        subLabel={false}
                        subValue={"_id"}
                        data={uniqueStudents}
                        search={true}
                        selectAllValue={true}
                        label={{ en: "Students List", bn: "শিক্ষার্থীর তালিকা" }}
                        errorMsg fontBold={false} required />
                        :
                        (courseId && batchId) &&
                        <Typography variant="body1" my={4} textAlign="center" sx={{ display: 'flex', flexDirection: "column", justifyContent: 'center', alignItems: "center" }}>
                            There is no students enrolled in this course/batch!
                            <NavLink to={'/students/student-enrollment'} style={{ fontSize: '16px', color: 'blue', marginTop: 1, textAlign: 'right', fontWeight: "bold" }}>Enroll Students to Course</NavLink>
                        </Typography>
                    }
                </AddForm>

            </Paper>
        </Page>
    )
}

export default SemesterEnrollment;