import Page from "../../Layout/Page";
import { Stack } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as Yup from "yup";
import PageHeader from "../../Layout/PageHeader";
import AddForm from "../../components/Filter/AddForm";
import TextInput from "../../components/Fields/TextInput";
import { useNavigate, } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-hot-toast";
import Loading from "../../Layout/Loading";
import PasswordField from "../../components/Fields/PasswordField";
import PhotoAvatarUpload from "../../components/Fields/PhotoAvatarUpload";
import ToggleSwitch from "../../components/Fields/ToggleSwitch";
import useGetUserInfo from "../../hooks/account/useGetUserInfo";
import { updateUserInfo } from "../../api/fetcherFn/accountSettings";
import { PNG_JPG_JPEG_GIF } from "../../Constants/FileTypes";

const validationSchema = Yup.object().shape({
    name: Yup.string().required("Required."),
    phone: Yup.string().required("Required."),
    password: Yup.string().when('toggle', {
        is: true,
        then: Yup.string().required("Required.").min(6, "Must be at least 6 characters."),
        otherwise: Yup.string(),
    }),
    confirm_password: Yup.string().when('toggle', {
        is: true,
        then: Yup.string().required("Required."),
        otherwise: Yup.string(),
    }),

});



export default function EditProfile() {
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const { data: user, isLoading } = useGetUserInfo();
    const { control, handleSubmit, reset, watch, unregister } = useForm({ resolver: yupResolver(validationSchema), mode: "onChange" });

    const toggle = watch('toggle');

    const update = useMutation(data => updateUserInfo(data), {
        onSuccess: (data) => {
            if (data.success) {
                toast.success(data.message);
                queryClient.invalidateQueries('user-info');
                navigate('/profile');
            } else {
                toast.error(data.message);
            }
        },
        onError: (err) => {
            toast.error(err.message);
        }
    });


    const onSubmit = async (data) => {
        const { password, confirm_password, toggle, ...restData } = data;
        if (toggle) {
            if (password !== confirm_password) return toast.error("Password Not Match!");
        }
        const formData = new FormData();
        if (!toggle) {
            for (const key in { password: undefined, ...restData }) {
                formData.append(key, restData[key]);
            }
        } else {
            const _data = { password, ...restData };
            for (const key in _data) {
                formData.append(key, _data[key]);
            }
        }
        await update.mutateAsync(formData);
    }



    return (
        <Page title='Edit Profile'>
            <PageHeader titleEn={`Edit Profile`} titleBn={`প্রোফাইল তথ্য পরিবর্তন`}
                link={{ to: '/profile', textEn: 'Profile', textBn: 'প্রোফাইল' }} />


            {isLoading ? <Loading /> : <AddForm handleSubmit={handleSubmit(onSubmit)} loading={update.isLoading} reset={reset}>
                <PhotoAvatarUpload
                    name="photo"
                    control={control}
                    imageUrl={user?.response_data?.photo_url}
                    fileType={PNG_JPG_JPEG_GIF} />

                <TextInput
                    name="name"
                    control={control}
                    value={user?.response_data?.name}
                    label={{ en: "Full Name", bn: "পুরো নাম" }}
                    errorMsg fontBold={false} required />
                <TextInput
                    name="phone"
                    type="tel"
                    value={user?.response_data?.phone}
                    control={control}
                    label={{ en: "Mobile Number", bn: "মোবাইল নাম্বার" }}
                    errorMsg fontBold={false} required />

                <ToggleSwitch
                    name='toggle'
                    control={control}
                    label={{ en: "Update password!", bn: "পাসওয়ার্ড আপডেট করুন!" }}
                    unregister={unregister}
                    unregisterFields={['password']} />


                {toggle &&
                    <Stack gap={2}>
                        <PasswordField
                            name="password"
                            type="password"
                            control={control}
                            label={{ en: "Password", bn: "পাসওয়ার্ড" }}
                            errorMsg fontBold={false} required />
                        <PasswordField
                            name="confirm_password"
                            type="password"
                            control={control}
                            label={{ en: "Confirm Password", bn: "কনফার্ম পাসওয়ার্ড" }}
                            errorMsg fontBold={false} required />
                    </Stack>
                }

            </AddForm>}


        </Page>
    )
}