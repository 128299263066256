import { useQuery } from '@tanstack/react-query';
import { getClasses, getClassesSelf } from '../../api/fetcherFn/class';
import usePermissions from '../permission/usePermissions';
import { ROUTES } from '../../Pages/Settings/permission_data';

const useClasses = (page, limit = 0, course, batch, semester) => {
    const { check } = usePermissions();
    return useQuery(['classes', page + 1, limit, course, batch, semester],
        check(ROUTES.CLASS.MANAGE.M) ? () => getClasses(page + 1, limit, course, batch, semester) : () => getClassesSelf(page + 1, limit, course, batch, semester), {
        keepPreviousData: true
    });
};

export default useClasses;