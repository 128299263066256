import { useQuery } from '@tanstack/react-query';
import { getSemesterEnrollments, getSemesterEnrollmentsSelf } from '../../api/fetcherFn/student';
import usePermissions from '../permission/usePermissions';
import { ROUTES } from '../../Pages/Settings/permission_data';

const useGetSemesterEnrolledStudents = (page = 0, limit = 0, course, batch, semesterNum, enabled = true, keepPreviousData = true) => {
    const { check } = usePermissions();
    return useQuery(['semester-enrollments', page + 1, limit, course, batch, semesterNum],
        check(ROUTES.STUDENT.SEMESTER.MANAGE.M) ? () => getSemesterEnrollments(page + 1, limit, course, batch, semesterNum) : () => getSemesterEnrollmentsSelf(page + 1, limit, course, batch, semesterNum), {
        keepPreviousData: keepPreviousData, enabled: enabled ? true : !!course && !!batch
    });
};

export default useGetSemesterEnrolledStudents;