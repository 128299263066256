import Page from "../../Layout/Page";
import { useParams } from "react-router-dom";
import { Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import PageHeader from "../../Layout/PageHeader";
import TRow from "../../components/Table/TRow";
import Loading from "../../Layout/Loading";
import React, { useRef } from 'react'
import TCell from "../../components/Table/TCell";
import { useAuth } from "../../hooks/useAuth";
import { grey, } from "@mui/material/colors";
import { IoMdPrint } from "react-icons/io";
import { HiArrowRight } from "react-icons/hi";
import useCourse from "../../hooks/course/useCourse";
import { useReactToPrint } from "react-to-print";
import Translate from "../../components/Translate/Translate";


const CourseDetails = () => {
  const { lang } = useAuth();
  const { id } = useParams();
  const { data, isLoading } = useCourse(id);
  const course = data?.response_data;
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Course Details`,
  });


  return (

    <Page title="Course Details">
      <PageHeader titleEn="Course Details" titleBn="কোর্সের বিস্তারিত"
        link={{ to: '/courses/manage', textEn: "Manage Course", textBn: "কোর্স ব্যবস্থাপনা" }} />

      {isLoading ? <Loading /> :
        <Stack component={Paper} px={{ md: 4 }} sx={{ py: 2, maxWidth: 900, margin: '0 auto' }} className="rad-grad" >
          <div style={{ width: '100%', display: 'inline-flex', justifyContent: 'flex-end' }}>
            <IoMdPrint onClick={handlePrint} size={25} color="gray" cursor="pointer" />
          </div>
          <Stack ref={componentRef} p={{ md: 4, xs: 2 }} >

            {course.type === 'long' ? <TRow data={[
              { label: { en: "Course ID", bn: "কোর্সের আইডি" }, value: course.id },
              { label: { en: "Course Name", bn: "কোর্সের নাম" }, value: course.name },
              { label: { en: "Course Type", bn: "কোর্সের ধরন" }, value: course.type },
              { label: { en: "Total Credit", bn: "সর্বমোট ক্রেডিট" }, value: course.total_credit },
              { label: { en: "Duration(in months)", bn: "কোর্সের মেয়াদকাল" }, value: course.duration },
              { label: { en: "Course Fee", bn: "কোর্সের ফি" }, value: course.course_fee },
              { label: { en: "Total Semester", bn: "মোট সেমিস্টার" }, value: course.total_semester },
              { label: { en: "Semester Wise Fee", bn: "সেমিস্টার ভিত্তিক ফি" }, value: course.per_semester_fee },
              { label: { en: "Semester Duration(in months)", bn: "সেমিস্টারের মেয়াদকাল(মাসে)" }, value: course.semester_duration },
              { label: { en: "Created at", bn: "তৈরির তারিখ" }, value: course.created_at },
              { label: { en: "Description", bn: "বিস্তারিত" }, value: course.description },
              { label: { en: "Syllabus", bn: "সিলেবাস" }, value: course.syllabus },
            ]} /> :
              <TRow data={[
                { label: { en: "Course ID", bn: "কোর্সের আইডি" }, value: course.id },
                { label: { en: "Course Name", bn: "কোর্সের নাম" }, value: course.name },
                { label: { en: "Course Type", bn: "কোর্সের ধরন" }, value: course.type },
                { label: { en: "Total Credit", bn: "সর্বমোট ক্রেডিট" }, value: course.total_credit },
                { label: { en: "Duration(in months)", bn: "কোর্সের মেয়াদকাল" }, value: course.duration },
                { label: { en: "Course Fee", bn: "কোর্সের ফি" }, value: course.course_fee },
                { label: { en: "Created at", bn: "তৈরির তারিখ" }, value: course.created_at },
                { label: { en: "Syllabus", bn: "সিলেবাস" }, value: course.syllabus },
              ]} />
            }


            {course.type === "long" ?
              course.semester_wise_subjects?.map((data, i) => (
                <Stack gap={1.5} key={i}>
                  <TableContainer >
                    <TableCell sx={{ fontSize: '1.1rem', fontWeight: 'bold', border: 0, px: 0, display: 'inline-flex', alignItems: 'center', gap: 1 }}><Translate en="Semester" bn="সেমিস্টার" /> : <span>{`${data?.semester}`}</span> <HiArrowRight /> <Translate en="Subjects" bn="বিষয়সমূহ" /></TableCell>
                    <Table sx={{ minWidth: 650, borderRadius: 1, border: `1px solid ${grey[100]}` }} size="medium">
                      <TableHead sx={{ bgcolor: grey[100] }}>
                        <TableRow>
                          <TCell bold align="center" >#</TCell>
                          <TCell bold><Translate en="Subject Name" bn="বিষয়ের নাম" /> </TCell>
                          <TCell bold><Translate en="Subject Code" bn="বিষয়ের কোড" /> </TCell>
                          <TCell bold align="center"><Translate en="Subject Credit" bn="বিষয়ের ক্রেডিট" /> </TCell>
                          <TCell bold align="center"><Translate en="Subject Marks" bn="বিষয়ের মার্কস" /> </TCell>
                        </TableRow>
                      </TableHead>
                      <TableBody >
                        {data?.subjects.map((row, index) => (
                          <TableRow key={index}>
                            <TCell align="center">{index + 1}</TCell>
                            <TCell>{row.name}</TCell>
                            <TCell>{row.subject_code}</TCell>
                            <TCell align="center">{row.credit}</TCell>
                            <TCell align="center">{row.marks}</TCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>

                  </TableContainer>
                </Stack>
              ))

              :
              <TableContainer sx={{ mt: 2, borderRadius: 1, border: `1px solid ${grey[100]}` }}>
                <TableCell sx={{ fontSize: '1.1rem', fontWeight: 'bold', border: 0, px: 0, py: 1 }}><Translate en="Subjects" bn="বিষয়সমূহ" /></TableCell>
                <Table sx={{ minWidth: 650, borderRadius: 1, border: `1px solid ${grey[100]}` }} size="medium">
                  <TableHead sx={{ bgcolor: grey[100] }}>
                    <TableRow>
                      <TCell bold align="center" >#</TCell>
                      <TCell bold>{lang === "en" ? "Subject Name" : "বিষয়ের নাম"}</TCell>
                      <TCell bold>{lang === "en" ? "Subject Code" : "বিষয়ের কোড"}</TCell>
                      <TCell bold align="center">{lang === "en" ? "Subject Credit" : "বিষয়ের ক্রেডিট"}</TCell>
                      <TCell bold align="center">{lang === "en" ? "Subject Marks" : "বিষয়ের মার্কস"}</TCell>
                    </TableRow>

                  </TableHead>
                  <TableBody>
                    {course.subjects?.map((item, i) => (
                      <TableRow key={i}>
                        <TCell align="center">{i + 1}</TCell>
                        <TCell>{item.name}</TCell>
                        <TCell>{item.subject_code}</TCell>
                        <TCell align="center">{item.credit}</TCell>
                        <TCell align="center">{item.marks}</TCell>
                      </TableRow>
                    ))
                    }
                  </TableBody>
                </Table>
              </TableContainer>
            }

          </Stack>
        </Stack>
      }
    </Page>

  );
}

export default CourseDetails;