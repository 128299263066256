import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { Button, CircularProgress, Paper, Stack } from '@mui/material';
import React from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from "yup";
import Translate from '../../components/Translate/Translate';
import TextInput from '../../components/Fields/TextInput';
import { useAuth } from '../../hooks/useAuth';
import { useMutation } from '@tanstack/react-query';
import { changeEmail } from '../../api/fetcherFn/accountSettings';
import { toast } from 'react-hot-toast';
import { AiOutlineCloudUpload } from 'react-icons/ai';

const validationSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Required'),
});


const ChangeEmail = () => {
    const { user, logout } = useAuth();
    const { control, handleSubmit } = useForm({ resolver: yupResolver(validationSchema), mode: "onChange", });
    const emailChange = useMutation(data => changeEmail(user.id, data), {
        onSuccess: (data) => {
            if (data.success) {
                toast.success(data.message);
                logout();
            } else {
                toast.error(data.message);
            }
        },
        onError: (err) => {
            toast.error(err.message);
        }
    });

    const onSubmit = async (data) => {
        await emailChange.mutateAsync(data);
    }

    return (
        <Paper sx={{ pt: 2, pb: 4, px: 4 }} className='rad-grad'>
            <form onSubmit={handleSubmit(onSubmit)}  >

                <Stack justifyContent="space-between" alignItems="center" gap={5} width={{ md: 400 }}>
                    <TextInput
                        name="email"
                        type="email"
                        control={control}
                        label={{ en: "Email Address", bn: "ইমেইল এড্রেস" }}
                        errorMsg fontBold={false} required />

                    <Button variant="contained" type="submit" color="primary" size='large'  >
                        <Stack direction="row" alignItems='center' gap={1} textTransform="capitalize">
                            {emailChange.isLoading ? <CircularProgress size={18} /> : <AiOutlineCloudUpload size={20} />}
                            <Translate en="Change Email" bn="ইমেইল পরিবর্তন" />
                        </Stack>
                    </Button>
                </Stack>
            </form>
        </Paper >
    );
};

export default ChangeEmail;