import Page from "../../Layout/Page";
import { useParams } from "react-router-dom";
import { Paper, Stack } from "@mui/material";
import PageHeader from "../../Layout/PageHeader";
import TRow from "../../components/Table/TRow";
import Loading from "../../Layout/Loading";
import useNotice from "../../hooks/notice-subject/useNotice";
import React, { useRef } from 'react'
import { IoMdPrint } from "react-icons/io";
import { useReactToPrint } from "react-to-print";
import { BsFileEarmarkText } from "react-icons/bs";


const NoticeDetails = () => {
    const { id } = useParams();
    const { data: notice, isLoading } = useNotice(id);
    const _notice = notice?.response_data;
    const componentRef = useRef();

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: `Notice Details of ${_notice?.title}`,
    });


    return (
        <Page title="Notice Details">
            <PageHeader titleEn="Notice Details" titleBn="নোটিশের বিস্তারিত"
                link={{ to: '/notices/manage', textEn: "Manage Notice", textBn: "নোটিশ ব্যবস্থাপনা" }} />

            {isLoading ? <Loading /> :
                <Stack component={Paper} px={{ md: 4 }} sx={{ py: 2, maxWidth: 900, minHeight: '75vh', margin: '0 auto' }} className="rad-grad">
                    <div style={{ width: '100%', display: 'inline-flex', justifyContent: 'flex-end' }}>
                        <IoMdPrint onClick={handlePrint} size={25} color="gray" cursor="pointer" />
                    </div>

                    <Stack ref={componentRef} p={{ md: 4, xs: 2 }} gap={4}>
                        <TRow data={[
                            { label: { en: "Notice Title", bn: "নোটিশ টাইটেল" }, value: _notice.title },
                            { label: { en: "Notice Date", bn: "নোটিশ তারিখ" }, value: _notice.date },
                            { label: { en: "Notice For", bn: "কার উদ্দেশ্যে?" }, value: _notice.noticeFor.name },
                            {
                                label: { en: "Attachment", bn: "সংযুক্তি" }, value: <Stack direction="row" alignItems="center" gap={1} >
                                    <BsFileEarmarkText size={18} />
                                    <a href={_notice.attachment} target="_blank" download rel="noreferrer">Download File</a>
                                </Stack>
                            },
                            { label: { en: "Notice Description", bn: "নোটিশের বিস্তারিত" }, value: _notice.description },
                        ]} />

                    </Stack>
                </Stack>}
        </Page>
    );
}

export default NoticeDetails;