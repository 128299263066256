import Page from "../../Layout/Page";
import {
    Button,
    IconButton,
    Paper,
    Stack,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
} from "@mui/material";
import { grey, } from "@mui/material/colors";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as Yup from "yup";
import Translate from "../../components/Translate/Translate";
import PageHeader from "../../Layout/PageHeader";
import FilterForm from "../../components/Filter/FilterForm";
import SelectField from "../../components/Fields/SelectField";
import TCell from "../../components/Table/TCell";
import { BiDotsVerticalRounded } from "react-icons/bi";
import ActionMenu from "../../components/Menus/ActionMenu";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import DeleteDialog from "../../components/HelperComponents/DeleteDialog";
import useGetAllCourse from "../../hooks/course/useGetAllCourse";
import { deleteFinalResult, exportSemesterToCSV, exportSemesterToExcel } from "../../api/fetcherFn/result";
import Loading from "../../Layout/Loading";
import usePermissions from "../../hooks/permission/usePermissions";
import { ROUTES } from "../Settings/permission_data";
import FinalResultDialog from "../../components/Dialogs/FinalResultDialog";
import ResultViewDialog from "../../components/Dialogs/ResultViewDialog";
import useFinalResults from "../../hooks/result/useFinalResults";
import { saveAs } from 'file-saver';
import { toast } from "react-hot-toast";


const validationSchema = Yup.object().shape({
    batch_id: Yup.number(),
    course_id: Yup.number(),
    semester_number: Yup.number(),
});

export default function ManageFinalResult() {
    const { check } = usePermissions();
    const queryClient = useQueryClient();
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const [data, setData] = useState({});
    const [anchorEl, setAnchorEl] = useState(null);
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [resultDialog, setResultDialog] = useState(false);
    const [viewDialog, setViewDialog] = useState(false);
    const open = Boolean(anchorEl);

    const { control, watch, setValue } = useForm({ resolver: yupResolver(validationSchema), mode: "onChange", });
    const course = watch('course_id');
    const batch = watch('batch_id');
    const semesterNum = watch('semester_number');

    const allCourse = useGetAllCourse();

    const { data: results, isLoading } = useFinalResults(page, limit, course, batch, semesterNum);
    const { mutateAsync } = useMutation(() => deleteFinalResult(data.id), { onSuccess: () => { queryClient.invalidateQueries(['final-results']); } });

    const handleClick = (event, row) => {
        setAnchorEl(event.currentTarget);
        setData(row)
    };

    const exportToExcelFormat = async () => {
        try {
            const responseData = await exportSemesterToExcel();
            const filename = 'semester_result_details.xlsx';
            saveAs(responseData, filename);
        } catch (err) {
            toast.error('Error downloading file:', err.message);
        }
    }
    const exportToCSVFormat = async () => {
        try {
            const responseData = await exportSemesterToCSV();
            const filename = 'semester_result_details.csv';
            saveAs(responseData, filename);
        } catch (err) {
            toast.error('Error downloading file:', err.message);
        }
    }

    const courses = allCourse?.data?.response_data;
    const batches = courses && courses?.find(c => c.id === course)?.batches;
    const semesters = courses && courses?.find(c => c.id === course)?.semester_wise_subjects;

    return (
        <Page title={"Manage Final Result"}>
            <PageHeader titleEn="Manage Final Result (Semester Wise)" titleBn="চূড়ান্ত ফলাফল ব্যবস্থাপনা" />


            <Stack direction={"row"} justifyContent={"flex-end"} alignItems={"center"} mb={1} gap={1.5}>
                <Button onClick={exportToExcelFormat} variant="outlined" size="small" sx={{ textTransform: 'unset', fontWeight: "bold" }}>Export to Excel</Button>
                <Button onClick={exportToCSVFormat} variant="outlined" size="small" sx={{ textTransform: 'unset', fontWeight: "bold" }} color="warning">Export to CSV</Button>
            </Stack>
            <Paper elevation={0} sx={{ px: 3, py: 2, minHeight: '75vh' }} className="rad-grad">
                <FilterForm button={check(ROUTES.RESULT.FINAL_PUB)} handleBtn={() => setResultDialog(true)} btnText={{ en: "Publish New", bn: "পাবলিশ করুন" }}>
                    <SelectField
                        name="course_id"
                        control={control}
                        data={courses}
                        handelChange={() => {
                            setValue('batch_id', null);
                            setValue('semester_number', null);
                        }}
                        label={{ en: "Select Course", bn: "কোর্স নির্বাচন" }} />
                    <SelectField
                        name="batch_id"
                        control={control}
                        data={batches}
                        uniqueKey={course}
                        label={{ en: "Select Batch", bn: "ব্যাচ নির্বাচন" }} />
                    <SelectField
                        name="semester_number"
                        control={control}
                        uniqueKey={course}
                        data={semesters?.map(option => ({ id: option.semester, name: `Semester ${option.semester}` }))}
                        label={{ en: "Select Semester", bn: "সেমিস্টার নির্বাচন" }} />
                </FilterForm>


                {isLoading ? <Loading /> :
                    <>
                        <TableContainer sx={{ mt: 2, borderRadius: 1, border: `1px solid ${grey[100]}` }}>
                            <Table sx={{ minWidth: 650 }} size={"medium"}>
                                <TableHead sx={{ bgcolor: `${grey[100]}` }}>
                                    <TableRow>
                                        <TCell bold align="center">#</TCell>
                                        <TCell bold><Translate en="Student ID" bn="শিক্ষার্থীর আইডি" /></TCell>
                                        <TCell bold><Translate en="Student Name" bn="শিক্ষার্থীর নাম" /></TCell>
                                        <TCell bold><Translate en="Course Name" bn="কোর্স নাম" /></TCell>
                                        <TCell bold><Translate en="Batch" bn="ব্যাচ" /></TCell>
                                        <TCell bold><Translate en="Semester" bn="সেমিস্টার" /></TCell>
                                        <TCell bold><Translate en="GPA" bn="প্রাপ্ত জিপিএ" /></TCell>
                                        <TCell bold><Translate en="Published at" bn="প্রকাশের তারিখ" /></TCell>
                                        <TCell bold align="center"><Translate en="Action" bn="অ্যাকশন" /></TCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {!results.success || results?.response_data?.data?.length === 0 ?
                                        <TableRow sx={{ textAlign: "center" }}><TCell align="center" bold colSpan={10}>No record found.</TCell></TableRow> :
                                        results?.response_data?.data?.map((row, index) => (
                                            <TableRow key={index} hover>
                                                <TCell align="center">{index + 1}</TCell>
                                                <TCell>{row?.student?._id}</TCell>
                                                <TCell>{row?.student?.name}</TCell>
                                                <TCell>{row.course.name}</TCell>
                                                <TCell>{row.batch.name}</TCell>
                                                <TCell>{row.semester_number ? `Semester ${row.semester_number}` : 'none'}</TCell>
                                                <TCell>{row.gpa}</TCell>
                                                <TCell>{row.published_at}</TCell>
                                                <TCell align="center"><IconButton
                                                    onClick={(e) => handleClick(e, row)}><BiDotsVerticalRounded /></IconButton></TCell>

                                            </TableRow>
                                        ))}

                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            component="div"
                            count={results?.response_data?.meta?.data_found}
                            page={page}
                            onPageChange={(e, newPage) => {
                                setPage(newPage);
                            }}
                            rowsPerPage={limit}
                            onRowsPerPageChange={(e) => {
                                setLimit(parseInt(e.target.value, 10));
                                setPage(0);
                            }}
                        />
                    </>
                }
            </Paper>

            <ActionMenu
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
                open={open}
                handleView={() => setViewDialog(true)}
                editUrl={check(ROUTES.RESULT.FINAL.E) && `/results/final/publish/${data.id}`}
                handleDeleteDialog={check(ROUTES.RESULT.FINAL.D) && (() => setDeleteDialog(true))} />


            {viewDialog && <ResultViewDialog openDialog={viewDialog} setOpenDialog={setViewDialog}
                metadata={{
                    title: { en: `Semester Result of "${data.student.name}"`, bn: `সেমিস্টার ফলাফলঃ "${data.student.name}"`, }, data
                }} />}

            {deleteDialog && <DeleteDialog handleDelete={async () => await mutateAsync(data.id)} dialog={deleteDialog} setDialog={setDeleteDialog} />}
            {resultDialog && <FinalResultDialog openDialog={resultDialog} setOpenDialog={setResultDialog} />}
        </Page>
    );
}