import { Avatar, Dialog, DialogContent, DialogTitle, Divider, } from "@mui/material";
import Translate from "../Translate/Translate";
import { blueGrey } from "@mui/material/colors";
import TRow from "../Table/TRow";


const ViewDialog = ({ openDialog, setOpenDialog, metadata }) => {
    const { title, data } = metadata;
    const _data = [
        { id: 1, label: { en: "ID", bn: "আইডি" }, value: data._id },
        { id: 2, label: { en: "Staff name", bn: "স্টাফের নাম" }, value: data.name },
        { id: 3, label: { en: "Email address", bn: "ইমেইল" }, value: data.email },
        { id: 4, label: { en: "Phone number", bn: "মোবাইল" }, value: data.phone },
        { id: 4, label: { en: "Role", bn: "ভূমিকা" }, value: data?.role?.name },
        { id: 4, label: { en: "Account Creation Date", bn: "একাউন্ট তৈরির তারিখ" }, value: data.acc_created_at }
    ];

    return (
        <Dialog onClose={() => setOpenDialog(!openDialog)} open={openDialog} fullWidth maxWidth={"sm"} scroll="paper">
            <DialogTitle><Translate en={title.en} bn={title.bn} /></DialogTitle>
            <Divider />
            <DialogContent sx={{ pt: 3, pb: 6 }}>

                {data?.photo_url ?
                    <Avatar
                        src={data.photo_url}
                        alt="Profile"
                        sx={{
                            width: 100,
                            height: 100,
                            display: "flex",
                            margin: "0 auto",
                            marginBottom: 3,
                        }}
                    /> :
                    <Avatar sx={{
                        width: 100,
                        height: 100,
                        display: "flex",
                        margin: "0 auto",
                        marginBottom: 3, bgcolor: blueGrey[400], fontSize: '2.5rem', fontWeight: 'bold'
                    }}>{data.name.charAt(0)}</Avatar>}

                <TRow data={_data} />

            </DialogContent>
        </Dialog>
    );
};

export default ViewDialog;