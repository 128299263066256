import {
    AppBar,
    Avatar,
    Box, Divider,
    IconButton,
    Menu, MenuItem,
    Stack, ToggleButton, ToggleButtonGroup,
    Toolbar,
    Tooltip, Typography,
} from "@mui/material";
import { AiFillSetting, AiOutlineLogin } from "react-icons/ai";
import { HiOutlineMenuAlt1 } from "react-icons/hi";
import { useState } from "react";
import { useAuth } from "../hooks/useAuth";
import { NavLink, useNavigate } from "react-router-dom";
import { blueGrey } from "@mui/material/colors";

const Navbar = ({ open, toggleDrawer, handle }) => {
    const navigate = useNavigate();
    const { user, logout, lang, toggleLang } = useAuth();
    const [anchorEl, setAnchorEl] = useState(null);
    const menuOpen = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };


    return (
        // <AppBar position="fixed" open={open} sx={{ background: "white", zIndex: (theme) => theme.zIndex.drawer + 1, boxShadow: "0 0 8px rgba(0,0,0,0.25)" }}>
        <AppBar position="fixed" open={open} sx={{ background: "white", zIndex: (theme) => theme.zIndex.drawer + 1, boxShadow: 'none', borderBottom: '1px solid lightgray' }}>
            <Toolbar>
                <NavLink to="/dashboard/home">
                    <Stack direction={"row"} alignItems={"center"} gap={1.2}>
                        <img src="/favicon.png" alt="GDN ERP Logo" style={{ maxHeight: "40px" }} />
                        <Typography variant={"h5"} fontWeight={"bold"} color={"#ED1B24"}>BCTI</Typography>
                        {/* <Stack spacing={-1} justifyContent={'flex-start'} marginLeft={1}>
                                <Typography variant={"subtitle"} color={"green"}>Training Info</Typography>
                            </Stack> */}
                    </Stack>
                </NavLink>
                <Stack sx={{ width: "100%" }} direction="row" justifyContent="space-between" alignItems="center">

                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={toggleDrawer}
                        edge="start"
                        sx={{ ml: 2 }} >
                        <HiOutlineMenuAlt1 color={"#0F3F62"} />
                    </IconButton>

                    <Stack direction="row" alignItems="center">
                        <ToggleButtonGroup
                            value={lang}
                            exclusive
                            sx={{ p: 0 }}
                            size={"small"}
                            onChange={toggleLang}
                            aria-label="text alignment" >
                            <ToggleButton sx={{ fontWeight: 'bold' }} value="en">EN</ToggleButton>
                            <ToggleButton sx={{ fontWeight: 'bold' }} value="bn">BN</ToggleButton>
                        </ToggleButtonGroup>
                        <Box>
                            <Tooltip title="Account settings">
                                <IconButton
                                    onClick={handleClick}
                                    size="small"
                                    sx={{ ml: 2 }}
                                    aria-controls={menuOpen ? 'account-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={menuOpen ? 'true' : undefined} >
                                    {user?.photo_url ?
                                        <Avatar
                                            alt="Profile"
                                            src={user?.photo_url}
                                            width={30} height={30} /> :
                                        <Avatar width={30} height={30} sx={{ bgcolor: blueGrey[400], fontSize: '1.2rem', fontWeight: 'bold' }}>{user.name.charAt(0)}</Avatar>}
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </Stack>
                    <Menu
                        anchorEl={anchorEl}
                        id="account-menu"
                        open={menuOpen}
                        onClose={handleClose}
                        onClick={handleClose}
                        PaperProps={{
                            elevation: 0,
                            sx: {
                                overflow: 'visible',
                                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                mt: 1.5,
                                '& .MuiAvatar-root': {
                                    width: 32,
                                    height: 32,
                                    ml: -0.5,
                                    mr: 1,
                                },
                                '&:before': {
                                    content: '""',
                                    display: 'block',
                                    position: 'absolute',
                                    top: 0,
                                    right: 14,
                                    width: 10,
                                    height: 10,
                                    bgcolor: 'background.paper',
                                    transform: 'translateY(-50%) rotate(45deg)',
                                    zIndex: 0,
                                },
                            },
                        }}
                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }} >
                        <MenuItem onClick={() => navigate('/profile')}>
                            <Avatar /> {lang === "en" ? "My Profile" : "আমার প্রোফাইল"}
                        </MenuItem>
                        <MenuItem onClick={() => navigate('/account-settings')}>
                            <Avatar><AiFillSetting /></Avatar> {lang === "en" ? "Account Settings" : "অ্যাকাউন্ট সেটিংস"}
                        </MenuItem>
                        <Divider />
                        <MenuItem onClick={logout}>
                            <Avatar><AiOutlineLogin /></Avatar> {lang === "en" ? "Logout" : "লগ আউট"}
                        </MenuItem>
                    </Menu>
                </Stack>
            </Toolbar>
        </AppBar>
    )
}

export default Navbar