import { Stack, } from '@mui/material';
import React from 'react';
import TRow from '../../components/Table/TRow';
import GpaTable from '../../components/Table/GpaTable';

const FinalResult = ({ data, componentRef, gpaTable }) => {

    return (
        <Stack ref={componentRef} mx={3} my={4}>
            <Stack direction={"row"} justifyContent={"space-between"} gap={2}>
                <TRow mt={1} data={[
                    { label: { en: "Student Name", bn: "শিক্ষার্থীর নাম" }, value: data?.student.name },
                    { label: { en: "Student ID", bn: "শিক্ষার্থীর আইডি" }, value: data?.student._id },
                    { label: { en: "Course Name", bn: "কোর্সের নাম" }, value: data?.course.name },
                    { label: { en: "Batch Name", bn: "ব্যাচের নাম" }, value: data?.batch.name },
                    { label: { en: "Total Credit", bn: "সর্বমোট ক্রেডিট" }, value: data?.course.total_credit },
                    { label: { en: "Duration(In months)", bn: "মেয়াদকাল(মাসে)" }, value: `${data?.course.duration} months` },
                    { label: { en: "Result Published", bn: "রেজাল্ট পাবলিশের তারিখ" }, value: data?.published_at },
                    { label: { en: "CGPA", bn: "সিজিপিএ" }, value: data?.cgpa },
                ]} />


                <Stack display={{ md: 'block', xs: "none" }}>
                    <GpaTable gpaTable={gpaTable} />
                </Stack>
            </Stack>

        </Stack>
    );
};

export default FinalResult;