import Page from "../../Layout/Page";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as Yup from "yup";
import PageHeader from "../../Layout/PageHeader";
import AddForm from "../../components/Filter/AddForm";
import SelectField from "../../components/Fields/SelectField";
import MultilineInput from "../../components/Fields/MultilineInput";
import { useMutation } from "@tanstack/react-query";
import { addRequest } from "../../api/fetcherFn/requests";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { requestDocSubTypes, requestDocTypes } from "../../_mockData/requestDocTypes";
import usePermissions from "../../hooks/permission/usePermissions";
import { ROUTES } from "../Settings/permission_data";
import useGetAllCourse from "../../hooks/course/useGetAllCourse";
import { Stack } from "@mui/material";
import useGetSemesterEnrolledStudents from "../../hooks/student/useGetSemesterEnrolledStudents";



export default function NewRequest() {
    const { check } = usePermissions();
    const navigate = useNavigate();
    const allCourse = useGetAllCourse();

    const validationSchema = Yup.object().shape({
        course_id: Yup.number().nullable().transform(v => !isNaN(v) ? v : null).required("Required."),
        batch_id: Yup.number().nullable().transform(v => !isNaN(v) ? v : null).required("Required."),
        semester_number: Yup.number().when('course_id', (courseId, schema) => {
            const course = allCourse.data?.response_data?.find(c => c.id === courseId);
            return course?.semester_wise_subjects?.length > 0
                ? schema.nullable().transform(v => !isNaN(v) ? v : null).required('Required.')
                : schema.nullable().transform(v => !isNaN(v) ? v : null);
        }),
        requested_for: Yup.number().nullable().transform(v => !isNaN(v) ? v : null),
        document_type: Yup.string().nullable().required('Required'),
        transcript_type: Yup.string().nullable().when('document_type', {
            is: 'transcript',
            then: Yup.string().nullable().required("Required"),
            otherwise: Yup.string().nullable(),
        }),
        details: Yup.string().required('Required')
    });

    const { control, handleSubmit, watch, setValue } = useForm({ resolver: yupResolver(validationSchema), mode: "onChange", });

    const cId = watch('course_id');
    const bId = watch('batch_id');
    const semesterNum = watch('semester_number');
    const docType = watch('document_type');

    const enrolledData = useGetSemesterEnrolledStudents(0, 0, cId, bId, semesterNum, false, false);
    const students = enrolledData?.data?.response_data?.data?.flatMap(data => data.students);
    const uniqueStudents = students?.filter((student, index, self) => index === self.findIndex((t) => t.id === student.id));


    const add = useMutation(data => addRequest(data), {
        onSuccess: (data) => {
            if (data.success) {
                toast.success(data.message);
                navigate('/applications/manage');
            } else {
                toast.error(data.message);
            }
        },
        onError: (err) => {
            toast.error(err.message);
        }
    });

    const onSubmit = async (data) => {
        if (check(ROUTES.REQUEST.MANAGE.M) && data.request_for === undefined) {
            toast.error("Select Student!");
            return;
        }
        await add.mutateAsync(data);
    }


    const courses = allCourse?.data?.response_data;
    const batches = courses && courses?.find(c => c.id === cId)?.batches;
    const semesters = courses && courses?.find(c => c.id === cId)?.semester_wise_subjects;



    return (
        <Page title={"New Request"}>
            <PageHeader titleEn="New Request" titleBn="নতুন রিকুয়েস্ট"
                link={{ to: '/applications/manage', textEn: "Manage Requests", textBn: "রিকুয়েস্ট ব্যবস্থাপনা" }} />


            <AddForm handleSubmit={handleSubmit(onSubmit)} submitBtn="Submit" >

                <SelectField
                    name="course_id"
                    control={control}
                    isLoading={allCourse.isLoading}
                    data={courses}
                    handelChange={() => {
                        setValue('batch_id', null);
                        setValue('semester_number', null);
                        setValue('request_for', null);
                    }}
                    label={{ en: "Select Course", bn: "কোর্স নির্বাচন" }}
                    errorMsg fontBold={false} required />

                <Stack direction="row" gap={1}>
                    <SelectField
                        name="batch_id"
                        control={control}
                        data={batches}
                        uniqueKey={cId}
                        label={{ en: "Select Batch", bn: "ব্যাচ নির্বাচন" }}
                        errorMsg fontBold={false} required />
                    {semesters && <SelectField
                        name="semester_number"
                        control={control}
                        subLabel={false}
                        uniqueKey={cId}
                        data={semesters?.map(option => ({ id: option.semester, name: `Semester ${option.semester}` }))}
                        label={{ en: "Select Semester", bn: "সেমিস্টার নির্বাচন" }}
                        errorMsg fontBold={false} required />}
                </Stack>

                {check(ROUTES.REQUEST.MANAGE.M) && uniqueStudents && <SelectField
                    name="request_for"
                    control={control}
                    isLoading={enrolledData.isLoading}
                    data={uniqueStudents}
                    subLabel={false}
                    subValue={"_id"}
                    label={{ en: "Select Request For", bn: "কার জন্য?" }}
                    errorMsg fontBold={false} required />}

                <SelectField
                    name="document_type"
                    control={control}
                    subLabel={false}
                    data={requestDocTypes}
                    handelChange={() => {
                        setValue('transcript_type', null);
                    }}
                    label={{ en: "Select Document Type", bn: "ডকুমেন্ট ধরন নির্বাচন" }}
                    errorMsg fontBold={false} required />
                {docType === 'transcript' && <SelectField
                    name="transcript_type"
                    control={control}
                    subLabel={false}
                    uniqueKey={docType}
                    data={requestDocSubTypes}
                    label={{ en: "Select Transcript Type", bn: "নম্বরপত্র ধরন নির্বাচন" }}
                    errorMsg fontBold={false} required={docType === 'transcript'} />}

                <MultilineInput
                    name="details"
                    control={control}
                    label={{ en: "Details of the Request", bn: "রিকুয়েস্টের বিস্তারিত" }}
                    errorMsg required rows={10} />
            </AddForm>


        </Page>
    )
}