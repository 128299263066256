import Page from "../../Layout/Page";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as Yup from "yup";
import PageHeader from "../../Layout/PageHeader";
import AddForm from "../../components/Filter/AddForm";
import TextInput from "../../components/Fields/TextInput";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updateResult } from "../../api/fetcherFn/result";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-hot-toast";
import Loading from "../../Layout/Loading";
import useResult from "../../hooks/result/useResult";
import TRow from "../../components/Table/TRow";

const validationSchema = Yup.object().shape({
    total_marks: Yup.number()
        .required("Required"),
    examiner_comment: Yup.string(),
});


const ResultEdit = () => {
    const params = useParams();
    const id = parseInt(params.id, 10);
    const queryClient = useQueryClient();
    const navigate = useNavigate();


    const { control, handleSubmit, } = useForm({ resolver: yupResolver(validationSchema) });

    const { data: resultData, isLoading } = useResult(id);
    const result = resultData?.response_data;

    const update = useMutation((data) => updateResult(id, data), {
        onSuccess: (data) => {
            if (data.success) {
                toast.success(data.message);
                queryClient.invalidateQueries(['result', id]);
                navigate('/results/manage');
            } else {
                toast.error(data.message);
            }
        },
        onError: (err) => {
            toast.error(err.message);
        }
    })

    const onSubmit = async (data) => {
        await update.mutateAsync(data);
    };


    return (
        <Page title={"Edit Result"}>
            <PageHeader titleEn="Edit Result" titleBn="ফলাফল পরিবর্তন"
                link={{ to: '/results/manage', textEn: "Manage Result", textBn: "ফলাফল ব্যবস্থাপনা" }} />


            {(id && isLoading) ? <Loading /> :
                <AddForm handleSubmit={handleSubmit(onSubmit)} loading={update.isLoading} >
                    <TRow mt={0} data={[
                        { label: { en: "Course Name", bn: "কোর্সের নাম" }, value: result?.course.name },
                        { label: { en: "Batch Name", bn: "ব্যাচের নাম" }, value: result?.batch.name },
                        { label: { en: "Semester", bn: "সেমিস্টার" }, value: `${result.semester_number ? `Semester ${result.semester_number}` : 'None'}` },
                        { label: { en: "Subject", bn: "বিষয়" }, value: result?.exam?.subject.name },
                        { label: { en: "Exam Title", bn: "পরীক্ষার টাইটেল" }, value: result?.exam.title },
                        { label: { en: "Exam Date", bn: "পরীক্ষার তারিখ" }, value: result?.exam.exam_date },
                        { label: { en: "Exam Total Marks", bn: "পরীক্ষার সর্বমোট মার্কস" }, value: result?.exam.total_mark },
                    ]} />


                    <TextInput
                        name="total_marks"
                        type="number"
                        control={control}
                        value={result?.total_marks}
                        label={{ en: "Total Marks", bn: "মোট মার্ক" }}
                        placeholder="e.g. 75"
                        errorMsg fontBold={false} required />

                    <TextInput
                        name="examiner_comment"
                        control={control}
                        value={result?.examiner_comment}
                        label={{ en: "Examinner Comment", bn: "পরীক্ষকের মন্তব্য" }}
                        errorMsg fontBold={false} />

                </AddForm>}

        </Page>
    )
}

export default ResultEdit;