// Hook (use-auth.js)
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useState, useEffect, createContext, useContext } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { getloginUser } from "../api/fetcherFn/login";
import jwtDecode from 'jwt-decode';
import { toast } from "react-hot-toast";

const authContext = createContext();
// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function ProvideAuth({ children }) {
    const auth = useProvideAuth();
    return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => {
    return useContext(authContext);
};

// Provider hook that creates auth object and handles state
function useProvideAuth() {
    const [user, setUser] = useState(null);
    const [lang, setLang] = useState(localStorage.getItem('lang') || 'bn');
    const [showDelete, setShowDelete] = useState(false);
    const navigate = useNavigate()

    const queryClient = useQueryClient();
    const [isLoading, setIsLoading] = useState(true);

    // handle user login
    const login = useMutation(data => getloginUser(data), {
        onSuccess: (data) => {
            if (data.success) {
                localStorage.setItem('accessToken', data.response_data.accessToken);
                const decodedToken = jwtDecode(data.response_data.accessToken);
                queryClient.clear();
                setUser(decodedToken);
                toast.success(data.message);
                navigate('/dashboard');
                // window.location.reload()
            } else {
                toast.error(data.message)
            }
        }
    });

    // handle user logout
    const logout = () => {
        localStorage.removeItem('accessToken');
        navigate('/login');
        setUser(null);

        // invalidate any existing query data
        queryClient.clear();
    };
    // get the user data from local storage
    useEffect(() => {
        const storedToken = localStorage.getItem('accessToken');
        if (storedToken) {
            const decodedToken = jwtDecode(storedToken);
            setUser(decodedToken);
            setIsLoading(false);
        }
        setIsLoading(false);
    }, []);

    const toggleLang = () => {
        if (lang === 'bn') {
            setLang('en');
            localStorage.setItem('lang', 'en')
        } else {
            setLang('bn');
            localStorage.setItem('lang', 'bn');
        }
    }


    const handleDeleteOpen = () => {
        setShowDelete(true);
    }

    const handleDeleteClose = () => {
        setShowDelete(false);
    }

    return {
        user,
        lang,
        showDelete,
        toggleLang,
        handleDeleteOpen,
        handleDeleteClose,
        login,
        logout,
        isLoading,
    };
}; 