import Page from "../../Layout/Page";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as Yup from "yup";
import PageHeader from "../../Layout/PageHeader";
import AddForm from "../../components/Filter/AddForm";
import SelectField from "../../components/Fields/SelectField";
import MultipleSelectField from "../../components/Fields/MultipleSelectField";
import useGetAllCourse from "../../hooks/course/useGetAllCourse";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-hot-toast";
import Loading from "../../Layout/Loading";
import { getStudentEnrollments, updateSemesterEnroll } from "../../api/fetcherFn/student";
import useGetASemesterEnroll from "../../hooks/student/useGetASemesterEnroll";
import { useState } from "react";
import { Paper, Stack, Typography } from "@mui/material";
import { MdWarning } from "react-icons/md";
import useGetCourseEnrolledStudents from "../../hooks/student/useGetCourseEnrolledStudents";


const EditSemesterEnrollment = () => {
    const queryClient = useQueryClient();
    const params = useParams();
    const id = parseInt(params.id, 10);
    const navigate = useNavigate();
    const [selectDefaultValue, setSelectDefaultValue] = useState("Not Null");

    const allCourse = useGetAllCourse();

    const validationSchema = Yup.object().shape({
        course_id: Yup.number().nullable().transform(v => !isNaN(v) ? v : null).required("Required"),
        batch_id: Yup.number().nullable().transform(v => !isNaN(v) ? v : null).required("Required"),
        semester_number: Yup.number().nullable().transform(v => !isNaN(v) ? v : null).when('course_id', (courseId, schema) => {
            const course = allCourse.data?.response_data?.find(c => c.id === courseId);
            return course?.semester_wise_subjects?.length > 0
                ? schema.nullable().transform(v => !isNaN(v) ? v : null).required('Required.')
                : schema.nullable().transform(v => !isNaN(v) ? v : null);
        }),
        subjects: Yup.array()
            .nullable().required(),
        students: Yup.array()
            .nullable().required()
            .min(1, "Required"),
    });


    const { control, handleSubmit, watch, setValue } = useForm({ resolver: yupResolver(validationSchema), mode: "onChange", });
    const courseId = watch('course_id');
    const batchId = watch('batch_id');
    const semesterNum = watch('semester_number');

    const { data: semesterEnrollment, isLoading } = useGetASemesterEnroll(id);
    const course = semesterEnrollment?.response_data?.course.id;
    const batch = semesterEnrollment?.response_data?.batch.id;

    const { data: studentCourses, isLoading: studentLoading } = useGetCourseEnrolledStudents(0, 0, courseId || course, batchId || batch, false, false);

    const update = useMutation(data => updateSemesterEnroll(id, data), {
        onSuccess: (data) => {
            if (data.success) {
                toast.success(data.message);
                queryClient.invalidateQueries(['semester-enroll', id])
                navigate('/students/semester-enrollment');
            } else {
                toast.error(data.message);
            }
        },
        onError: (err) => {
            toast.error(err.message);
        }
    });

    const courses = allCourse?.data?.response_data;
    const semester = semesterEnrollment?.response_data?.semester_number;
    const batches = (courses || course) && courses?.find(c => c.id === (courseId || course))?.batches;
    const semesters = (courses || course) && courses?.find(c => c.id === (courseId || course))?.semester_wise_subjects;
    const subjects = (courses || course) && (courses?.find(c => c.id === (courseId || course))?.semester_wise_subjects?.find(s => s.semester === semesterNum || s.semester === semester)?.subjects
        || courses?.find(c => c.id === (courseId || course))?.subjects);

    const students = studentCourses?.response_data?.data?.flatMap(d => d.students);
    const uniqueStudents = students?.filter((student, index, self) => index === self.findIndex((t) => t.id === student.id));

    const onSubmit = async (data) => {
        data.students = data.students?.map(id => ({ id: id }))
        data.subjects = subjects?.map(subject => ({ id: subject.id }));
        await update.mutateAsync(data);
        window.scrollTo(0, 0);
    }


    return (
        <Page title={'Edit Semester Enrollment'}>
            <PageHeader titleEn={'Edit Semester Enrollment'} titleBn={`সেমিস্টার বরাদ্দকরণ পরিবর্তন `}
                link={{ to: '/students/semester-enrollment', textEn: "Manage Semester Enrollment", textBn: "সেমিস্টার বরাদ্দকরণ ব্যবস্থাপনা" }} />

            {isLoading ? <Loading /> : <AddForm handleSubmit={handleSubmit(onSubmit)} loading={update.isLoading}>
                <SelectField
                    name="course_id"
                    control={control}
                    defaultValue={course}
                    data={courses}
                    handelChange={() => {
                        setValue('batch_id', null);
                        setValue('semester_number', null);
                        setSelectDefaultValue(null);
                    }}
                    label={{ en: "Select Course", bn: "কোর্স নির্বাচন" }}
                    errorMsg fontBold={false} required
                    link={{ label: "Not found Course? Create one", to: "/courses/add" }} />
                <SelectField
                    name="batch_id"
                    control={control}
                    uniqueKey={courseId}
                    defaultValue={selectDefaultValue && semesterEnrollment?.response_data?.batch.id}
                    data={batches}
                    label={{ en: "Select Batch", bn: "ব্যাচ নির্বাচন" }}
                    errorMsg fontBold={false} required
                    link={{ label: "Not found Batch? Create one", to: "/batch/add-batch" }} />

                {semesters && <SelectField
                    name="semester_number"
                    control={control}
                    subLabel={false}
                    uniqueKey={courseId}
                    defaultValue={selectDefaultValue && semester}
                    data={semesters?.map(option => ({ id: option.semester, name: `Semester ${option.semester}` }))}
                    label={{ en: "Select Semester", bn: "সেমিস্টার নির্বাচন" }}
                    errorMsg fontBold={false} required={semesters?.length > 0} />}

                {subjects?.length > 0 && <MultipleSelectField
                    name="subjects"
                    control={control}
                    defaultValue={subjects}
                    data={subjects}
                    label={{ en: "Subjects", bn: "বিষয়সমূহ" }}
                    errorMsg fontBold={false} required disabled />}

                {studentLoading ? <Loading size={30} /> : uniqueStudents?.length > 0 ? <MultipleSelectField
                    name="students"
                    control={control}
                    subLabel={false}
                    subValue={"_id"}
                    defaultValue={semesterEnrollment?.response_data?.students}
                    data={uniqueStudents}
                    search={true}
                    selectAllValue={true}
                    label={{ en: "Students List", bn: "শিক্ষার্থীর তালিকা" }}
                    errorMsg fontBold={false} required />
                    :
                    (courseId && batchId) &&
                    <Typography variant="body1" my={4} textAlign="center" sx={{ display: 'flex', flexDirection: "column", justifyContent: 'center', alignItems: "center" }}>
                        There is no students enrolled in this course/batch!
                        <NavLink to={'/students/student-enrollment'} style={{ fontSize: '16px', color: 'blue', marginTop: 1, textAlign: 'right', fontWeight: "bold" }}>Enroll Students to Course</NavLink>
                    </Typography>
                }
            </AddForm>}

        </Page>
    )
}

export default EditSemesterEnrollment;