import { useQuery } from "@tanstack/react-query";
import { getRoles } from "../../api/fetcherFn/settings";


const useRoles = () => {
    const { data: roles, isLoading: roleLoading } = useQuery(['all-roles'], getRoles);

    return {
        roles,
        roleLoading
    }
};

export default useRoles;