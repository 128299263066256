import { useQuery } from "@tanstack/react-query";
import { getAllRoles } from "../../api/fetcherFn/settings";

const useAllRoles = (page = 0, limit = 10, name = undefined) => {
    const { data: roles, isLoading: roleLoading } = useQuery(['roles', page + 1, limit, name], () => getAllRoles(page + 1, limit, name), {
        keepPreviousData: true
    });

    return {
        roles,
        roleLoading
    }
};


export default useAllRoles;