export const PDF_DOC_DOCX = [
    "application/pdf",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
];
export const PDF_DOC_DOCX_PNG_JPG_JPEG = [
    "application/pdf",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "image/png",
    "image/jpg",
    "image/jpeg",
];
export const PNG_JPG_JPEG_WEBP = [
    "image/png",
    "image/jpg",
    "image/jpeg",
];
export const PNG_JPG_PDF = [
    "image/png",
    "image/jpg",
    "image/jpeg",
    "application/pdf",
];
export const PNG_JPG_JPEG_GIF = [
    "image/png",
    "image/jpg",
    "image/jpeg",
    "image/gif",
];
export const CSV_JSON_SQL = [
    "text/csv",
    "application/json",
    "application/sql",
];
export const MP4_WAV_WEBM_MPEG = ["video/mp4", "video/wmv", "video/webm", "video/mpeg"];