import { toast } from "react-hot-toast";
import api from "../setup";

export const addRequest = async (data) => {
    try {
        const response = await api.post('/api/request/new', data);
        return response.data;
    } catch (err) {
        toast.error(err.message);
    }
}

export const getRequest = async (id) => {
    try {
        const response = await api.get(`/api/request/get/${id}`);
        return response.data;
    } catch (err) {
        toast.error(err.message);
    }
}
export const getRequests = async (page, limit, course, batch, studentId) => {
    try {
        let response;
        if (course) { response = await api.get(`/api/request/manage?page=${page}&limit=${limit}&course=${course}`); }
        if (batch) { response = await api.get(`/api/request/manage?page=${page}&limit=${limit}&batch=${batch}`); }
        if (studentId) { response = await api.get(`/api/request/manage?page=${page}&limit=${limit}&studentId=${studentId}`); }
        if (course && batch) { response = await api.get(`/api/request/manage?page=${page}&limit=${limit}&course=${course}&batch=${batch}`); }
        if (!course && !batch && !studentId) { response = await api.get(`/api/request/manage?page=${page}&limit=${limit}`); }

        return response.data;
    } catch (err) {
        toast.error(err.message);
    }
}
export const getRequestsSelf = async (page, limit, course, batch, studentId) => {
    try {
        let response;
        if (course) { response = await api.get(`/api/request/manage/self?page=${page}&limit=${limit}&course=${course}`); }
        if (batch) { response = await api.get(`/api/request/manage/self?page=${page}&limit=${limit}&batch=${batch}`); }
        if (studentId) { response = await api.get(`/api/request/manage/self?page=${page}&limit=${limit}&studentId=${studentId}`); }
        if (course && batch) { response = await api.get(`/api/request/manage/self?page=${page}&limit=${limit}&course=${course}&batch=${batch}`); }
        if (!course && !batch && !studentId) { response = await api.get(`/api/request/manage/self?page=${page}&limit=${limit}`); }

        return response.data;
    } catch (err) {
        toast.error(err.message);
    }
}

export const approveRequest = async (id) => {
    try {
        const response = await api.patch(`/api/request/approve/${id}`);
        return response.data;
    } catch (err) {
        toast.error(err.message);
    }
}
export const cancelRequest = async (id) => {
    try {
        const response = await api.patch(`/api/request/cancel/${id}`);
        return response.data;
    } catch (err) {
        toast.error(err.message);
    }
}
export const deleteRequest = async (id) => {
    try {
        const response = await api.delete(`/api/request/delete/${id}`);
        return response.data;
    } catch (err) {
        toast.error(err.message);
    }
}

export const exportRequestsToExcel = async () => {
    try {
        const response = await api.get(`/api/request/export/excel`, {
            responseType: 'blob',
        });
        return response.data;
    } catch (err) {
        toast.error(err.message);
    }
}
export const exportRequestsToCSV = async () => {
    try {
        const response = await api.get(`/api/request/export/csv`, {
            responseType: 'blob',
        });
        return response.data;
    } catch (err) {
        toast.error(err.message);
    }
}



// Issued Documents 
export const getIssuedDocuments = async (page, limit, course, batch, docType, student_id) => {
    try {
        let response;
        const studentId = parseInt(student_id?.split('-')[2]?.slice(-5), 10) || student_id;
        if (course) { response = await api.get(`/api/request/documents/manage?page=${page}&limit=${limit}&course=${course}`); }
        if (batch) { response = await api.get(`/api/request/documents/manage?page=${page}&limit=${limit}&batch=${batch}`); }
        if (docType) { response = await api.get(`/api/request/documents/manage?page=${page}&limit=${limit}&type=${docType}`); }
        if (studentId) { response = await api.get(`/api/request/documents/manage?page=${page}&limit=${limit}&studentId=${studentId}`); }
        if (course && batch) { response = await api.get(`/api/request/documents/manage?page=${page}&limit=${limit}&course=${course}&batch=${batch}`); }
        if (!course && !batch && !studentId && !docType) { response = await api.get(`/api/request/documents/manage?page=${page}&limit=${limit}`); }

        return response.data;
    } catch (err) {
        toast.error(err.message);
    }
}
export const getIssuedDocumentsSelf = async (page, limit, course, batch, docType, student_id) => {
    try {
        let response;
        const studentId = parseInt(student_id?.split('-')[2]?.slice(-5), 10) || student_id;
        if (course) { response = await api.get(`/api/request/documents/manage/self?page=${page}&limit=${limit}&course=${course}`); }
        if (batch) { response = await api.get(`/api/request/documents/manage/self?page=${page}&limit=${limit}&batch=${batch}`); }
        if (docType) { response = await api.get(`/api/request/documents/manage/self?page=${page}&limit=${limit}&type=${docType}`); }
        if (studentId) { response = await api.get(`/api/request/documents/manage/self?page=${page}&limit=${limit}&studentId=${studentId}`); }
        if (course && batch) { response = await api.get(`/api/request/documents/manage/self?page=${page}&limit=${limit}&course=${course}&batch=${batch}`); }
        if (!course && !batch && !studentId && !docType) { response = await api.get(`/api/request/documents/manage/self?page=${page}&limit=${limit}`); }

        return response.data;
    } catch (err) {
        toast.error(err.message);
    }
}


export const exportToExcel = async () => {
    try {
        const response = await api.get(`/api/request/documents/export/excel`, {
            responseType: 'blob',
        });
        return response.data;
    } catch (err) {
        toast.error(err.message);
    }
}
export const exportToCSV = async () => {
    try {
        const response = await api.get(`/api/request/documents/export/csv`, {
            responseType: 'blob',
        });
        return response.data;
    } catch (err) {
        toast.error(err.message);
    }
}