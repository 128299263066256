import Page from "../../Layout/Page";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as Yup from "yup";
import PageHeader from "../../Layout/PageHeader";
import TextInput from "../../components/Fields/TextInput";
import { Button, CircularProgress, Divider, FormControl, OutlinedInput, Paper, Stack, Table, TableBody, TableContainer, TableHead, TableRow, } from "@mui/material";
import Translate from "../../components/Translate/Translate";
import TCell from "../../components/Table/TCell";
import { grey } from "@mui/material/colors";
import { useMutation } from "@tanstack/react-query";
import { createResult, } from "../../api/fetcherFn/result";
import { toast } from "react-hot-toast";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import TRow from "../../components/Table/TRow";
import useExam from "../../hooks/exam/useExam";
import Loading from "../../Layout/Loading";

const ValidationSchema = Yup.object().shape({
    results: Yup
        .array()
        .of(
            Yup.object().shape({
                total_marks: Yup.number()
                    .required("Required"),
                examiner_comment: Yup.string(),
            })
        ),
});


const PrepareResult = () => {
    const queries = new URLSearchParams(window.location.search);
    const examId = parseInt(queries.get('exam'), 10);
    const navigate = useNavigate();

    const { control, handleSubmit } = useForm({ resolver: yupResolver(ValidationSchema) });

    const { data: examData, isLoading } = useExam(examId);
    const exam = examData?.response_data;

    const create = useMutation((data) => createResult(data), {
        onSuccess: (data) => {
            if (data.success) {
                toast.success(data.message);
                navigate('/results/manage');
            } else {
                toast.error(data.message);
            }
        },
        onError: (err) => {
            toast.error(err.message);
        }
    })

    const handleSaveResult = async (data) => {
        const _data = {
            course_id: exam.course.id,
            batch_id: exam.batch.id,
            semester_number: exam.semester_number,
            exam_id: exam.id,
            results: exam.participants?.map((participant, i) => ({
                student_id: participant.id,
                ...data.results[i]
            }))
        };
        await create.mutateAsync(_data);
    };


    return (
        <Page title={"Add Result"}>
            <PageHeader titleEn="Add Result" titleBn="ফলাফল যুক্তকরণ"
                link={{ to: '/results/manage', textEn: "Manage Result", textBn: "ফলাফল ব্যবস্থাপনা" }} />


            {isLoading ? <Loading /> :
                <Paper elevation={0} sx={{ px: 2, py: 4, minHeight: '75vh' }} className="rad-grad">

                    <TRow mt={0} data={[
                        { label: { en: "Course Name", bn: "কোর্সের নাম" }, value: exam?.course.name },
                        { label: { en: "Batch Name", bn: "ব্যাচের নাম" }, value: exam?.batch.name },
                        { label: { en: "Semester", bn: "সেমিস্টার" }, value: `${exam.semester_number ? `Semester ${exam.semester_number}` : 'None'}` },
                        { label: { en: "Subject", bn: "বিষয়" }, value: exam?.subject.name },
                        { label: { en: "Exam Title", bn: "পরীক্ষার টাইটেল" }, value: exam.title },
                        { label: { en: "Exam Date", bn: "পরীক্ষার তারিখ" }, value: exam.exam_date },
                        { label: { en: "Exam Total Marks", bn: "পরীক্ষার সর্বমোট মার্কস" }, value: exam.total_mark },
                    ]} />
                    <Divider sx={{ my: 2.5 }} />


                    <form onSubmit={handleSubmit(handleSaveResult)} >
                        <TableContainer sx={{ mt: 2, borderRadius: 1, border: `1px solid ${grey[100]}` }}>
                            <Table sx={{ minWidth: 650 }} size={"medium"}>
                                <TableHead sx={{ bgcolor: grey[100] }}>
                                    <TableRow>
                                        <TCell bold><Translate en="Student ID" bn="শিক্ষার্থীর আইডি" /></TCell>
                                        <TCell bold ><Translate en="Student Name" bn="শিক্ষার্থীর নাম" /></TCell>
                                        <TCell bold align="center"  ><Translate en="Marks Achieved" bn="প্রাপ্ত মার্কস" /></TCell>
                                        <TCell bold align="center"><Translate en="Examinner Comment" bn="পরীক্ষকের মন্তব্য" /></TCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {exam?.participants?.map((student, i) => (
                                        <TableRow key={i} hover>
                                            <TCell>{student._id}</TCell>
                                            <TCell >{student.name}</TCell>
                                            <TCell align="center" width={200}>

                                                <Controller
                                                    name={`results[${i}].total_marks`}
                                                    control={control}
                                                    defaultValue={""}
                                                    render={({ field, formState }) => (
                                                        <FormControl fullWidth variant="outlined" size="small">
                                                            <OutlinedInput
                                                                {...field}
                                                                type="number"
                                                                placeholder="e.g. 25"
                                                                error={!!formState.errors?.results?.[i].total_marks}
                                                            />

                                                        </FormControl>
                                                    )}
                                                />

                                            </TCell>
                                            <TCell align="center" width={230} >
                                                <TextInput
                                                    // name="examinner_comment"
                                                    name={`results[${i}].examiner_comment`}
                                                    control={control}
                                                    placeholder="e.g. good"
                                                    errorMsg fontBold={false} />
                                            </TCell>
                                        </TableRow>
                                    ))}


                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Stack justifyContent="flex-end" direction={{ xs: 'column', md: 'row' }} mt={2}>
                            <Button type="submit" variant="outlined" size="small" sx={{ textTransform: 'capitalize' }} >
                                <Stack direction={"row"} gap={1} alignItems={"center"}>
                                    {create.isLoading ? <CircularProgress size={18} /> : <AiOutlineCloudUpload size={20} />}
                                    <Translate en="Save result" bn="সংরক্ষণ করুন" />
                                </Stack>
                            </Button>
                        </Stack>

                    </form>

                </Paper>}


        </Page >
    )
}

export default PrepareResult;