import Page from "../../Layout/Page";
import {
    Button,
    IconButton,
    Paper,
    Stack,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
} from "@mui/material";
import { grey, } from "@mui/material/colors";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as Yup from "yup";
import { useAuth } from "../../hooks/useAuth";
import PageHeader from "../../Layout/PageHeader";
import FilterForm from "../../components/Filter/FilterForm";
import SelectField from "../../components/Fields/SelectField";
import TextInput from "../../components/Fields/TextInput";
import { BiDotsVerticalRounded } from "react-icons/bi";
import TCell from "../../components/Table/TCell";
import ActionMenu from "../../components/Menus/ActionMenu";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deleteStudent, exportToCSV, exportToExcel, } from "../../api/fetcherFn/student";
import Loading from "../../Layout/Loading";
import DeleteDialog from "../../components/HelperComponents/DeleteDialog";
import useStudents from "../../hooks/student/useStudents";
import useGetAllCourse from "../../hooks/course/useGetAllCourse";
import useGetAllBatch from "../../hooks/batch/useGetAllBatch";
import usePermissions from "../../hooks/permission/usePermissions";
import { ROUTES } from "../Settings/permission_data";
import { toast } from "react-hot-toast";
import { saveAs } from 'file-saver';

const validationSchema = Yup.object().shape({
    course_id: Yup.number(),
    batch_id: Yup.number(),
    semester_num: Yup.number(),
    subject_id: Yup.number(),
    search: Yup.string()
});

export default function StudentsManage() {
    const { check } = usePermissions();
    const queryClient = useQueryClient();
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const [data, setData] = useState({});
    const [anchorEl, setAnchorEl] = useState(null);
    const [deleteDialog, setDeleteDialog] = useState(false);
    const open = Boolean(anchorEl);
    const { lang, user } = useAuth();

    const { control, handleSubmit, watch, setValue } = useForm({ resolver: yupResolver(validationSchema), mode: "onChange", });
    const course = watch('course_id');
    const batch = watch('batch_id');
    const name = watch('search');
    const semester = watch('semester_num');
    const subject = watch('subject_id');

    const allCourse = useGetAllCourse();
    const allBatch = useGetAllBatch(course);

    const { data: students, isLoading } = useStudents(page, limit, name);
    const { mutateAsync } = useMutation(() => deleteStudent(data.id), { onSuccess: () => { queryClient.invalidateQueries(['students']); } });


    const handleClick = (event, row) => {
        setAnchorEl(event.currentTarget);
        setData(row)
    };
    const onSubmit = (data) => { };

    const exportToExcelFormat = async () => {
        try {
            const responseData = await exportToExcel();
            const filename = 'student_details.xlsx';
            saveAs(responseData, filename);
        } catch (err) {
            toast.error('Error downloading file:', err.message);
        }
    }
    const exportToCSVFormat = async () => {
        try {
            const responseData = await exportToCSV();
            const filename = 'student_details.csv';
            saveAs(responseData, filename);
        } catch (err) {
            toast.error('Error downloading file:', err.message);
        }
    }




    const courses = course && allCourse.data?.response_data?.find(c => c.id === course).batches;
    const semesters = course && allCourse.data?.response_data?.find(c => c.id === course).semesters.map(s => ({ id: s.semester, name: `Semester ${s.semester}` }));
    const subjects = course && semester && allCourse.data?.response_data?.find(c => c.id === course).semesters.find(s => s.semester === semester).subjects;

    return (
        <Page title={"Manage Students"}>
            <PageHeader titleEn="Manage Students" titleBn="শিক্ষার্থী ব্যবস্থাপনা" mb={1} />


            <Stack direction={"row"} justifyContent={"flex-end"} alignItems={"center"} mb={1} gap={1.5}>
                <Button onClick={exportToExcelFormat} variant="outlined" size="small" sx={{ textTransform: 'unset', fontWeight: "bold" }}>Export to Excel</Button>
                <Button onClick={exportToCSVFormat} variant="outlined" size="small" sx={{ textTransform: 'unset', fontWeight: "bold" }} color="warning">Export to CSV</Button>
            </Stack>
            <Paper elevation={0} sx={{ px: 3, py: 3, minHeight: '75vh' }} className="rad-grad">
                <FilterForm handleSubmit={handleSubmit(onSubmit)} button={check(ROUTES.STUDENT.ADD)} navigate="/students/add">

                    {/* <SelectField
                        name="course_id"
                        control={control}
                        isLoading={allCourse.isLoading}
                        data={courseBatch}
                        // data={allCourse?.data?.response_data}
                        label={{ en: "Select Course", bn: "কোর্স নির্বাচন" }} />
                    <SelectField
                        name="batch_id"
                        control={control}
                        data={courses}
                        // data={allBatch?.data?.response_data}
                        label={{ en: "Select Batch", bn: "ব্যাচ নির্বাচন" }} />
                    <SelectField
                        name="semester_num"
                        control={control}
                        data={semesters}
                        // data={allBatch?.data?.response_data}
                        label={{ en: "Select Semester", bn: "ব্যাচ নির্বাচন" }} />
                    <SelectField
                        name="subject_id"
                        control={control}
                        data={subjects}
                        // data={allBatch?.data?.response_data}
                        label={{ en: "Select Subject", bn: "ব্যাচ নির্বাচন" }} /> */}

                    <TextInput
                        name="search"
                        control={control}
                        // label={{ en: "Search", bn: "খুজুন" }}
                        placeholder="Search by Name..." />

                </FilterForm>

                {isLoading ? <Loading /> :
                    <>
                        <TableContainer sx={{ mt: 2, borderRadius: 1, border: `1px solid ${grey[100]}` }}>
                            <Table sx={{ minWidth: 650 }} size="medium">
                                <TableHead sx={{ bgcolor: grey[100] }}>
                                    <TableRow>
                                        <TCell bold align="center" >#</TCell>
                                        <TCell bold>{lang === "en" ? "Student ID" : "শিক্ষার্থীর আইডি"}</TCell>
                                        <TCell bold>{lang === "en" ? "Student Name" : "শিক্ষার্থীর নাম"}</TCell>
                                        <TCell bold>{lang === "en" ? "Email Address" : "ই-মেইল ঠিকানা"}</TCell>
                                        <TCell bold>{lang === "en" ? "Phone" : "ফোন"}</TCell>
                                        <TCell bold align="center">{lang === "en" ? "Enrolled" : "তালিকাভুক্তি"}</TCell>
                                        <TCell align="center" bold>{lang === "en" ? "Action" : "অ্যাকশন"}</TCell>

                                    </TableRow>
                                </TableHead>
                                <TableBody >
                                    {!students.success || students.response_data.data.length === 0 ?
                                        <TableRow sx={{ textAlign: "center" }}><TCell align="center" bold colSpan={7}>No record found.</TCell></TableRow> :
                                        students.response_data.data.map((row, index) => (
                                            <TableRow key={index} hover>
                                                <TCell align="center">{index + 1}</TCell>
                                                <TCell>{row.user._id}</TCell>
                                                <TCell>{row.name_en}</TCell>
                                                <TCell>{row.user.email}</TCell>
                                                <TCell>{row.user.phone}</TCell>
                                                <TCell align="center">{row.enrolled_courses?.length}</TCell>
                                                <TCell align="center"><IconButton
                                                    onClick={(e) => handleClick(e, row)}><BiDotsVerticalRounded /></IconButton></TCell>

                                            </TableRow>
                                        ))}

                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            component="div"
                            count={students?.response_data?.meta.data_found}
                            page={page}
                            onPageChange={(e, newPage) => {
                                setPage(newPage);
                            }}
                            rowsPerPage={limit}
                            onRowsPerPageChange={(e) => {
                                setLimit(parseInt(e.target.value, 10));
                                setPage(0);
                            }}
                        />
                    </>
                }
            </Paper>

            <ActionMenu
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
                open={open}
                viewUrl={check(ROUTES.STUDENT.MANAGE.V) && `/students/details/${data.id}`}
                editUrl={check(ROUTES.STUDENT.MANAGE.E) && `/students/edit/${data.id}`}
                handleDeleteDialog={check(ROUTES.STUDENT.MANAGE.D) && (() => setDeleteDialog(true))} />



            {deleteDialog && <DeleteDialog handleDelete={async () => await mutateAsync(data.id)} dialog={deleteDialog} setDialog={setDeleteDialog} />}

        </Page>
    );
}