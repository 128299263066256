import Page from "../../Layout/Page";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as Yup from "yup";
import PageHeader from "../../Layout/PageHeader";
import TextInput from "../../components/Fields/TextInput";
import AddForm from "../../components/Filter/AddForm";
import FormSection from "../../components/Filter/FormSection";
import SelectField from "../../components/Fields/SelectField";
import MultilineInput from "../../components/Fields/MultilineInput";
import PasswordField from "../../components/Fields/PasswordField";
import { useNavigate, } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-hot-toast";
import { addTeacher, } from "../../api/fetcherFn/teacher";
import PhotoAvatarUpload from "../../components/Fields/PhotoAvatarUpload";
import { PNG_JPG_JPEG_GIF } from "../../Constants/FileTypes";
import { getEighteenYearsPrevDate } from "../../utils/constants";

const validationSchema = Yup.object().shape({
    name_en: Yup.string().required("Teacher's name is required"),
    name_bn: Yup.string().required("Teacher's name is required"),
    gender: Yup.string().nullable().required('Gender is required'),
    dob: Yup
        .string()
        .required("Date of birth is required"),
    nid: Yup.string(),
    phone: Yup.string()
        .required('Mobile number is required').max(12, "Invalid"),
    designation: Yup.string().required("Required."),
    bio: Yup.string(),
    present_address: Yup.string().required('Present address is required'),
    permanent_address: Yup.string().required('Permanent address is required'),
    email: Yup.string().email("Invalid Email").required('Proper email is required'),
    password: Yup.string()
        .required('Password is required')
        .min(6, 'Password should be atleast 6 digits.'),
    photo: Yup
        .mixed()
        .test("fileSize", "File size is too large", (value) => {
            if (!value) return true;
            return value && value.size <= 1024000; //1 MB
        })
        .test("fileType", "Unsupported file format", (value) => {
            if (!value) return true;
            return value && ["image/jpeg", "image/png"].includes(value.type);
        })
});

export default function TeachersAdd() {
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const { control, handleSubmit, reset } = useForm({ resolver: yupResolver(validationSchema), mode: "onChange", });
    const add = useMutation(data => addTeacher(data), {
        onSuccess: (data) => {
            if (data.success) {
                toast.success(data.message);
                queryClient.invalidateQueries('teacher');
                navigate('/teachers/manage');
            } else {
                toast.error(data.message);
            }
        },
        onError: (err) => {
            toast.error(err.message);
        }
    });


    const onSubmit = async (data) => {
        const formData = new FormData();
        for (const key in data) {
            formData.append(key, data[key]);
        }
        await add.mutateAsync(data);
    }

    return (
        <Page title={`Add Teacher`}>
            <PageHeader titleEn={`Add Teacher`} titleBn={`শিক্ষক সংযুক্তিকরণ`}
                link={{ to: '/teachers/manage', textEn: "Manage Teachers", textBn: "শিক্ষক ব্যবস্থাপনা" }} />

            <AddForm handleSubmit={handleSubmit(onSubmit)} loading={add.isLoading} reset={reset} maxWidth={700}>
                <PhotoAvatarUpload
                    name="photo"
                    control={control}
                    fileType={PNG_JPG_JPEG_GIF} />

                <FormSection title={{ en: "Personal Information", bn: "ব্যাক্তিগত তথ্য" }}>

                    <TextInput
                        name="name_en"
                        control={control}
                        label={{ en: "Teacher Name (English)", bn: "শিক্ষকের নাম (ইংরেজীতে)" }}
                        errorMsg fontBold={false} required autoFocus />
                    <TextInput
                        name="name_bn"
                        control={control}
                        label={{ en: "Teacher Name (Bangla)", bn: "শিক্ষকের নাম (বাংলায়)" }}
                        errorMsg fontBold={false} required />
                    <SelectField
                        name="gender"
                        control={control}
                        subLabel={false}
                        data={[{ id: "male", name: "Male" }, { id: "female", name: "Female" }, { id: "other", name: "Other" }]}
                        label={{ en: "Gender", bn: "লিঙ্গ" }}
                        errorMsg fontBold={false} required />
                    <TextInput
                        name="dob"
                        type="date"
                        control={control}
                        min={"1970-01-01"}
                        max={getEighteenYearsPrevDate}
                        label={{ en: "Date of Birth", bn: "জন্ম তারিখ" }}
                        errorMsg fontBold={false} required />
                    <TextInput
                        name="nid"
                        type="number"
                        control={control}
                        label={{ en: "NID Number", bn: "এনআইডি নাম্বার" }}
                        placeholder="e.g. 8263215417"
                        errorMsg fontBold={false} />
                    <TextInput
                        name="phone"
                        type="tel"
                        control={control}
                        label={{ en: "Mobile Number", bn: "মোবাইল নাম্বার" }}
                        placeholder="e.g. 01710000000"
                        errorMsg fontBold={false} required />
                    <TextInput
                        name="designation"
                        control={control}
                        label={{ en: "Designation", bn: "উপাধি" }}
                        errorMsg fontBold={false} required />

                    <MultilineInput
                        name="bio"
                        control={control}
                        label={{ en: "Bio (Short Description)", bn: "বায়ো (সংক্ষিপ্ত বিবরণ)" }}
                        rows={3} errorMsg />
                    <MultilineInput
                        name="present_address"
                        control={control}
                        label={{ en: "Present Address", bn: "বর্তমান ঠিকানা" }}
                        rows={4} errorMsg required />
                    <MultilineInput
                        name="permanent_address"
                        control={control}
                        label={{ en: "Permanent Address", bn: "স্থায়ী ঠিকানা" }}
                        rows={4} errorMsg required />

                </FormSection>

                <FormSection title={{ en: "Login Informations", bn: "লগইন করার তথ্যাবলী" }}>
                    <TextInput
                        name="email"
                        type="email"
                        control={control}
                        label={{ en: "Email", bn: "ইমেইল" }}
                        errorMsg fontBold={false} required />
                    <PasswordField
                        name="password"
                        type="password"
                        control={control}
                        label={{ en: "Password", bn: "পাসওয়ার্ড" }}
                        errorMsg fontBold={false} required />
                </FormSection>
            </AddForm>

        </Page>
    )
}