import { Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import Page from "../../Layout/Page";
import { useParams } from "react-router-dom";
import React, { useRef } from 'react';
import TRowCustom from "../../components/Table/TRow_CUSTOM";
import Translate from "../../components/Translate/Translate";
import PageHeader from "../../Layout/PageHeader";
import TRow from "../../components/Table/TRow";
import useGetATeacher from "../../hooks/teacher/useGetATeacher";
import Loading from "../../Layout/Loading";
import TCell from "../../components/Table/TCell";
import { useAuth } from "../../hooks/useAuth";
import { grey, } from "@mui/material/colors";
import { IoMdPrint } from "react-icons/io";
import { useReactToPrint } from "react-to-print";


const AssignedCourseDetails = () => {
  const { lang } = useAuth();
  const { id } = useParams();
  const { data, isLoading } = useGetATeacher(id);
  const assignedCourse = data?.response_data;

  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Assigned Course Details`,
  });

  return (
    <Page title="Assigned Course Details">
      <PageHeader
        titleEn="Assigned Course Details"
        titleBn="বরাদ্দকৃত কোর্স বিস্তারিত"
        link={{
          to: "/teachers/assigned-course-manage",
          textEn: "Manage Assigned Course",
          textBn: "বরাদ্দকৃত কোর্স ব্যবস্থাপনা",
        }}
      />
      {isLoading ? <Loading /> :

        <Stack component={Paper} px={{ md: 4 }} sx={{ py: 2, maxWidth: 900, margin: '0 auto' }} className="rad-grad">
          <div style={{ width: '100%', display: 'inline-flex', justifyContent: 'flex-end' }}>
            <IoMdPrint onClick={handlePrint} size={25} color="gray" cursor="pointer" />
          </div>

          <Stack ref={componentRef} p={{ md: 4, xs: 2 }} gap={4}>
            <TRow data={[
              { label: { en: "Course Name", bn: "কোর্সের নাম" }, value: assignedCourse.course.name },
              { label: { en: "Course ID", bn: "কোর্সের আইডি" }, value: assignedCourse.course.id },
              { label: { en: "Batch Name", bn: "ব্যাচের নাম" }, value: assignedCourse.batch.name },
              { label: { en: "Semester", bn: "সেমিস্টার" }, value: `Semester ${assignedCourse.semester_number}` },
              { label: { en: "Assigned at", bn: "বরাদ্দকরণের সময়" }, value: assignedCourse.assigned_at },
            ]} />

            {assignedCourse.course.type === "long" ?
              <TableContainer sx={{ mt: 2, }}>
                <TableCell sx={{ fontSize: '1.2rem', fontWeight: 'bold', border: 0, px: 0, py: 1 }}><Translate en="Subject Details" bn="বিষয়ের বিস্তারিত" /> </TableCell>
                <Table sx={{ minWidth: 650, borderRadius: 1, border: `1px solid ${grey[100]}` }} size="medium">
                  <TableHead sx={{ bgcolor: grey[100] }}>
                    <TableRow>
                      <TCell bold align="center" >#</TCell>
                      <TCell bold><Translate en="Subject Name" bn="বিষয়ের নাম" /> </TCell>
                      <TCell bold><Translate en="Subject Code" bn="বিষয়ের কোড" /> </TCell>
                      <TCell bold align="center"><Translate en="Subject Credit" bn="বিষয়ের ক্রেডিট" /> </TCell>
                      <TCell bold><Translate en="Subject Marks" bn="বিষয়ের মার্কস" /> </TCell>
                    </TableRow>
                  </TableHead>
                  <TableBody >
                    {assignedCourse.course.semester_wise_subjects.find(item => item.semester === assignedCourse.semester_number).subjects.map((row, index) => (
                      <TableRow key={index}>
                        <TCell align="center">{index + 1}</TCell>
                        <TCell>{row.name}</TCell>
                        <TCell>{row.subject_code}</TCell>
                        <TCell align="center">{row.credit}</TCell>
                        <TCell>{row.marks}</TCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              :
              <TableContainer sx={{ mt: 2, borderRadius: 1, border: `1px solid ${grey[100]}` }}>
                <TableCell sx={{ fontSize: '1.2rem', fontWeight: 'bold', border: 0, px: 0, py: 1 }}><Translate en="Subject Details" bn="বিষয়ের বিস্তারিত" /></TableCell>
                <Table sx={{ minWidth: 650, borderRadius: 1, border: `1px solid ${grey[100]}` }} size="medium">
                  <TableHead sx={{ bgcolor: grey[100] }}>
                    <TableRow>
                      <TCell bold align="center" >#</TCell>
                      <TCell bold>{lang === "en" ? "Subject Name" : "বিষয়ের নাম"}</TCell>
                      <TCell bold>{lang === "en" ? "Subject Code" : "বিষয়ের কোড"}</TCell>
                      <TCell bold>{lang === "en" ? "Subject Credit" : "বিষয়ের ক্রেডিট"}</TCell>
                      <TCell bold>{lang === "en" ? "Subject Marks" : "বিষয়ের মার্কস"}</TCell>
                    </TableRow>

                  </TableHead>
                  <TableBody>
                    {assignedCourse.course.subjects?.map((item, i) => (
                      <TableRow key={i}>
                        <TCell align="center">{i + 1}</TCell>
                        <TCell>{item.name}</TCell>
                        <TCell>{item.subject_code}</TCell>
                        <TCell>{item.credit}</TCell>
                        <TCell>{item.marks}</TCell>
                      </TableRow>
                    ))
                    }
                  </TableBody>
                </Table>
              </TableContainer>
            }

            {assignedCourse?.teachers && <TableContainer sx={{ mt: 2, borderRadius: 1, border: `1px solid ${grey[100]}` }}>
              <TableCell sx={{ fontSize: '1.2rem', fontWeight: 'bold', border: 0, px: 0, py: 1 }}><Translate en="Assigned Teachers" bn="বরাদ্দকৃত শিক্ষকগণ" /></TableCell>
              <Table sx={{ minWidth: 650, borderRadius: 1, border: `1px solid ${grey[100]}` }} size="medium">
                <TableHead sx={{ bgcolor: grey[100] }}>
                  <TableRow>
                    <TCell bold align="center">#</TCell>
                    <TCell bold><Translate en={"Teacher ID"} bn={"শিক্ষকের আইডি"} /></TCell>
                    <TCell bold> <Translate en={"Teacher Name"} bn={"শিক্ষকের নাম"} /> </TCell>
                    <TCell bold><Translate en={"Email Address"} bn={"ই-মেইল"} /> </TCell>
                    <TCell bold><Translate en={"Phone"} bn={"ফোন নাম্বার"} /></TCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {assignedCourse?.teachers.map((data, i) => (
                    <TableRow key={i}>
                      <TCell align="center">{i + 1}</TCell>
                      <TCell>{data._id}</TCell>
                      <TCell>{data.name}</TCell>
                      <TCell>{data.email}</TCell>
                      <TCell>{data.phone}</TCell>
                    </TableRow>
                  ))}


                </TableBody>
              </Table>
            </TableContainer>}
          </Stack>

        </Stack>

      }

    </Page>
  )
};

export default AssignedCourseDetails;
