import Page from "../../Layout/Page";
import {
    Paper,
    Table,
    TableBody, TableContainer,
    TableHead,
    TablePagination,
    TableRow,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import PageHeader from "../../Layout/PageHeader";
import { useState } from "react";
import AddNew from "../../components/Buttons/AddNew";
import TCell from "../../components/Table/TCell";
import useClasses from "../../hooks/class/useClasses";
import { useNavigate } from "react-router-dom";
import Loading from "../../Layout/Loading";
import Translate from "../../components/Translate/Translate";
import usePermissions from "../../hooks/permission/usePermissions";
import { ROUTES } from "../Settings/permission_data";
import { BsCalendarDate } from "react-icons/bs";
import CalenderDialog from "../../components/Dialogs/CalenderDialog";

export default function ClassRoutineManage() {
    const { check } = usePermissions();
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const [openCanlender, setOpenCanlender] = useState(false);
    const navigate = useNavigate();

    const { data: classes, isLoading } = useClasses(page, limit);

    const calendarData = classes?.response_data?.data?.map(event => ({
        title: event.name,
        start: new Date(`${event.class_date + " " + event.start_time}`),
        end: new Date(`${event.class_date + " " + event.end_time}`),
        desc: event.description,
        location: event.place
    }))


    return (
        <Page title={"Class Routine"}>
            <PageHeader titleEn="Class Routine" titleBn="ক্লাশ রুটিন"
                link={{ to: '/classes/manage', textEn: "Manage Class", textBn: "ক্লাস ব্যবস্থাপনা" }} />

            <AddNew variant="contained" btnText={{ en: "Calender View", bn: "ক্যালেন্ডার ভিউ" }} handleClick={() => setOpenCanlender(true)} icon={<BsCalendarDate size={16} />} />
            <Paper elevation={0} sx={{ p: 3, minHeight: '70vh' }} className="rad-grad">
                {check(ROUTES.CLASS.ADD) && <AddNew handleClick={() => navigate("/classes/add")} />}

                {isLoading ? <Loading /> :
                    <>
                        <TableContainer sx={{ mt: 2, borderRadius: 1, border: `1px solid ${grey[100]}` }}>
                            <Table sx={{ minWidth: 650 }} size={"medium"}>
                                <TableHead sx={{ bgcolor: grey[100] }}>
                                    <TableRow>
                                        <TCell bold align="center">#</TCell>
                                        <TCell bold><Translate en="Class Name" bn="ক্লাসের নাম" /></TCell>
                                        <TCell bold><Translate en="Class Duration" bn="ক্লাসের সময়কাল" /></TCell>
                                        <TCell bold><Translate en="Date" bn="তারিখ" /></TCell>
                                        <TCell bold><Translate en="Place" bn="স্থান" /></TCell>
                                        <TCell bold><Translate en="Course Name" bn="কোর্সের নাম" /></TCell>
                                        <TCell bold><Translate en="Batch" bn="ব্যাচ" /></TCell>
                                        <TCell bold><Translate en="Assigned Teacher" bn="শিক্ষক" /></TCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {!classes.success || classes.response_data.data.length === 0 ?
                                        <TableRow sx={{ textAlign: "center" }}><TCell align="center" bold colSpan={10}>No record found.</TCell></TableRow> :
                                        classes.response_data.data.map((row, index) => (
                                            <TableRow key={index} hover>
                                                <TCell bold align="center">{index + 1}</TCell>
                                                <TCell >{row?.name}</TCell>
                                                <TCell >{row?.start_time} - {row?.end_time}</TCell>
                                                <TCell >{row?.class_date}</TCell>
                                                <TCell >{row?.place}</TCell>
                                                <TCell >{row?.course?.name}</TCell>
                                                <TCell >{row?.batch?.name}</TCell>
                                                <TCell >{row?.teacher?.name}</TCell>
                                            </TableRow>
                                        ))}

                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            component="div"
                            count={classes?.response_data?.meta.data_found}
                            page={page}
                            onPageChange={(e, newPage) => {
                                setPage(newPage);
                            }}
                            rowsPerPage={limit}
                            onRowsPerPageChange={(e) => {
                                setLimit(parseInt(e.target.value, 10));
                                setPage(0);
                            }}
                        />
                    </>}
            </Paper>
            {openCanlender && <CalenderDialog openDialog={openCanlender} setOpenDialog={setOpenCanlender} events={calendarData} title={{ en: "Routine Calender View", bn: "রুটিন ক্যালেন্ডার ভিউ" }} />}

        </Page>
    )
}