import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { useEffect, useState } from "react";
import { Box, CssBaseline, styled } from "@mui/material";
import Navbar from "./Navbar";
import { Outlet, useNavigate } from "react-router-dom"
import Page from "./Page";
import NoInternetConnection from "./NoInternetConnection";
import { useAuth } from "../hooks/useAuth";
import Sidebar from "./Sidebar";
import Loading from "./Loading";

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        flexGrow: 1,
        padding: theme.spacing(1.5),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: `-${drawerWidth}px`,
        ...(open && {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: 0,
        }),
    }),
);

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));



const Layout = () => {
    const navigate = useNavigate();
    useEffect(() => {
        if (!localStorage.getItem('accessToken')) {
            navigate('/login');
        }
    }, [navigate]);

    const { isLoading } = useAuth()
    const handle = useFullScreenHandle();
    const [open, setOpen] = useState(true);

    const toggleDrawer = () => {
        setOpen(!open)
    }

    const onClose = () => {
        setOpen(false)
    }

    if (isLoading) return <Loading />

    return (
        <FullScreen handle={handle} className="my-fullscreen">
            <Box bgcolor="red">
                <CssBaseline />
                <Navbar open={open} toggleDrawer={toggleDrawer} handle={handle} />
                <Sidebar open={open} onClose={onClose} />
                <Main open={open} sx={{ background: "#f9fafb", minHeight: "100vh" }}>
                    <DrawerHeader />
                    <Page>
                        <Box sx={{ ml: 30 }}>
                            <NoInternetConnection>
                                <Outlet />
                            </NoInternetConnection>
                        </Box>
                    </Page>
                </Main>
            </Box>
        </FullScreen>
    );
}

export default Layout;