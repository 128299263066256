import Page from "../../Layout/Page";
import {
    Paper,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as Yup from "yup";
import PageHeader from "../../Layout/PageHeader";
import AddForm from "../../components/Filter/AddForm";
import TextInput from "../../components/Fields/TextInput";
import MultilineInput from "../../components/Fields/MultilineInput";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { addRole, getRole, updateRole } from "../../api/fetcherFn/settings";
import { toast } from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../../Layout/Loading";

const validationSchema = Yup.object().shape({
    name: Yup.string().required('Required'),
    brief_description: Yup.string(),
});


export default function RoleAdd() {
    const queryClient = useQueryClient();
    const params = useParams();
    const id = parseInt(params.id);
    const navigate = useNavigate();
    const { control, handleSubmit, reset } = useForm({ resolver: yupResolver(validationSchema), mode: "onChange" });
    const { data: role, isLoading } = useQuery(['role', id], () => getRole(id), { enabled: !!id });
    const add = useMutation(data => addRole(data), {
        onSuccess: (data) => {
            if (data.success) {
                toast.success(data.message);
                queryClient.invalidateQueries('role');
                navigate('/settings/manage-role');
            } else {
                toast.error(data.message);
            }
        },
        onError: (err) => {
            toast.error(err.message);
        }
    });

    const update = useMutation(data => updateRole(id, data), {
        onSuccess: (data) => {
            if (data.success) {
                toast.success(data.message);
                queryClient.invalidateQueries('role');
                navigate('/settings/manage-role');
            } else {
                toast.error(data.message);
            }
        },
        onError: (err) => {
            toast.error(err.message);
        }
    });


    const onSubmit = async (data) => {
        if (id) {
            await update.mutateAsync(data);
            return;
        }
        await add.mutateAsync(data);
    }


    return (
        <Page title={`${id ? 'Edit' : 'Add'} Role`}>
            <PageHeader titleEn={`${id ? 'Edit' : 'Add'} Role`} titleBn={`ভূমিকা ${id ? 'তথ্য পরিবর্তন' : 'সংযুক্তি'}`}
                link={{ to: '/settings/manage-role', textEn: 'Manage Role', textBn: 'ভূমিকা ব্যবস্থাপনা' }} />


            {(id && isLoading) ? <Loading /> :
                <AddForm handleSubmit={handleSubmit(onSubmit)} reset={reset} loading={add.isLoading || update.isLoading}>
                    <TextInput
                        name="name"
                        value={role?.response_data?.name}
                        disabled={['admin', 'teacher', 'student'].includes(role?.response_data?.name?.toLowerCase())}
                        control={control}
                        label={{ en: "Role Name", bn: "ভূমিকার নাম" }}
                        errorMsg fontBold={false} required autoFocus />
                    <MultilineInput
                        name="brief_description"
                        value={role?.response_data?.brief_description}
                        control={control}
                        label={{ en: "Brief Description", bn: "সংক্ষিপ্ত বিবরণ" }}
                        errorMsg />
                </AddForm>}


        </Page>
    )
}