import React from 'react';
import { Controller } from "react-hook-form";
import { FormControl, FormHelperText, OutlinedInput } from "@mui/material";
import Label from '../Filter/Label';

const TextInput = ({ name = "", type = "text", control, value = "", placeholder = "", autoFocus = false, min = 0, max, step = null, errorMsg = false, fontBold = true, label, required = false, disabled = false }) => {
    return (
        <>
            <Controller
                name={name}
                control={control}
                defaultValue={value}
                render={({ field, formState }) => (
                    <FormControl fullWidth variant="outlined" size="small">
                        {label && < Label error={formState.errors?.[name]} en={label.en} bn={label.bn} bold={fontBold} required={required} />}
                        <OutlinedInput
                            {...field}
                            type={type}
                            autoFocus={autoFocus}
                            inputProps={{ min, max, step }}
                            placeholder={placeholder}
                            error={!!formState.errors?.[name]}
                            disabled={disabled}
                        />
                        {errorMsg && !!formState.errors?.[name] &&
                            <FormHelperText
                                error>{formState?.errors?.[name].message}</FormHelperText>}

                    </FormControl>
                )}
            />
        </>
    );
};

export default TextInput;