import Page from "../../Layout/Page";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as Yup from "yup";
import PageHeader from "../../Layout/PageHeader";
import { Button, CircularProgress, Paper, Stack, Table, TableBody, TableRow, TextField, Typography, } from "@mui/material";
import Translate from "../../components/Translate/Translate";
import TCell from "../../components/Table/TCell";
import { grey } from "@mui/material/colors";
import { useMutation, useQueryClient, } from "@tanstack/react-query";
import { updateFinalResult, } from "../../api/fetcherFn/result";
import { toast } from "react-hot-toast";
import { AiOutlineSave } from "react-icons/ai";
import ReactSelect from 'react-select';
import useCGPA from "../../hooks/result/useCGPA";
import { useNavigate, useParams, } from "react-router-dom";
import useFinalResult from "../../hooks/result/useFinalResult";
import Loading from "../../Layout/Loading";
import { useState } from "react";
import TRow from "../../components/Table/TRow";

const ValidationSchema = Yup.object().shape({
    results: Yup
        .array()
        .of(
            Yup.object().shape({
                total_marks: Yup.number()
                    .required("Required"),
                grade: Yup.string()
                    .required("Required"),
                point: Yup.number()
                    .required("Required"),
            })
        ),
});

const EditFinalResult = () => {
    const queryClient = useQueryClient();
    const params = useParams();
    const id = parseInt(params.id, 10);
    const navigate = useNavigate();

    const { control, handleSubmit, setValue, watch } = useForm({ resolver: yupResolver(ValidationSchema) });

    const { data: cgpaData, isLoading: gradeLoading } = useCGPA();
    const { data, isLoading } = useFinalResult(id);

    const finalResult = data?.response_data;
    const grades = cgpaData?.response_data;
    const [gpa, setGPA] = useState(finalResult?.gpa);

    const update = useMutation((data) => updateFinalResult(id, data), {
        onSuccess: (data) => {
            if (data.success) {
                toast.success(data.message);
                queryClient.invalidateQueries(['final-result', id]);
                navigate('/results/final/manage');
            } else {
                toast.error(data.message);
            }
        },
        onError: (err) => {
            toast.error(err.message);
        }
    })

    const handleSaveResult = async (data) => {
        const _data = {
            gpa: gpa,
            results: finalResult?.results?.map((result, i) => ({
                subject_id: result.subject.id,
                ...data.results[i]
            }))
        };

        await update.mutateAsync(_data);
    };


    const calculateCGPA = (i) => {
        const resultsData = watch(`results[${i}]`);
        let totalGradePoint = 0;
        let totalCredit = 0;
        totalCredit += finalResult?.results[i]?.subject.credit;
        totalGradePoint += finalResult?.results[i]?.subject.credit * resultsData.point;
        const totalGPA = (totalGradePoint / totalCredit);
        setGPA(totalGPA);
    }




    return (
        <Page title={"Edit Semester Result"}>
            <PageHeader titleEn="Edit Semester Result" titleBn="সেমিস্টার ফলাফল পরিবর্তন"
                link={{ to: '/results/final/manage', textEn: "Manage Final Result", textBn: "চূড়ান্ত ফলাফল ব্যবস্থাপনা" }} />

            <Paper elevation={0} sx={{ px: 3, py: 3, minHeight: '75vh' }} className="rad-grad">

                {(id && isLoading) ? <Loading /> :
                    <>
                        <TRow mt={0} data={[
                            { label: { en: "Student ID", bn: "শিক্ষার্থীর আইডি" }, value: finalResult?.student?._id },
                            { label: { en: "Student Name", bn: "শিক্ষার্থীর নাম" }, value: finalResult?.student?.name },
                            { label: { en: "Course Name", bn: "কোর্সের নাম" }, value: finalResult?.course?.name },
                            { label: { en: "Batch Name", bn: "ব্যাচের নাম" }, value: finalResult?.batch?.name },
                            {
                                label: { en: "Semester", bn: "সেমিস্টার" }, value: `${finalResult.semester_number ? `Semester ${finalResult.semester_number}` : 'None'}`
                            },
                        ]} />

                        <form onSubmit={handleSubmit(handleSaveResult)} >
                            <Table sx={{ minWidth: 650, mt: 2 }} size={"small"} >
                                <TableBody  >
                                    {finalResult.results?.map((result, i) => (
                                        <TableRow key={i}>
                                            <TCell bold sx={{ border: `1px solid ${grey[200]}` }}>{result.subject.name} ({result.subject.subject_code})</TCell>
                                            <TCell bold sx={{ border: `1px solid ${grey[200]}` }}>{result.subject.credit}</TCell>
                                            <TCell bold width={200} sx={{ border: `1px solid ${grey[200]}` }} >
                                                <Controller
                                                    name={`results[${i}].total_marks`}
                                                    control={control}
                                                    defaultValue={result.total_marks || ""}
                                                    render={({ field }) => (
                                                        <TextField {...field}
                                                            placeholder="Total Marks"
                                                            size="small" required />
                                                    )}
                                                />
                                            </TCell>
                                            <TCell bold width={200} sx={{ border: `1px solid ${grey[200]}` }}   >
                                                <Controller
                                                    name={`results[${i}].grade`}
                                                    control={control}
                                                    defaultValue={result.grade || ""}
                                                    render={({ field, formState }) => (
                                                        <ReactSelect
                                                            styles={{
                                                                control: (base) => ({
                                                                    ...base,
                                                                    border: !!formState.errors?.results?.[i].grade
                                                                        ? "1px solid #D32F2F"
                                                                        : "1px solid #C4C4C4",
                                                                    "&:hover": {
                                                                        border: !!formState.errors?.results?.[i].grade
                                                                            ? "1px solid #D32F2F"
                                                                            : "1px solid #C4C4C4",
                                                                    },
                                                                }),
                                                            }}
                                                            className="basic-single"
                                                            classNamePrefix="select"
                                                            name={`results[${i}].grade`}
                                                            isSearchable={false}
                                                            isLoading={gradeLoading}
                                                            defaultValue={grades?.map(d => ({ value: d.grade, label: d.grade })).find(d => d.value === result.grade)}
                                                            options={grades?.map(d => ({ value: d.grade, label: d.grade }))}
                                                            value={field?.label && data?.find((option) => option?.grade === field?.grade)}
                                                            onChange={selectedValue => {
                                                                field.onChange(selectedValue?.value);
                                                                setValue(`results[${i}].point`, grades?.find(g => g.grade === selectedValue?.value)?.point);
                                                                calculateCGPA(i);
                                                            }}
                                                            getOptionValue={(option) => option.value}
                                                        />
                                                    )}
                                                />
                                            </TCell>
                                            <TCell bold width={200} sx={{ border: `1px solid ${grey[200]}` }} >
                                                <Controller
                                                    name={`results[${i}].point`}
                                                    control={control}
                                                    defaultValue={result.point || ""}
                                                    render={({ field }) => (
                                                        <TextField {...field}
                                                            type="number"
                                                            inputProps={{ readOnly: true }}
                                                            placeholder="Point"
                                                            onChange={() => {
                                                                field.onChange(field.value);
                                                            }}
                                                            size="small" />
                                                    )}
                                                />
                                            </TCell>
                                        </TableRow>
                                    ))}
                                </TableBody>

                            </Table>
                            <Stack alignItems="flex-end" mt={2}>
                                <Typography fontWeight="bold" fontSize={20} my={1.5}>GPA: {gpa ? gpa : finalResult?.gpa} </Typography>
                                <Button type="submit" variant={"contained"} color="success" size="medium" >
                                    <Stack direction={"row"} gap={1} alignItems={"center"}>
                                        {update.isLoading ? <CircularProgress size={18} /> : <AiOutlineSave size={18} />}
                                        <Typography textTransform={"capitalize"}><Translate en="Update Result" bn="ফলাফল পরিবর্তন" /></Typography>
                                    </Stack>
                                </Button>
                            </Stack>
                        </form>
                    </>
                }

            </Paper>

        </Page >
    )
}

export default EditFinalResult;