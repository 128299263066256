import Page from "../../Layout/Page";
import { useParams } from "react-router-dom";
import { Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import PageHeader from "../../Layout/PageHeader";
import TRow from "../../components/Table/TRow";
import Translate from "../../components/Translate/Translate";
import React, { useRef } from 'react';
import Loading from "../../Layout/Loading";
import useGetASemesterEnroll from "../../hooks/student/useGetASemesterEnroll";
import TCell from "../../components/Table/TCell";
import { useAuth } from "../../hooks/useAuth";
import { grey, } from "@mui/material/colors";
import { IoMdPrint } from "react-icons/io";
import { useReactToPrint } from "react-to-print";

const SemesterEnrollmentDetails = () => {
  const { lang, user } = useAuth();
  const { id } = useParams();

  const { data, isLoading } = useGetASemesterEnroll(id);
  const semester = data?.response_data;
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Semester Enrollment Details`,
  });

  return (
    <Page title="Semester Enrollment Details">
      <PageHeader titleEn="Semester Enrollment Details" titleBn="সেমিস্টার বরাদ্দকরণ বিস্তারিত"
        link={{ to: '/students/semester-enrollment', textEn: "Manage Semester Enrollment", textBn: "সেমিস্টার বরাদ্দকরণ ব্যবস্থাপনা" }} />

      {isLoading ? <Loading /> :
        <Stack component={Paper} px={{ md: 4 }} sx={{ py: 2, maxWidth: 900, margin: '0 auto' }} className="rad-grad">
          <div style={{ width: '100%', display: 'inline-flex', justifyContent: 'flex-end' }}>
            <IoMdPrint onClick={handlePrint} size={25} color="gray" cursor="pointer" />
          </div>

          <Stack ref={componentRef} p={{ md: 4, xs: 2 }} gap={4}>
            <TRow data={[
              { label: { en: "Course ID", bn: "কোর্সের আইডি" }, value: semester?.course.id },
              { label: { en: "Course Name", bn: "কোর্সের নাম" }, value: semester?.course.name },
              { label: { en: "Batch Name", bn: "ব্যাচের নাম" }, value: semester?.batch.name },
              { label: { en: "Semester", bn: "সেমিস্টার" }, value: `${semester?.semester_number ? `Semester ${semester?.semester_number}` : 'None'}` },
              { label: { en: "Enrolled at", bn: "তালিকাভুক্তির সময়" }, value: semester?.semester_enrolled_at },
            ]} />

            {semester?.course.type === "long" ?
              <TableContainer sx={{ mt: 2, }}>
                <TableCell sx={{ fontSize: '1.1rem', fontWeight: 'bold', border: 0, px: 0, py: 1 }}><Translate en="Subject Details" bn="বিষয়ের বিস্তারিত" /> </TableCell>
                <Table sx={{ minWidth: 650, borderRadius: 1, border: `1px solid ${grey[100]}` }} size="medium">
                  <TableHead sx={{ bgcolor: grey[100] }}>
                    <TableRow>
                      <TCell bold align="center" >#</TCell>
                      <TCell bold><Translate en="Subject Name" bn="বিষয়ের নাম" /> </TCell>
                      <TCell bold  ><Translate en="Subject Code" bn="বিষয়ের কোড" /> </TCell>
                      <TCell bold align="center"><Translate en="Subject Credit" bn="বিষয়ের ক্রেডিট" /> </TCell>
                      <TCell bold align="center"><Translate en="Subject Marks" bn="বিষয়ের মার্কস" /> </TCell>
                    </TableRow>
                  </TableHead>
                  <TableBody >
                    {semester.course.semester_wise_subjects.find(item => item.semester === semester.semester_number).subjects.map((row, index) => (
                      <TableRow key={index}>
                        <TCell align="center">{index + 1}</TCell>
                        <TCell>{row.name}</TCell>
                        <TCell >{row.subject_code}</TCell>
                        <TCell align="center">{row.credit}</TCell>
                        <TCell align="center">{row.marks}</TCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              :
              <TableContainer sx={{ mt: 2, borderRadius: 1, }}>
                <TableCell sx={{ fontSize: '1.1rem', fontWeight: 'bold', border: 0, px: 0, py: 1 }}><Translate en="Subject Details" bn="বিষয়ের বিস্তারিত" /></TableCell>
                <Table sx={{ minWidth: 650, borderRadius: 1, border: `1px solid ${grey[100]}` }} size="medium">
                  <TableHead sx={{ bgcolor: grey[100] }}>
                    <TableRow>
                      <TCell bold align="center" >#</TCell>
                      <TCell bold>{lang === "en" ? "Subject Name" : "বিষয়ের নাম"}</TCell>
                      <TCell bold  >{lang === "en" ? "Subject Code" : "বিষয়ের কোড"}</TCell>
                      <TCell bold align="center">{lang === "en" ? "Subject Credit" : "বিষয়ের ক্রেডিট"}</TCell>
                      <TCell bold align="center">{lang === "en" ? "Subject Marks" : "বিষয়ের মার্কস"}</TCell>
                    </TableRow>

                  </TableHead>
                  <TableBody>
                    {semester.subjects?.map((item, i) => (
                      <TableRow key={i}>
                        <TCell align="center">{i + 1}</TCell>
                        <TCell>{item.name}</TCell>
                        <TCell >{item.subject_code}</TCell>
                        <TCell align="center">{item.credit}</TCell>
                        <TCell align="center">{item.marks}</TCell>
                      </TableRow>
                    ))
                    }
                  </TableBody>
                </Table>
              </TableContainer>
            }

            {semester?.students?.length > 0 && <TableContainer sx={{ mt: 2, borderRadius: 1, border: `1px solid ${grey[100]}` }}>
              <TableCell sx={{ fontSize: '1.1rem', fontWeight: 'bold', border: 0, px: 0, py: 1 }}><Translate en="Enrolled Students" bn="এনরোল্ড শিক্ষার্থীগণ" /></TableCell>
              <Table sx={{ minWidth: 650, borderRadius: 1, border: `1px solid ${grey[100]}` }} size="medium">
                <TableHead sx={{ bgcolor: grey[100] }}>
                  <TableRow>
                    <TCell bold align="center">#</TCell>
                    <TCell bold><Translate en={"Student ID"} bn={"শিক্ষার্থীর আইডি"} /></TCell>
                    <TCell bold> <Translate en={"Student Name"} bn={"শিক্ষার্থীর নাম"} /> </TCell>
                    <TCell bold><Translate en={"Email Address"} bn={"ই-মেইল"} /> </TCell>
                    <TCell bold><Translate en={"Phone"} bn={"ফোন নাম্বার"} /></TCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {semester?.students.map((data, i) => (
                    <TableRow key={i}>
                      <TCell align="center">{i + 1}</TCell>
                      <TCell>{data._id}</TCell>
                      <TCell>{data.name}</TCell>
                      <TCell>{data.email}</TCell>
                      <TCell>{data.phone}</TCell>
                    </TableRow>
                  ))}


                </TableBody>
              </Table>
            </TableContainer>}
          </Stack>

        </Stack>
      }

    </Page>
  );
}

export default SemesterEnrollmentDetails;