import Page from "../../Layout/Page";
import {
    Button,
    CircularProgress,
    IconButton,
    Paper,
    Stack,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
} from "@mui/material";
import { grey, } from "@mui/material/colors";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as Yup from "yup";
import Translate from "../../components/Translate/Translate";
import PageHeader from "../../Layout/PageHeader";
import FilterForm from "../../components/Filter/FilterForm";
import SelectField from "../../components/Fields/SelectField";
import TCell from "../../components/Table/TCell";
import { BiDotsVerticalRounded } from "react-icons/bi";
import ActionMenu from "../../components/Menus/ActionMenu";
import useGetAllCourse from "../../hooks/course/useGetAllCourse";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import useExams from "../../hooks/exam/useExams";
import { deleteExam, exportToCSV, exportToExcel, sendExamNotification } from "../../api/fetcherFn/exam";
import DeleteDialog from "../../components/HelperComponents/DeleteDialog";
import Loading from "../../Layout/Loading";
import usePermissions from "../../hooks/permission/usePermissions";
import { ROUTES } from "../Settings/permission_data";
import AddNew from "../../components/Buttons/AddNew";
import CalenderDialog from "../../components/Dialogs/CalenderDialog";
import { BsCalendarDate } from "react-icons/bs";
import { toast } from "react-hot-toast";
import { saveAs } from 'file-saver';

const validationSchema = Yup.object().shape({
    batch_id: Yup.number(),
    course_id: Yup.number(),
    semester_number: Yup.number(),
});

export default function ExamManage() {
    const { check } = usePermissions();
    const queryClient = useQueryClient();
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const [data, setData] = useState({});
    const [anchorEl, setAnchorEl] = useState(null);
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [openCanlender, setOpenCanlender] = useState(false);
    const [loadingRow, setLoadingRow] = useState(null);
    const open = Boolean(anchorEl);

    const { control, handleSubmit, watch, setValue } = useForm({ resolver: yupResolver(validationSchema), mode: "onChange", });
    const course = watch('course_id');
    const batch = watch('batch_id');
    const semesterNum = watch('semester_number');

    const allCourse = useGetAllCourse();
    const { data: exams, isLoading } = useExams(page, limit, course, batch, null, semesterNum);


    const { mutateAsync } = useMutation(() => deleteExam(data.id), {
        onSuccess: (data) => {
            if (data.success) {
                toast.success(data.message);
                queryClient.invalidateQueries(['exams']);
            } else {
                toast.error(data.message);
            }
        }
    });
    const notify = useMutation(() => sendExamNotification(data.id), {
        onSuccess: (data) => {
            if (data.success) {
                toast.success(data.message);
            } else {
                toast.error(data.message);
            }
            setLoadingRow(null);
        }
    });

    const handleClick = (i, event, row) => {
        setAnchorEl(event.currentTarget);
        setData(row);
        setLoadingRow(i);
    };

    const onSubmit = (data) => { };
    const handleSendNotification = async () => {
        await notify.mutateAsync();
    }

    const exportToExcelFormat = async () => {
        try {
            const responseData = await exportToExcel();
            const filename = 'exam_details.xlsx';
            saveAs(responseData, filename);
        } catch (err) {
            toast.error('Error downloading file:', err.message);
        }
    }
    const exportToCSVFormat = async () => {
        try {
            const responseData = await exportToCSV();
            const filename = 'exam_details.csv';
            saveAs(responseData, filename);
        } catch (err) {
            toast.error('Error downloading file:', err.message);
        }
    }

    const courses = allCourse?.data?.response_data;
    const batches = courses && courses?.find(c => c.id === course)?.batches;
    const semesters = courses && courses?.find(c => c.id === course)?.semester_wise_subjects;

    const calendarData = exams?.response_data?.data?.map(event => ({
        id: event.id,
        title: event.title,
        start: new Date(`${event.exam_date + " " + event.start_time}`),
        end: new Date(`${event.exam_date + " " + event.end_time}`),
        description: event.description,
        location: event.place
    }))

    return (
        <Page title={"Exam Manage"}>
            <PageHeader titleEn="Manage Exams" titleBn="পরীক্ষা ব্যবস্থাপনা" />


            <Stack direction={"row"} justifyContent={"flex-end"} alignItems={"center"} mb={1} gap={1.5}>
                <Button onClick={exportToExcelFormat} variant="outlined" size="small" sx={{ textTransform: 'unset', fontWeight: "bold" }}>Export to Excel</Button>
                <Button onClick={exportToCSVFormat} variant="outlined" size="small" sx={{ textTransform: 'unset', fontWeight: "bold" }} color="warning">Export to CSV</Button>
                <AddNew variant="contained" btnSize="small" btnText={{ en: "Calender View", bn: "ক্যালেন্ডার ভিউ" }} handleClick={() => setOpenCanlender(true)} icon={<BsCalendarDate size={16} />} />
            </Stack>
            <Paper elevation={0} sx={{ px: 3, py: 2, minHeight: '75vh' }} className="rad-grad">
                <FilterForm handleSubmit={handleSubmit(onSubmit)} button={check(ROUTES.EXAM.ADD)} navigate="/exam/add">
                    <SelectField
                        name="course_id"
                        control={control}
                        isLoading={allCourse.isLoading}
                        data={courses}
                        handelChange={() => {
                            setValue('batch_id', null);
                            setValue('semester_number', null);
                        }}
                        label={{ en: "Select Course", bn: "কোর্স নির্বাচন" }} />
                    <SelectField
                        name="batch_id"
                        control={control}
                        data={batches}
                        uniqueKey={course}
                        label={{ en: "Select Batch", bn: "ব্যাচ নির্বাচন" }} />
                    <SelectField
                        name="semester_number"
                        control={control}
                        uniqueKey={course}
                        data={semesters?.map(option => ({ id: option.semester, name: `Semester ${option.semester}` }))}
                        label={{ en: "Select Semester", bn: "সেমিস্টার নির্বাচন" }}
                        errorMsg />


                </FilterForm>

                {isLoading ? <Loading /> :
                    <>
                        <TableContainer sx={{ mt: 2, borderRadius: 1, border: `1px solid ${grey[100]}` }}>
                            <Table sx={{ minWidth: 650 }} size={"medium"}>
                                <TableHead sx={{ bgcolor: `${grey[100]}` }}>
                                    <TableRow>
                                        <TCell bold align="center">#</TCell>
                                        <TCell bold><Translate en="Course Name" bn="কোর্সের নাম" /></TCell>
                                        <TCell bold><Translate en="Batch" bn="ব্যাচ" /></TCell>
                                        <TCell bold><Translate en="Exam Title" bn="এক্সাম টাইটেল" /></TCell>
                                        <TCell bold><Translate en="Date" bn="তারিখ" /></TCell>
                                        <TCell bold><Translate en="Duration" bn="সময়কাল" /></TCell>
                                        <TCell bold align="center"><Translate en="Exam Students" bn="এক্সামের শিক্ষার্থী" /></TCell>
                                        <TCell bold align="center"><Translate en="Action" bn="অ্যাকশন" /></TCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {!exams.success || exams.response_data.data.length === 0 ?
                                        <TableRow sx={{ textAlign: "center" }}><TCell align="center" bold colSpan={7}>No record found.</TCell></TableRow> :
                                        exams.response_data.data.map((row, index) => (
                                            <TableRow key={index} hover>
                                                <TCell align="center">{index + 1}</TCell>
                                                <TCell>{row.course.name}</TCell>
                                                <TCell>{row.batch.name}</TCell>
                                                <TCell>{row.title}</TCell>
                                                <TCell>{row.exam_date}</TCell>
                                                <TCell>{row.start_time} - {row.end_time}</TCell>
                                                <TCell align="center">{row.participants?.length}</TCell>
                                                <TCell align="center">
                                                    {notify.isLoading && loadingRow === index ? <CircularProgress size={33} /> : <IconButton
                                                        onClick={(e) => handleClick(index, e, row)}><BiDotsVerticalRounded /></IconButton>}
                                                </TCell>
                                            </TableRow>
                                        ))}

                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            component="div"
                            count={exams?.response_data?.meta.data_found}
                            page={page}
                            onPageChange={(e, newPage) => {
                                setPage(newPage);
                            }}
                            rowsPerPage={limit}
                            onRowsPerPageChange={(e) => {
                                setLimit(parseInt(e.target.value, 10));
                                setPage(0);
                            }}
                        />
                    </>}
            </Paper>

            <ActionMenu
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
                open={open}
                viewUrl={check(ROUTES.EXAM.MANAGE.V) && `/exam/details/${data.id}`}
                editUrl={check(ROUTES.EXAM.MANAGE.E) && `/exam/edit/${data.id}`}
                handleNotification={check(ROUTES.EXAM.MANAGE.N) && handleSendNotification}
                handleDeleteDialog={check(ROUTES.EXAM.MANAGE.D) && (() => setDeleteDialog(true))} />

            {deleteDialog && <DeleteDialog handleDelete={async () => await mutateAsync(data.id)} dialog={deleteDialog} setDialog={setDeleteDialog} />}
            {openCanlender && <CalenderDialog openDialog={openCanlender} setOpenDialog={setOpenCanlender} events={calendarData} title={{ en: "Exam Calender View", bn: "পরীক্ষার ক্যালেন্ডার ভিউ" }} />}

        </Page>
    );
}