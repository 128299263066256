import Page from "../../Layout/Page";
import { useParams } from "react-router-dom";
import { Divider, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import PageHeader from "../../Layout/PageHeader";
import TRow from "../../components/Table/TRow";
import Translate from "../../components/Translate/Translate";
import useExam from "../../hooks/exam/useExam";
import Loading from "../../Layout/Loading";
import { Stack, } from "@mui/material";
import { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import TCell from "../../components/Table/TCell";
import { grey } from "@mui/material/colors";
import { IoMdPrint } from "react-icons/io";
import { formatTime } from "../../utils/constants";


const ExamDetails = () => {
  const { id } = useParams();
  const { data, isLoading } = useExam(id);
  const exam = data?.response_data;
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Class Details of ${exam?.title}`,
  });


  return (
    <Page title={"Exam Details"}>
      <PageHeader titleEn="Exam Details" titleBn="পরীক্ষার বিস্তারিত"
        link={{ to: '/exam/manage', textEn: "Manage Exams", textBn: "পরীক্ষা ব্যবস্থাপনা" }} />

      {isLoading ? <Loading /> :
        <Stack component={Paper} px={{ md: 4 }} sx={{ py: 2, maxWidth: 900, margin: '0 auto' }} className="rad-grad">
          <div style={{ width: '100%', display: 'inline-flex', justifyContent: 'flex-end' }}>
            <IoMdPrint onClick={handlePrint} size={25} color="gray" cursor="pointer" />
          </div>

          <Stack ref={componentRef} p={{ md: 4, xs: 2 }} gap={4}>

            <Stack direction={{ xs: "column", md: "row" }} justifyContent={"space-between"} gap={2}>
              <TRow data={[
                { label: { en: "Exam ID", bn: "পরীক্ষার আইডি" }, value: exam.id },
                { label: { en: "Exam Title", bn: "পরীক্ষার টাইটেল" }, value: exam.title },
                { label: { en: "Exam Subject", bn: "পরীক্ষার বিষয়ের" }, value: exam.subject.name },
                { label: { en: "Type", bn: "ধরন" }, value: exam.type },
                { label: { en: "Exam Date", bn: "পরীক্ষার তারিখ" }, value: exam.exam_date },
                { label: { en: "Start Time", bn: "পরীক্ষা শুরু" }, value: formatTime(exam.start_time) },
                { label: { en: "End Time", bn: "পরীক্ষা শেষ" }, value: formatTime(exam.end_time) },
                { label: { en: "Exam Place", bn: "পরীক্ষার লোকেশন" }, value: exam.place },
                { label: { en: "Exam Created at", bn: "পরীক্ষা তৈরির তারিখ" }, value: exam.exam_created_at },
                { label: { en: "Status", bn: "স্ট্যাটাস" }, value: exam.status },
                { label: { en: "Brief Description", bn: "সংক্ষিপ্ত বিবরণ" }, value: exam.description },
              ]} />
              <Divider sx={{ mt: 2, border: `1px solid ${grey[300]}` }} />
              <TRow data={[
                { label: { en: "Course ID", bn: "কোর্সের আইডি" }, value: exam.course.id },
                { label: { en: "Course Name", bn: "কোর্সের নাম" }, value: exam.course.name },
                { label: { en: "Batch Name", bn: "ব্যাচের নাম" }, value: exam.batch.name },
                { label: { en: "Semester", bn: "সেমিস্টার" }, value: `${exam.semester_number ? `Semester ${exam.semester_number}` : 'None'}` }
              ]} />
            </Stack>


            {exam?.participants && <TableContainer sx={{ mt: 2, borderRadius: 1, }}>
              <TableCell sx={{ fontSize: '1.1rem', fontWeight: 'bold', border: 0, px: 0, py: 1 }}><Translate en="Exam Participants" bn="পরীক্ষায় অংশগ্রহণকারীগণ" /></TableCell>
              <Table sx={{ minWidth: 650, borderRadius: 1, border: `1px solid ${grey[100]}` }} size="medium">
                <TableHead sx={{ bgcolor: grey[100] }}>
                  <TableRow>
                    <TCell bold align="center">#</TCell>
                    <TCell bold><Translate en={"Student ID"} bn={"শিক্ষার্থীর আইডি"} /></TCell>
                    <TCell bold> <Translate en={"Student Name"} bn={"শিক্ষার্থীর নাম"} /> </TCell>
                    <TCell bold><Translate en={"Email Address"} bn={"ই-মেইল"} /> </TCell>
                    <TCell bold><Translate en={"Phone"} bn={"ফোন নাম্বার"} /></TCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {exam?.participants.map((data, i) => (
                    <TableRow key={i}>
                      <TCell align="center">{i + 1}</TCell>
                      <TCell>{data._id}</TCell>
                      <TCell>{data.name}</TCell>
                      <TCell>{data.email}</TCell>
                      <TCell>{data.phone}</TCell>
                    </TableRow>
                  ))}


                </TableBody>
              </Table>
            </TableContainer>}


            {exam?.examiners && <TableContainer sx={{ mt: 2, borderRadius: 1, }}>
              <TableCell sx={{ fontSize: '1.1rem', fontWeight: 'bold', border: 0, px: 0, py: 1 }}><Translate en="Exam Examiners" bn="পরীক্ষার পরিক্ষকবৃন্দ" /></TableCell>
              <Table sx={{ minWidth: 650, borderRadius: 1, border: `1px solid ${grey[100]}` }} size="medium">
                <TableHead sx={{ bgcolor: grey[100] }}>
                  <TableRow>
                    <TCell bold align="center">#</TCell>
                    <TCell bold><Translate en={"Teacher ID"} bn={"শিক্ষকের আইডি"} /></TCell>
                    <TCell bold> <Translate en={"Teacher Name"} bn={"শিক্ষকের নাম"} /> </TCell>
                    <TCell bold><Translate en={"Email Address"} bn={"ই-মেইল"} /> </TCell>
                    <TCell bold><Translate en={"Phone"} bn={"ফোন নাম্বার"} /></TCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {exam?.examiners.map((data, i) => (
                    <TableRow key={i}>
                      <TCell align="center">{i + 1}</TCell>
                      <TCell>{data._id}</TCell>
                      <TCell>{data.name}</TCell>
                      <TCell>{data.email}</TCell>
                      <TCell>{data.phone}</TCell>
                    </TableRow>
                  ))}


                </TableBody>
              </Table>
            </TableContainer>}


          </Stack>
        </Stack>}
    </Page>
  );
}

export default ExamDetails;