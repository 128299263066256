import { Table, TableBody, TableContainer, TableHead, TableRow } from '@mui/material';
import { grey } from '@mui/material/colors';
import React from 'react';
import TCell from './TCell';
import Translate from '../Translate/Translate';

const GpaTable = ({ gpaTable }) => {
    return (
        <TableContainer   >
            <Table sx={{ minWidth: 400 }} size={"small"}>
                <TableHead  >
                    <TableRow>
                        <TCell sx={{ border: `1px solid ${grey[100]}`, fontSize: '12px', fontWeight: 'bold' }}><Translate en="Number Range" bn="নাম্বার" /></TCell>
                        <TCell sx={{ border: `1px solid ${grey[100]}`, fontSize: '12px', fontWeight: 'bold' }}><Translate en="Grade" bn="গ্রেড" /></TCell>
                        <TCell sx={{ border: `1px solid ${grey[100]}`, fontSize: '12px', fontWeight: 'bold' }}><Translate en="Point" bn="পয়েন্ট" /></TCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {gpaTable?.map((row, i) => (
                        <TableRow key={i}>
                            <TCell sx={{ border: `1px solid ${grey[100]}`, fontSize: '12px' }}>{row.number_range}</TCell>
                            <TCell sx={{ border: `1px solid ${grey[100]}`, fontSize: '12px' }}>{row.grade}</TCell>
                            <TCell sx={{ border: `1px solid ${grey[100]}`, fontSize: '12px' }}> {row.point}</TCell>
                        </TableRow>
                    ))}

                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default GpaTable;