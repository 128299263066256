import { toast } from "react-hot-toast";
import api from "../setup";

export const addClass = async (data) => {
    try {
        const response = await api.post('/api/class/create', data);
        return response.data;
    } catch (err) {
        toast.error(err.message);
    }
}
export const updateClass = async (id, data) => {
    try {
        const response = await api.patch(`/api/class/update/${id}`, data);
        return response.data;
    } catch (err) {
        toast.error(err.message);
    }
}

export const getClass = async (id) => {
    try {
        const response = await api.get(`/api/class/get/${id}`);
        return response.data;
    } catch (err) {
        toast.error(err.message);
    }
}
export const getClasses = async (page, limit, course, batch, semester) => {
    try {
        let response;
        if (course) { response = await api.get(`/api/class/manage?page=${page}&limit=${limit}&course=${course}`); }
        if (batch) { response = await api.get(`/api/class/manage?page=${page}&limit=${limit}&batch=${batch}`); }
        if (semester) { response = await api.get(`/api/class/manage?page=${page}&limit=${limit}&semester=${semester}`); }
        if (course && batch && semester) { response = await api.get(`/api/class/manage?page=${page}&limit=${limit}&course=${course}&batch=${batch}&semester=${semester}`); }
        if (!course && !batch && !semester) { response = await api.get(`/api/class/manage?page=${page}&limit=${limit}`); }

        return response.data;
    } catch (err) {
        toast.error(err.message);
    }
}
export const getClassesSelf = async (page, limit, course, batch, semester) => {
    try {
        let response;
        if (course) { response = await api.get(`/api/class/manage/self?page=${page}&limit=${limit}&course=${course}`); }
        if (batch) { response = await api.get(`/api/class/manage/self?page=${page}&limit=${limit}&batch=${batch}`); }
        if (semester) { response = await api.get(`/api/class/manage/self?page=${page}&limit=${limit}&semester=${semester}`); }
        if (course && batch && semester) { response = await api.get(`/api/class/manage/self?page=${page}&limit=${limit}&course=${course}&batch=${batch}&semester=${semester}`); }
        if (!course && !batch && !semester) { response = await api.get(`/api/class/manage/self?page=${page}&limit=${limit}`); }

        return response.data;
    } catch (err) {
        toast.error(err.message);
    }
}
export const deleteClass = async (id) => {
    try {
        const response = await api.delete(`/api/class/delete/${id}`);
        return response.data;
    } catch (err) {
        toast.error(err.message);
    }
}

export const sendClassNotification = async (id) => {
    try {
        const response = await api.post(`/api/class/notification/${id}`);
        return response.data;
    } catch (err) {
        toast.error(err.message);
    }
}

export const exportToExcel = async () => {
    try {
        const response = await api.get(`/api/class/export/excel`, {
            responseType: 'blob',
        });
        return response.data;
    } catch (err) {
        toast.error(err.message);
    }
}
export const exportToCSV = async () => {
    try {
        const response = await api.get(`/api/class/export/csv`, {
            responseType: 'blob',
        });
        return response.data;
    } catch (err) {
        toast.error(err.message);
    }
}
