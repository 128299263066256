import Page from "../../Layout/Page";
import { useParams } from "react-router-dom";
import { Paper, Stack, } from "@mui/material";
import PageHeader from "../../Layout/PageHeader";
import TRow from "../../components/Table/TRow";
import useBatch from "../../hooks/batch/useBatch";
import Loading from "../../Layout/Loading";
import React, { useRef } from 'react'
import { IoMdPrint } from "react-icons/io";
import { useReactToPrint } from "react-to-print";

const BatchDetails = () => {
    const { id } = useParams();
    const { data, isLoading } = useBatch(id);
    const batch = data?.response_data;
    const componentRef = useRef();

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: `Batch Details of ${batch?.name}`,
    });



    return (
        <Page title={"Batch Details"}>
            <PageHeader titleEn="Batch Details" titleBn="ব্যাচের বিস্তারিত"
                link={{ to: '/batches/manage', textEn: "Manage Batch", textBn: "ব্যাচ ব্যবস্থাপনা" }} />

            {isLoading ? <Loading /> :
                <Stack component={Paper} px={{ md: 4 }} sx={{ py: 2, maxWidth: 900, minHeight: '75vh', margin: '0 auto' }} className="rad-grad">
                    <div style={{ width: '100%', display: 'inline-flex', justifyContent: 'flex-end' }}>
                        <IoMdPrint onClick={handlePrint} size={25} color="gray" cursor="pointer" />
                    </div>

                    <Stack ref={componentRef} p={{ md: 4, xs: 2 }} gap={4}>
                        <TRow data={[
                            { label: { en: "Batch ID", bn: "ব্যাচের আইডি" }, value: batch.id },
                            { label: { en: "Batch Name", bn: "ব্যাচের নাম" }, value: batch.name },
                            { label: { en: "Course Name", bn: "কোর্সের নাম" }, value: batch.course.name },
                            { label: { en: "Start Date", bn: "শুরুর তারিখ" }, value: batch.start_date },
                            { label: { en: "End Date", bn: "শেষের তারিখ" }, value: batch.end_date },
                            { label: { en: "Created at", bn: "তৈরির সময়" }, value: batch.created_at },
                            { label: { en: "Description", bn: "বিস্তারিত" }, value: batch.description },
                        ]} />



                    </Stack>

                </Stack>}
        </Page>
    );
}

export default BatchDetails;