import Page from "../../Layout/Page";
import { useParams } from "react-router-dom";
import PageHeader from "../../Layout/PageHeader";
import { useQuery } from '@tanstack/react-query';
import { getRequest } from "../../api/fetcherFn/requests";
import { Divider, Paper, Stack, TableCell, Typography, } from "@mui/material";
import TRow from "../../components/Table/TRow";
import Loading from "../../Layout/Loading";
import TCell from "../../components/Table/TCell";
import { grey, } from "@mui/material/colors";
import Translate from "../../components/Translate/Translate";
import { Table, TableBody, TableContainer, TableHead, TableRow } from "@mui/material";
import { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { IoMdPrint } from "react-icons/io";

export default function ApplicationDetails() {
  const params = useParams();
  const id = parseInt(params.id, 10);
  const { data, isLoading } = useQuery(['request', id], () => getRequest(id), { enabled: !!id });
  const request = data?.response_data;
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Application Details for ${request?.document_type}`,
  });


  return (
    <Page title={"Application Details"}>
      <PageHeader titleEn="Application Details" titleBn="আবেদনের বিস্তারিত"
        link={{ to: '/applications/manage', textEn: "Manage Applications", textBn: "আবেদন ব্যবস্থাপনা" }} />
      {isLoading ? <Loading /> :
        <Stack component={Paper} px={{ md: 4 }} sx={{ py: 2, maxWidth: 900, margin: '0 auto' }} className="rad-grad">
          <div style={{ width: '100%', display: 'inline-flex', justifyContent: 'flex-end' }}>
            <IoMdPrint onClick={handlePrint} size={25} color="gray" cursor="pointer" />
          </div>

          <Stack ref={componentRef} p={{ md: 4, xs: 2 }} gap={4}>

            <Stack direction={{ xs: "column", md: "row" }} justifyContent={"space-between"} gap={2}>
              <TRow data={[
                { label: { en: "Request ID", bn: "অনুরোধের আইডি" }, value: request.id },
                { label: { en: "Document Type", bn: "ডকুমেন্টের ধরন" }, value: request.document_type },
                { label: { en: "Request Status", bn: "অনুরোধের অবস্থা" }, value: request.request_status },
                { label: { en: "Details", bn: "বিস্তারিত" }, value: request.details },
                { label: { en: "Status", bn: "স্ট্যাটাস" }, value: request.status },
                { label: { en: "Request Created at", bn: "অনুরোধের তারিখ" }, value: request.created_at },
              ]} />

              <Divider sx={{ mt: 2, border: `1px solid ${grey[300]}` }} />
              <TRow data={[
                { label: { en: "Course ID", bn: "কোর্সের আইডি" }, value: request.course.id },
                { label: { en: "Course Name", bn: "কোর্সের নাম" }, value: request.course.name },
                { label: { en: "Batch Name", bn: "ব্যাচের নাম" }, value: request.batch.name },
                { label: { en: "Semester", bn: "সেমিস্টার" }, value: `${request.semester_number ? `Semester ${request.semester_number}` : "None"}` },
              ]} />
            </Stack>



            {request.requestBy && <TableContainer sx={{ mt: 2, borderRadius: 1 }}>
              <TableCell sx={{ fontSize: '1.1rem', fontWeight: 'bold', border: 0, px: 0, py: 1 }}><Translate en="Request By" bn="অনুরোধকারী" /></TableCell>
              <Table sx={{ minWidth: 650, borderRadius: 1, border: `1px solid ${grey[100]}` }} size="medium">
                <TableHead sx={{ bgcolor: grey[100] }}>
                  <TableRow>
                    <TCell bold><Translate en={"Requestor ID"} bn={"অনুরোধকারীর আইডি"} /></TCell>
                    <TCell bold> <Translate en={"Requestor Name"} bn={"অনুরোধকারীর নাম"} /> </TCell>
                    <TCell bold><Translate en={"Requestor Email"} bn={"ই-মেইল"} /> </TCell>
                    <TCell bold><Translate en={"Requestor Phone"} bn={"ফোন"} /></TCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  <TableRow>
                    <TCell>{request.requestBy._id}</TCell>
                    <TCell>{request.requestBy.name}</TCell>
                    <TCell>{request.requestBy.email}</TCell>
                    <TCell>{request.requestBy.phone}</TCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>}

            {request.cancelledBy && <TableContainer sx={{ mt: 2, borderRadius: 1, }}>
              <TableCell sx={{ fontSize: '1.1rem', fontWeight: 'bold', border: 0, px: 0, py: 1 }}><Translate en="cancelled By" bn="বাতিলকারী" /></TableCell>
              <Table sx={{ minWidth: 650, borderRadius: 1, border: `1px solid ${grey[100]}` }} size="medium">
                <TableHead sx={{ bgcolor: grey[100] }}>
                  <TableRow>
                    <TCell bold><Translate en={"Canceller ID"} bn={"বাতিলকারীর আইডি"} /></TCell>
                    <TCell bold> <Translate en={"Canceller Name"} bn={"বাতিলকারীর নাম"} /> </TCell>
                    <TCell bold><Translate en={"Email"} bn={"ই-মেইল"} /> </TCell>
                    <TCell bold><Translate en={"Phone"} bn={"ফোন"} /></TCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  <TableRow>
                    <TCell>{request.cancelledBy._id}</TCell>
                    <TCell>{request.cancelledBy.name}</TCell>
                    <TCell>{request.cancelledBy.email}</TCell>
                    <TCell>{request.cancelledBy.phone}</TCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>}


            {request.approvedBy && <TableContainer sx={{ mt: 2, borderRadius: 1, }}>
              <TableCell sx={{ fontSize: '1.1rem', fontWeight: 'bold', border: 0, px: 0, py: 1 }}><Translate en="Approved By" bn="অনুমোদনকারী" /></TableCell>
              <Table sx={{ minWidth: 650, borderRadius: 1, border: `1px solid ${grey[100]}` }} size="medium">
                <TableHead sx={{ bgcolor: grey[100] }}>
                  <TableRow>
                    <TCell bold><Translate en={"Approver ID"} bn={"অনুমোদনকারীর আইডি"} /></TCell>
                    <TCell bold> <Translate en={"Approver Name"} bn={"অনুমোদনকারীর নাম"} /> </TCell>
                    <TCell bold><Translate en={"Email"} bn={"ই-মেইল"} /> </TCell>
                    <TCell bold><Translate en={"Phone"} bn={"ফোন"} /></TCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  <TableRow>
                    <TCell>{request.approvedBy._id}</TCell>
                    <TCell>{request.approvedBy.name}</TCell>
                    <TCell>{request.approvedBy.email}</TCell>
                    <TCell>{request.approvedBy.phone}</TCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>}


          </Stack>
        </Stack>}
    </Page>
  );
}