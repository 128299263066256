import Page from "../../Layout/Page";
import {
    Stack,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as Yup from "yup";
import PageHeader from "../../Layout/PageHeader";
import AddForm from "../../components/Filter/AddForm";
import TextInput from "../../components/Fields/TextInput";
import MultilineInput from "../../components/Fields/MultilineInput";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-hot-toast";
import Loading from "../../Layout/Loading";
import { addSubject, updateSubject } from "../../api/fetcherFn/subject";
import useSubject from "../../hooks/notice-subject/useSubject";

const validationSchema = Yup.object().shape({
    name: Yup.string().required("Required."),
    subject_code: Yup.string().required("Required."),
    credit: Yup.number().transform(v => !isNaN(v) ? v : 0).required("Required.").min(1, "Credit should be as minimum as 1."),
    marks: Yup.number().nullable().transform(v => !isNaN(v) ? v : null).required("Required."),
    description: Yup.string(),
});


export default function AddSubject() {
    const queryClient = useQueryClient();
    const params = useParams();
    const id = parseInt(params.id, 10);
    const navigate = useNavigate();
    const { data: subject, isLoading } = useSubject(id);
    const { control, handleSubmit, reset } = useForm({ resolver: yupResolver(validationSchema), mode: "onChange", });
    const add = useMutation(data => addSubject(data), {
        onSuccess: (data) => {
            if (data.success) {
                toast.success(data.message);
                navigate('/subject/manage');
            } else {
                toast.error(data.message);
            }
        },
        onError: (err) => {
            toast.error(err.message);
        }
    });
    const update = useMutation(data => updateSubject(id, data), {
        onSuccess: (data) => {
            if (data.success) {
                toast.success(data.message);
                queryClient.invalidateQueries('subject');
                navigate('/subject/manage');
            } else {
                toast.error(data.message);
            }
        },
        onError: (err) => {
            toast.error(err.message);
        }
    });



    const onSubmit = async (data) => {
        if (id) {
            await update.mutateAsync(data);
            return;
        }
        await add.mutateAsync(data);
    }

    return (
        <Page title={`${id ? 'Edit' : 'Add'} Subject`}>
            <PageHeader titleEn={`${id ? 'Edit' : 'Add'} Subject`} titleBn={`বিষয় ${id ? 'তথ্য পরিবর্তন' : 'সংযুক্তিকরণ'}`}
                link={{ to: '/subject/manage', textEn: "Manage Subjects", textBn: "বিষয়সমূহ ব্যবস্থাপনা" }} />


            {(id && isLoading) ? <Loading /> : <AddForm handleSubmit={handleSubmit(onSubmit)} loading={add.isLoading || update.isLoading} reset={reset}>
                <TextInput
                    name="name"
                    control={control}
                    value={subject?.response_data?.name}
                    label={{ en: "Subject Name", bn: "বিষয়ের নাম" }}
                    errorMsg fontBold={false} required />
                <TextInput
                    name="subject_code"
                    control={control}
                    value={subject?.response_data?.subject_code}
                    label={{ en: "Subject Code", bn: "বিষয় কোড" }}
                    errorMsg fontBold={false} required />

                <Stack direction="row" gap={1}>
                    <TextInput
                        name="credit"
                        type="number"
                        control={control}
                        min={1}
                        value={subject?.response_data?.credit}
                        label={{ en: "Credit", bn: "ক্রেডিট" }}
                        errorMsg fontBold={false} required />
                    <TextInput
                        name="marks"
                        type="number"
                        control={control}
                        value={subject?.response_data?.marks}
                        min={0}
                        label={{ en: "Marks", bn: "মার্কস" }}
                        errorMsg fontBold={false} required />
                </Stack>


                <MultilineInput
                    name="description"
                    control={control}
                    value={subject?.response_data?.description}
                    label={{ en: "Subject Description", bn: "বিষয়ের বিস্তারিত" }}
                    errorMsg />

            </AddForm>}

        </Page>
    )
}