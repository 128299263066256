import { toast } from "react-hot-toast";
import api from "../setup";

export const addTeacher = async (data) => {
    try {
        const response = await api.post('/api/teacher/add', data, {
            headers: {
                "Content-Type": 'multipart/form-data'
            }
        });
        return response.data;
    } catch (err) {
        toast.error(err.message);
    }
}
export const updateTeacher = async (id, data) => {
    try {
        const response = await api.patch(`/api/teacher/update/${id}`, data, {
            headers: {
                "Content-Type": 'multipart/form-data'
            }
        });
        return response.data;
    } catch (err) {
        toast.error(err.message);
    }
}

export const getTeacher = async (id) => {
    try {
        const response = await api.get(`/api/teacher/get/${id}`);
        return response.data;
    } catch (err) {
        toast.error(err.message);
    }
}
export const getTeachers = async (page, limit, name) => {
    try {
        let response;
        if (name) { response = await api.get(`/api/teacher/manage?page=${page}&limit=${limit}&name=${name}`); }
        else {
            response = await api.get(`/api/teacher/manage?page=${page}&limit=${limit}`);
        }
        return response.data;
    } catch (err) {
        toast.error(err.message);
    }
}
export const getTeachersSelf = async (page, limit, name) => {
    try {
        let response;
        if (name) { response = await api.get(`/api/teacher/manage/self?page=${page}&limit=${limit}&name=${name}`); }
        else {
            response = await api.get(`/api/teacher/manage/self?page=${page}&limit=${limit}`);
        }
        return response.data;
    } catch (err) {
        toast.error(err.message);
    }
}
export const deleteTeacher = async (id) => {
    try {
        const response = await api.delete(`/api/teacher/delete/${id}`);
        return response.data;
    } catch (err) {
        toast.error(err.message);
    }
}


export const exportToExcel = async () => {
    try {
        const response = await api.get(`/api/teacher/export/excel`, {
            responseType: 'blob',
        });
        return response.data;
    } catch (err) {
        toast.error(err.message);
    }
}
export const exportToCSV = async () => {
    try {
        const response = await api.get(`/api/teacher/export/csv`, {
            responseType: 'blob',
        });
        return response.data;
    } catch (err) {
        toast.error(err.message);
    }
}

export const getInfiniteTeachers = async (pageParam, limit) => {
    try {
        console.log(typeof pageParam)
        const response = await api.get(`/api/teacher/all?page=${pageParam}&limit=${limit}`);
        return response.data;
    } catch (err) {
        toast.error(err.message);
    }
}




// Teacher Course Assign
export const assignTeacher = async (data) => {
    try {
        const response = await api.post('/api/teacher-course/assign', data);
        return response.data;
    } catch (err) {
        toast.error(err.message);
    }
}
export const updateTeacherAssign = async (id, data) => {
    try {
        const response = await api.patch(`/api/teacher-course/update/${id}`, data);
        return response.data;
    } catch (err) {
        toast.error(err.message);
    }
}

export const getTeacherAssigned = async (id) => {
    try {
        const response = await api.get(`/api/teacher-course/get/${id}`);
        return response.data;
    } catch (err) {
        toast.error(err.message);
    }
}
export const getAssignedTeachers = async (page, limit, course, batch, semester) => {
    try {
        let response;
        if (course) { response = await api.get(`/api/teacher-course/manage?page=${page}&limit=${limit}&course=${course}`); }
        if (batch) { response = await api.get(`/api/teacher-course/manage?page=${page}&limit=${limit}&batch=${batch}`); }
        if (semester) { response = await api.get(`/api/teacher-course/manage?page=${page}&limit=${limit}&semester=${semester}`); }
        if (course && batch && semester) { response = await api.get(`/api/teacher-course/manage?page=${page}&limit=${limit}&course=${course}&batch=${batch}&semester=${semester}`); }
        if (!course && !batch && !semester) { response = await api.get(`/api/teacher-course/manage?page=${page}&limit=${limit}`); }
        return response.data;
    } catch (err) {
        toast.error(err.message);
    }
}
export const getAssignedTeachersSelf = async (page, limit, course, batch, semester) => {
    try {
        let response;
        if (course) { response = await api.get(`/api/teacher-course/manage/self?page=${page}&limit=${limit}&course=${course}`); }
        if (batch) { response = await api.get(`/api/teacher-course/manage/self?page=${page}&limit=${limit}&batch=${batch}`); }
        if (semester) { response = await api.get(`/api/teacher-course/manage/self?page=${page}&limit=${limit}&semester=${semester}`); }
        if (course && batch && semester) { response = await api.get(`/api/teacher-course/manage/self?page=${page}&limit=${limit}&course=${course}&batch=${batch}&semester=${semester}`); }
        if (!course && !batch && !semester) { response = await api.get(`/api/teacher-course/manage/self?page=${page}&limit=${limit}`); }
        return response.data;
    } catch (err) {
        toast.error(err.message);
    }
}
export const deleteTeacherAssigned = async (id) => {
    try {
        const response = await api.delete(`/api/teacher-course/delete/${id}`);
        return response.data;
    } catch (err) {
        toast.error(err.message);
    }
}

