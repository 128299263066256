import { toast } from "react-hot-toast";
import api from "../setup";

export const createExam = async (data) => {
    try {
        const response = await api.post('/api/exam/create', data);
        return response.data;
    } catch (err) {
        toast.error(err.message);
    }
}
export const updateExam = async (id, data) => {
    try {
        const response = await api.patch(`/api/exam/update/${id}`, data);
        return response.data;
    } catch (err) {
        toast.error(err.message);
    }
}

export const getExam = async (id) => {
    try {
        const response = await api.get(`/api/exam/get/${id}`);
        return response.data;
    } catch (err) {
        toast.error(err.message);
    }
}
export const getExams = async (page, limit, course, batch, exam, semester, subject) => {
    try {
        let response;
        if (course) { response = await api.get(`/api/exam/manage?page=${page}&limit=${limit}&course=${course}`); }
        if (batch) { response = await api.get(`/api/exam/manage?page=${page}&limit=${limit}&batch=${batch}`); }
        if (exam) { response = await api.get(`/api/exam/manage?page=${page}&limit=${limit}&exam=${exam}`); }
        if (subject) { response = await api.get(`/api/exam/manage?page=${page}&limit=${limit}&subject=${subject}`); }
        if (semester) { response = await api.get(`/api/exam/manage?page=${page}&limit=${limit}&semester=${semester}`); }
        if (course && batch && exam) { response = await api.get(`/api/exam/manage?page=${page}&limit=${limit}&course=${course}&batch=${batch}&exam=${exam}`); }
        if (course && batch && semester && exam) { response = await api.get(`/api/exam/manage?page=${page}&limit=${limit}&course=${course}&batch=${batch}&semester=${semester}&exam=${exam}`); }
        if (course && batch && subject) { response = await api.get(`/api/exam/manage?page=${page}&limit=${limit}&course=${course}&batch=${batch}&subject=${subject}`); }
        if (course && semester) { response = await api.get(`/api/exam/manage?page=${page}&limit=${limit}&course=${course}&semester=${semester}`); }
        if (!course && !batch && !semester && !subject && !exam) { response = await api.get(`/api/exam/manage?page=${page}&limit=${limit}`); }

        return response.data;
    } catch (err) {
        toast.error(err.message);
    }
}
export const getExamsSelf = async (page, limit, course, batch, exam, semester, subject) => {
    try {
        let response;
        if (course) { response = await api.get(`/api/exam/manage/self?page=${page}&limit=${limit}&course=${course}`); }
        if (batch) { response = await api.get(`/api/exam/manage/self?page=${page}&limit=${limit}&batch=${batch}`); }
        if (exam) { response = await api.get(`/api/exam/manage/self?page=${page}&limit=${limit}&exam=${exam}`); }
        if (subject) { response = await api.get(`/api/exam/manage/self?page=${page}&limit=${limit}&subject=${subject}`); }
        if (semester) { response = await api.get(`/api/exam/manage/self?page=${page}&limit=${limit}&semester=${semester}`); }
        if (course && batch && exam) { response = await api.get(`/api/exam/manage/self?page=${page}&limit=${limit}&course=${course}&batch=${batch}&exam=${exam}`); }
        if (course && batch && semester && exam) { response = await api.get(`/api/exam/manage/self?page=${page}&limit=${limit}&course=${course}&batch=${batch}&semester=${semester}&exam=${exam}`); }
        if (course && batch && subject) { response = await api.get(`/api/exam/manage/self?page=${page}&limit=${limit}&course=${course}&batch=${batch}&subject=${subject}`); }
        if (course && semester) { response = await api.get(`/api/exam/manage/self?page=${page}&limit=${limit}&course=${course}&semester=${semester}`); }
        if (!course && !batch && !semester && !subject && !exam) { response = await api.get(`/api/exam/manage/self?page=${page}&limit=${limit}`); }

        return response.data;
    } catch (err) {
        toast.error(err.message);
    }
}
export const deleteExam = async (id) => {
    try {
        const response = await api.delete(`/api/exam/delete/${id}`);
        return response.data;
    } catch (err) {
        toast.error(err.message);
    }
}

export const sendExamNotification = async (id) => {
    try {
        const response = await api.post(`/api/exam/notification/${id}`);
        return response.data;
    } catch (err) {
        toast.error(err.message);
    }
}
export const getRoutines = async (page, limit, semester) => {
    try {
        let response;
        if (semester) { response = await api.get(`/api/exam/routine?page=${page}&limit=${limit}&semester=${semester}`); }
        else { response = await api.get(`/api/exam/routine?page=${page}&limit=${limit}`); }

        return response.data;
    } catch (err) {
        toast.error(err.message);
    }
}

export const exportToExcel = async () => {
    try {
        const response = await api.get(`/api/exam/export/excel`, {
            responseType: 'blob',
        });
        return response.data;
    } catch (err) {
        toast.error(err.message);
    }
}
export const exportToCSV = async () => {
    try {
        const response = await api.get(`/api/exam/export/csv`, {
            responseType: 'blob',
        });
        return response.data;
    } catch (err) {
        toast.error(err.message);
    }
}