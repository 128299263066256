import Page from "../../Layout/Page";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as Yup from "yup";
import PageHeader from "../../Layout/PageHeader";
import AddForm from "../../components/Filter/AddForm";
import SelectField from "../../components/Fields/SelectField";
import TextInput from "../../components/Fields/TextInput";
import MultilineInput from "../../components/Fields/MultilineInput";
import FileInput from "../../components/Fields/FileInput";
import { PDF_DOC_DOCX_PNG_JPG_JPEG } from "../../Constants/FileTypes";
import { useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-hot-toast";
import { createNotice, } from "../../api/fetcherFn/notice";
import useRoles from "../../hooks/settings/useRoles";


const validationSchema = Yup.object().shape({
    title: Yup.string().required('Notice Title is required'),
    description: Yup.string(),
    notice_for: Yup.string().required("Select for whoom to notify."),
    attachment: Yup.array()
        .min(1, "Please select at least one file")
        .max(1, "Maximum 1 file.")
        .test("fileSize", "File size must be less than 5MB", (value) =>
            value.every((file) => file.size <= 5000000)
        )
        .test("fileType", "Invalid file type", (value) =>
            value.every((file) => PDF_DOC_DOCX_PNG_JPG_JPEG.includes(file.type))
        ),
});

export default function NoticeAdd() {
    const queryClient = useQueryClient();
    const navigate = useNavigate();

    const { roles, roleLoading } = useRoles();

    const { control, handleSubmit, reset } = useForm({ resolver: yupResolver(validationSchema), mode: "onChange", });
    const add = useMutation(data => createNotice(data), {
        onSuccess: (data) => {
            if (data.success) {
                toast.success(data.message);
                queryClient.invalidateQueries('notice');
                navigate('/notices/manage');
            } else {
                toast.error(data.message);
            }
        },
        onError: (err) => {
            toast.error(err.message);
        }
    });

    const onSubmit = async (data) => {
        const { attachment, ...rest } = data;
        const formData = new FormData();
        formData.append('attachment', attachment[0]);
        for (const key in rest) {
            formData.append(key, rest[key]);
        }

        await add.mutateAsync(formData);
    }



    return (
        <Page title={`Add Notice`}>
            <PageHeader titleEn={`Add Notice`} titleBn={`নোটিস সংযুক্তি`}
                link={{ to: '/notices/manage', textEn: "Manage Notice", textBn: "নোটিস ব্যবস্থাপনা" }} />



            <AddForm handleSubmit={handleSubmit(onSubmit)} loading={add.isLoading} reset={reset}>
                <TextInput
                    name="title"
                    control={control}
                    label={{ en: "Notice Title", bn: "নোটিস টাইটেল" }}
                    errorMsg fontBold={false} required autoFocus />
                <MultilineInput
                    name="description"
                    control={control}
                    label={{ en: "Deccription", bn: "বিস্তারিত" }}
                    errorMsg />
                <FileInput
                    name="attachment"
                    control={control}
                    fileTypes={PDF_DOC_DOCX_PNG_JPG_JPEG}
                    placeholder=".pdf, .doc, .png, .jpg, .jpeg" required />


                <SelectField
                    name="notice_for"
                    control={control}
                    subLabel={false}
                    isLoading={roleLoading}
                    data={roles?.response_data}
                    label={{ en: "Notice For?", bn: "কার জন্য?" }}
                    errorMsg fontBold={false} required />

            </AddForm>

        </Page>
    )
}