import { toast } from "react-hot-toast";
import api from "../setup";

export const createAttendance = async (data) => {
    try {
        const response = await api.post('/api/attendance/create', data);
        return response.data;
    } catch (err) {
        toast.error(err.message);
    }
}
export const updateOneAttendance = async (id, data) => {
    try {
        const response = await api.patch(`/api/attendance/update/${id}`, data);
        return response.data;
    } catch (err) {
        toast.error(err.message);
    }
}
export const updateAllAttendance = async (data) => {
    try {
        const response = await api.patch(`/api/attendance/update-many`, data);
        return response.data;
    } catch (err) {
        toast.error(err.message);
    }
}

export const getAttendances = async (page, limit, course, batch, _class, semester, classDate, studentId) => {
    try {
        let response;
        if (course) { response = await api.get(`/api/attendance/manage?page=${page}&limit=${limit}&course=${course}`); }
        if (batch) { response = await api.get(`/api/attendance/manage?page=${page}&limit=${limit}&batch=${batch}`); }
        if (course && batch) { response = await api.get(`/api/attendance/manage?page=${page}&limit=${limit}&course=${course}&batch=${batch}`); }
        if (course && batch && studentId) { response = await api.get(`/api/attendance/manage?page=${page}&limit=${limit}&course=${course}&batch=${batch}&student=${studentId}`); }
        if (_class) { response = await api.get(`/api/attendance/manage?page=${page}&limit=${limit}&_class=${_class}`); }
        if (course && batch && _class) { response = await api.get(`/api/attendance/manage?page=${page}&limit=${limit}&course=${course}&batch=${batch}&_class=${_class}`); }
        if (course && batch && semester) { response = await api.get(`/api/attendance/manage?page=${page}&limit=${limit}&course=${course}&batch=${batch}&semester=${semester}`); }
        if (course && batch && semester && studentId) { response = await api.get(`/api/attendance/manage?page=${page}&limit=${limit}&course=${course}&batch=${batch}&semester=${semester}&student=${studentId}`); }
        if (course && batch && _class && studentId) { response = await api.get(`/api/attendance/manage?page=${page}&limit=${limit}&course=${course}&batch=${batch}&student=${studentId}`); }
        if (course && batch && semester && _class && studentId) { response = await api.get(`/api/attendance/manage?page=${page}&limit=${limit}&course=${course}&batch=${batch}&semester=${semester}&_class=${_class}&student=${studentId}`); }
        if (classDate) { response = await api.get(`/api/attendance/manage?page=${page}&limit=${limit}&date=${classDate}`); }
        if (!course && !batch && !_class && !semester && !classDate && !studentId) { response = await api.get(`/api/attendance/manage?page=${page}&limit=${limit}`); }

        return response.data;
    } catch (err) {
        toast.error(err.message);
    }
}
export const getAttendancesSelf = async (page, limit, course, batch, _class, semester, classDate, studentId) => {
    try {
        let response;
        if (course) { response = await api.get(`/api/attendance/manage/self?page=${page}&limit=${limit}&course=${course}`); }
        if (batch) { response = await api.get(`/api/attendance/manage/self?page=${page}&limit=${limit}&batch=${batch}`); }
        if (course && batch) { response = await api.get(`/api/attendance/manage/self?page=${page}&limit=${limit}&course=${course}&batch=${batch}`); }
        if (course && batch && studentId) { response = await api.get(`/api/attendance/manage/self?page=${page}&limit=${limit}&course=${course}&batch=${batch}&student=${studentId}`); }
        if (_class) { response = await api.get(`/api/attendance/manage/self?page=${page}&limit=${limit}&_class=${_class}`); }
        if (course && batch && _class) { response = await api.get(`/api/attendance/manage/self?page=${page}&limit=${limit}&course=${course}&batch=${batch}&_class=${_class}`); }
        if (course && batch && semester) { response = await api.get(`/api/attendance/manage/self?page=${page}&limit=${limit}&course=${course}&batch=${batch}&semester=${semester}`); }
        if (course && batch && semester && studentId) { response = await api.get(`/api/attendance/manage/self?page=${page}&limit=${limit}&course=${course}&batch=${batch}&semester=${semester}&student=${studentId}`); }
        if (course && batch && _class && studentId) { response = await api.get(`/api/attendance/manage/self?page=${page}&limit=${limit}&course=${course}&batch=${batch}&student=${studentId}`); }
        if (course && batch && semester && _class && studentId) { response = await api.get(`/api/attendance/manage/self?page=${page}&limit=${limit}&course=${course}&batch=${batch}&semester=${semester}&_class=${_class}&student=${studentId}`); }
        if (classDate) { response = await api.get(`/api/attendance/manage/self?page=${page}&limit=${limit}&date=${classDate}`); }
        if (!course && !batch && !_class && !semester && !classDate && !studentId) { response = await api.get(`/api/attendance/manage/self?page=${page}&limit=${limit}`); }

        return response.data;
    } catch (err) {
        toast.error(err.message);
    }
}

export const exportToExcel = async () => {
    try {
        const response = await api.get(`/api/attendance/export/excel`, {
            responseType: 'blob',
        });
        return response.data;
    } catch (err) {
        toast.error(err.message);
    }
}
export const exportToCSV = async () => {
    try {
        const response = await api.get(`/api/attendance/export/csv`, {
            responseType: 'blob',
        });
        return response.data;
    } catch (err) {
        toast.error(err.message);
    }
}



// Attendance Summary Report 
export const getAttendanceReport = async (page, limit, course, batch, semester, student) => {
    try {
        let response;
        if (course) { response = await api.get(`/api/attendance/report/manage?page=${page}&limit=${limit}&course=${course}`); }
        if (batch) { response = await api.get(`/api/attendance/report/manage?page=${page}&limit=${limit}&batch=${batch}`); }
        if (course && batch) { response = await api.get(`/api/attendance/report/manage?page=${page}&limit=${limit}&course=${course}&batch=${batch}`); }
        // if (_class) { response = await api.get(`/api/attendance/report/manage?page=${page}&limit=${limit}&_class=${_class}`); }
        if (semester) { response = await api.get(`/api/attendance/report/manage?page=${page}&limit=${limit}&semester=${semester}`); }
        if (student) { response = await api.get(`/api/attendance/report/manage?page=${page}&limit=${limit}&student=${student}`); }
        if (course && batch && semester && student) { response = await api.get(`/api/attendance/report/manage?page=${page}&limit=${limit}&course=${course}&batch=${batch}&semester=${semester}&student=${student}`); }
        // if (classDate) { response = await api.get(`/api/attendance/report/manage?page=${page}&limit=${limit}&date=${classDate}`); }
        if (!course && !batch && !semester && !student) { response = await api.get(`/api/attendance/report/manage?page=${page}&limit=${limit}`); }

        return response.data;
    } catch (err) {
        toast.error(err.message);
    }
}
export const getAttendanceReportSelf = async (page, limit, course, batch, semester, student) => {
    try {
        let response;
        if (course) { response = await api.get(`/api/attendance/report/manage/self?page=${page}&limit=${limit}&course=${course}`); }
        if (batch) { response = await api.get(`/api/attendance/report/manage/self?page=${page}&limit=${limit}&batch=${batch}`); }
        if (course && batch) { response = await api.get(`/api/attendance/report/manage/self?page=${page}&limit=${limit}&course=${course}&batch=${batch}`); }
        // if (_class) { response = await api.get(`/api/attendance/report/manage/self?page=${page}&limit=${limit}&_class=${_class}`); }
        if (semester) { response = await api.get(`/api/attendance/report/manage/self?page=${page}&limit=${limit}&semester=${semester}`); }
        if (student) { response = await api.get(`/api/attendance/report/manage/self?page=${page}&limit=${limit}&student=${student}`); }
        if (course && batch && semester && student) { response = await api.get(`/api/attendance/report/manage/self?page=${page}&limit=${limit}&course=${course}&batch=${batch}&semester=${semester}&student=${student}`); }
        // if (classDate) { response = await api.get(`/api/attendance/report/manage/self?page=${page}&limit=${limit}&date=${classDate}`); }
        if (!course && !batch && !semester && !student) { response = await api.get(`/api/attendance/report/manage/self?page=${page}&limit=${limit}`); }

        return response.data;
    } catch (err) {
        toast.error(err.message);
    }
}
export const exportReportToExcel = async () => {
    try {
        const response = await api.get(`/api/attendance/report/export/excel`, {
            responseType: 'blob',
        });
        return response.data;
    } catch (err) {
        toast.error(err.message);
    }
}
export const exportReportToCSV = async () => {
    try {
        const response = await api.get(`/api/attendance/report/export/csv`, {
            responseType: 'blob',
        });
        return response.data;
    } catch (err) {
        toast.error(err.message);
    }
}