import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import { HiArrowSmDown } from 'react-icons/hi';

const AccordionElement = ({ title = "Accordion", details = "Accordion Details" }) => {
    return (
        <Accordion>
            <AccordionSummary
                expandIcon={<HiArrowSmDown />}
                aria-controls="panel2a-content"
                id="panel2a-header" >
                <Typography>{title}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Typography>
                    {details}
                </Typography>
            </AccordionDetails>
        </Accordion>
    );
};

export default AccordionElement;