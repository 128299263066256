import { Paper, Stack, Typography } from "@mui/material";

const DashboardStatecard = ({ cardTitle = "Sample Title", cardData = 0, img = "", bgcolor = '#dafdfc' }) => {
    return (
        <Paper elevation={0} sx={{ p: 3, bgcolor: bgcolor }}>
            <Stack direction={"row"} justifyContent={"space-around"}>
                <img src={img} style={{ width: '6rem', height: '6rem', objectFit: 'cover', opacity: '0.8' }} alt={cardTitle} />
                <Stack alignItems={"center"} justifyContent={"center"}>
                    <Typography variant={"h6"} fontWeight={"bold"}>{cardTitle}</Typography>
                    <Typography variant={"h4"} fontWeight={"bold"} sx={{ my: 1, color: 'green' }}>{cardData}</Typography>
                </Stack>
            </Stack>
        </Paper>
    )
}

export default DashboardStatecard;