import {
    Button, Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    Stack,
    Typography
} from "@mui/material";
import { RiErrorWarningLine } from "react-icons/ri";
import { toast } from "react-hot-toast";
import { useAuth } from "../../hooks/useAuth";


// const Transition = forwardRef(function Transition(props, ref) {
//     return <Slide direction="up" ref={ref} {...props}/>;
// });

const DeleteDialog = ({ handleDelete, setDialog, dialog }) => {
    const { lang } = useAuth();
    return (
        <Dialog
            open={dialog}
            maxWidth="xs"
            // TransitionComponent={Transition}
            // keepMounted
            onClose={() => setDialog(!dialog)}
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle>
                <Stack
                    alignItems={"center"}
                    direction={"row"}
                >
                    <RiErrorWarningLine size={24} color={"#eed202"} />
                    <Typography sx={{ ml: 1 }} variant={"h6"}>
                        {lang === "en" ? "Confirm Delete!" : "আপনি কি নিশ্চিত!"}
                    </Typography>
                </Stack>
            </DialogTitle>
            <Divider></Divider>
            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                    {lang === "en" ? "Are you sure you want to delete this data? Once deleted, you will not get this data back." : "এই তথ্যটি মুছে ফেলার ব্যাপারে আপনি কি নিশ্চিত? মুছে ফেলা তথ্য ফেরতযোগ্য নয়।"}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    sx={{ textTransform: 'capitalize' }}
                    variant={"contained"}
                    color={"error"}
                    onClick={() => setDialog(!dialog)}  >
                    {lang === "en" ? "Disagree" : "আমি নিশ্চিত নই"}
                </Button>
                <Button
                    sx={{ textTransform: 'capitalize' }}
                    variant={"contained"}
                    color={"success"}
                    onClick={() => {
                        handleDelete()
                        toast.success("Successfully Deleted.");
                        setDialog(!dialog);
                    }} >
                    {lang === "en" ? "Agree" : "আমি নিশ্চিত"}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default DeleteDialog;