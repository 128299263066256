import { useQuery } from "@tanstack/react-query";
import { useAuth } from "../useAuth";
import { getPermissions } from "../../api/fetcherFn/settings";

const usePermissions = () => {
    const { user } = useAuth();
    const { data: permissions, isLoading: isPermiting } = useQuery(['permissions', user?.id], () => getPermissions(user?.id), {
        refetchOnWindowFocus: true
    });

    const check = (...routes) => {
        const exists = permissions?.response_data?.find(p => (routes.includes(p.route) && p.status === 'active'));

        if (exists) return true;
        return false;
    }

    return {
        permissions,
        isPermiting,
        check
    }
};

export default usePermissions;