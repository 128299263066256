import { Divider, Grid, Stack, Typography } from '@mui/material';
import React from 'react';
import Translate from '../Translate/Translate';

const FormSection = ({ children, title }) => {
    let childrens = [];
    if (!children.length) {
        childrens.push(children)
    } else {
        childrens = [...children];
    }

    return (
        <Stack mb={3}>
            <Typography variant="h6" fontWeight="bold"><Translate en={title.en} bn={title.bn} /></Typography>
            <Divider sx={{ mb: 2 }} />

            <Grid container spacing={2}>
                {childrens?.map((item, i) =>
                    <Grid item xs={12} md={6} key={i}>
                        {item}
                    </Grid>)}
            </Grid>
        </Stack>
    );
};

export default FormSection;