import { Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import React from 'react';
import Translate from '../Translate/Translate';

const TRow = ({ data, mt = 2 }) => {
    return (
        <TableContainer sx={{ mt: mt, mx: -2 }}>
            <TableBody>
                <Table sx={{ minWidth: 400 }} size='small'>
                    {data?.map((d, i) => (
                        <TableRow key={i}>
                            <TableCell sx={{ border: 0, fontWeight: 'bold' }}><Translate en={d.label.en} bn={d.label.bn} /></TableCell>
                            <TableCell sx={{ border: 0 }}>:</TableCell>
                            <TableCell sx={{ border: 0 }}>{d.value}</TableCell>
                        </TableRow>
                    ))}
                </Table>
            </TableBody>
        </TableContainer>
    );
};

export default TRow;