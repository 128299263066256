import Page from "../../Layout/Page";
import {
    Button, Divider,
    FormControl, FormHelperText,
    Grid,
    IconButton,
    Menu, MenuItem, OutlinedInput,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow, Typography
} from "@mui/material";
import { grey, red } from "@mui/material/colors";
import { useState } from "react";
import { CgMenuRound } from "react-icons/cg";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import ReactSelect from "react-select";
import { lastTenYears } from "../../_mockData/datesAndYears";
import * as Yup from "yup";
import { BsSearch } from "react-icons/bs";
import PageHeader from "../../Layout/PageHeader";
import Translate from "../../components/Translate/Translate";
import { my_courses } from "../../_mockData/myCourses";
import { courses } from "../../_mockData/courses";
import ViewDialog from "../../components/Dialogs/ViewDialog";
import TCell from "../../components/Table/TCell";
import ActionMenu from "../../components/Menus/ActionMenu";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { batches } from "../../_mockData/batches";

const validationSchema = Yup.object().shape({});

const initialData = {
    id: 1,
    course_name: courses[0].label,
    duration: courses[0].duration,
    start_end_date: `${courses[0].from_date} - ${courses[0].to_date}`,
    fee: courses[0].fees
};

export default function MyCourses() {
    //Menu Stuff
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const [openDialog, setOpenDialog] = useState(false);
    const [data, setData] = useState(initialData);

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(validationSchema),
        mode: "onChange",
        defaultValues: {}
    });
    const onSubmit = (data) => {
        console.log(data)
    };

    return (
        <Page title={"My Courses"}>
            <PageHeader titleEn="My Courses" titleBn="আমার কোর্সসমূহ" />
            <Paper elevation={0} sx={{ px: 3, py: 2, minHeight: '70vh' }} className="rad-grad">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container alignItems={"end"} justifyContent="flex-end" gap={1}>

                        <Grid item xs={12} md={4}>
                            <Controller
                                render={({ field, formState }) => (
                                    <FormControl fullWidth>
                                        <Typography fontWeight="bold" color={!!formState.errors?.batch ? red[700] : ""}><Translate en="Select Batch" bn="ব্যাচ নির্বাচন" /></Typography>
                                        <ReactSelect
                                            styles={{
                                                control: (base) => ({
                                                    ...base,
                                                    border: !!formState.errors?.batch
                                                        ? "1px solid #D32F2F"
                                                        : "1px solid #C4C4C4",
                                                    "&:hover": {
                                                        border: !!formState.errors?.batch
                                                            ? "1px solid #D32F2F"
                                                            : "1px solid #C4C4C4",
                                                    },
                                                }),
                                            }}
                                            className="basic-single"
                                            classNamePrefix="select"
                                            isClearable={true}
                                            isSearchable={true}
                                            name="batch"
                                            options={courses}
                                            //value={field?.label !== "" ? lastTenYears()?.filter((option) => option?.id === field?.id)[0]: null}
                                            getOptionValue={(option) => option.id}
                                        />
                                        {!!formState.errors?.batch ? (
                                            <FormHelperText error>
                                                {errors?.batch?.message}
                                            </FormHelperText>
                                        ) : (
                                            ""
                                        )}
                                    </FormControl>
                                )}
                                name="batch"
                                control={control}
                                defaultValue=""
                            />
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <Controller
                                render={({ field, formState }) => (
                                    <FormControl fullWidth>
                                        <Typography fontWeight="bold" color={!!formState.errors?.batch ? red[700] : ""}><Translate en="Select Batch" bn="ব্যাচ নির্বাচন" /></Typography>
                                        <ReactSelect
                                            styles={{
                                                control: (base) => ({
                                                    ...base,
                                                    border: !!formState.errors?.batch
                                                        ? "1px solid #D32F2F"
                                                        : "1px solid #C4C4C4",
                                                    "&:hover": {
                                                        border: !!formState.errors?.batch
                                                            ? "1px solid #D32F2F"
                                                            : "1px solid #C4C4C4",
                                                    },
                                                }),
                                            }}
                                            className="basic-single"
                                            classNamePrefix="select"
                                            isClearable={true}
                                            isSearchable={true}
                                            name="batch"
                                            options={batches}
                                            //value={field?.label !== "" ? lastTenYears()?.filter((option) => option?.id === field?.id)[0]: null}
                                            getOptionValue={(option) => option.id}
                                        />
                                        {!!formState.errors?.batch ? (
                                            <FormHelperText error>
                                                {errors?.batch?.message}
                                            </FormHelperText>
                                        ) : (
                                            ""
                                        )}
                                    </FormControl>
                                )}
                                name="batch"
                                control={control}
                                defaultValue=""
                            />
                        </Grid>
                    </Grid>
                </form>
                <TableContainer sx={{ mt: 2, borderRadius: 1, border: `1px solid ${grey[100]}` }}>
                    <Table sx={{ minWidth: 650 }} size={"medium"}>
                        <TableHead sx={{ bgcolor: `${grey[100]}` }}>
                            <TableRow>
                                <TCell align="center" bold>#</TCell>
                                <TCell bold><Translate en="Course Name" bn="কোর্সের নাম" /></TCell>
                                <TCell bold><Translate en="Batch" bn="ব্যাচ" /></TCell>
                                <TCell bold><Translate en="Duration" bn="সময়কাল" /></TCell>
                                <TCell bold><Translate en="Start & End Time" bn="শুরু এবং শেষের তারিখ" /></TCell>
                                <TCell bold><Translate en="Registration Timestamp" bn="নিবন্ধনের সময়" /></TCell>
                                <TCell align="center" bold>Action</TCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {my_courses?.map((row, index) => (
                                <TableRow key={index} hover>
                                    <TCell align="center">{++index}</TCell>
                                    <TCell>{courses[index].label}</TCell>
                                    <TCell>{row.batch}</TCell>
                                    <TCell>{courses[index].duration}</TCell>
                                    <TCell>{courses[index].from_date} - {courses[index].to_date}</TCell>
                                    <TCell>{row.reg_timestamp}</TCell>
                                    <TCell align="center">
                                        <IconButton onClick={(e) => {
                                            setAnchorEl(e.currentTarget);
                                            setData({ ...data, ...row });
                                        }}><BiDotsVerticalRounded /></IconButton></TCell>
                                </TableRow>
                            ))}

                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    component="div"
                    count={100}
                    page={1}
                    onPageChange={() => {
                    }}
                    rowsPerPage={10}
                    onRowsPerPageChange={() => {
                    }}
                />
            </Paper>

            <ActionMenu
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
                open={open}
                handleView={() => setOpenDialog(true)} />

            {openDialog && <ViewDialog openDialog={openDialog} setOpenDialog={setOpenDialog}
                metadata={{ title: { en: "Course Details", bn: "কোর্সের বিস্তারিত" }, data }} />}
        </Page>
    );
}