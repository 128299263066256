import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { Button, CircularProgress, Paper, Stack } from '@mui/material';
import React from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from "yup";
import PasswordField from '../../components/Fields/PasswordField';
import Translate from '../../components/Translate/Translate';
import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-hot-toast';
import { useAuth } from '../../hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import { changePassword } from '../../api/fetcherFn/accountSettings';
import { AiOutlineCloudUpload } from 'react-icons/ai';

const validationSchema = Yup.object().shape({
    password: Yup.string()
        .required('Required')
        .min(6, 'Password must be atleast 6 digits'),
    confirm_password: Yup.string()
        .required('Required')
});


const ChangePassword = () => {
    const { user } = useAuth();
    const navigate = useNavigate();
    const { control, handleSubmit } = useForm({ resolver: yupResolver(validationSchema), mode: "onChange", });
    const changePass = useMutation(data => changePassword(user.id, data), {
        onSuccess: (data) => {
            if (data.success) {
                toast.success(data.message);
                navigate('/dashboard/home');
            } else {
                toast.error(data.message);
            }
        },
        onError: (err) => {
            toast.error(err.message);
        }
    });

    const onSubmit = async (data) => {
        const { password, confirm_password } = data;
        if (data.password !== confirm_password) return toast.error("Password Not Match!");
        await changePass.mutateAsync({ password });
    }

    return (
        <Paper sx={{ pt: 2, pb: 4, px: 4 }} className='rad-grad'>
            <form onSubmit={handleSubmit(onSubmit)}  >

                <Stack justifyContent="space-between" alignItems="center" gap={3} width={{ md: 400 }}>
                    <PasswordField
                        name="password"
                        type="password"
                        control={control}
                        label={{ en: "Password", bn: "পাসওয়ার্ড" }}
                        errorMsg fontBold={false} required />
                    <PasswordField
                        name="confirm_password"
                        type="password"
                        control={control}
                        label={{ en: "Confirm Password", bn: "কনফার্ম পাসওয়ার্ড" }}
                        errorMsg fontBold={false} required />

                    <Button variant="contained" type="submit" color="primary" size='large' >
                        <Stack direction="row" alignItems='center' gap={1} fontWeight={'bold'} textTransform="capitalize">
                            {changePass.isLoading ? <CircularProgress size={18} /> : <AiOutlineCloudUpload size={20} />}
                            <Translate en="Change Password" bn="পাসওয়ার্ড পরিবর্তন" />
                        </Stack>
                    </Button>
                </Stack>
            </form>
        </Paper >
    );
};

export default ChangePassword;