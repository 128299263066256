import { useQuery } from '@tanstack/react-query';
import { getStudents, getStudentsSelf } from '../../api/fetcherFn/student';
import usePermissions from '../permission/usePermissions';
import { ROUTES } from '../../Pages/Settings/permission_data';

const useStudents = (page = 0, limit = 0, name) => {
    const { check } = usePermissions();
    return useQuery(['students', page + 1, limit, name],
        check(ROUTES.STUDENT.MANAGE.M) ? () => getStudents(page + 1, limit, name) : () => getStudentsSelf(page + 1, limit, name), {
        keepPreviousData: true
    });
};

export default useStudents;