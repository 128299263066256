import { useQuery } from '@tanstack/react-query';
import { getStudentEnrollments, getStudentEnrollmentsSelf } from '../../api/fetcherFn/student';
import usePermissions from '../permission/usePermissions';
import { ROUTES } from '../../Pages/Settings/permission_data';

const useGetCourseEnrolledStudents = (page = 0, limit = 0, course, batch, enabled = true, keepPreviousData = true) => {
    const { check } = usePermissions();
    return useQuery(['student-courses', page + 1, limit, course, batch],
        check(ROUTES.STUDENT.COURSE.MANAGE.M) ? () => getStudentEnrollments(page + 1, limit, course, batch) : () => getStudentEnrollmentsSelf(page + 1, limit, course, batch), {
        keepPreviousData: keepPreviousData, enabled: enabled ? true : !!course && !!batch
    });
};

export default useGetCourseEnrolledStudents;