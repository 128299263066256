import { toast } from "react-hot-toast";
import api from "../setup";

export const addStudent = async (data) => {
    try {
        const response = await api.post('/api/student/add', data, {
            headers: {
                "Content-Type": 'multipart/form-data'
            }
        });
        return response.data;
    } catch (err) {
        toast.error(err.message);
    }
}
export const updateStudent = async (id, data) => {
    try {
        const response = await api.patch(`/api/student/update/${id}`, data, {
            headers: {
                "Content-Type": 'multipart/form-data'
            }
        });
        return response.data;
    } catch (err) {
        toast.error(err.message);
    }
}

export const getStudent = async (id) => {
    try {
        const response = await api.get(`/api/student/get/${id}`);
        return response.data;
    } catch (err) {
        toast.error(err.message);
    }
}
export const getStudents = async (page, limit, name) => {
    try {
        let response;
        if (name) { response = await api.get(`/api/student/manage?page=${page}&limit=${limit}&name=${name}`); }
        else {
            response = await api.get(`/api/student/manage?page=${page}&limit=${limit}`);
        }
        return response.data;
    } catch (err) {
        toast.error(err.message);
    }
}
export const getStudentsSelf = async (page, limit, name) => {
    try {
        let response;
        if (name) { response = await api.get(`/api/student/manage/self?page=${page}&limit=${limit}&name=${name}`); }
        else {
            response = await api.get(`/api/student/manage/self?page=${page}&limit=${limit}`);
        }
        return response.data;
    } catch (err) {
        toast.error(err.message);
    }
}
export const deleteStudent = async (id) => {
    try {
        const response = await api.delete(`/api/student/delete/${id}`);
        return response.data;
    } catch (err) {
        toast.error(err.message);
    }
}



// Student Course Enrollment 
export const studentEnroll = async (data) => {
    try {
        const response = await api.post('/api/student-course/enroll', data);
        return response.data;
    } catch (err) {
        toast.error(err.message);
    }
}
export const updateStudentEnroll = async (id, data) => {
    try {
        const response = await api.patch(`/api/student-course/update/${id}`, data);
        return response.data;
    } catch (err) {
        toast.error(err.message);
    }
}
export const deleteStudentEnroll = async (id, data) => {
    try {
        const response = await api.delete(`/api/student-course/delete/${id}`);
        return response.data;
    } catch (err) {
        toast.error(err.message);
    }
}

export const getStudentEnroll = async (id) => {
    try {
        const response = await api.get(`/api/student-course/get/${id}`);
        return response.data;
    } catch (err) {
        toast.error(err.message);
    }
}
export const getStudentEnrollments = async (page, limit, course, batch) => {
    try {
        let response;
        if (course) { response = await api.get(`/api/student-course/manage?page=${page}&limit=${limit}&course=${course}`); }
        if (batch) { response = await api.get(`/api/student-course/manage?page=${page}&limit=${limit}&batch=${batch}`); }
        if (course && batch) { response = await api.get(`/api/student-course/manage?page=${page}&limit=${limit}&course=${course}&batch=${batch}`); }
        if (!course && !batch) {
            response = await api.get(`/api/student-course/manage?page=${page}&limit=${limit}`);
        }
        return response.data;
    } catch (err) {
        toast.error(err.message);
    }
}
export const getStudentEnrollmentsSelf = async (page, limit, course, batch) => {
    try {
        let response;
        if (course) { response = await api.get(`/api/student-course/manage/self?page=${page}&limit=${limit}&course=${course}`); }
        if (batch) { response = await api.get(`/api/student-course/manage/self?page=${page}&limit=${limit}&batch=${batch}`); }
        if (course && batch) { response = await api.get(`/api/student-course/manage/self?page=${page}&limit=${limit}&course=${course}&batch=${batch}`); }
        if (!course && !batch) {
            response = await api.get(`/api/student-course/manage/self?page=${page}&limit=${limit}`);
        }
        return response.data;
    } catch (err) {
        toast.error(err.message);
    }
}

export const exportToExcel = async () => {
    try {
        const response = await api.get(`/api/student/export/excel`, {
            responseType: 'blob'
        });
        return response.data;
    } catch (err) {
        toast.error(err.message);
    }
}
export const exportToCSV = async () => {
    try {
        const response = await api.get(`/api/student/export/csv`, {
            responseType: 'blob'
        });
        return response.data;
    } catch (err) {
        toast.error(err.message);
    }
}


// Student Course Enrollment 
export const semesterEnroll = async (data) => {
    try {
        const response = await api.post('/api/semester-enrollment/enroll', data);
        return response.data;
    } catch (err) {
        toast.error(err.message);
    }
}
export const updateSemesterEnroll = async (id, data) => {
    try {
        const response = await api.patch(`/api/semester-enrollment/update/${id}`, data);
        return response.data;
    } catch (err) {
        toast.error(err.message);
    }
}
export const deleteSemesterEnroll = async (id, data) => {
    try {
        const response = await api.delete(`/api/semester-enrollment/delete/${id}`);
        return response.data;
    } catch (err) {
        toast.error(err.message);
    }
}

export const getSemesterEnroll = async (id) => {
    try {
        const response = await api.get(`/api/semester-enrollment/get/${id}`);
        return response.data;
    } catch (err) {
        toast.error(err.message);
    }
}
export const getSemesterEnrollments = async (page, limit, course, batch, semester, subject) => {
    try {
        let response;
        if (course) { response = await api.get(`/api/semester-enrollment/manage?page=${page}&limit=${limit}&course=${course}`); }
        if (batch) { response = await api.get(`/api/semester-enrollment/manage?page=${page}&limit=${limit}&batch=${batch}`); }
        if (semester) { response = await api.get(`/api/semester-enrollment/manage?page=${page}&limit=${limit}&semester=${semester}`); }
        if (subject) { response = await api.get(`/api/semester-enrollment/manage?subject=${subject}`); }
        if (course && semester) { response = await api.get(`/api/semester-enrollment/manage?page=${page}&limit=${limit}&course=${course}&semester=${semester}`); }
        if (course && batch) { response = await api.get(`/api/semester-enrollment/manage?page=${page}&limit=${limit}&course=${course}&batch=${batch}`); }
        if (course && batch && semester) { response = await api.get(`/api/semester-enrollment/manage?page=${page}&limit=${limit}&course=${course}&batch=${batch}&semester=${semester}`); }
        if (course && batch && subject) {
            response = await api.get(`/api/semester-enrollment/manage?course=${course}&batch=${batch}&subject=${subject}`);
        } if (!course && !batch && !semester && !subject) {
            response = await api.get(`/api/semester-enrollment/manage?page=${page}&limit=${limit}`);
        }
        return response.data;
    } catch (err) {
        toast.error(err.message);
    }
}
export const getSemesterEnrollmentsSelf = async (page, limit, course, batch, semester, subject) => {
    try {
        let response;
        if (course) { response = await api.get(`/api/semester-enrollment/manage/self?page=${page}&limit=${limit}&course=${course}`); }
        if (batch) { response = await api.get(`/api/semester-enrollment/manage/self?page=${page}&limit=${limit}&batch=${batch}`); }
        if (semester) { response = await api.get(`/api/semester-enrollment/manage/self?page=${page}&limit=${limit}&semester=${semester}`); }
        if (subject) { response = await api.get(`/api/semester-enrollment/manage/self?subject=${subject}`); }
        if (course && semester) { response = await api.get(`/api/semester-enrollment/manage/self?page=${page}&limit=${limit}&course=${course}&semester=${semester}`); }
        if (course && batch) { response = await api.get(`/api/semester-enrollment/manage/self?page=${page}&limit=${limit}&course=${course}&batch=${batch}`); }
        if (course && batch && semester) { response = await api.get(`/api/semester-enrollment/manage/self?page=${page}&limit=${limit}&course=${course}&batch=${batch}&semester=${semester}`); }
        if (course && batch && subject) {
            response = await api.get(`/api/semester-enrollment/manage/self?course=${course}&batch=${batch}&subject=${subject}`);
        }
        if (!course && !batch && !semester && !subject) {
            response = await api.get(`/api/semester-enrollment/manage/self?page=${page}&limit=${limit}`);
        }
        return response.data;
    } catch (err) {
        toast.error(err.message);
    }
} 