import Page from "../../Layout/Page";
import {
    Paper,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import PageHeader from "../../Layout/PageHeader";
import Translate from "../../components/Translate/Translate";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as Yup from "yup";
import TCell from "../../components/Table/TCell";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import Loading from "../../Layout/Loading";
import { getRoutines } from "../../api/fetcherFn/exam";
import FilterForm from "../../components/Filter/FilterForm";
import { useForm } from "react-hook-form";
import TextInput from "../../components/Fields/TextInput";
import { useAuth } from "../../hooks/useAuth";
import AddNew from "../../components/Buttons/AddNew";
import CalenderDialog from "../../components/Dialogs/CalenderDialog";
import { BsCalendarDate } from "react-icons/bs";

const validationSchema = Yup.object().shape({
    semester_number: Yup.string(),
});

const ExamRoutine = () => {
    const { user } = useAuth();
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const [openCanlender, setOpenCanlender] = useState(false);
    const { control, handleSubmit, watch } = useForm({ resolver: yupResolver(validationSchema), mode: "onChange", });
    const semesterNum = watch('semester_number');

    const { data: routines, isLoading } = useQuery(['exam-routine', page + 1, limit, semesterNum], () => getRoutines(page + 1, limit, semesterNum));

    const onSubmit = (data) => { };

    const calendarData = routines?.response_data?.data?.map(event => ({
        id: event.id,
        title: event.title,
        start: new Date(`${event.exam_date + " " + event.start_time}`),
        end: new Date(`${event.exam_date + " " + event.end_time}`),
        description: event.description,
        location: event.place
    }))



    return (
        <Page title={"Exam Routine"}>
            <PageHeader titleEn="Exam Routine" titleBn="পরীক্ষার রুটিন" />

            <AddNew variant="contained" btnText={{ en: "Calender View", bn: "ক্যালেন্ডার ভিউ" }} handleClick={() => setOpenCanlender(true)} icon={<BsCalendarDate size={16} />} />
            <Paper elevation={0} sx={{ p: 3, minHeight: '100vh' }} className="rad-grad">

                <FilterForm handleSubmit={handleSubmit(onSubmit)} button={user?.type === 'admin' && true} >
                    <TextInput
                        name="semester_number"
                        control={control}
                        label={{ en: "Search with Semester", bn: "সেমিস্টার দ্বারা খুজুন" }}
                        placeholder="Search with Semester No..." />
                </FilterForm>


                {isLoading ? <Loading /> :
                    <>
                        <TableContainer sx={{ mt: 2, borderRadius: 1, border: `1px solid ${grey[100]}` }}>
                            <Table sx={{ minWidth: 650 }} size={"medium"}>
                                <TableHead sx={{ bgcolor: `${grey[100]}` }}>
                                    <TableRow>
                                        <TCell bold align="center">#</TCell>
                                        <TCell bold><Translate en="Course Name" bn="কোর্সের নাম" /></TCell>
                                        <TCell bold><Translate en="Batch" bn="ব্যাচ" /></TCell>
                                        <TCell bold><Translate en="Semester" bn="সেমিস্টার" /></TCell>
                                        <TCell bold><Translate en="Exam Title" bn="এক্সাম টাইটেল" /></TCell>
                                        <TCell bold><Translate en="Date" bn="তারিখ" /></TCell>
                                        <TCell bold><Translate en="Time" bn="সময়" /></TCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {!routines.success || routines.response_data.data.length === 0 ?
                                        <TableRow sx={{ textAlign: "center" }}><TCell align="center" bold colSpan={7}>No record found.</TCell></TableRow> :
                                        routines.response_data.data.map((row, index) => (
                                            <TableRow key={index} hover>
                                                <TCell align="center">{index + 1}</TCell>
                                                <TCell>{row.course.name}</TCell>
                                                <TCell>{row.batch.name}</TCell>
                                                <TCell>Semester {row.semester_number}</TCell>
                                                <TCell>{row.title}</TCell>
                                                <TCell>{row?.exam_date}</TCell>
                                                <TCell>{row?.start_time} - {row.end_time}</TCell>
                                            </TableRow>
                                        ))}

                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            component="div"
                            count={routines?.response_data?.meta.data_found}
                            page={page}
                            onPageChange={(e, newPage) => {
                                setPage(newPage);
                            }}
                            rowsPerPage={limit}
                            onRowsPerPageChange={(e) => {
                                setLimit(parseInt(e.target.value, 10));
                                setPage(0);
                            }}
                        />
                    </>
                }
            </Paper>

            {openCanlender && <CalenderDialog openDialog={openCanlender} setOpenDialog={setOpenCanlender} events={calendarData} title={{ en: "Exam Calender View", bn: "পরীক্ষার ক্যালেন্ডার ভিউ" }} />}

        </Page>
    )
}
export default ExamRoutine;