import Page from "../../Layout/Page";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as Yup from "yup";
import PageHeader from "../../Layout/PageHeader";
import AddForm from "../../components/Filter/AddForm";
import SelectField from "../../components/Fields/SelectField";
import MultipleSelectField from "../../components/Fields/MultipleSelectField";
import useGetAllCourse from "../../hooks/course/useGetAllCourse";
import { NavLink, } from "react-router-dom";
import { useMutation, } from "@tanstack/react-query";
import { toast } from "react-hot-toast";
import Loading from "../../Layout/Loading";
import { studentEnroll, } from "../../api/fetcherFn/student";
import useStudents from "../../hooks/student/useStudents";
import { useState } from "react";
import { Paper, Stack, Typography } from "@mui/material";
import { MdVerified, MdWarning } from "react-icons/md";

const validationSchema = Yup.object().shape({
    course_id: Yup.number().nullable().required("Course is required"),
    batch_id: Yup.number().nullable().required("Batch is required"),
    students: Yup.array()
        .nullable()
        .required()
        .min(1, "Required"),
});

const StudentEnrollment = () => {
    const [successMessage, setSuccessMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);

    const allCourse = useGetAllCourse();
    const { data, isLoading: studentLoading } = useStudents();
    const { control, handleSubmit, watch, setValue } = useForm({ resolver: yupResolver(validationSchema), mode: "onChange", });
    const courseId = watch('course_id');

    const add = useMutation(data => studentEnroll(data), {
        onSuccess: (data) => {
            if (data.success) {
                setSuccessMessage(data.message);
                // navigate('/students/manage-enrollment');
            } else if (!data.success) {
                setErrorMessage(data.message);
                // toast.error(data.message);
            }
        },
        onError: (err) => {
            toast.error(err.message);
        }
    });


    const onSubmit = async (data) => {
        setSuccessMessage(false);
        setErrorMessage(false);
        data.students = data.students?.map(id => ({ id: id }))
        await add.mutateAsync(data);
        window.scrollTo(0, 0);
    }

    const courses = allCourse?.data?.response_data;
    const batches = courses && courses?.find(c => c.id === courseId)?.batches;
    const students = data?.response_data?.data.map(d => d.user);

    return (
        <Page title={'Student Enrollment'}>
            <PageHeader titleEn={'Student Enrollment'} titleBn={'শিক্ষার্থী তালিকাভুক্তি'}
                link={{ to: '/students/manage-enrollment', textEn: "Manage Course Enrollment", textBn: "কোর্স তালিকাভুক্তি ব্যবস্থাপনা" }} />

            <Paper elevation={0} sx={{ minHeight: '75vh' }} className="rad-grad #msg" >
                {errorMessage &&
                    <Stack direction={"row"} py={3} color="orangered" alignItems={"center"} gap={1} justifyContent={"center"}><MdWarning size={25} /> {errorMessage}
                        <NavLink to={`/students/student-enrollment/edit/${add.data?.response_data?.enrolled_id}`} style={{ color: 'blue', fontWeight: "bold", textDecoration: "underline" }}>Update Existing</NavLink>
                    </Stack>
                }
                {successMessage && <Stack py={3} sx={{ display: 'flex', flexDirection: "column", justifyContent: 'center', alignItems: "center" }}>
                    <Typography sx={{ display: "inline-flex", alignItems: "center", gap: 1, color: "green" }}><MdVerified /> {successMessage}</Typography>
                    <Stack direction={"row"} justifyContent={"space-between"} gap={2}>
                        <NavLink to={'/students/semester-enrollment/enroll'} style={{ fontSize: '16px', color: 'blue', marginTop: 1, fontWeight: "bold", textDecoration: "underline" }}>Enroll Semester now</NavLink>
                        <NavLink to={'/students/manage-enrollment'} style={{ fontSize: '16px', color: 'blue', marginTop: 1, fontWeight: "bold" }}>Do Later</NavLink>
                    </Stack>
                </Stack>}
                <AddForm gradient={false} handleSubmit={handleSubmit(onSubmit)} loading={add.isLoading}>


                    <SelectField
                        name="course_id"
                        control={control}
                        data={courses}
                        handelChange={() => {
                            setValue('batch_id', null);
                            setValue('subject_id', null);
                        }}
                        label={{ en: "Select Course", bn: "কোর্স নির্বাচন" }}
                        errorMsg fontBold={false} required
                        link={{ label: "Not found Course? Create one", to: "/courses/add" }} />
                    <SelectField
                        name="batch_id"
                        control={control}
                        uniqueKey={courseId}
                        data={batches}
                        label={{ en: "Select Batch", bn: "ব্যাচ নির্বাচন" }}
                        errorMsg fontBold={false} required
                        link={{ label: "Not found Batch? Create one", to: "/batch/add-batch" }} />

                    {studentLoading ? <Loading /> : students && <MultipleSelectField
                        name="students"
                        control={control}
                        subLabel={false}
                        subValue={"_id"}
                        data={students}
                        search={true}
                        selectAllValue={true}
                        label={{ en: "Student List", bn: "শিক্ষার্থীর তালিকা" }}
                        errorMsg fontBold={false} required />}
                </AddForm>
            </Paper>


        </Page>
    )
}

export default StudentEnrollment;