import { toast } from "react-hot-toast";
import api from "../setup";

export const createNotice = async (data) => {
    try {
        const response = await api.post('/api/notice/create', data, {
            headers: {
                "Content-Type": 'multipart/form-data'
            }
        });
        return response.data;
    } catch (err) {
        toast.error(err.message);
    }
}
export const updateNotice = async (id, data) => {
    try {
        const response = await api.patch(`/api/notice/update/${id}`, data, {
            headers: {
                "Content-Type": 'multipart/form-data'
            }
        });
        return response.data;
    } catch (err) {
        toast.error(err.message);
    }
}

export const getNotice = async (id) => {
    try {
        const response = await api.get(`/api/notice/get/${id}`);
        return response.data;
    } catch (err) {
        toast.error(err.message);
    }
}
export const getNotices = async (page, limit, title) => {
    try {
        let response;
        if (title) {
            response = await api.get(`/api/notice/manage?page=${page}&limit=${limit}&title=${title}`);
        } else {
            response = await api.get(`/api/notice/manage?page=${page}&limit=${limit}`);
        }
        return response.data;
    } catch (err) {
        toast.error(err.message);
    }
}
export const getNoticeboard = async (page, limit) => {
    try {
        let response = await api.get(`/api/notice/noticeboard?page=${page}&limit=${limit}`);
        return response.data;
    } catch (err) {
        toast.error(err.message);
    }
}
export const getNoticesSelf = async (page, limit, title) => {
    try {
        let response;
        if (title) {
            response = await api.get(`/api/notice/manage/self?page=${page}&limit=${limit}&title=${title}`);
        } else {
            response = await api.get(`/api/notice/manage/self?page=${page}&limit=${limit}`);
        }
        return response.data;
    } catch (err) {
        toast.error(err.message);
    }
}
export const deleteNotice = async (id) => {
    try {
        const response = await api.delete(`/api/notice/delete/${id}`);
        return response.data;
    } catch (err) {
        toast.error(err.message);
    }
}