import Page from "../../Layout/Page";
import {
    IconButton,
    Paper,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as Yup from "yup";
import Translate from "../../components/Translate/Translate";
import PageHeader from "../../Layout/PageHeader";
import TCell from "../../components/Table/TCell";
import ActionMenu from "../../components/Menus/ActionMenu";
import { BiDotsVerticalRounded } from "react-icons/bi";
import useNotices from "../../hooks/notice-subject/useNotices";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deleteNotice } from "../../api/fetcherFn/notice";
import DeleteDialog from "../../components/HelperComponents/DeleteDialog";
import Loading from "../../Layout/Loading";
import usePermissions from "../../hooks/permission/usePermissions";
import { ROUTES } from "../Settings/permission_data";
import TextInput from "../../components/Fields/TextInput";
import FilterForm from "../../components/Filter/FilterForm";


const validationSchema = Yup.object().shape({
    search: Yup.string()
});

export default function NoticeManage() {
    const { check } = usePermissions();
    const queryClient = useQueryClient();
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const [data, setData] = useState({});
    const [anchorEl, setAnchorEl] = useState(null);
    const [deleteDialog, setDeleteDialog] = useState(false);
    const open = Boolean(anchorEl);
    const { control, watch } = useForm({ resolver: yupResolver(validationSchema), mode: "onChange", });
    const title = watch('search');

    const { data: notices, isLoading } = useNotices(page, limit, title);
    const { mutateAsync } = useMutation(() => deleteNotice(data.id), { onSuccess: () => { queryClient.invalidateQueries(['notices']); } });


    const handleClick = (event, data) => {
        setAnchorEl(event.currentTarget);
        setData(data)
    };



    return (
        <Page title={"Notice Manage"}>
            <PageHeader titleEn="Manage Notice" titleBn="নোটিস ব্যবস্থাপনা" />

            <Paper elevation={0} sx={{ px: 3, py: 2, minHeight: '75vh' }} className="rad-grad">
                <FilterForm button={check(ROUTES.NOTICE.MANAGE.M)} navigate="/notices/create">
                    <TextInput
                        name="search"
                        control={control}
                        // label={{ en: "Search By Title", bn: "টাইটেল দ্বারা খুঁজুন" }}
                        placeholder="Search by Title..." />

                </FilterForm>

                {isLoading ? <Loading /> :
                    <>
                        <TableContainer sx={{ mt: 2, borderRadius: 1, border: `1px solid ${grey[100]}` }}>
                            <Table sx={{ minWidth: 650 }} size={"medium"}>
                                <TableHead sx={{ bgcolor: `${grey[100]}` }}>
                                    <TableRow>
                                        <TCell bold align="center">#</TCell>
                                        <TCell bold><Translate en="Notice Title" bn="নোটিস টাইটেল" /></TCell>
                                        <TCell bold><Translate en="Download" bn="ডাউনলোড" /></TCell>
                                        <TCell bold><Translate en="Date" bn="তারিখ" /></TCell>
                                        {check(ROUTES.NOTICE.MANAGE.M) && <TCell bold><Translate en="Notice For" bn="কার জন্য?" /></TCell>}
                                        <TCell bold align="center"><Translate en="Action" bn="অ্যাকশন" /></TCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {!notices.success || notices.response_data.data.length === 0 ?
                                        <TableRow sx={{ textAlign: "center" }}><TCell align="center" bold colSpan={7}>No record found.</TCell></TableRow> :
                                        notices.response_data.data.map((row, index) => (
                                            <TableRow key={index} hover>
                                                <TCell align="center">{index + 1}</TCell>
                                                <TCell>{row.title}</TCell>
                                                <TCell>{row.attachment ? <a href={row.attachment} rel="noreferrer" >Download File</a> : 'No attachment'}</TCell>
                                                <TCell>{row.date}</TCell>
                                                {check(ROUTES.NOTICE.MANAGE.M) && <TCell>{row?.noticeFor?.name || "All"}</TCell>}
                                                <TCell align="center"><IconButton
                                                    onClick={(e) => handleClick(e, row)}><BiDotsVerticalRounded /></IconButton></TCell>
                                            </TableRow>
                                        ))}

                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            component="div"
                            count={notices?.response_data?.meta.data_found}
                            page={page}
                            onPageChange={(e, newPage) => {
                                setPage(newPage);
                            }}
                            rowsPerPage={limit}
                            onRowsPerPageChange={(e) => {
                                setLimit(parseInt(e.target.value, 10));
                                setPage(0);
                            }}
                        />
                    </>}
            </Paper>

            <ActionMenu
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
                open={open}
                viewUrl={check(ROUTES.NOTICE.MANAGE.V) && `/notices/details/${data.id}`}
                editUrl={check(ROUTES.NOTICE.MANAGE.E) && `/notices/edit/${data.id}`}
                handleDeleteDialog={check(ROUTES.NOTICE.MANAGE.D) && (() => setDeleteDialog(true))} />

            {deleteDialog && <DeleteDialog handleDelete={async () => await mutateAsync(data.id)} dialog={deleteDialog} setDialog={setDeleteDialog} />}
        </Page>
    );
}