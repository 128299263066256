import { useQuery } from '@tanstack/react-query';
import { getResults, getResultsSelf } from '../../api/fetcherFn/result';
import usePermissions from '../permission/usePermissions';
import { ROUTES } from '../../Pages/Settings/permission_data';

const useResults = (page = 0, limit = 0, course, batch, semester, exam) => {
    const { check } = usePermissions();
    return useQuery(['results', page + 1, limit, course, batch, semester, exam],
        check(ROUTES.RESULT.MANAGE.M) ? () => getResults(page + 1, limit, course, batch, semester, exam) : () => getResultsSelf(page + 1, limit, course, batch, semester, exam), {
        keepPreviousData: true
    });
};

export default useResults;