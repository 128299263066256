export const exams = [
    {
        id: 1,
        examTitle: "Introduction to Film Production Final Exam",
        examTitleBangla: "চলচ্চিত্র উন্নয়নে প্রস্থান সমাপনী পরীক্ষা",
        syllabus: ["Scriptwriting", "Pre-production", "Filming", "Post-production"],
        description: "The final exam for the Introduction to Film Production class will cover all aspects of the film production process, including scriptwriting, pre-production, filming, and post-production.",
        course: 2,
        batch: "2022",
        date: "Monday, March 21st, 2022",
        time: "10:00 am to 12:00 PM",
        totalMarks: 50,
        examiner: 8
    },
    {
        id: 2,
        examTitle: "Screenwriting for Television Final Exam",
        examTitleBangla: "টেলিভিশনের জন্য স্ক্রিনরাইটিং সমাপনী পরীক্ষা",
        syllabus: ["Television Script Format", "Story Structure", "Character Development", "Dialogue Writing"],
        description: "The final exam for the Screenwriting for Television class will focus on the techniques and strategies for writing scripts for television shows and series, including television script format, story structure, character development, and dialogue writing.",
        course: 3,
        batch: "2022",
        date: "Wednesday, March 23rd, 2022",
        time: "10:00 am to 12:00 PM",
        totalMarks: 50,
        examiner: 10
    },
    {
        id: 3,
        examTitle: "Cinematography Techniques Final Exam",
        examTitleBangla: "চলচ্চিত্র দৃষ্টিকোণ প্রক্রিয়া সমাপনী পরীক্ষা",
        syllabus: ["Lighting Techniques", "Camera Movement", "Composition", "Camera Lenses"],
        description: "The final exam for the Cinematography Techniques class will explore the technical aspects of capturing and recording images for film and television, including lighting techniques, camera movement, composition, and camera lenses.",
        course: 1,
        batch: "2022",
        date: "Friday, March 25th, 2022",
        time: "10:00 am to 12:00 PM",
        totalMarks: 50,
        examiner: 2
    },
    {
        id: 4,
        examTitle: "Editing for Film and Television Final Exam",
        examTitleBangla: "চলচ্চিত্র এবং টেলিভিশনের জন্য সম্পাদন সমাপনী পরীক্ষা",
        syllabus: ["Storytelling through Editing", "Pacing", "Sound Design", "Color Grading"],
        description: "The final exam for the Editing for Film and Television class will cover the principles and techniques of editing for film and television, including storytelling through editing, pacing, sound design, and color grading.",
        course: 4,
        batch: "2022",
        date: "Monday, March 28th, 2022",
        time: "10:00 am to 12:00 PM",
        totalMarks: 50,
        examiner: 7
    },
    {
        id: 5,
        examTitle: "Directing for Film and Television Final Exam",
        examTitleBangla: "চলচ্চিত্র এবং টেলিভিশনের জন্য নির্দেশনা সমাপনী পরীক্ষা",
        syllabus: ["Visual Storytelling", "Working with Actors", "Production Management"],
        description: "The final exam for the Directing for Film and Television class will cover the art and craft of directing for film and television, including visual storytelling, working with actors, and managing a production.",
        course: 0,
        batch: "2022",
        date: "Wednesday, March 30th, 2022",
        time: "10:00 am to 12:00 PM",
        totalMarks: 50,
        examiner: 4
    }
]

export const exam_type = [
    { id: "mcq", name: "MCQ" },
    { id: "written", name: "Written" },
    { id: "practical", name: "Practical" },
    { id: "viva", name: "Viva" },
    { id: "screening", name: "Screning" },
    { id: "presentation", name: "Presentation" },
];
