import { toast } from "react-hot-toast";
import api from "../setup";

export const changePassword = async (id, data) => {
    try {
        const response = await api.patch(`/api/user/change-password/${id}`, data);
        return response.data;
    } catch (err) {
        toast.error(err.message);
    }
}
export const changeEmail = async (id, data) => {
    try {
        const response = await api.patch(`/api/user/change-email/${id}`, data);
        return response.data;
    } catch (err) {
        toast.error(err.message);
    }
}


// Profile 
export const getUserInfo = async () => {
    try {
        const response = await api.get(`/api/user/userInfo`);
        return response.data;
    } catch (err) {
        toast.error(err.message);
    }
}

export const updateUserInfo = async (data) => {
    try {
        const response = await api.patch(`/api/user/userInfo/update`, data, {
            headers: {
                "Content-Type": 'multipart/form-data'
            }
        });
        return response.data;
    } catch (err) {
        toast.error(err.message);
    }
}