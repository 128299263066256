import { Navigate } from "react-router-dom";
import Loading from "../../Layout/Loading";
import usePermissions from "../../hooks/permission/usePermissions";
import jwtDecode from "jwt-decode";

const AuthGuard = ({ children, hasPermission = 'all' }) => {
    const token = localStorage.getItem('accessToken');
    const { check, isPermiting } = usePermissions();


    const checkAccessTokenValidity = () => {
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000;

        return decodedToken.exp && decodedToken.exp < currentTime ? false : true;
    }

    if (token) {
        if (checkAccessTokenValidity()) {
            if (isPermiting) {
                return <Loading />;
            } else {
                if (check(...hasPermission) || hasPermission === 'all') {
                    return <>{children}</>;
                } else {
                    return <Navigate to={"/dashboard/home"} />;
                }
            }
        } else {
            localStorage.removeItem('accessToken');
        }

    }
    return <Navigate to='/login' />;

};

export default AuthGuard;
