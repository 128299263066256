import Page from "../../Layout/Page";
import {
    Button,
    Paper,
    Stack,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
} from "@mui/material";
import { grey, } from "@mui/material/colors";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as Yup from "yup";
import Translate from "../../components/Translate/Translate";
import PageHeader from "../../Layout/PageHeader";
import FilterForm from "../../components/Filter/FilterForm";
import SelectField from "../../components/Fields/SelectField";
import TextInput from "../../components/Fields/TextInput";
import TCell from "../../components/Table/TCell";
import { requestDocTypes } from "../../_mockData/requestDocTypes";
import Loading from "../../Layout/Loading";
import { useQuery, } from "@tanstack/react-query";
import useGetAllCourse from "../../hooks/course/useGetAllCourse";
import { exportToCSV, exportToExcel, getIssuedDocuments, getIssuedDocumentsSelf } from "../../api/fetcherFn/requests";
import usePermissions from "../../hooks/permission/usePermissions";
import { ROUTES } from "../Settings/permission_data";
import { saveAs } from 'file-saver';
import { toast } from "react-hot-toast";

const validationSchema = Yup.object().shape({
    batch_id: Yup.number(),
    course_id: Yup.number(),
    doc_type: Yup.string(),
    student_id: Yup.string(),
});

export default function CertificateManage() {
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const { check } = usePermissions();

    const { control, handleSubmit, watch, setValue } = useForm({ resolver: yupResolver(validationSchema), mode: "onChange", });
    const course = watch('course_id');
    const batch = watch('batch_id');
    const type = watch('doc_type');
    const studentId = watch('student_id');

    const allCourse = useGetAllCourse();

    const { data: requests, isLoading } = useQuery(['documents', page + 1, limit, course, batch, type, studentId],
        check(ROUTES.DOCUMENT.MANAGE.M) ? () => getIssuedDocuments(page + 1, limit, course, batch, type, studentId) : () => getIssuedDocumentsSelf(page + 1, limit, course, batch, type, studentId), {
        keepPreviousData: true
    });


    const onSubmit = (data) => { };

    const exportToExcelFormat = async () => {
        try {
            const responseData = await exportToExcel();
            const filename = 'issued_documents.xlsx';
            saveAs(responseData, filename);
        } catch (err) {
            toast.error('Error downloading file:', err.message);
        }
    }
    const exportToCSVFormat = async () => {
        try {
            const responseData = await exportToCSV();
            const filename = 'issued_documents.csv';
            saveAs(responseData, filename);
        } catch (err) {
            toast.error('Error downloading file:', err.message);
        }
    }


    const courses = allCourse?.data?.response_data;
    const batches = courses && courses?.find(c => c.id === course)?.batches;

    return (
        <Page title={"Manage Documents"}>
            <PageHeader titleEn="Manage Documents" titleBn="নথিপত্র ব্যবস্থাপনা" />

            <Stack direction={"row"} justifyContent={"flex-end"} alignItems={"center"} mb={1} gap={1.5}>
                <Button onClick={exportToExcelFormat} variant="outlined" size="small" sx={{ textTransform: 'unset', fontWeight: "bold" }}>Export to Excel</Button>
                <Button onClick={exportToCSVFormat} variant="outlined" size="small" sx={{ textTransform: 'unset', fontWeight: "bold" }} color="warning">Export to CSV</Button>
            </Stack>
            <Paper elevation={0} sx={{ px: 3, py: 2, minHeight: '75vh' }} className="rad-grad">
                <FilterForm handleSubmit={handleSubmit(onSubmit)} button={check(ROUTES.REQUEST.ADD)} navigate="/applications/new-request">
                    <SelectField
                        name="course_id"
                        control={control}
                        isLoading={allCourse.isLoading}
                        data={courses}
                        handelChange={() => {
                            setValue('batch_id', null);
                            setValue('doc_type', null);
                        }}
                        label={{ en: "Select Course", bn: "কোর্স নির্বাচন" }} />
                    <SelectField
                        name="batch_id"
                        control={control}
                        data={batches}
                        uniqueKey={course}
                        label={{ en: "Select Batch", bn: "ব্যাচ নির্বাচন" }} />

                    <SelectField
                        name="doc_type"
                        control={control}
                        subLabel={false}
                        data={requestDocTypes}
                        uniqueKey={course}
                        label={{ en: "Document Type", bn: "ডকুমেন্টের ধরন" }} />

                    {check(ROUTES.DOCUMENT.MANAGE.M) && <TextInput
                        name="student_id"
                        control={control}
                        label={{ en: "Student ID", bn: "শিক্ষার্থীর আইডি" }}
                        placeholder="Search by ID..." />}


                </FilterForm>


                {isLoading ? <Loading /> :
                    <>
                        <TableContainer sx={{ mt: 2, borderRadius: 1, border: `1px solid ${grey[100]}` }}>
                            <Table sx={{ minWidth: 650 }} size={"medium"}>
                                <TableHead sx={{ bgcolor: `${grey[100]}` }}>
                                    <TableRow>
                                        <TCell bold align="center">#</TCell>
                                        {check(ROUTES.DOCUMENT.MANAGE.M) &&
                                            <>
                                                <TCell bold><Translate en="Student ID" bn="শিক্ষার্থীর আইডি" /></TCell>
                                                <TCell bold><Translate en="Name" bn="নাম" /></TCell>
                                            </>}

                                        <TCell bold><Translate en="Course Name" bn="কোর্সের নাম" /></TCell>
                                        <TCell bold><Translate en="Batch" bn="ব্যাচ" /></TCell>
                                        <TCell bold><Translate en="Semester" bn="সেমিস্টার" /></TCell>
                                        <TCell bold><Translate en="Request Type" bn="আবেদনের টাইপ" /></TCell>
                                        <TCell bold align="center"><Translate en="Type" bn="ধরন" /></TCell>
                                        <TCell bold><Translate en="Issue Date" bn="ইস্যুর তারিখ" /></TCell>
                                        <TCell bold><Translate en="Document" bn="ডকুমেন্ট" /></TCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {!requests.success || requests.response_data.data.length === 0 ?
                                        <TableRow sx={{ textAlign: "center" }}><TCell align="center" bold colSpan={9}>No record found.</TCell></TableRow> :
                                        requests.response_data.data.map((row, index) => (
                                            <TableRow key={index} hover>
                                                <TCell align="center">{index + 1}</TCell>
                                                {check(ROUTES.DOCUMENT.MANAGE.M) &&
                                                    <>
                                                        <TCell>{row?.requestBy?._id}</TCell>
                                                        <TCell>{row?.requestBy?.name}</TCell>
                                                    </>}
                                                <TCell>{row.course.name}</TCell>
                                                <TCell>{row.batch.name}</TCell>
                                                <TCell>{row?.semester_number ? `Semester ${row?.semester_number}` : "None"}</TCell>
                                                <TCell>{row.document_type}</TCell>
                                                <TCell>{row.transcript_type || "None"}</TCell>
                                                <TCell>{row.issued_at}</TCell>
                                                <TCell><a href={row.attach_file} download>Download</a></TCell>
                                            </TableRow>
                                        ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            component="div"
                            count={requests?.response_data?.meta.data_found}
                            page={page}
                            onPageChange={(e, newPage) => {
                                setPage(newPage);
                            }}
                            rowsPerPage={limit}
                            onRowsPerPageChange={(e) => {
                                setLimit(parseInt(e.target.value, 10));
                                setPage(0);
                            }}
                        />
                    </>}
            </Paper>

        </Page>
    );
}