import Page from "../../Layout/Page";
import {
    Paper, Stack
} from "@mui/material";
import PageHeader from "../../Layout/PageHeader";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as Yup from "yup";
import FilterForm from "../../components/Filter/FilterForm";
import SelectField from "../../components/Fields/SelectField";
import useGetAllCourse from "../../hooks/course/useGetAllCourse";
import SemesterResult from "./SemesterResult";
import FinalResult from "./FinalResult";
import img from "../../assets/result.jpg";
import TextInput from "../../components/Fields/TextInput";
import { useMutation } from "@tanstack/react-query";
import { resultCheck } from "../../api/fetcherFn/result";
import { toast } from "react-hot-toast";
import Loading from "../../Layout/Loading";
import { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { IoMdPrint } from "react-icons/io";
import useCGPA from "../../hooks/result/useCGPA";

const resultType = [
    { id: 'semester', name: "Semester" },
    { id: 'final', name: "Final Result" }
];

const CheckResult = () => {
    const componentRef = useRef();
    const allCourse = useGetAllCourse();
    const validationSchema = Yup.object().shape({
        course_id: Yup.number().nullable().transform(v => !isNaN(v) ? v : null).required("Required."),
        batch_id: Yup.number().nullable().transform(v => !isNaN(v) ? v : null).required("Required."),
        type: Yup.string(),
        semester_number: Yup.number().nullable().transform(v => !isNaN(v) ? v : null).when('type', {
            is: 'semester',
            then: Yup.number().nullable().transform(v => !isNaN(v) ? v : null).required("Required"),
            otherwise: Yup.number().nullable().transform(v => !isNaN(v) ? v : null),
        }),
        student_id: Yup.string().required("Required."),
    });


    const { control, handleSubmit, watch, setValue } = useForm({ resolver: yupResolver(validationSchema), mode: "onChange", });
    const course = watch('course_id');
    const type = watch('type');
    const { data: cgpaData, refetch } = useCGPA(false);

    const { data, mutateAsync, isLoading, reset } = useMutation(data => resultCheck(data), {
        onSuccess: (data) => {
            if (data.success) {
                refetch();
                toast.success(data.message);
            } else {
                toast.error(data.message);
            }
        },
        onError: (err) => {
            toast.error(err.message);
        }
    });


    const onSubmit = async (data) => {
        const { student_id, ...rest } = data;
        const _data = { student_id: student_id, ...rest };
        console.log(_data)
        await mutateAsync(_data);
    };

    const courses = allCourse?.data?.response_data;
    const batches = courses && courses?.find(c => c.id === course)?.batches;
    const semesters = courses && courses?.find(c => c.id === course)?.semester_wise_subjects;


    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: `Result of ${data?.response_data?.student?.name}`,
    });


    return (
        <Page title={"Check Result"}>
            <PageHeader titleEn="Check Result" titleBn="ফলাফল অনুসন্ধান" />

            <Paper elevation={0} sx={{ p: 3, minHeight: '75vh' }} className="rad-grad">
                <FilterForm handleSubmit={handleSubmit(onSubmit)} search button={false} isLoading={isLoading}  >
                    <SelectField
                        name="type"
                        control={control}
                        subLabel={false}
                        data={resultType}
                        handelChange={() => {
                            reset()
                            setValue('batch_id', null);
                            setValue('semester_number', null)
                        }
                        }
                        label={{ en: "Result Type", bn: "ফলাফলের ধরন" }} />

                    <SelectField
                        name="course_id"
                        control={control}
                        data={courses}
                        uniqueKey={type}
                        label={{ en: "Select Course", bn: "কোর্স নির্বাচন" }} />
                    <SelectField
                        name="batch_id"
                        control={control}
                        data={batches}
                        uniqueKey={type}
                        label={{ en: "Select Batch", bn: "ব্যাচ নির্বাচন" }} />

                    {type === 'semester' && <SelectField
                        name="semester_number"
                        control={control}
                        subLabel={false}
                        uniqueKey={type}
                        data={semesters?.map(option => ({ id: option.semester, name: `Semester ${option.semester}` }))}
                        label={{ en: "Select Semester", bn: "সেমিস্টার নির্বাচন" }} />}
                    <TextInput
                        name="student_id"
                        control={control}
                        placeholder="Student ID..."
                        label={{ en: "Student ID", bn: "শিক্ষার্থীর আইডি" }} />
                </FilterForm>

                <Stack mt={3} mx={{ md: 9 }}>
                    <div style={{ width: '100%', display: 'inline-flex', justifyContent: 'flex-end', marginBottom: '1rem' }}>
                        <IoMdPrint onClick={handlePrint} size={25} color="gray" cursor="pointer" />
                    </div>
                    {isLoading ? <Loading /> :
                        (data?.success && type === 'semester') ? <SemesterResult data={data?.response_data} componentRef={componentRef} gpaTable={cgpaData?.response_data} /> :
                            (data?.success && type === 'final') ? <FinalResult data={data?.response_data} componentRef={componentRef} gpaTable={cgpaData?.response_data} /> : <img src={img} alt="Result" style={{ width: '50%', margin: '0 auto' }} />
                    }
                </Stack>

            </Paper>
        </Page >
    )
}
export default CheckResult;