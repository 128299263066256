import React from 'react';
import { Checkbox, Dialog, DialogContent, DialogTitle, Divider, FormControlLabel, Stack, Typography, } from "@mui/material";
import Translate from '../Translate/Translate';
import useChangePermission from '../../hooks/permission/useChangePermission';
import { useQuery } from '@tanstack/react-query';
import { getUserPermissions } from '../../api/fetcherFn/settings';
import Loading from '../../Layout/Loading';
import { ROUTES, permission_data } from '../../Pages/Settings/permission_data';
import useDisablePermission from '../../hooks/permission/useDisablePermission';

const PermissionDialog = ({ openDialog, setOpenDialog, metadata }) => {
    const { data, title } = metadata;
    const { id, role } = data;
    const { data: permissions, isLoading } = useQuery(['user-permissions', id], () => getUserPermissions(id), {
        enabled: !!id
    });
    const { mutateAsync } = useChangePermission(id, role.id);
    const disable = useDisablePermission(id, role.id);

    const checkPermission = (route) => {
        const has = permissions?.response_data?.find(p => (p.route === route && p.status === 'active'));
        if (has) return true;
        return false;
    }


    const handlePermissionChange = async (e) => {
        if (e.target.value.endsWith('/self')) {
            const route = e.target.value.split('/self')[0];
            await mutateAsync({ route: e.target.value, user_id: id });

            await disable.mutateAsync({ route: route, user_id: id });


        } else if (e.target.value.endsWith('/manage')) {
            const route = `${e.target.value}/self`;
            await mutateAsync({ route: e.target.value, user_id: id });

            await disable.mutateAsync({ route: route, user_id: id });


        } else {
            await mutateAsync({ route: e.target.value, user_id: id });
        }
    }


    return (
        <Dialog onClose={() => setOpenDialog(!openDialog)} open={openDialog} fullWidth maxWidth={"sm"} scroll="paper">
            <DialogTitle>
                <Typography variant='h6' fontWeight="bold"><Translate en={title.en} bn={title.bn} /> </Typography>
                <Typography fontSize={14}><Translate en='Please click on the checkmark to give permission access.'
                    bn='অনুমতি দিতে চেকমার্কে ক্লিক করুন.' /></Typography>
            </DialogTitle>
            <Divider />
            <DialogContent sx={{ py: 2, pb: 6 }}>
                {isLoading ? <Loading /> :
                    <Stack gap={2}>
                        {permission_data?.map((p, i) =>
                            <Stack key={i}>
                                <Typography variant='h6' fontWeight={700}><Translate en={p.module} bn='শিক্ষার্থী' /></Typography>
                                <Divider sx={{ mb: 1 }} />
                                {p?.menus?.map((m, i) =>
                                    <Stack direction="row" justifyContent="space-between" alignItems="flex-start" key={i}>
                                        <Stack>
                                            <Typography variant='body2' fontWeight="bold" color="#6a6868"><Translate en={m.name} bn='শিক্ষার্থী ব্যবস্থাপনা' /></Typography>
                                            <Stack direction="column" gap={0.3} mb={2} key={i + 10}>
                                                {
                                                    m?.action?.map((action, i) => <FormControlLabel key={i + 10} control={
                                                        <Checkbox size='small'
                                                            value={action.route}
                                                            defaultChecked={checkPermission(action.route)}
                                                            onChange={handlePermissionChange}
                                                            disabled={permissions?.response_data?.find(p => (p.route === action.route && p.status === 'inactive')) ? true : false} />}
                                                        label={<Typography variant="caption" color="blueviolet" style={{ fontSize: '14px', }}>{action.name}</Typography>} />)
                                                }

                                            </Stack>
                                        </Stack>
                                        {m?.route && <Checkbox size='small' disabled={checkPermission(ROUTES.SETTINGS.PERMISSION.MANAGE.M) && m.route === ROUTES.SETTINGS.PERMISSION.MANAGE.M} value={m.route} defaultChecked={checkPermission(m.route)} onChange={handlePermissionChange} />}
                                    </Stack>
                                )}
                            </Stack>
                        )}
                    </Stack>
                }
            </DialogContent>
        </Dialog>
    );
};

export default PermissionDialog;