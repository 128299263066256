import Page from "../../Layout/Page";
import {
    Button,
    CircularProgress,
    IconButton,
    Paper,
    Stack,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
} from "@mui/material";
import { grey, } from "@mui/material/colors";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as Yup from "yup";
import Translate from "../../components/Translate/Translate";
import PageHeader from "../../Layout/PageHeader";
import FilterForm from "../../components/Filter/FilterForm";
import SelectField from "../../components/Fields/SelectField";
import TCell from "../../components/Table/TCell";
import ActionMenu from "../../components/Menus/ActionMenu";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import DeleteDialog from "../../components/HelperComponents/DeleteDialog";
import useClasses from "../../hooks/class/useClasses";
import { deleteClass, exportToCSV, exportToExcel, sendClassNotification } from "../../api/fetcherFn/class";
import Loading from "../../Layout/Loading";
import useGetAllCourse from "../../hooks/course/useGetAllCourse";
import AddNew from "../../components/Buttons/AddNew";
import CalenderDialog from "../../components/Dialogs/CalenderDialog";
import usePermissions from "../../hooks/permission/usePermissions";
import { ROUTES } from "../Settings/permission_data";
import { BsCalendarDate } from "react-icons/bs";
import { toast } from "react-hot-toast";
import { saveAs } from 'file-saver';


const validationSchema = Yup.object().shape({
    batch_id: Yup.number(),
    course_id: Yup.number(),
});

export default function ClassManage() {
    const { check } = usePermissions();
    const queryClient = useQueryClient();
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const [data, setData] = useState({});
    const [anchorEl, setAnchorEl] = useState(null);
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [openCanlender, setOpenCanlender] = useState(false);
    const [loadingRow, setLoadingRow] = useState(null);
    const open = Boolean(anchorEl);

    const { control, handleSubmit, watch, setValue } = useForm({ resolver: yupResolver(validationSchema), mode: "onChange", });
    const course = watch('course_id');
    const batch = watch('batch_id');

    const allCourse = useGetAllCourse();
    const { data: classes, isLoading } = useClasses(page, limit, course, batch);
    const deleteRecord = useMutation(() => deleteClass(data.id), {
        onSuccess: (data) => {
            if (data.success) {
                toast.success(data.message);
                queryClient.invalidateQueries('classes');
            } else {
                toast.error(data.message);
            }
        }
    });
    const notify = useMutation(() => sendClassNotification(data.id), {
        onSuccess: (data) => {
            if (data.success) {
                toast.success(data.message);
            } else {
                toast.error(data.message);
            }
            setLoadingRow(null);
        }
    });

    const handleClick = (i, event, row) => {
        setAnchorEl(event.currentTarget);
        setData(row);
        setLoadingRow(i);
    };

    const onSubmit = (data) => { };
    const handleSendNotification = async () => {
        await notify.mutateAsync();
    }

    const exportToExcelFormat = async () => {
        try {
            const responseData = await exportToExcel();
            const filename = 'issued_documents.xlsx';
            saveAs(responseData, filename);
        } catch (err) {
            toast.error('Error downloading file:', err.message);
        }
    }
    const exportToCSVFormat = async () => {
        try {
            const responseData = await exportToCSV();
            const filename = 'issued_documents.csv';
            saveAs(responseData, filename);
        } catch (err) {
            toast.error('Error downloading file:', err.message);
        }
    }

    const courses = allCourse?.data?.response_data;
    const batches = courses && courses?.find(c => c.id === course)?.batches;

    const calendarData = classes?.response_data?.data?.map(event => ({
        title: event.name,
        start: new Date(`${event.class_date + " " + event.start_time}`),
        end: new Date(`${event.class_date + " " + event.end_time}`),
        desc: event.description,
        location: event.place
    }))



    return (
        <Page title={"Class Manage"}>
            <PageHeader titleEn="Manage Class" titleBn="ক্লাস ব্যবস্থাপনা" />


            <Stack direction={"row"} justifyContent={"flex-end"} alignItems={"center"} mb={1} gap={1.5}>
                <Button onClick={exportToExcelFormat} variant="outlined" size="small" sx={{ textTransform: 'unset', fontWeight: "bold" }}>Export to Excel</Button>
                <Button onClick={exportToCSVFormat} variant="outlined" size="small" sx={{ textTransform: 'unset', fontWeight: "bold" }} color="warning">Export to CSV</Button>
                <AddNew variant="contained" btnSize="small" btnText={{ en: "Calender View", bn: "ক্যালেন্ডার ভিউ" }} handleClick={() => setOpenCanlender(true)} icon={<BsCalendarDate size={16} />} />
            </Stack>
            <Paper elevation={0} sx={{ px: 3, py: 2, minHeight: '75vh' }} className="rad-grad">
                <FilterForm handleSubmit={handleSubmit(onSubmit)} button={check(ROUTES.CLASS.ADD)} navigate="/classes/add">
                    <SelectField
                        name="course_id"
                        control={control}
                        isLoading={allCourse.isLoading}
                        data={courses}
                        handelChange={() => {
                            setValue('batch_id', null);
                        }}
                        label={{ en: "Select Course", bn: "কোর্স নির্বাচন" }} />
                    <SelectField
                        name="batch_id"
                        control={control}
                        data={batches}
                        uniqueKey={course}
                        label={{ en: "Select Batch", bn: "ব্যাচ নির্বাচন" }} />

                </FilterForm>

                {isLoading ? <Loading /> :
                    <>
                        <TableContainer sx={{ mt: 2, borderRadius: 1, border: `1px solid ${grey[100]}` }}>
                            <Table sx={{ minWidth: 650 }} size={"medium"}>
                                <TableHead sx={{ bgcolor: grey[100] }} >
                                    <TableRow>
                                        <TCell bold align="center">#</TCell>
                                        <TCell bold><Translate en="Class Name" bn="ক্লাসের নাম" /></TCell>
                                        <TCell bold><Translate en="Class Duration" bn="ক্লাসের সময়কাল" /></TCell>
                                        <TCell bold><Translate en="Date" bn="তারিখ" /></TCell>
                                        <TCell bold><Translate en="Place" bn="স্থান" /></TCell>
                                        <TCell bold><Translate en="Course Name" bn="কোর্সের নাম" /></TCell>
                                        <TCell bold><Translate en="Batch" bn="ব্যাচ" /></TCell>
                                        <TCell bold><Translate en="Assigned Teacher" bn="শিক্ষক" /></TCell>
                                        <TCell bold align="center"><Translate en="Action" bn="অ্যাকশন" /></TCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {!classes.success || classes.response_data.data.length === 0 ?
                                        <TableRow sx={{ textAlign: "center" }}><TCell align="center" bold colSpan={10}>No record found.</TCell></TableRow> :
                                        classes.response_data.data.map((row, index) => (
                                            <TableRow key={index} hover>
                                                <TCell bold align="center">{index + 1}</TCell>
                                                <TCell >{row?.name}</TCell>
                                                <TCell >{row?.start_time} - {row?.end_time}</TCell>
                                                <TCell >{row?.class_date}</TCell>
                                                <TCell >{row?.place}</TCell>
                                                <TCell >{row?.course?.name}</TCell>
                                                <TCell >{row?.batch?.name}</TCell>
                                                <TCell >{row?.teacher?.name}</TCell>
                                                <TCell align="center">
                                                    {notify.isLoading && loadingRow === index ? <CircularProgress size={33} /> : <IconButton
                                                        onClick={(e) => handleClick(index, e, row)}><BiDotsVerticalRounded /></IconButton>}
                                                </TCell>
                                            </TableRow>
                                        ))}

                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            component="div"
                            count={classes?.response_data?.meta.data_found}
                            page={page}
                            onPageChange={(e, newPage) => {
                                setPage(newPage);
                            }}
                            rowsPerPage={limit}
                            onRowsPerPageChange={(e) => {
                                setLimit(parseInt(e.target.value, 10));
                                setPage(0);
                            }}
                        />
                    </>}
            </Paper>

            <ActionMenu
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
                open={open}
                viewUrl={check(ROUTES.CLASS.MANAGE.V) && `/classes/view/${data.id}`}
                editUrl={check(ROUTES.CLASS.MANAGE.E) && `/classes/add/${data.id}`}
                handleNotification={check(ROUTES.CLASS.MANAGE.N) && handleSendNotification}
                handleDeleteDialog={check(ROUTES.CLASS.MANAGE.D) && (() => setDeleteDialog(true))}
            />

            {deleteDialog && <DeleteDialog handleDelete={async () => await deleteRecord.mutateAsync(data.id)} dialog={deleteDialog} setDialog={setDeleteDialog} />}
            {openCanlender && <CalenderDialog openDialog={openCanlender} setOpenDialog={setOpenCanlender} events={calendarData} title={{ en: "Class Calender View", bn: "ক্লাসের ক্যালেন্ডার ভিউ" }} />}

        </Page>
    );
}