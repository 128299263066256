import Page from "../../Layout/Page";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as Yup from "yup";
import PageHeader from "../../Layout/PageHeader";
import FileInput from "../../components/Fields/FileInput";
import { PNG_JPG_JPEG_GIF, PNG_JPG_PDF } from "../../Constants/FileTypes";
import SelectField from "../../components/Fields/SelectField";
import AddForm from "../../components/Filter/AddForm";
import TextInput from "../../components/Fields/TextInput";
import FormSection from "../../components/Filter/FormSection";
import MultilineInput from "../../components/Fields/MultilineInput";
import PasswordField from "../../components/Fields/PasswordField";
import { useMutation, useQueryClient, } from "@tanstack/react-query";
import { updateStudent } from "../../api/fetcherFn/student";
import { toast } from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../../Layout/Loading";
import useStudent from "../../hooks/student/useStudent";
import PhotoAvatarUpload from "../../components/Fields/PhotoAvatarUpload";
import ToggleSwitch from "../../components/Fields/ToggleSwitch";
import { BsFileEarmarkText } from 'react-icons/bs';
import { Stack } from "@mui/material";
import { getEighteenYearsPrevDate } from "../../utils/constants";

const validationSchema = Yup.object().shape({
    name_en: Yup.string().required('Student name is required'),
    name_bn: Yup.string().required('Student name is required'),
    father_name_en: Yup.string().required('Teacher name is required'),
    father_name_bn: Yup.string().required('Teacher name is required'),
    mother_name_en: Yup.string().required('Teacher name is required'),
    mother_name_bn: Yup.string().required('Teacher name is required'),
    gender: Yup.string().nullable().required('Required'),
    dob: Yup.string().required("Date of birth is required"),
    nid: Yup.string(),
    phone: Yup.string().required('Mobile number is required').max(12, "Invalid"),
    nationality: Yup.string().nullable().required('Required'),
    city: Yup.string().required('City name is required'),
    present_address: Yup.string().required('Present address is required'),
    permanent_address: Yup.string().required('Permanent address is required'),
    qualification: Yup.string(),
    email: Yup.string().email("Invalid Email").when('toggle', {
        is: true,
        then: Yup.string().required("Required."),
        otherwise: Yup.string(),
    }),
    password: Yup.string().when('toggle', {
        is: true,
        then: Yup.string().required("Required.").min(6, "Must be at least 6 characters."),
        otherwise: Yup.string(),
    }),
    qualification_file: Yup.array()
        .max(1, "Please select at least one file")
        .test("fileSize", "File size must be less than 5MB", (value) =>
            value.every((file) => file.size <= 5000000)
        )
        .test("fileType", "Invalid file type", (value) =>
            value.every((file) => PNG_JPG_PDF.includes(file.type))
        ),
    photo: Yup
        .mixed()
        .test("fileSize", "File size is too large", (value) => {
            if (!value) return true;
            return value && value.size <= 1024000; //1 MB
        })
        .test("fileType", "Unsupported file format", (value) => {
            if (!value) return true;
            return value && ["image/jpeg", "image/png"].includes(value.type);
        })
});

export default function StudentsEdit() {
    const params = useParams();
    const id = parseInt(params.id, 10);
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const { data: student, isLoading } = useStudent(id);
    const { control, handleSubmit, reset, watch, unregister } = useForm({ resolver: yupResolver(validationSchema), mode: "onChange", });
    const toggle = watch('toggle');

    const update = useMutation(data => updateStudent(id, data), {
        onSuccess: (data) => {
            if (data.success) {
                toast.success(data.message);
                queryClient.invalidateQueries(['student', id]);
                navigate('/students/manage');
            } else {
                toast.error(data.message);
            }
        },
        onError: (err) => {
            toast.error(err.message);
        }
    });

    const onSubmit = async (data) => {
        const { email, password, toggle, ...rest } = data;
        if (!rest.qualification_file.length) {
            rest.qualification_file = undefined;
            console.log(rest.qualification_file)
        } else {
            rest.qualification_file = rest.qualification_file[0];
        }
        const formData = new FormData();

        if (!toggle) {
            for (const key in { photo: undefined, qualification_file: undefined, password: undefined, ...data }) {
                formData.append(key, rest[key]);
            }
        } else {
            const _data = { email, password, ...rest };
            for (const key in { photo: undefined, qualification_file: undefined, ..._data }) {
                formData.append(key, _data[key]);
            }
        }
        await update.mutateAsync(formData);
    }


    return (
        <Page title={`${id ? 'Edit' : 'Add'} Student`}>
            <PageHeader titleEn={`${id ? 'Edit' : 'Add'} Student`} titleBn={`শিক্ষার্থী ${id ? ' তথ্য পরিবর্তন' : 'সংযুক্তিকরণ'}`}
                link={{ to: '/students/manage', textEn: "Manage Student", textBn: "শিক্ষার্থী ব্যবস্থাপনা" }} />

            {(id && isLoading) ? <Loading /> : <AddForm handleSubmit={handleSubmit(onSubmit)} loading={update.isLoading} reset={reset} maxWidth={700}>

                <PhotoAvatarUpload
                    name="photo"
                    control={control}
                    imageUrl={student?.response_data?.user?.photo_url}
                    fileType={PNG_JPG_JPEG_GIF} />

                <FormSection title={{ en: "Personal Information", bn: "ব্যাক্তিগত তথ্য" }}>
                    <TextInput
                        name="name_en"
                        control={control}
                        value={student?.response_data?.name_en}
                        label={{ en: "Student Name (English)", bn: "শিক্ষার্থীর নাম (ইংরেজীতে)" }}
                        errorMsg fontBold={false} required />
                    <TextInput
                        name="name_bn"
                        control={control}
                        value={student?.response_data?.name_bn}
                        label={{ en: "Student Name (Bangla)", bn: "শিক্ষার্থীর নাম (বাংলায়)" }}
                        errorMsg fontBold={false} required />
                    <TextInput
                        name="father_name_en"
                        control={control}
                        value={student?.response_data?.father_name_en}
                        label={{ en: "Father's Name (English)", bn: "পিতার নাম (ইংরেজীতে)" }}
                        errorMsg fontBold={false} required />
                    <TextInput
                        name="father_name_bn"
                        control={control}
                        value={student?.response_data?.father_name_bn}
                        label={{ en: "Father's Name (Bangla)", bn: "পিতার নাম (বাংলায়)" }}
                        errorMsg fontBold={false} required />

                    <TextInput
                        name="mother_name_en"
                        control={control}
                        value={student?.response_data?.mother_name_en}
                        label={{ en: "Mother's Name (English)", bn: "মাতার নাম (ইংরেজীতে)" }}
                        errorMsg fontBold={false} required />
                    <TextInput
                        name="mother_name_bn"
                        control={control}
                        value={student?.response_data?.mother_name_bn}
                        label={{ en: "Mother's Name (Bangla)", bn: "মাতার নাম (বাংলায়)" }}
                        errorMsg fontBold={false} required />
                    <SelectField
                        name="gender"
                        control={control}
                        subLabel={false}
                        defaultValue={student?.response_data?.gender}
                        data={[{ id: "male", name: "Male" }, { id: "female", name: "Female" }, { id: "other", name: "Other" }]}
                        label={{ en: "Gender", bn: "লিঙ্গ" }}
                        errorMsg fontBold={false} required />
                    <TextInput
                        name="dob"
                        type="date"
                        value={student?.response_data?.dob}
                        control={control}
                        min={"1970-01-01"}
                        max={getEighteenYearsPrevDate}
                        label={{ en: "Date of Birth", bn: "জন্ম তারিখ" }}
                        errorMsg fontBold={false} required />
                    <TextInput
                        name="nid"
                        type="number"
                        control={control}
                        value={student?.response_data?.nid}
                        label={{ en: "NID Number", bn: "এনআইডি নাম্বার" }}
                        placeholder="e.g. 8263215417"
                        errorMsg fontBold={false} />
                    <TextInput
                        name="phone"
                        type="tel"
                        max={12}
                        control={control}
                        value={student?.response_data?.user.phone}
                        label={{ en: "Mobile Number", bn: "মোবাইল নাম্বার" }}
                        placeholder="e.g. 01710000000"
                        errorMsg fontBold={false} required />

                    <SelectField
                        name="nationality"
                        control={control}
                        subLabel={false}
                        defaultValue={student?.response_data?.nationality}
                        data={[{ id: 'Bangladeshi', name: "Bangladeshi" }]}
                        label={{ en: "Nationality", bn: "জাতীয়তা" }}
                        errorMsg fontBold={false} required />
                    <TextInput
                        name="city"
                        control={control}
                        value={student?.response_data?.city}
                        label={{ en: "City", bn: "শহর" }}
                        placeholder="e.g. Narsingdi"
                        errorMsg fontBold={false} required />
                    <MultilineInput
                        name="present_address"
                        control={control}
                        value={student?.response_data?.present_address}
                        label={{ en: "Present Address", bn: "বর্তমান ঠিকানা" }}
                        rows={4} errorMsg required />
                    <MultilineInput
                        name="permanent_address"
                        control={control}
                        value={student?.response_data?.permanent_address}
                        label={{ en: "Permanent Address", bn: "স্থায়ী ঠিকানা" }}
                        rows={4} errorMsg required />

                </FormSection>

                <FormSection title={{ en: "Other Informations", bn: "অতিরিক্ত তথ্য" }}>
                    <TextInput
                        name="qualification"
                        control={control}
                        value={student?.response_data?.qualification}
                        label={{ en: "Last Educational Qualification", bn: "সর্বশেষ শিক্ষাগতযোগ্যতা সনদ" }}
                        required errorMsg fontBold={false} />
                    <FileInput
                        name="qualification_file"
                        control={control}
                        fileTypes={PNG_JPG_PDF}
                        required
                        placeholder=".png, .jpg, .pdf" />

                </FormSection>
                {student?.response_data?.qualification_file && !watch('attachment') &&
                    <Stack direction="row" alignItems="center" gap={1} justifyContent="flex-end" mt={-2.5}>
                        <BsFileEarmarkText size={18} />
                        <a href={student?.response_data?.qualification_file} target="_blank" rel="noreferrer">Attachment File</a>
                    </Stack>}

                <ToggleSwitch
                    name='toggle'
                    control={control}
                    label={{ en: "Update Login Information!", bn: "লগইন তথ্য আপডেট করুন!" }}
                    unregister={unregister}
                    unregisterFields={['email', 'password']} />

                {toggle &&
                    <FormSection title={{ en: "Login Informations", bn: "লগইন করার তথ্যাবলী" }}>
                        <TextInput
                            name="email"
                            type="email"
                            control={control}
                            value={student?.response_data?.user.email}
                            label={{ en: "Email", bn: "ইমেইল" }}
                            errorMsg fontBold={false} required />
                        <PasswordField
                            name="password"
                            type="password"
                            control={control}
                            label={{ en: "Password", bn: "পাসওয়ার্ড" }}
                            errorMsg fontBold={false} required />
                    </FormSection>}
            </AddForm>}

        </Page>
    )
}