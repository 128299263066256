import { useQuery } from '@tanstack/react-query';
import { getFinalResults, getFinalResultsSelf, } from '../../api/fetcherFn/result';
import usePermissions from '../permission/usePermissions';
import { ROUTES } from '../../Pages/Settings/permission_data';

const useFinalResults = (page = 0, limit = 0, course, batch, semester) => {
    const { check } = usePermissions();
    return useQuery(['final-results', page + 1, limit, course, batch, semester],
        check(ROUTES.RESULT.FINAL.M) ? () => getFinalResults(page + 1, limit, course, batch, semester) : () => getFinalResultsSelf(page + 1, limit, course, batch, semester), {
        keepPreviousData: true
    });
};

export default useFinalResults;