import Page from "../../Layout/Page";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as Yup from "yup";
import PageHeader from "../../Layout/PageHeader";
import { Button, CircularProgress, Paper, Stack, Table, TableBody, TableRow, TextField, Typography, } from "@mui/material";
import Translate from "../../components/Translate/Translate";
import TCell from "../../components/Table/TCell";
import { grey } from "@mui/material/colors";
import { useMutation, } from "@tanstack/react-query";
import { publishResult, } from "../../api/fetcherFn/result";
import { toast } from "react-hot-toast";
import { AiOutlineSave } from "react-icons/ai";
import SelectField from "../../components/Fields/SelectField";
import useGetAllCourse from "../../hooks/course/useGetAllCourse";
import ReactSelect from 'react-select';
import useCGPA from "../../hooks/result/useCGPA";
import useExams from "../../hooks/exam/useExams";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import TRow from "../../components/Table/TRow";

const ValidationSchema = Yup.object().shape({
    student_id: Yup.number().nullable().transform(v => !isNaN(v) ? v : null).required("Required."),
    results: Yup
        .array()
        .of(
            Yup.object().shape({
                total_marks: Yup.number()
                    .required("Required"),
                grade: Yup.string()
                    .required("Required"),
                point: Yup.number()
                    .required("Required"),
            })
        ),
});


const FinalResult = () => {
    const queries = new URLSearchParams(window.location.search);
    const course = parseInt(queries.get('course'), 10);
    const batch = parseInt(queries.get('batch'), 10);
    const semesterNum = parseInt(queries.get('semester'), 10);
    const navigate = useNavigate();
    const [gpa, setGPA] = useState(0);

    const { control, handleSubmit, setValue, watch, reset } = useForm({
        resolver: yupResolver(ValidationSchema), defaultValues: {
            student_id: null,
            results: []
        },
    });

    const allCourse = useGetAllCourse();
    const { data: cgpaData, isLoading: gradeLoading } = useCGPA();
    const { data: exams, isLoading: studentLoading } = useExams(0, 0, course, batch, null, semesterNum);
    const students = exams?.response_data?.data?.flatMap(d => d.participants);
    const uniqueStudents = students?.filter((student, index, self) => index === self.findIndex((t) => t.id === student.id));


    const publish = useMutation((data) => publishResult(data), {
        onSuccess: (data) => {
            if (data.success) {
                toast.success(data.message);
                // navigate('/results/final/manage'); 
            } else {
                toast.error(data.message);
            }
        },
        onError: (err) => {
            toast.error(err.message);
        }
    })

    const handleSaveResult = async (data) => {
        const _data = {
            course_id: course,
            batch_id: batch,
            semester_number: semesterNum,
            student_id: data.student_id,
            gpa: gpa,
            results: subjects?.map((subject, i) => ({
                subject_id: subject.id,
                ...data.results[i]
            }))
        };

        await publish.mutateAsync(_data);
    };


    const courses = allCourse?.data?.response_data;
    const batches = courses && courses?.find(c => c.id === course)?.batches;
    const semesters = courses && courses?.find(c => c.id === course)?.semester_wise_subjects;
    let subjects = (courses || course) && (courses?.find(c => c.id === course)?.semester_wise_subjects?.find(s => s.semester === semesterNum)?.subjects
        || courses?.find(c => c.id === course)?.subjects);

    const grades = cgpaData?.response_data;

    const calculateCGPA = (i) => {
        const resultsData = watch(`results[${i}]`);
        let totalGradePoint = 0;
        let totalCredit = 0;
        totalCredit += subjects[i].credit;
        totalGradePoint += subjects[i].credit * resultsData.point;
        const totalGPA = (totalGradePoint / totalCredit);
        setGPA(totalGPA);
    }




    return (
        <Page title={"Semester/Final Result"}>
            <PageHeader titleEn="Semester/Final Result" titleBn="সেমিস্টার/চূড়ান্ত ফলাফল"
                link={{ to: '/results/final/manage', textEn: "Manage Final Result", textBn: "চূড়ান্ত ফলাফল ব্যবস্থাপনা" }} />

            <Paper elevation={0} sx={{ px: 3, py: 3, minHeight: '75vh' }} className="rad-grad">

                {subjects?.length > 0 && batch &&
                    <TRow mt={0} data={[
                        { label: { en: "Course Name", bn: "কোর্সের নাম" }, value: courses?.find(c => c.id === course)?.name },
                        { label: { en: "Batch Name", bn: "ব্যাচের নাম" }, value: batches?.find(b => b.id === batch)?.name },
                        {
                            label: { en: "Semester", bn: "সেমিস্টার" }, value: `${semesters ? `Semester ${semesters?.find(s => s.semester === semesterNum)?.semester}` : 'None'}`
                        },
                    ]} />
                }


                <Stack direction={"row"} width={{ md: '30%' }} mt={2}  >
                    {(course && batch) && <SelectField
                        name="student_id"
                        control={control}
                        subLabel={false}
                        subValue={"_id"}
                        isLoading={studentLoading}
                        data={uniqueStudents}
                        label={{ en: "Select Student", bn: "শিক্ষার্থী নির্বাচন" }}
                        required />}

                </Stack>

                {subjects?.length > 0 && batch &&
                    <form onSubmit={handleSubmit(handleSaveResult)} >
                        <Table sx={{ minWidth: 650, mt: 2 }} size={"small"} >
                            <TableBody  >
                                {subjects?.map((subject, i) => (
                                    <TableRow key={i}>
                                        <TCell bold sx={{ border: `1px solid ${grey[200]}` }}>{subject.name} ({subject.subject_code})</TCell>
                                        <TCell bold sx={{ border: `1px solid ${grey[200]}` }}>{subject.credit}</TCell>
                                        <TCell bold width={200} sx={{ border: `1px solid ${grey[200]}` }} >
                                            <Controller
                                                name={`results[${i}].total_marks`}
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField {...field}
                                                        placeholder="Total Marks"
                                                        size="small" required />
                                                )}
                                            />
                                        </TCell>
                                        <TCell bold width={200} sx={{ border: `1px solid ${grey[200]}` }}   >

                                            <Controller
                                                name={`results[${i}].grade`}
                                                control={control}
                                                defaultValue={""}
                                                render={({ field, formState }) => (
                                                    <ReactSelect
                                                        styles={{
                                                            control: (base) => ({
                                                                ...base,
                                                                border: !!formState.errors?.results?.[i].grade
                                                                    ? "1px solid #D32F2F"
                                                                    : "1px solid #C4C4C4",
                                                                "&:hover": {
                                                                    border: !!formState.errors?.results?.[i].grade
                                                                        ? "1px solid #D32F2F"
                                                                        : "1px solid #C4C4C4",
                                                                },
                                                            }),
                                                        }}
                                                        className="basic-single"
                                                        classNamePrefix="select"
                                                        name={`results[${i}].grade`}
                                                        placeholder={'Grade'}
                                                        isSearchable={false}
                                                        isLoading={gradeLoading}
                                                        options={grades?.map(d => ({ value: d.grade, label: d.grade }))}
                                                        value={field?.label && grades?.find((option) => option?.grade === field?.grade)}
                                                        onChange={selectedValue => {
                                                            field.onChange(selectedValue?.value);
                                                            setValue(`results[${i}].point`, grades?.find(g => g.grade === selectedValue?.value)?.point);
                                                            calculateCGPA(i);
                                                        }}
                                                        getOptionValue={(option) => option.value}
                                                    />
                                                )}
                                            />
                                        </TCell>
                                        <TCell bold width={200} sx={{ border: `1px solid ${grey[200]}` }} >
                                            <Controller
                                                name={`results[${i}].point`}
                                                control={control}
                                                render={({ field, formState }) => (
                                                    <TextField {...field}
                                                        type="number"
                                                        inputProps={{ steps: 0.01, readOnly: true }}
                                                        placeholder="Point"
                                                        onChange={() => {
                                                            field.onChange(field.value);
                                                        }}
                                                        size="small" />
                                                )}
                                            />
                                        </TCell>
                                    </TableRow>
                                ))
                                }
                            </TableBody >

                        </Table >
                        <Stack alignItems="flex-end" mt={2}>
                            <Typography fontWeight="bold" fontSize={20} my={1.5}>GPA: {gpa} </Typography>
                            <Button type="submit" variant={"contained"} color="success" size="medium" >
                                <Stack direction={"row"} gap={1} alignItems={"center"}>
                                    {publish.isLoading ? <CircularProgress size={18} /> : <AiOutlineSave size={18} />}
                                    <Typography textTransform={"capitalize"}><Translate en="Save Result" bn="ফলাফল সংরক্ষণ" /></Typography>
                                </Stack>
                            </Button>
                        </Stack>
                    </form >
                }

            </Paper >

        </Page >
    )
}

export default FinalResult;