import Page from "../../Layout/Page";
import {
    Stack, easing,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as Yup from "yup";
import PageHeader from "../../Layout/PageHeader";
import AddForm from "../../components/Filter/AddForm";
import SelectField from "../../components/Fields/SelectField";
import TextInput from "../../components/Fields/TextInput";
import MultilineInput from "../../components/Fields/MultilineInput";
import { useNavigate, useParams } from "react-router-dom";
import useGetAllCourse from "../../hooks/course/useGetAllCourse";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-hot-toast";
import useClass from "../../hooks/class/useClass";
import { addClass, updateClass } from "../../api/fetcherFn/class";
import Loading from "../../Layout/Loading";
import useGetTeacherCourses from "../../hooks/teacher/useGetTeacherCourses";
import { getTodaysDate } from "../../utils/constants";
import { useEffect, useState } from "react";



export default function ClassAdd() {
    const queryClient = useQueryClient();
    const params = useParams();
    const id = parseInt(params.id, 10);
    const navigate = useNavigate();
    const [selectDefaultValue, setSelectDefaultValue] = useState("Not Null");

    const allCourse = useGetAllCourse();
    const validationSchema = Yup.object().shape({
        course_id: Yup.number()
            .nullable()
            .transform(v => !isNaN(v) ? v : null).required("Required"),
        batch_id: Yup.number()
            .nullable()
            .transform(v => !isNaN(v) ? v : null).required("Required"),
        // Semester would be optional on Condition => if there is semester_wise_subjects in course
        semester_number: Yup.number().when('course_id', (courseId, schema) => {
            const course = allCourse.data?.response_data?.find(c => c.id === courseId);
            return course?.semester_wise_subjects?.length > 0
                ? schema.nullable().transform(v => !isNaN(v) ? v : null).required('Required.')
                : schema.nullable().transform(v => !isNaN(v) ? v : null);
        }),
        subject_id: Yup.number()
            .nullable()
            .transform(v => !isNaN(v) ? v : null).required("Required"),
        name: Yup.string().required("Class title is required"),
        class_date: Yup.string()
            .required("Class Date is required"),
        start_time: Yup.string().required("Start time is required"),
        end_time: Yup.string().required("End time is required"),
        description: Yup.string(),
        place: Yup.string(),
        teacher_id: Yup.number().nullable()
            .transform(v => !isNaN(v) ? v : null).required("Required"),
    });

    const { control, handleSubmit, watch, setValue } = useForm({ resolver: yupResolver(validationSchema), mode: "onChange", });

    const cId = watch('course_id');
    const bId = watch('batch_id');
    const semesterNum = watch('semester_number');

    const { data, isLoading } = useClass(id);
    const _class = data?.response_data;
    const course = _class?.course?.id;
    const batch = _class?.batch?.id;

    const teachersData = useGetTeacherCourses(0, 0, cId, bId, semesterNum, false, false);

    const add = useMutation(data => addClass(data), {
        onSuccess: (data) => {
            if (data.success) {
                toast.success(data.message);
                queryClient.invalidateQueries('class');
                navigate('/classes/manage');
            } else {
                toast.error(data.message);
            }
        },
        onError: (err) => {
            toast.error(err.message);
        }
    });
    const update = useMutation(data => updateClass(id, data), {
        onSuccess: (data) => {
            if (data.success) {
                toast.success(data.message);
                queryClient.invalidateQueries('class');
                navigate('/classes/manage');
            } else {
                toast.error(data.message);
            }
        },
        onError: (err) => {
            toast.error(err.message);
        }
    });

    const onSubmit = async (data) => {
        if (id) {
            await update.mutateAsync(data);
            return;
        }
        await add.mutateAsync(data);
    }

    const courses = allCourse?.data?.response_data;
    const batches = courses && courses?.find(c => c.id === (cId || course))?.batches;
    const semesters = courses && courses?.find(c => c.id === (cId || course))?.semester_wise_subjects;
    let subjects = courses && (courses?.find(c => c.id === (cId || course))?.semester_wise_subjects?.find(s => (s.semester === semesterNum || s.semester === _class?.semester_number))?.subjects
        || courses?.find(c => c.id === (cId || course))?.subjects);

    const teachers = teachersData.data?.response_data?.data?.flatMap(d => d.teachers);
    const uniqueTeachers = teachers?.filter((teacher, index, self) => index === self.findIndex((t) => t.id === teacher.id));

    useEffect(() => {
        if (id) {
            setValue('course_id', course);
        }
    }, [id, setValue, course])

    return (
        <Page title={`${id ? 'Edit' : 'Add'} Class`}>
            <PageHeader titleEn={`${id ? 'Edit' : 'Add'} Class`} titleBn={`ক্লাস ${id ? 'পরিবর্তন' : 'সংযুক্তি'}`}
                link={{ to: '/classes/manage', textEn: "Manage Class", textBn: "ক্লাস ব্যবস্থাপনা" }} />



            {(id && isLoading) ? <Loading /> : <AddForm handleSubmit={handleSubmit(onSubmit)} loading={add.isLoading || update.isLoading}>

                <SelectField
                    name="course_id"
                    control={control}
                    isLoading={allCourse.isLoading}
                    defaultValue={_class?.course?.id}
                    data={courses}
                    handelChange={() => {
                        setValue('batch_id', null);
                        setValue('semester_number', null);
                        setValue('subject_id', null);
                        setValue('subject_id', null);
                        setValue('teacher_id', null);
                        setSelectDefaultValue(null);
                    }}
                    label={{ en: "Select Course", bn: "কোর্স নির্বাচন" }}
                    errorMsg fontBold={false} required />

                <Stack direction="row" gap={1}>
                    <SelectField
                        name="batch_id"
                        control={control}
                        defaultValue={_class?.batch?.id}
                        data={batches}
                        uniqueKey={cId}
                        label={{ en: "Select Batch", bn: "ব্যাচ নির্বাচন" }}
                        errorMsg fontBold={false} required />
                    {semesters && <SelectField
                        name="semester_number"
                        control={control}
                        subLabel={false}
                        uniqueKey={cId}
                        defaultValue={selectDefaultValue && _class?.semester_number}
                        data={semesters?.map(option => ({ id: option.semester, name: `Semester ${option.semester}` }))}
                        handelChange={() => {
                            setValue('subject_id', null);
                        }}
                        label={{ en: "Select Semester", bn: "সেমিস্টার নির্বাচন" }}
                        errorMsg fontBold={false} required />}
                </Stack>

                {subjects && <SelectField
                    name="subject_id"
                    control={control}
                    uniqueKey={cId}
                    defaultValue={selectDefaultValue && _class?.subject.id}
                    data={subjects}
                    label={{ en: "Select Subject", bn: "বিষয় নির্বাচন" }}
                    errorMsg fontBold={false} required />}

                <TextInput
                    name="name"
                    type="text"
                    control={control}
                    value={_class?.name}
                    label={{ en: "Class Name", bn: "ক্লাস টাইটেল" }}
                    errorMsg fontBold={false} required />
                <TextInput
                    name="class_date"
                    type="date"
                    control={control}
                    min={getTodaysDate}
                    value={_class?.class_date}
                    label={{ en: "Class Date", bn: "ক্লাসের তারিখ" }}
                    errorMsg fontBold={false} required />


                <Stack direction="row" gap={2}>
                    <TextInput
                        name="start_time"
                        type="time"
                        control={control}
                        value={_class?.start_time}
                        label={{ en: "Start Time", bn: "শুরুর সময়" }}
                        errorMsg fontBold={false} required />
                    <TextInput
                        name="end_time"
                        type="time"
                        control={control}
                        value={_class?.end_time}
                        label={{ en: "End Time", bn: "শেষের সময়" }}
                        errorMsg fontBold={false} required />
                </Stack>

                <MultilineInput
                    name="description"
                    control={control}
                    value={_class?.description}
                    label={{ en: "Brief  Description", bn: "সংক্ষিপ্ত বিবরণ" }}
                    errorMsg />
                <TextInput
                    name="place"
                    control={control}
                    value={_class?.place}
                    label={{ en: "Place", bn: "স্থান" }}
                    errorMsg fontBold={false} />

                {teachers?.length > 0 && <SelectField
                    name="teacher_id"
                    control={control}
                    subLabel={false}
                    subValue={"_id"}
                    uniqueKey={cId}
                    isLoading={teachersData.isLoading}
                    defaultValue={selectDefaultValue && _class?.teacher.id}
                    data={uniqueTeachers}
                    label={{ en: "Select Teacher", bn: "শিক্ষক নির্বাচন" }}
                    errorMsg fontBold={false} required />}
            </AddForm>}


        </Page>
    )
}