import { useMutation, useQueryClient } from '@tanstack/react-query';
import { disablePermission } from '../../api/fetcherFn/settings';

const useDisablePermission = (roleId, userRoleId) => {
    const queryClient = useQueryClient();
    return useMutation(data => disablePermission(data), {
        onSuccess: (data) => {
            // toast.success(data.message);

            queryClient.invalidateQueries(['role-permissions', roleId]);
            queryClient.invalidateQueries(['roles']);
            queryClient.invalidateQueries(['permissions', userRoleId]);
        }
    });
};

export default useDisablePermission;