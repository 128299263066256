import Page from "../../Layout/Page";
import { useParams } from "react-router-dom";
import { Avatar, Paper, Stack, } from "@mui/material";
import PageHeader from "../../Layout/PageHeader";
import useTeacher from "../../hooks/teacher/useTeacher";
import Loading from "../../Layout/Loading";
import TRow from "../../components/Table/TRow";
import { blueGrey } from "@mui/material/colors";
import React, { useRef } from 'react'
import { IoMdPrint } from "react-icons/io";
import { useReactToPrint } from "react-to-print";


export default function TeacherDetails() {
    const { id } = useParams();

    const { data, isLoading } = useTeacher(id);
    const teacher = data?.response_data;
    const componentRef = useRef();

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: `Student Details of ${teacher?.name_en}`,
    });


    return (
        <Page title="Teacher Details">
            <PageHeader titleEn="Teacher Details" titleBn="শিক্ষকের বিস্তারিত"
                link={{ to: '/teachers/manage', textEn: "Manage Teachers", textBn: "শিক্ষক ব্যবস্থাপনা" }} />

            {isLoading ? <Loading /> :
                <Stack component={Paper} px={{ md: 4 }} sx={{ py: 2, maxWidth: 900, margin: '0 auto' }} className="rad-grad" >
                    <div style={{ width: '100%', display: 'inline-flex', justifyContent: 'flex-end' }}>
                        <IoMdPrint onClick={handlePrint} size={25} color="gray" cursor="pointer" />
                    </div>
                    <Stack ref={componentRef} p={{ md: 4, xs: 2 }} >
                        {teacher.user.photo_url ?
                            <Avatar
                                src={teacher.user.photo_url}
                                alt="Profile"
                                sx={{
                                    width: 120,
                                    height: 120,
                                    display: "flex",
                                    margin: "0 auto"
                                }}
                            /> :
                            <Avatar sx={{
                                width: 120,
                                height: 120,
                                display: "flex",
                                margin: "0 auto", bgcolor: blueGrey[200], fontSize: '2.5rem', fontWeight: 'bold'
                            }}>{teacher.user.name.charAt(0)}</Avatar>}


                        <TRow data={[
                            { label: { en: "Teacher ID", bn: "শিক্ষকের আইডি" }, value: teacher?.user._id },
                            { label: { en: "Teacher Name", bn: "শিক্ষকের নাম" }, value: teacher.name_en },
                            { label: { en: "Teacher Name(Bangla)", bn: "শিক্ষকের নাম(বাংলা)" }, value: teacher.name_bn },
                            { label: { en: "Gender", bn: "লিঙ্গ" }, value: teacher.gender },
                            { label: { en: "Email", bn: "ই-মেইল" }, value: teacher.user.email },
                            { label: { en: "Phone No.", bn: "ফোন নাম্বার" }, value: teacher.user.phone },
                            { label: { en: "Date of Birth", bn: "জন্ম তারিখ" }, value: teacher.dob },
                            { label: { en: "NID No.", bn: "জাতীয় পরিচয়পত্র নাম্বার" }, value: teacher.nid },
                            { label: { en: "Designation", bn: "উপাধি" }, value: teacher.designation },
                            { label: { en: "Bio", bn: "বায়ো" }, value: teacher.bio },
                            { label: { en: "Present Address", bn: "বর্তমান ঠিকানা" }, value: teacher.present_address },
                            { label: { en: "Permanent Address", bn: "স্থায়ী ঠিকানা" }, value: teacher.permanent_address },
                        ]} />

                    </Stack>
                </Stack>
            }
        </Page>
    );
}