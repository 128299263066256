import { useQuery } from '@tanstack/react-query';
import { getTeachers, getTeachersSelf } from '../../api/fetcherFn/teacher';
import usePermissions from '../permission/usePermissions';
import { ROUTES } from '../../Pages/Settings/permission_data';

const useTeachers = (page = 0, limit = 0, name) => {
    const { check } = usePermissions();
    return useQuery(['teachers', page + 1, limit, name],
        check(ROUTES.TEACHER.MANAGE.M) ? () => getTeachers(page + 1, limit, name) : () => getTeachersSelf(page + 1, limit, name), {
        keepPreviousData: true
    });
};

export default useTeachers;