import { Checkbox, Divider, FormControlLabel, Paper, Stack, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { getRolePermissions } from '../../api/fetcherFn/settings';
import Translate from '../../components/Translate/Translate';
import useChangePermission from '../../hooks/permission/useChangePermission';
import Loading from '../../Layout/Loading';
import { ROUTES, permission_data } from './permission_data';
import { useAuth } from '../../hooks/useAuth';
import useDisablePermission from '../../hooks/permission/useDisablePermission';

const PermissionCard = ({ setOpenPermission, id }) => {
    const { user } = useAuth();
    const { data: permissions, isLoading } = useQuery(['role-permissions', id], () => getRolePermissions(id), {
        enabled: !!id
    });
    const permit = useChangePermission(id, user.role_id);
    const disable = useDisablePermission(id, user.role_id);


    const checkPermission = (route) => {
        const has = permissions?.response_data?.find(p => (p.route === route && p.status === 'active'));
        if (has) return true;
        return false;
    }


    const handlePermissionChange = async (e) => {
        if (e.target.value.endsWith('/self')) {
            const route = e.target.value.split('/self')[0];
            await permit.mutateAsync({ route: e.target.value, role_id: id });

            await disable.mutateAsync({ route: route, role_id: id });


        } else if (e.target.value.endsWith('/manage')) {
            const route = `${e.target.value}/self`;
            await permit.mutateAsync({ route: e.target.value, role_id: id });

            await disable.mutateAsync({ route: route, role_id: id });


        } else {
            await permit.mutateAsync({ route: e.target.value, role_id: id });
        }
    }

    return (
        <Paper sx={{ p: 3, flexBasis: '50%' }} className='rad-grad'>
            <Stack direction="row" justifyContent="space-between" alignItems="center" mb={3}>
                <Typography variant='h6' fontWeight="bold" sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }} ><Translate en="Permission" bn='শিক্ষার্থী' /> <span style={{ fontSize: 12 }}>(Click the checkbox.)</span> </Typography>
                <AiOutlineCloseCircle size={28} onClick={() => setOpenPermission(false)} style={{ color: 'red', cursor: 'pointer' }} />
            </Stack>

            {isLoading ? <Loading /> :
                <Stack gap={2}>
                    {permission_data?.map((p, i) =>
                        <Stack key={i}>
                            <Typography variant='h6' fontWeight={700}><Translate en={p.module} bn='শিক্ষার্থী' /></Typography>
                            <Divider sx={{ mb: 1 }} />
                            {p?.menus?.map((m, i) =>
                                <Stack direction="row" justifyContent="space-between" alignItems="flex-start" key={i}>
                                    <Stack>
                                        <Typography variant='body2' fontWeight="bold" color="#6a6868"><Translate en={m.name} bn='শিক্ষার্থী ব্যবস্থাপনা' /></Typography>
                                        <Stack direction="column" gap={0.3} mb={2} key={i + 10}>

                                            {
                                                m?.action?.map((action, i) => <FormControlLabel key={i + 10} control={
                                                    <Checkbox size='small'
                                                        value={action.route}
                                                        defaultChecked={checkPermission(action.route)}
                                                        onChange={handlePermissionChange}
                                                        disabled={permissions?.response_data?.find(p => (p.route === action.route && p.status === 'inactive')) ? true : false} />}
                                                    label={<Typography variant="caption" color="blueviolet" style={{ fontSize: '14px', }}>{action.name}</Typography>} />)
                                            }

                                        </Stack>
                                    </Stack>
                                    {m?.route && <Checkbox size='small' disabled={checkPermission(ROUTES.SETTINGS.PERMISSION.MANAGE.M) && m.route === ROUTES.SETTINGS.PERMISSION.MANAGE.M} value={m.route} defaultChecked={checkPermission(m.route)} onChange={handlePermissionChange} />}
                                </Stack>
                            )}
                        </Stack>
                    )}
                </Stack>
            }
        </Paper>
    );
};

export default PermissionCard;