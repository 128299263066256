import { useQuery } from '@tanstack/react-query';
import { getAssignedTeachers, getAssignedTeachersSelf } from '../../api/fetcherFn/teacher';
import usePermissions from '../permission/usePermissions';
import { ROUTES } from '../../Pages/Settings/permission_data';

const useGetTeacherCourses = (page = 0, limit = 0, course, batch, semester, enabled = true, keepPreviousData = true) => {
    const { check } = usePermissions();
    return useQuery(['teacher-courses', page + 1, limit, course, batch, semester],
        check(ROUTES.TEACHER.COURSE.MANAGE.M) ? () => getAssignedTeachers(page + 1, limit, course, batch, semester) : () => getAssignedTeachersSelf(page + 1, limit, course, batch, semester), {
        keepPreviousData: keepPreviousData, enabled: enabled ? true : !!course && !!batch
    });
};

export default useGetTeacherCourses;