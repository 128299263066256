import { useMutation, useQueryClient } from '@tanstack/react-query';
import { changePermission } from '../../api/fetcherFn/settings';
import { toast } from 'react-hot-toast';

const useChangePermission = (roleId, userRoleId) => {
    const queryClient = useQueryClient();
    return useMutation(data => changePermission(data), {
        onSuccess: (data) => {
            toast.success(data.message);

            queryClient.invalidateQueries(['role-permissions', roleId]);
            queryClient.invalidateQueries(['roles']);
            queryClient.invalidateQueries(['permissions', userRoleId]);
        }
    });
};

export default useChangePermission;