import Page from "../../Layout/Page";
import { useParams } from "react-router-dom";
import PageHeader from "../../Layout/PageHeader";
import TRow from "../../components/Table/TRow";
import Loading from "../../Layout/Loading";
import { Paper, Stack } from "@mui/material";
import useSubject from "../../hooks/notice-subject/useSubject";
import React, { useRef } from 'react'
import { IoMdPrint } from "react-icons/io";
import { useReactToPrint } from "react-to-print";

const SubjectDetails = () => {
    const { id } = useParams();
    const { data, isLoading } = useSubject(id);
    const subject = data?.response_data;
    const componentRef = useRef();

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: `Student Details of ${subject?.name}`,
    });




    return (
        <Page title="Subject Details">
            <PageHeader titleEn="Subject Details" titleBn="বিষয়ের বিস্তারিত"
                link={{ to: '/subject/manage', textEn: "Manage Subjects", textBn: "বিষয়সমূহ ব্যবস্থাপনা" }} />

            {isLoading ? <Loading /> :
                <Stack component={Paper} px={{ md: 4 }} sx={{ py: 2, maxWidth: 900, minHeight: '75vh', margin: '0 auto' }} className="rad-grad" >
                    <div style={{ width: '100%', display: 'inline-flex', justifyContent: 'flex-end' }}>
                        <IoMdPrint onClick={handlePrint} size={25} color="gray" cursor="pointer" />
                    </div>
                    <Stack ref={componentRef} p={{ md: 4, xs: 2 }} >
                        <TRow data={[
                            { label: { en: "Name", bn: "নাম" }, value: subject.name },
                            { label: { en: "Subject Code", bn: "বিষয় কোড" }, value: subject.subject_code },
                            { label: { en: "Credit", bn: "ক্রেডিট" }, value: subject.credit },
                            { label: { en: "Marks", bn: "মার্কস" }, value: subject.marks },
                            { label: { en: "Subject Description", bn: "বিষয়ের বিস্তারিত" }, value: subject.description },

                        ]} />

                    </Stack>
                </Stack>}
        </Page>
    );
}

export default SubjectDetails;