import { Button, CircularProgress, Grid, Paper, Stack } from '@mui/material';
import React from 'react';
import { BiArrowBack } from 'react-icons/bi';
import { AiOutlineCloudUpload } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import Translate from '../Translate/Translate';

const AddForm = ({ handleSubmit, reset, maxWidth = 500, submitBtn = "Submit", loading = false, children, gradient = true }) => {
    const navigate = useNavigate();
    const childrenProps = children.filter(child => child !== false && child !== undefined && child !== null);


    return (
        <Paper elevation={0} sx={{ p: 2, minHeight: '75vh' }} className={gradient ? "rad-grad" : ''}>
            <form onSubmit={handleSubmit}>
                <Grid container gap={2} sx={{ maxWidth, margin: "1rem auto" }} alignItems="center" justifyContent="center">
                    {childrenProps?.map((item, i) => <Grid item xs={12} key={i}>{item} </Grid>)}

                    <Grid container spacing={2} sx={{ pt: 3, pb: 5 }}>
                        <Grid item xs={6}>
                            <Button variant="outlined" onClick={() => navigate(-1)} color="success" size='large' fullWidth >
                                <Stack direction="row" alignItems='center' gap={1} fontWeight={'bold'} textTransform="capitalize">
                                    <BiArrowBack size={20} />
                                    <Translate en="Back" bn="পিছনে যান" />
                                </Stack>
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button variant="contained" disabled={loading} type="submit" color="primary" size='large' fullWidth>
                                <Stack direction="row" alignItems='center' gap={1} fontWeight={'bold'} textTransform="capitalize">
                                    {loading ? <CircularProgress size={18} /> : <AiOutlineCloudUpload size={20} />}
                                    <Translate en={submitBtn} bn="সাবমিট" />
                                </Stack>
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
        </Paper>
    );
};

export default AddForm;