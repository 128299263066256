import {
    Paper,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow, Typography
} from "@mui/material";
import { grey } from "@mui/material/colors";
import TCell from "../../components/Table/TCell";
import Loading from "../../Layout/Loading";
import Translate from "../../components/Translate/Translate";
import useNoticeboard from "../../hooks/notice-subject/useNoticeboard";

const NoticeBoard = () => {
    const { data: notices, isLoading } = useNoticeboard(0, 0);

    return (
        <Paper elevation={0} sx={{ px: 3, py: 4, mt: 3, minHeight: '60vh' }}>
            <Typography variant={"h5"} fontWeight="bold"><Translate en="Notice Board" bn="নোটিসবোর্ড" /> </Typography>

            {isLoading ? <Loading /> :
                <>
                    <TableContainer sx={{ mt: 2, borderRadius: 1, border: `1px solid ${grey[100]}` }}>
                        <Table sx={{ minWidth: 650 }} size={"medium"}>
                            <TableHead sx={{ bgcolor: `${grey[100]}` }}>
                                <TableRow>
                                    <TCell bold align="center">#</TCell>
                                    <TCell bold><Translate en="Notice Title" bn="নোটিস টাইটেল" /></TCell>
                                    <TCell bold><Translate en="Download" bn="ডাউনলোড" /></TCell>
                                    <TCell bold align="center"><Translate en="Date & Time" bn="সময় ও তারিখ" /></TCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {!notices.success || notices.response_data.data.length === 0 ?
                                    <TableRow sx={{ textAlign: "center" }}><TCell align="center" bold colSpan={7}>No record found.</TCell></TableRow> :
                                    notices.response_data.data.map((row, index) => (
                                        <TableRow key={index} hover>
                                            <TCell align="center">{index + 1}</TCell>
                                            <TCell>{row.title}</TCell>
                                            <TCell>{row.attachment ? <a href={row.attachment} target="_blank" rel="noreferrer">Download File</a> : 'No attachment'}</TCell>
                                            <TCell align="center">{row.date}</TCell>
                                        </TableRow>
                                    ))}

                            </TableBody>
                        </Table>
                    </TableContainer>
                </>}

        </Paper>
    );
};

export default NoticeBoard;