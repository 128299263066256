import Page from "../../Layout/Page";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as Yup from "yup";
import PageHeader from "../../Layout/PageHeader";
import FileInput from "../../components/Fields/FileInput";
import { PNG_JPG_JPEG_GIF, PNG_JPG_PDF } from "../../Constants/FileTypes";
import SelectField from "../../components/Fields/SelectField";
import AddForm from "../../components/Filter/AddForm";
import TextInput from "../../components/Fields/TextInput";
import FormSection from "../../components/Filter/FormSection";
import MultilineInput from "../../components/Fields/MultilineInput";
import PasswordField from "../../components/Fields/PasswordField";
import { useMutation, } from "@tanstack/react-query";
import { addStudent, } from "../../api/fetcherFn/student";
import { toast } from "react-hot-toast";
import { useNavigate, } from "react-router-dom";
import PhotoAvatarUpload from "../../components/Fields/PhotoAvatarUpload";
import { getEighteenYearsPrevDate } from "../../utils/constants";

const validationSchema = Yup.object().shape({
    name_en: Yup.string().required('Student name is required'),
    name_bn: Yup.string().required('Student name is required'),
    father_name_en: Yup.string().required('Teacher name is required'),
    father_name_bn: Yup.string().required('Teacher name is required'),
    mother_name_en: Yup.string().required('Teacher name is required'),
    mother_name_bn: Yup.string().required('Teacher name is required'),
    gender: Yup.string().nullable().required('Required'),
    dob: Yup
        .string()
        .required("Date of birth is required"),
    nid: Yup.string(),
    phone: Yup.string().required('Mobile number is required').max(12, "Invalid"),
    nationality: Yup.string().nullable().required('Required'),
    city: Yup.string().required('City name is required'),
    present_address: Yup.string().required('Present address is required'),
    permanent_address: Yup.string().required('Permanent address is required'),
    qualification: Yup.string(),
    email: Yup.string().email().required('Proper email is required'),
    password: Yup.string()
        .required('Password is required')
        .min(6, "Passwor should be atleast 6 characters."),
    qualification_file: Yup.array()
        // .min(1, "Please select at least one file")
        .max(1, "Please select at least one file")
        .test("fileSize", "File size must be less than 5MB", (value) =>
            value.every((file) => file.size <= 5000000)
        )
        .test("fileType", "Invalid file type", (value) =>
            value.every((file) => PNG_JPG_PDF.includes(file.type))
        ),
    photo: Yup
        .mixed()
        .test("fileSize", "File size is too large", (value) => {
            if (!value) return true;
            return value && value.size <= 1024000; //1 MB
        })
        .test("fileType", "Unsupported file format", (value) => {
            if (!value) return true;
            return value && ["image/jpeg", "image/png"].includes(value.type);
        })
});

export default function StudentsAdd() {
    const navigate = useNavigate();
    const { control, handleSubmit, reset } = useForm({ resolver: yupResolver(validationSchema), mode: "onChange", });
    const add = useMutation(data => addStudent(data), {
        onSuccess: (data) => {
            if (data.success) {
                toast.success(data.message);
                navigate('/students/manage');
            } else {
                toast.error(data.message);
            }
        },
        onError: (err) => {
            toast.error(err.message);
        }
    });


    const onSubmit = async (data) => {
        if (!data.qualification_file.length) {
            data.qualification_file = undefined;
        } else {
            data.qualification_file = data.qualification_file[0];
        }
        const formData = new FormData();
        for (const key in data) {
            formData.append(key, data[key]);
        }
        await add.mutateAsync(formData);
    }


    return (
        <Page title={`Add Student`}>
            <PageHeader titleEn={`Add Student`} titleBn={`শিক্ষার্থী সংযুক্তিকরণ`}
                link={{ to: '/students/manage', textEn: "Manage Student", textBn: "শিক্ষার্থী ব্যবস্থাপনা" }} />

            <AddForm handleSubmit={handleSubmit(onSubmit)} loading={add.isLoading} reset={reset} maxWidth={700}>

                <PhotoAvatarUpload
                    name="photo"
                    control={control}
                    fileType={PNG_JPG_JPEG_GIF} />

                <FormSection title={{ en: "Personal Information", bn: "ব্যাক্তিগত তথ্য" }}>
                    <TextInput
                        name="name_en"
                        control={control}
                        label={{ en: "Student Name (English)", bn: "শিক্ষার্থীর নাম (ইংরেজীতে)" }}
                        errorMsg fontBold={false} required autoFocus />
                    <TextInput
                        name="name_bn"
                        control={control}
                        label={{ en: "Student Name (Bangla)", bn: "শিক্ষার্থীর নাম (বাংলায়)" }}
                        errorMsg fontBold={false} required />
                    <TextInput
                        name="father_name_en"
                        control={control}
                        label={{ en: "Father's Name (English)", bn: "পিতার নাম (ইংরেজীতে)" }}
                        errorMsg fontBold={false} required />
                    <TextInput
                        name="father_name_bn"
                        control={control}
                        label={{ en: "Father's Name (Bangla)", bn: "পিতার নাম (বাংলায়)" }}
                        errorMsg fontBold={false} required />

                    <TextInput
                        name="mother_name_en"
                        control={control}
                        label={{ en: "Mother's Name (English)", bn: "মাতার নাম (ইংরেজীতে)" }}
                        errorMsg fontBold={false} required />
                    <TextInput
                        name="mother_name_bn"
                        control={control}
                        label={{ en: "Mother's Name (Bangla)", bn: "মাতার নাম (বাংলায়)" }}
                        errorMsg fontBold={false} required />
                    <SelectField
                        name="gender"
                        control={control}
                        subLabel={false}
                        data={[{ id: "male", name: "Male" }, { id: "female", name: "Female" }, { id: "other", name: "Other" }]}
                        label={{ en: "Gender", bn: "লিঙ্গ" }}
                        errorMsg fontBold={false} required />
                    <TextInput
                        name="dob"
                        type="date"
                        control={control}
                        min={"1970-01-01"}
                        max={getEighteenYearsPrevDate}
                        label={{ en: "Date of Birth", bn: "জন্ম তারিখ" }}
                        errorMsg fontBold={false} required />
                    <TextInput
                        name="nid"
                        type="number"
                        control={control}
                        label={{ en: "NID Number", bn: "এনআইডি নাম্বার" }}
                        placeholder="e.g. 8263215417"
                        errorMsg fontBold={false} />
                    <TextInput
                        name="phone"
                        type="tel"
                        max={12}
                        control={control}
                        label={{ en: "Mobile Number", bn: "মোবাইল নাম্বার" }}
                        placeholder="e.g. 01710000000"
                        errorMsg fontBold={false} required />

                    <SelectField
                        name="nationality"
                        control={control}
                        subLabel={false}
                        data={[{ id: 'Bangladeshi', name: "Bangladeshi" }]}
                        label={{ en: "Nationality", bn: "জাতীয়তা" }}
                        errorMsg fontBold={false} required />
                    <TextInput
                        name="city"
                        control={control}
                        label={{ en: "City", bn: "শহর" }}
                        placeholder="e.g. Narsingdi"
                        errorMsg fontBold={false} required />
                    <MultilineInput
                        name="present_address"
                        control={control}
                        label={{ en: "Present Address", bn: "বর্তমান ঠিকানা" }}
                        rows={4} errorMsg required />
                    <MultilineInput
                        name="permanent_address"
                        control={control}
                        label={{ en: "Permanent Address", bn: "স্থায়ী ঠিকানা" }}
                        rows={4} errorMsg required />

                </FormSection>

                <FormSection title={{ en: "Other Informations", bn: "অতিরিক্ত তথ্য" }}>
                    <TextInput
                        name="qualification"
                        control={control}
                        label={{ en: "Last Educational Qualification", bn: "সর্বশেষ শিক্ষাগতযোগ্যতা সনদ" }}
                        errorMsg fontBold={false} />
                    <FileInput
                        name="qualification_file"
                        control={control}
                        fileTypes={PNG_JPG_PDF}
                        placeholder=".png, .jpg, .pdf" />
                </FormSection>

                <FormSection title={{ en: "Login Informations", bn: "লগইন করার তথ্যাবলী" }}>
                    <TextInput
                        name="email"
                        type="email"
                        control={control}
                        label={{ en: "Email", bn: "ইমেইল" }}
                        errorMsg fontBold={false} required />
                    <PasswordField
                        name="password"
                        type="password"
                        control={control}
                        label={{ en: "Password", bn: "পাসওয়ার্ড" }}
                        errorMsg fontBold={false} required />
                </FormSection>
            </AddForm>

        </Page>
    )
}