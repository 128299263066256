import Page from "../../Layout/Page";
import {
    Button,
    Paper,
    Stack,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { useState } from "react";
import { BiLockOpen } from "react-icons/bi";
import PageHeader from "../../Layout/PageHeader";
import Translate from "../../components/Translate/Translate";
import TCell from "../../components/Table/TCell";
import useAllRoles from "../../hooks/settings/useAllRoles";
import Loading from "../../Layout/Loading";
import PermissionCard from "./PermissionCard";
import img from "../../assets/permission.jpg";


const Permissions = () => {
    const [openPermission, setOpenPermission] = useState(false);
    const [id, setId] = useState(null);
    const { roles, roleLoading } = useAllRoles();

    return (
        <Page title={"Permission"}>
            <PageHeader titleEn="Permission" titleBn="অনুমতি"
                link={{ to: '/settings/manage-role', textEn: "Role Management", textBn: "ভূমিকা ব্যবস্থাপনা" }} />

            <Paper elevation={0} sx={{ p: 2, minHeight: '75vh' }} className="rad-grad">
                <Stack direction={{ xs: "column", md: "row", mt: 3, }} justifyContent="space-between" gap={3}>
                    {roleLoading ? <Loading /> :
                        <TableContainer sx={{ borderRadius: 1, border: `1px solid ${grey[100]}`, height: 400, maxHeight: '100vh', flexBasis: '50%' }}>
                            <Table size={"medium"} >
                                <TableHead sx={{ bgcolor: grey[100] }}>
                                    <TableRow>
                                        <TCell bold align="center">#</TCell>
                                        <TCell bold><Translate en="Role" bn="ভূমিকা" /></TCell>
                                        <TCell bold align="center"><Translate en="Number of permissions" bn="অনুমতির সংখ্যা" /></TCell>
                                        {/* <TCell bold><Translate en="Last edited" bn="সর্বশেষ সম্পাদিত" /></TCell> */}
                                        <TCell bold align="center"><Translate en="Action" bn="অ্যাকশন" /></TCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {!roles.success || roles.response_data.data.length === 0 ?
                                        <TableRow sx={{ textAlign: "center" }}><TCell align="center" bold colSpan={7}>Nothing found.</TCell></TableRow> :

                                        roles.response_data.data.map((row, index) => (
                                            <TableRow key={index} hover>
                                                <TCell align="center">{index + 1}</TCell>
                                                <TCell >{row.name}</TCell>
                                                <TCell align="center">{row.permissions?.filter(permission => permission.status === 'active')?.length}</TCell>
                                                <TCell align="center">
                                                    <Button variant="outlined" size="small" sx={{ textTransform: 'capitalize' }}
                                                        onClick={() => {
                                                            setOpenPermission(!openPermission);
                                                            setId(row.id);
                                                        }}>
                                                        <Stack direction="row" alignItems="center" gap={1}>
                                                            <BiLockOpen size={20} /> Permission
                                                        </Stack>
                                                    </Button></TCell>
                                            </TableRow>
                                        ))}

                                </TableBody>
                            </Table>
                        </TableContainer>}

                    {openPermission ? <PermissionCard setOpenPermission={setOpenPermission} id={id} /> :
                        <img src={img} style={{ width: '30%', height: '30%', objectFit: 'cover', margin: '0 auto' }} alt="Permission" />}
                </Stack>
            </Paper>

        </Page>
    );
}

export default Permissions;