import { Stack, Table, TableBody, TableContainer, TableHead, TableRow, } from '@mui/material';
import React from 'react';
import TRow from '../../components/Table/TRow';
import GpaTable from '../../components/Table/GpaTable';
import { grey } from '@mui/material/colors';
import TCell from '../../components/Table/TCell';
import Translate from '../../components/Translate/Translate';

const SemesterResult = ({ data, componentRef, gpaTable }) => {

    return (
        <Stack ref={componentRef} mx={3} my={4}>
            <Stack direction="row" justifyContent={"space-between"}>
                <TRow mt={1} data={[
                    { label: { en: "Student Name", bn: "শিক্ষার্থীর নাম" }, value: data?.student.name },
                    { label: { en: "Student ID", bn: "শিক্ষার্থীর আইডি" }, value: data?.student._id },
                    { label: { en: "Course Name", bn: "কোর্সের নাম" }, value: data?.course.name },
                    { label: { en: "Batch Name", bn: "ব্যাচের নাম" }, value: data?.batch.name },
                    {
                        label: { en: "Semester", bn: "সেমিস্টার" }, value: `${data?.semester_number ? `Semester ${data?.semester_number}` : 'None'}`
                    },
                    { label: { en: "Result Published", bn: "রেজাল্ট পাবলিশের তারিখ" }, value: data?.published_at },
                    { label: { en: "GPA", bn: "জিপিএ" }, value: data?.gpa },
                ]} />

                <Stack>
                    <GpaTable gpaTable={gpaTable} />
                </Stack>
            </Stack>

            {data?.results &&
                <TableContainer>
                    <Table sx={{ minWidth: 650, mt: 4 }} size={"small"} >
                        <TableHead>
                            <TableRow sx={{ bgcolor: grey[300] }}>
                                <TCell bold sx={{ border: `1px solid ${grey[200]}`, fontWeight: 'bold' }}><Translate en="Subject Name" bn="বিষয়" /> </TCell>
                                <TCell bold sx={{ border: `1px solid ${grey[200]}`, fontWeight: 'bold', textAlign: "center" }}><Translate en="Credit" bn="ক্রেডিট" />  </TCell>
                                <TCell bold sx={{ border: `1px solid ${grey[200]}`, fontWeight: 'bold', textAlign: "center" }}><Translate en="Marks" bn="মার্কস" />  </TCell>
                                <TCell bold sx={{ border: `1px solid ${grey[200]}`, fontWeight: 'bold', textAlign: "center" }}><Translate en="Grade" bn="গ্রেড" /></TCell>
                                <TCell bold sx={{ border: `1px solid ${grey[200]}`, fontWeight: 'bold', textAlign: "center" }}><Translate en="Point" bn="পয়েন্ট" /></TCell>
                            </TableRow>
                        </TableHead>
                        <TableBody >
                            {data?.results?.map((result, i) => (
                                <TableRow key={i}>
                                    <TCell bold sx={{ border: `1px solid ${grey[200]}` }}>{result.subject.name} ({result.subject.subject_code})</TCell>
                                    <TCell bold sx={{ border: `1px solid ${grey[200]}`, textAlign: "center" }}>{result.subject.credit}</TCell>
                                    <TCell bold sx={{ border: `1px solid ${grey[200]}`, textAlign: "center" }}>{result.total_marks}</TCell>
                                    <TCell bold sx={{ border: `1px solid ${grey[200]}`, textAlign: "center" }}>{result.grade}</TCell>
                                    <TCell bold sx={{ border: `1px solid ${grey[200]}`, textAlign: "center" }}>{result.point}</TCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            }
        </Stack>
    );
};

export default SemesterResult;