import Page from "../../Layout/Page";
import {
    IconButton,
    Paper, Table, TableBody, TableContainer, TableHead, TablePagination, TableRow,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { grey } from "@mui/material/colors";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { useState } from "react";
import * as Yup from "yup";
import { useAuth } from "../../hooks/useAuth";
import PageHeader from "../../Layout/PageHeader";
import FilterForm from "../../components/Filter/FilterForm";
import SelectField from "../../components/Fields/SelectField";
import TCell from "../../components/Table/TCell";
import ActionMenu from "../../components/Menus/ActionMenu";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import useGetAllCourse from "../../hooks/course/useGetAllCourse";
import { deleteSemesterEnroll, } from "../../api/fetcherFn/student";
import DeleteDialog from "../../components/HelperComponents/DeleteDialog";
import Loading from "../../Layout/Loading";
import useGetSemesterEnrolledStudents from "../../hooks/student/useGetSemesterEnrolledStudents";
import usePermissions from "../../hooks/permission/usePermissions";
import { ROUTES } from "../Settings/permission_data";

const validationSchema = Yup.object().shape({
    batch_id: Yup.number(),
    course_id: Yup.number(),
    semester_number: Yup.number(),
});

export default function ManageSemesterEnrollment() {
    const { check } = usePermissions();
    const queryClient = useQueryClient();
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const [data, setData] = useState({});
    const [anchorEl, setAnchorEl] = useState(null);
    const [deleteDialog, setDeleteDialog] = useState(false);
    const open = Boolean(anchorEl);
    const { lang } = useAuth();

    const { control, handleSubmit, watch, setValue } = useForm({ resolver: yupResolver(validationSchema), mode: "onChange", });
    const course = watch('course_id');
    const batch = watch('batch_id');
    const semesterNum = watch('semester_number');

    const allCourse = useGetAllCourse();
    const { data: semesterEnrollments, isLoading } = useGetSemesterEnrolledStudents(page, limit, course, batch, semesterNum);
    const { mutateAsync } = useMutation(() => deleteSemesterEnroll(data.id), { onSuccess: () => { queryClient.invalidateQueries(['semester-enrollments']); } });


    const handleClick = (event, row) => {
        setAnchorEl(event.currentTarget);
        setData(row)
    };

    const onSubmit = (data) => { };

    const courses = allCourse?.data?.response_data;
    const semesters = courses && courses?.find(c => c.id === course)?.semester_wise_subjects;



    return (
        <Page title={"Manage Semester Enrollment"}>
            <PageHeader titleEn="Manage Semester Enrollment" titleBn="সেমিস্টার বরাদ্দকরণ ব্যবস্থাপনা"
                link={{ to: '/students/manage', textEn: "Manage Student", textBn: "শিক্ষার্থী ব্যবস্থাপনা" }} />

            <Paper elevation={0} sx={{ p: 2, minHeight: '75vh' }} className="rad-grad">
                <FilterForm handleSubmit={handleSubmit(onSubmit)} button={check(ROUTES.STUDENT.SEMESTER.ADD)} btnText={{ en: "Enroll new", bn: "নতুন বরাদ্দ" }} navigate="/students/semester-enrollment/enroll">
                    <SelectField
                        name="course_id"
                        control={control}
                        label={{ en: "Select Course", bn: "কোর্স নির্বাচন" }}
                        data={courses}
                        handelChange={() => {
                            setValue('batch_id', null);
                            setValue('semester_number', null);
                        }} />
                    <SelectField
                        name="batch_id"
                        control={control}
                        uniqueKey={course}
                        label={{ en: "Select Batch", bn: "ব্যাচ নির্বাচন" }}
                        data={courses && courses?.find(c => c.id === course)?.batches} />
                    <SelectField
                        name="semester_number"
                        control={control}
                        uniqueKey={course}
                        label={{ en: "Select Semester", bn: "সেমিস্টার নির্বাচন" }}
                        data={semesters && semesters?.map(option => ({ id: option.semester, name: `Semester ${option.semester}` }))} />

                </FilterForm>

                {isLoading ? <Loading /> :
                    <>

                        <TableContainer sx={{ mt: 2, borderRadius: 1, border: `1px solid ${grey[100]}` }}>
                            <Table sx={{ minWidth: 650 }} size={"medium"}>
                                <TableHead sx={{ bgcolor: grey[100] }}>
                                    <TableRow>
                                        <TCell align="center" bold>#</TCell>
                                        <TCell bold>{lang === "en" ? "Course Name" : "কোর্সের নাম"}</TCell>
                                        <TCell bold>{lang === "en" ? "Batch" : "ব্যাচ"}</TCell>
                                        <TCell bold>{lang === "en" ? "Semester" : "সেমিস্টার"}</TCell>
                                        <TCell bold align="center">{lang === "en" ? "Registered Students" : "নিবন্ধিত শিক্ষার্থী"}</TCell>
                                        <TCell bold align="center">{lang === "en" ? "Total Subjects" : "সর্বমোট বিষয়"}</TCell>
                                        <TCell bold>{lang === "en" ? "Semester Enrolled at" : "সেমিস্টার তালিকাভুক্তির সময়"}</TCell>
                                        <TCell align="center" bold>{lang === "en" ? "Action" : "অ্যাকশন"}</TCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {!semesterEnrollments.success || semesterEnrollments.response_data.data.length === 0 ?
                                        <TableRow sx={{ textAlign: "center" }}><TCell align="center" bold colSpan={9}>No record found.</TCell></TableRow> :
                                        semesterEnrollments.response_data.data?.map((row, index) => (
                                            <TableRow key={index} hover>
                                                <TCell align="center">{index + 1}</TCell>
                                                <TCell>{row.course.name}</TCell>
                                                <TCell>{row.batch.name}</TCell>
                                                <TCell>{row.semester_number ? `Semester ${row.semester_number}` : "None"}</TCell>
                                                <TCell align="center">{row.students?.length}</TCell>
                                                <TCell align="center">{row.subjects?.length}</TCell>
                                                <TCell>{row.semester_enrolled_at}</TCell>
                                                <TCell align="center"><IconButton
                                                    onClick={(e) => handleClick(e, row)}><BiDotsVerticalRounded /></IconButton></TCell>
                                            </TableRow>
                                        ))}

                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            component="div"
                            count={semesterEnrollments?.response_data?.meta.data_found}
                            page={page}
                            onPageChange={(e, newPage) => {
                                setPage(newPage);
                            }}
                            rowsPerPage={limit}
                            onRowsPerPageChange={(e) => {
                                setLimit(parseInt(e.target.value, 10));
                                setPage(0);
                            }}
                        />
                    </>
                }

            </Paper>

            <ActionMenu
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
                open={open}
                viewUrl={check(ROUTES.STUDENT.SEMESTER.MANAGE.V) && `/students/semester-enrollment/view/${data.id}`}
                editUrl={check(ROUTES.STUDENT.SEMESTER.MANAGE.E) && `/students/semester-enrollment/edit/${data.id}`}
                handleDeleteDialog={check(ROUTES.STUDENT.SEMESTER.MANAGE.D) && (() => setDeleteDialog(true))} />

            {deleteDialog && <DeleteDialog handleDelete={async () => await mutateAsync(data.id)} dialog={deleteDialog} setDialog={setDeleteDialog} />}

        </Page>
    )
}