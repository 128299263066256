import { toast } from "react-hot-toast";
import api from "../setup";


export const addRole = async (data) => {
    try {
        const response = await api.post('/api/role/add', data);
        return response.data;
    } catch (err) {
        toast.error(err.message);
    }
}
export const updateRole = async (id, data) => {
    try {
        const response = await api.patch(`/api/role/update/${id}`, data);
        return response.data;
    } catch (err) {
        toast.error(err.message);
    }
}
export const getRole = async (id) => {
    try {
        const response = await api.get(`/api/role/get/${id}`);
        return response.data;
    } catch (err) {
        toast.error(err.message);
    }
}
export const deleteRole = async (id) => {
    try {
        const response = await api.delete(`/api/role/delete/${id}`);
        return response.data;
    } catch (err) {
        toast.error(err.message);
    }
}

export const getRoles = async () => {
    try {
        let response = await api.get(`/api/role/all`);
        return response.data;
    } catch (err) {
        toast.error(err.message);
    }
}

export const addUser = async (data) => {
    try {
        const response = await api.post('/api/user/add', data, {
            headers: {
                "Content-Type": 'multipart/form-data'
            }
        });
        return response.data;
    } catch (err) {
        toast.error(err.message);
    }
}

export const getUser = async (id) => {
    try {
        const response = await api.get(`/api/user/get/${id}`);
        return response.data;
    } catch (err) {
        toast.error(err.message);
    }
}
export const updateUser = async (id, data) => {
    try {
        const response = await api.patch(`/api/user/update/${id}`, data, {
            headers: {
                "Content-Type": 'multipart/form-data'
            }
        });
        return response.data;
    } catch (err) {
        toast.error(err.message);
    }
}
export const deleteUser = async (id) => {
    try {
        const response = await api.delete(`/api/user/delete/${id}`);
        return response.data;
    } catch (err) {
        toast.error(err.message);
    }
}
export const getUsers = async (page, limit, roleId, name) => {
    try {
        let response;
        if (roleId) { response = await api.get(`/api/user/manage?page=${page}&limit=${limit}&role=${roleId}`); }
        if (name) { response = await api.get(`/api/user/manage?page=${page}&limit=${limit}&name=${name}`); }
        if (roleId && name) { response = await api.get(`/api/user/manage?page=${page}&limit=${limit}&role=${roleId}&name=${name}`); }
        if (!roleId && !name) { response = await api.get(`/api/user/manage?page=${page}&limit=${limit}`); }
        return response.data;
    } catch (err) {
        toast.error(err.message);
    }
}
export const changeStatus = async (data) => {
    try {
        const response = await api.patch(`/api/user/update-status/${data.id}`, { status: data.status });
        return response.data;
    } catch (err) {
        toast.error(err.message);
    }
}


export const getAllRoles = async (page, limit, name) => {
    try {
        let response;
        if (name) {
            response = await api.get(`/api/role/manage?page=${page}&limit=${limit}&name=${name}`);
        }
        else { response = await api.get(`/api/role/manage?page=${page}&limit=${limit}`); }
        return response.data;
    } catch (err) {
        toast.error(err.message);
    }
}

export const getRolePermissions = async (roleId) => {
    try {
        const response = await api.get(`/api/permission/role/${roleId}`);
        return response.data;
    } catch (err) {
        toast.error(err.message);
    }
}

export const changePermission = async (data) => {
    try {
        const response = await api.put(`/api/permission/permit`, data);
        return response.data;
    } catch (err) {
        toast.error(err.message);
    }
}
export const disablePermission = async (data) => {
    try {
        const response = await api.put(`/api/permission/disable`, data);
        return response.data;
    } catch (err) {
        toast.error(err.message);
    }
}

export const getUserPermissions = async (userId) => {
    try {
        const response = await api.get(`/api/permission/get/${userId}`);
        return response.data;
    } catch (err) {
        toast.error(err.message);
    }
}

// Permissions 
export const getPermissions = async (userId) => {
    try {
        const response = await api.get(`/api/permission/get/${userId}`);
        return response.data;
    } catch (err) {
        toast.error(err.message);
    }
}