import { toast } from "react-hot-toast";
import api from "../setup";

export const addSubject = async (data) => {
    try {
        const response = await api.post('/api/subject/add', data);
        return response.data;
    } catch (err) {
        toast.error(err.message);
    }
}
export const updateSubject = async (id, data) => {
    try {
        const response = await api.patch(`/api/subject/update/${id}`, data);
        return response.data;
    } catch (err) {
        toast.error(err.message);
    }
}

export const getSubject = async (id) => {
    try {
        const response = await api.get(`/api/subject/get/${id}`);
        return response.data;
    } catch (err) {
        toast.error(err.message);
    }
}
export const getSubjects = async (page, limit, name) => {
    try {
        let response;
        if (name) { response = await api.get(`/api/subject/manage?page=${page}&limit=${limit}&name=${name}`); }
        else {
            response = await api.get(`/api/subject/manage?page=${page}&limit=${limit}`);
        }
        return response.data;
    } catch (err) {
        toast.error(err.message);
    }
}
export const deleteSubject = async (id) => {
    try {
        const response = await api.delete(`/api/subject/delete/${id}`);
        return response.data;
    } catch (err) {
        toast.error(err.message);
    }
}