import {
    Button,
    Paper,
    Stack,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Typography,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { grey, } from "@mui/material/colors";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as Yup from "yup";
import Translate from "../../components/Translate/Translate";
import PageHeader from "../../Layout/PageHeader";
import Page from "../../Layout/Page";
import SelectField from "../../components/Fields/SelectField";
import TCell from "../../components/Table/TCell";
import FilterForm from "../../components/Filter/FilterForm";
import useGetAllCourse from "../../hooks/course/useGetAllCourse";
import { useQuery } from "@tanstack/react-query";
import { exportReportToCSV, exportReportToExcel, getAttendanceReport, getAttendanceReportSelf } from "../../api/fetcherFn/attendance";
import Loading from "../../Layout/Loading";
import { useState } from "react";
import usePermissions from "../../hooks/permission/usePermissions";
import { ROUTES } from "../Settings/permission_data";
import { saveAs } from 'file-saver';
import { toast } from "react-hot-toast";

const schema = Yup.object().shape({
    attendance: Yup
        .array()
        .of(
            Yup.object().shape({
                id: Yup.string(),
                name: Yup.string(),
                status: Yup.string().oneOf(['present', 'absent']),
            })
        ),
});


const AttendanceReport = () => {
    const { check } = usePermissions();
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const { control, watch, setValue } = useForm({ resolver: yupResolver(schema) });

    const courseId = watch('course_id');
    const batchId = watch('batch_id');
    const semesterNum = watch('semester_number');
    const studentId = watch('student_id');

    const allCourse = useGetAllCourse();
    const { data, isLoading } = useQuery(['attendance-report', page + 1, limit, courseId, batchId, semesterNum, studentId],
        check(ROUTES.ATTENDANCE.REPORT.MANAGE.M) ? () => getAttendanceReport(page + 1, limit, courseId, batchId, semesterNum, studentId) : () => getAttendanceReportSelf(page + 1, limit, courseId, batchId, semesterNum, studentId), {
        enabled: !!courseId && !!batchId
    });

    const report = data?.response_data?.data;
    const totalData = data?.response_data?.meta.data_found;


    const courses = allCourse?.data?.response_data;
    const batches = courses && courses?.find(c => c.id === courseId)?.batches;
    const semesters = courses && courses?.find(c => c.id === courseId)?.semester_wise_subjects;
    const students = report?.attendanceResults?.map(re => (re.student));

    const exportToExcelFormat = async () => {
        try {
            const responseData = await exportReportToExcel();
            const filename = 'attendance_summary_report.xlsx';
            saveAs(responseData, filename);
        } catch (err) {
            toast.error('Error downloading file:', err.message);
        }
    }
    const exportToCSVFormat = async () => {
        try {
            const responseData = await exportReportToCSV();
            const filename = 'attendance_summary_report.csv';
            saveAs(responseData, filename);
        } catch (err) {
            toast.error('Error downloading file:', err.message);
        }
    }


    return (
        <Page title={"Attendance Summary Report"}>
            <PageHeader titleEn="Attendance Summary Report" titleBn="উপস্থিতি রিপোর্টের সারসংক্ষেপ"
                link={{ to: '/attendance/manage', textEn: "Manage Attendance", textBn: "উপস্থিতি ব্যবস্থাপনা" }} />

            {/* <Stack direction={"row"} justifyContent={"flex-end"} alignItems={"center"} mb={1} gap={1.5}>
                <Button onClick={exportToExcelFormat} variant="outlined" size="small" sx={{ textTransform: 'unset', fontWeight: "bold" }}>Export to Excel</Button>
                <Button onClick={exportToCSVFormat} variant="outlined" size="small" sx={{ textTransform: 'unset', fontWeight: "bold" }} color="warning">Export to CSV</Button>
            </Stack> */}
            <Paper elevation={0} sx={{ px: 2, py: 4, minHeight: '75vh' }} className="rad-grad">
                <FilterForm button={false}>
                    <SelectField
                        name="course_id"
                        control={control}
                        data={courses}
                        handelChange={() => {
                            setValue('batch_id', null);
                            setValue('semester_number', null);
                            setValue('student_id', null);
                        }}
                        label={{ en: "Select Course", bn: "কোর্স নির্বাচন" }} />
                    <SelectField
                        name="batch_id"
                        control={control}
                        data={batches}
                        uniqueKey={courseId}
                        label={{ en: "Select Batch", bn: "ব্যাচ নির্বাচন" }} />
                    <SelectField
                        name="semester_number"
                        control={control}
                        subLabel={false}
                        uniqueKey={courseId}
                        data={semesters?.map(option => ({ id: option.semester, name: `Semester ${option.semester}` }))}
                        label={{ en: "Select Semester", bn: "সেমিস্টার নির্বাচন" }} />
                    {/* <SelectField
                        name="class_id"
                        control={control}
                        data={classes}
                        label={{ en: "Select Class", bn: "ক্লাস নির্বাচন" }} /> */}
                    {/* <TextInput
                        name="date"
                        type="date"
                        control={control}
                        data={classes}
                        label={{ en: "Select Date", bn: "তারিখ নির্বাচন" }} /> */}
                    <SelectField
                        name="student_id"
                        control={control}
                        data={students}
                        uniqueKey={courseId}
                        label={{ en: "Select Student", bn: "শিক্ষার্থী নির্বাচন" }} />
                </FilterForm>

                {
                    !totalData > 0 ?
                        <p style={{ textAlign: 'center' }}>Will show results after filtering.</p> :
                        (isLoading) ? <Loading /> :
                            <>
                                <Typography variant="body1" my={1.5} fontWeight="bold">Total Classes: {report.totalClasses}</Typography>
                                <TableContainer sx={{ mt: 2, borderRadius: 1, border: `1px solid ${grey[100]}` }}>
                                    <Table sx={{ minWidth: 650 }} size={"medium"}>
                                        <TableHead sx={{ bgcolor: grey[100] }}>
                                            <TableRow>
                                                <TCell bold align="center">#</TCell>
                                                <TCell bold><Translate en="Student ID" bn="শিক্ষার্থীর আইডি" /></TCell>
                                                <TCell bold><Translate en="Student Name" bn="শিক্ষার্থীর নাম" /></TCell>
                                                <TCell bold align="center"><Translate en="Total Present" bn="মোট উপস্থিতি" /></TCell>
                                                <TCell bold align="center"><Translate en="Total Absent" bn="মোট অনুপস্থিতি" /></TCell>

                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {report?.attendanceResults?.map((report, i) => (
                                                <TableRow key={i} hover>
                                                    <TCell align="center">{i + 1}</TCell>
                                                    <TCell>{report.student._id}</TCell>
                                                    <TCell>{report.student.name}</TCell>
                                                    <TCell align="center">{report.total_present}</TCell>
                                                    <TCell align="center">{report.total_absent}</TCell>
                                                </TableRow>
                                            ))}

                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    component="div"
                                    count={totalData}
                                    page={page}
                                    onPageChange={(e, newPage) => {
                                        setPage(newPage);
                                    }}
                                    rowsPerPage={limit}
                                    onRowsPerPageChange={(e) => {
                                        setLimit(parseInt(e.target.value, 10));
                                        setPage(0);
                                    }}
                                />
                            </>
                }
            </Paper>

        </Page>
    )
}

export default AttendanceReport;