export const courses = [
    {
        id: 1,
        name: "২য় বেসিক সিনেমা ক্যামেরা অপারেশন এন্ড লাইটিং টেকনিকস কোর্স",
        duration: "12 months",
        from_date: "12-01-2023",
        to_date: "12-01-2024",
        fees: 12000
    },
    {
        id: 2,
        name: "৫ম চিত্রনাট্য লিখন প্রশিক্ষণ কোর্স",
        duration: "12 months",
        from_date: "12-01-2023",
        to_date: "12-01-2024",
        fees: 12000
    },
    {
        id: 3,
        name: "৬ষ্ঠ চলচ্চিত্র ও টেলিভিশন অভিনয় প্রশিক্ষণ কোর্স",
        duration: "12 months",
        from_date: "12-01-2023",
        to_date: "12-01-2024",
        fees: 12000
    },
    {
        id: 4,
        name: "৪র্থ চিত্রনাট্য লিখন প্রশিক্ষণ কোর্স (০১ নভেম্বর ২০২০ - ০৩ ডিসেম্বর ২০২০)",
        duration: "4 month",
        from_date: "12-01-2023",
        to_date: "12-02-2023",
        fees: 12000
    },
    {
        id: 5,
        name: "১ম চলচ্চিত্র অনুধাবন প্রশিক্ষণ কোর্স (১৩ ডিসেম্বর ২০২০ - ২১ জানুয়ারি ২০২১)",
        duration: "3 month",
        from_date: "12-01-2023",
        to_date: "12-02-2023",
        fees: 12000
    }
];