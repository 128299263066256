import Page from "../../Layout/Page";
import { NavLink, useParams } from "react-router-dom";
import { Avatar, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, } from "@mui/material";
import PageHeader from "../../Layout/PageHeader";
import TRow from "../../components/Table/TRow";
import useStudent from "../../hooks/student/useStudent";
import Loading from "../../Layout/Loading";
import { blueGrey, grey } from "@mui/material/colors";
import React, { useRef } from 'react'
import { IoMdPrint } from "react-icons/io";
import { CgExternal } from "react-icons/cg";
import { BsFileEarmarkText } from "react-icons/bs";
import { useReactToPrint } from 'react-to-print';
import Translate from "../../components/Translate/Translate";
import TCell from "../../components/Table/TCell";

const StudentDetails = () => {
    const { id } = useParams();
    const { data, isLoading } = useStudent(id);
    const student = data?.response_data;
    const componentRef = useRef();

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: `Student Details of ${student?.name_en}`,
    });

    return (
        <Page title={"Student Details"}>
            <PageHeader titleEn="Student Details" titleBn="শিক্ষার্থীর বিস্তারিত"
                link={{ to: '/students/manage', textEn: "Manage Student", textBn: "শিক্ষার্থী ব্যবস্থাপনা" }} />

            {isLoading ? <Loading /> :
                <Stack component={Paper} px={{ md: 4 }} sx={{ py: 2, maxWidth: 900, margin: '0 auto' }} className="rad-grad" >
                    <div style={{ width: '100%', display: 'inline-flex', justifyContent: 'flex-end' }}>
                        <IoMdPrint onClick={handlePrint} size={25} color="gray" cursor="pointer" />
                    </div>
                    <Stack ref={componentRef} p={{ md: 4, xs: 2 }} >
                        {student.user.photo_url ?
                            <Avatar
                                src={student.user.photo_url}
                                alt="Profile"
                                sx={{
                                    width: 120,
                                    height: 120,
                                    display: "flex",
                                    margin: "0 auto"
                                }}
                            /> :
                            <Avatar sx={{
                                width: 120,
                                height: 120,
                                display: "flex",
                                margin: "0 auto", bgcolor: blueGrey[200], fontSize: '2.5rem', fontWeight: 'bold'
                            }}>{student.user.name.charAt(0)}</Avatar>}


                        <TRow data={[
                            { label: { en: "Student ID", bn: "শিক্ষার্থীর আইডি" }, value: student?.user?._id },
                            { label: { en: "Student Full Name", bn: "শিক্ষার্থীর পুরো নাম" }, value: student.name_en },
                            { label: { en: "Student Full Name(Bangla)", bn: "শিক্ষার্থীর পুরো নাম(বাংলা)" }, value: student.name_bn },
                            { label: { en: "Father Name", bn: "পিতার নাম" }, value: student.father_name_en },
                            { label: { en: "Father Name(Bangla)", bn: "পিতার নাম(বাংলা)" }, value: student.father_name_bn },
                            { label: { en: "Mother Name", bn: "মাতার নাম" }, value: student.mother_name_en },
                            { label: { en: "Mother Name(Bangla)", bn: "মাতার নাম(বাংলা)" }, value: student.mother_name_bn },
                            { label: { en: "Gender", bn: "লিঙ্গ" }, value: student.gender },
                            { label: { en: "Date of Birth", bn: "জন্ম তারিখ" }, value: student.dob },
                            { label: { en: "Email", bn: "ই-মেইল" }, value: student.user.email },
                            { label: { en: "Phone No.", bn: "ফোন নাম্বার" }, value: student.user.phone },
                            { label: { en: "NID No.", bn: "জাতীয় পরিচয়পত্র নাম্বার" }, value: student.nid },
                            { label: { en: "Nationality", bn: "জাতীয়তা" }, value: student.nationality },
                            { label: { en: "City", bn: "শহর" }, value: student.city },
                            { label: { en: "Present Address", bn: "বর্তমান ঠিকানা" }, value: student.present_address },
                            { label: { en: "Permanent Address", bn: "স্থায়ী ঠিকানা" }, value: student.permanent_address },
                            { label: { en: "Last Educational Qualification", bn: "সর্বশেষ শিক্ষাগত যোগ্যতা" }, value: student.qualification },
                            {
                                label: { en: "Qualification File", bn: "শিক্ষাগত সনদ" }, value: (<Stack direction="row" alignItems="center" gap={1} >
                                    <BsFileEarmarkText size={18} />
                                    <a href={student.qualification_file} target="_blank" download rel="noreferrer">Attachment</a>
                                </Stack>)
                            }
                        ]} />


                        {student.enrolled_courses?.length > 0 && <TableContainer sx={{ mt: 4, borderRadius: 1, }}>
                            <TableCell sx={{ fontSize: '1.1rem', fontWeight: 'bold', border: 0, px: 0, py: 1 }}><Translate en="Course Enrollemnt Information:" bn="কোর্স তালিকাভুক্তি তথ্যাবলীঃ" /></TableCell>
                            <Table sx={{ minWidth: 650, borderRadius: 1, border: `1px solid ${grey[100]}` }} size="medium">
                                <TableHead sx={{ bgcolor: grey[100] }}>
                                    <TableRow>
                                        <TCell bold><Translate en="Course Name" bn="কোর্সের নাম" /></TCell>
                                        <TCell bold> <Translate en="Batch Name" bn="ব্যাচের নাম" /> </TCell>
                                        <TCell bold><Translate en="Semester" bn="সেমিস্টার" /> </TCell>
                                        <TCell bold align="center"><Translate en="Enrollment ID" bn="তালিকাভুক্তি আইডি" /> </TCell>
                                        <TCell bold align="center"><Translate en="Enrollment Date & Time" bn="তালিকাভুক্তির সময় ও তারিখ" /> </TCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {student.enrolled_courses?.map((course, i) => (
                                        <TableRow key={i}>
                                            <TCell>{course?.course}</TCell>
                                            <TCell>{course?.batch}</TCell>
                                            <TCell>{`${course?.semester_number ? `Semester ${course?.semester_number}` : 'None'}`}</TCell>
                                            <TCell align="center"> {course?.enrollment_id}  </TCell>
                                            <TCell align="center">{course?.enrolled_at}</TCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>}

                    </Stack>
                </Stack>}
        </Page>
    );
}

export default StudentDetails;