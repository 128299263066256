import { useQuery } from '@tanstack/react-query';
import { getNotices, getNoticesSelf } from '../../api/fetcherFn/notice';
import usePermissions from '../permission/usePermissions';
import { ROUTES } from '../../Pages/Settings/permission_data';

const useNotices = (page = 0, limit = 0, title) => {
    const { check } = usePermissions();
    return useQuery(['notices', page + 1, limit, title],
        check(ROUTES.NOTICE.MANAGE.M) ? () => getNotices(page + 1, limit, title) : () => getNoticesSelf(page + 1, limit, title), {
        keepPreviousData: true
    });
};

export default useNotices;