import Page from "../../Layout/Page";
import {
    IconButton,
    Paper,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
} from "@mui/material";
import { grey, } from "@mui/material/colors";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as Yup from "yup";
import { useAuth } from "../../hooks/useAuth";
import PageHeader from "../../Layout/PageHeader";
import FilterForm from "../../components/Filter/FilterForm";
import TCell from "../../components/Table/TCell";
import { BiDotsVerticalRounded } from "react-icons/bi";
import ActionMenu from "../../components/Menus/ActionMenu";
import TextInput from "../../components/Fields/TextInput";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { deleteCourse, getCourses } from "../../api/fetcherFn/course";
import Loading from "../../Layout/Loading";
import DeleteDialog from "../../components/HelperComponents/DeleteDialog";
import usePermissions from "../../hooks/permission/usePermissions";
import { ROUTES } from "../Settings/permission_data";

const validationSchema = Yup.object().shape({
    search: Yup.string(),
});

export default function CoursesManage() {
    const { check } = usePermissions();
    const queryClient = useQueryClient();
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const [data, setData] = useState({});
    const [anchorEl, setAnchorEl] = useState(null);
    const [deleteDialog, setDeleteDialog] = useState(false);
    const open = Boolean(anchorEl);
    const { lang } = useAuth();

    const { control, handleSubmit, watch } = useForm({ resolver: yupResolver(validationSchema), mode: "onChange", });
    const name = watch('search');
    const { data: courses, isLoading } = useQuery(['courses', page + 1, limit, name], () => getCourses(page + 1, limit, name), {
        keepPreviousData: true
    });
    const { mutateAsync } = useMutation(() => deleteCourse(data.id), { onSuccess: () => { queryClient.invalidateQueries(['courses']); } });


    const handleClick = (event, data) => {
        setAnchorEl(event.currentTarget);
        setData(data)
    };

    const onSubmit = (data) => { };

    return (
        <Page title={"Course Manage"}>
            <PageHeader titleEn="Manage Courses" titleBn="কোর্স ব্যবস্থাপনা" />

            <Paper elevation={0} sx={{ px: 3, py: 2, minHeight: '75vh' }}>
                <FilterForm handleSubmit={handleSubmit(onSubmit)} button={check(ROUTES.COURSE.ADD)} navigate='/courses/add'>
                    <TextInput
                        name="search"
                        control={control}
                        // label={{ en: "Search", bn: "অনুসন্ধান করুন" }}
                        placeholder="Search by Name..." />
                </FilterForm>


                {isLoading ? <Loading /> :
                    <>
                        <TableContainer sx={{ mt: 2, borderRadius: 1, border: `1px solid ${grey[100]}` }}>
                            <Table sx={{ minWidth: 650 }} size={"medium"}>
                                <TableHead sx={{ bgcolor: grey[100] }}>
                                    <TableRow>
                                        <TCell bold align="center">#</TCell>
                                        <TCell bold>{lang === 'en' ? "Course Name" : "কোর্সের নাম"}</TCell>
                                        <TCell bold>{lang === 'en' ? "Type" : "ধরন"}</TCell>
                                        <TCell bold>{lang === 'en' ? "Duration" : "সময়কাল"}</TCell>
                                        <TCell bold align="center">{lang === 'en' ? "Total Credit" : "সর্বমোট ক্রেডিট"}</TCell>
                                        <TCell bold align="center">{lang === 'en' ? "Course Fees" : "কোর্সের ফি"}</TCell>
                                        <TCell bold align="center">{lang === "en" ? "Action" : "অ্যাকশন"}</TCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {!courses.success || courses.response_data.data.length === 0 ?
                                        <TableRow sx={{ textAlign: "center" }}><TCell align="center" bold colSpan={7}>No record found.</TCell></TableRow> :
                                        courses.response_data.data.map((row, index) => (
                                            <TableRow key={index} hover>
                                                <TCell align="center">{index + 1}</TCell>
                                                <TCell >{row.name}</TCell>
                                                <TCell >{row.type}</TCell>
                                                <TCell >{row.duration} months</TCell>
                                                <TCell align="center">{row.total_credit}</TCell>
                                                <TCell align="center">{row.course_fee}</TCell>
                                                <TCell align="center"><IconButton
                                                    onClick={(e) => handleClick(e, row)}><BiDotsVerticalRounded /></IconButton></TCell>
                                            </TableRow>
                                        ))}

                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            component="div"
                            count={courses?.response_data?.meta.data_found}
                            page={page}
                            onPageChange={(e, newPage) => {
                                setPage(newPage);
                            }}
                            rowsPerPage={limit}
                            onRowsPerPageChange={(e) => {
                                setLimit(parseInt(e.target.value, 10));
                                setPage(0);
                            }}
                        />
                    </>
                }
            </Paper>

            <ActionMenu
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
                open={open}
                viewUrl={check(ROUTES.COURSE.MANAGE.V) && `/courses/details/${data.id}`}
                editUrl={check(ROUTES.COURSE.MANAGE.E) && `/courses/edit/${data.id}`}
                handleDeleteDialog={check(ROUTES.COURSE.MANAGE.D) && (() => setDeleteDialog(true))} />

            {deleteDialog && <DeleteDialog handleDelete={async () => await mutateAsync(data.id)} dialog={deleteDialog} setDialog={setDeleteDialog} />}
        </Page>
    );
}