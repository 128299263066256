import { Dialog, DialogContent, DialogTitle, Divider, Table, TableBody, TableContainer, TableHead, TableRow, Typography, } from "@mui/material";
import Translate from "../Translate/Translate";
import TCell from "../Table/TCell";
import { grey } from "@mui/material/colors";
import TRow from "../Table/TRow";
import { ROUTES } from "../../Pages/Settings/permission_data";
import usePermissions from "../../hooks/permission/usePermissions";


const ResultViewDialog = ({ openDialog, setOpenDialog, metadata }) => {
    const { check } = usePermissions();
    const { title, data } = metadata;

    const _data = [
        { id: 1, label: { en: "Course Name", bn: "কোর্সের নাম" }, value: data.course.name },
        { id: 2, label: { en: "Batch Name", bn: "ব্যাচের নাম" }, value: data.batch.name },
        { id: 3, label: { en: "Semester", bn: "সেমিস্টার" }, value: data.semester_number || "" },
        { id: 4, label: { en: "Result Published", bn: "পাবলিশের তারিখ" }, value: data.published_at },
        { id: 4, label: { en: "GPA", bn: "প্রাপ্ত জিপিএ" }, value: data.gpa }
    ];

    return (
        <Dialog onClose={() => setOpenDialog(!openDialog)} open={openDialog} maxWidth={"xl"} scroll="paper">
            <DialogTitle><Translate en={title.en} bn={title.bn} /></DialogTitle>
            <Divider />
            <DialogContent sx={{ pt: 3, pb: 6 }}>
                <TRow data={_data} />


                <TableContainer>
                    <Table sx={{ minWidth: 650, mt: 2, }} size={"small"} >
                        <TableHead>
                            <TableRow>
                                <TCell bold sx={{ border: `1px solid ${grey[200]}`, fontWeight: 'bold' }}><Translate en="Subject Name" bn="বিষয়" /> </TCell>
                                <TCell bold sx={{ border: `1px solid ${grey[200]}`, fontWeight: 'bold' }}><Translate en="Credit" bn="ক্রেডিট" />  </TCell>
                                <TCell bold sx={{ border: `1px solid ${grey[200]}`, fontWeight: 'bold' }}><Translate en="Marks" bn="মার্কস" />  </TCell>
                                <TCell bold sx={{ border: `1px solid ${grey[200]}`, fontWeight: 'bold' }}><Translate en="Grade" bn="গ্রেড" /></TCell>
                                <TCell bold sx={{ border: `1px solid ${grey[200]}`, fontWeight: 'bold' }}><Translate en="Point" bn="পয়েন্ট" /></TCell>
                            </TableRow>
                        </TableHead>
                        <TableBody  >
                            {data.results?.map((result, i) => (
                                <TableRow key={i}>
                                    <TCell bold sx={{ border: `1px solid ${grey[200]}` }}>{result.subject.name} ({result.subject.subject_code})</TCell>
                                    <TCell bold sx={{ border: `1px solid ${grey[200]}` }}>{result.subject.credit}</TCell>
                                    <TCell bold sx={{ border: `1px solid ${grey[200]}` }}>{result.total_marks}</TCell>
                                    <TCell bold sx={{ border: `1px solid ${grey[200]}` }}>{result.grade}</TCell>
                                    <TCell bold sx={{ border: `1px solid ${grey[200]}` }}>{result.point}</TCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

                {data?.preparedBy && check(ROUTES.RESULT.FINAL.M) && <Typography textAlign="right" mt={2}>Result prepared by <span style={{ color: 'green', fontWeight: 'bold' }}>{data?.preparedBy?.name}</span></Typography>}

            </DialogContent>
        </Dialog >
    );
};

export default ResultViewDialog;