import Page from "../../Layout/Page";
import {
    IconButton,
    Paper,
    Table, TableBody, TableContainer, TableHead, TablePagination, TableRow,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { grey, } from "@mui/material/colors";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { useState } from "react";
import * as Yup from "yup";
import { useAuth } from "../../hooks/useAuth";
import PageHeader from "../../Layout/PageHeader";
import FilterForm from "../../components/Filter/FilterForm";
import SelectField from "../../components/Fields/SelectField";
import TCell from "../../components/Table/TCell";
import ActionMenu from "../../components/Menus/ActionMenu";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import DeleteDialog from "../../components/HelperComponents/DeleteDialog";
import { deleteTeacherAssigned } from "../../api/fetcherFn/teacher";
import useGetAllCourse from "../../hooks/course/useGetAllCourse";
import Loading from "../../Layout/Loading";
import useGetTeacherCourses from "../../hooks/teacher/useGetTeacherCourses";
import usePermissions from "../../hooks/permission/usePermissions";
import { ROUTES } from "../Settings/permission_data";

const validationSchema = Yup.object().shape({
    course: Yup.number(),
    batch: Yup.number(),
});

const AssignedCourseManage = () => {
    const { check } = usePermissions();
    const queryClient = useQueryClient();
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const [data, setData] = useState({});
    const [anchorEl, setAnchorEl] = useState(null);
    const [deleteDialog, setDeleteDialog] = useState(false);
    const open = Boolean(anchorEl);
    const { lang } = useAuth();

    const { control, handleSubmit, watch, setValue } = useForm({ resolver: yupResolver(validationSchema), mode: "onChange", });
    const course = watch('course');
    const batch = watch('batch');

    const allCourse = useGetAllCourse();
    const { data: teacherCourses, isLoading } = useGetTeacherCourses(page, limit, course, batch);
    const { mutateAsync } = useMutation(() => deleteTeacherAssigned(data.id), { onSuccess: () => { queryClient.invalidateQueries(['teacher-courses']); } });


    const handleClick = (event, data) => {
        setAnchorEl(event.currentTarget);
        setData(data)
    };

    const onSubmit = (data) => { };

    const courses = allCourse?.data?.response_data;
    const batches = courses && courses?.find(c => c.id === course)?.batches;

    return (
        <Page title={"Manage Assigned Course"}>
            <PageHeader titleEn="Manage Assigned Course" titleBn="বরাদ্দকৃত কোর্স ব্যবস্থাপনা"
                link={{ to: '/teachers/manage', textEn: "Manage Teachers", textBn: "শিক্ষক ব্যবস্থাপনা" }} />

            <Paper elevation={0} sx={{ px: 3, py: 2, minHeight: '75vh' }}>
                <FilterForm handleSubmit={handleSubmit(onSubmit)} button={check(ROUTES.TEACHER.COURSE.ASSIGN)} navigate="/teachers/assign-course" btnText={{ en: 'Assign Course', bn: "কোর্স বরাদ্দ" }}>
                    <SelectField
                        name="course"
                        control={control}
                        label={{ en: "Select Course", bn: "কোর্স নির্বাচন" }}
                        data={courses}
                        handelChange={() => {
                            setValue('batch_id', null);
                        }} />
                    <SelectField
                        name="batch"
                        control={control}
                        uniqueKey={course}
                        label={{ en: "Select Batch", bn: "ব্যাচ নির্বাচন" }}
                        data={batches} />

                </FilterForm>

                {isLoading ? <Loading /> :
                    <>
                        <TableContainer sx={{ mt: 2, borderRadius: 1, border: `1px solid ${grey[100]}` }}>
                            <Table sx={{ minWidth: 650 }} size={"medium"}>
                                <TableHead sx={{ bgcolor: grey[100] }}>
                                    <TableRow>
                                        <TCell bold align="center">#</TCell>
                                        <TCell bold>{lang === "en" ? "Course Name" : "কোর্সের নাম"}</TCell>
                                        <TCell bold>{lang === "en" ? "Batch" : "ব্যাচ"}</TCell>
                                        <TCell bold>{lang === "en" ? "Semester" : "সেমিস্টার"}</TCell>
                                        <TCell bold align="center">{lang === "en" ? "Assigned Teachers" : "নিবন্ধিত শিক্ষকসমূহ"}</TCell>
                                        <TCell bold>{lang === "en" ? "Assigned at" : "নিবন্ধনের সময়"}</TCell>
                                        <TCell bold align="center">{lang === "en" ? "Action" : "অ্যাকশন"}</TCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {!teacherCourses.success || teacherCourses.response_data.data.length === 0 ?
                                        <TableRow sx={{ textAlign: "center" }}><TCell align="center" bold colSpan={7}>No record found.</TCell></TableRow> :
                                        teacherCourses.response_data.data?.map((row, index) => (
                                            <TableRow key={index} hover>
                                                <TCell align="center">{index + 1}</TCell>
                                                <TCell>{row.course.name}</TCell>
                                                <TCell>{row.batch.name}</TCell>
                                                <TCell>{row.semester_number ? `Semester ${row.semester_number}` : "None"}</TCell>
                                                <TCell align="center">{row.teachers.length}</TCell>
                                                <TCell>{row.assigned_at}</TCell>
                                                <TCell align="center"><IconButton
                                                    onClick={(e) => handleClick(e, row)}><BiDotsVerticalRounded /></IconButton></TCell>
                                            </TableRow>
                                        ))}

                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            component="div"
                            count={teacherCourses?.response_data?.meta.data_found}
                            page={page}
                            onPageChange={(e, newPage) => {
                                setPage(newPage);
                            }}
                            rowsPerPage={limit}
                            onRowsPerPageChange={(e) => {
                                setLimit(parseInt(e.target.value, 10));
                                setPage(0);
                            }}
                        />
                    </>}
            </Paper>

            <ActionMenu
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
                open={open}
                viewUrl={check(ROUTES.TEACHER.COURSE.MANAGE.V) && `/teachers/assigned-course-manage/details/${data.id}`}
                editUrl={check(ROUTES.TEACHER.COURSE.MANAGE.E) && `/teachers/assign-course/edit/${data.id}`}
                handleDeleteDialog={check(ROUTES.TEACHER.COURSE.MANAGE.D) && (() => setDeleteDialog(true))} />

            {deleteDialog && <DeleteDialog handleDelete={async () => await mutateAsync(data.id)} dialog={deleteDialog} setDialog={setDeleteDialog} />}
        </Page>
    )
}

export default AssignedCourseManage;