import Page from "../../Layout/Page";
import { Stack } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as Yup from "yup";
import PageHeader from "../../Layout/PageHeader";
import AddForm from "../../components/Filter/AddForm";
import TextInput from "../../components/Fields/TextInput";
import SelectField from "../../components/Fields/SelectField";
import { useNavigate, useParams } from "react-router-dom";
import useRoles from "../../hooks/settings/useRoles";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getUser, updateUser } from "../../api/fetcherFn/settings";
import { toast } from "react-hot-toast";
import Loading from "../../Layout/Loading";
import PasswordField from "../../components/Fields/PasswordField";
import PhotoAvatarUpload from "../../components/Fields/PhotoAvatarUpload";
import ToggleSwitch from "../../components/Fields/ToggleSwitch";
import { PNG_JPG_JPEG_GIF } from "../../Constants/FileTypes";

const validationSchema = Yup.object().shape({
    name: Yup.string().required("Required."),
    role_id: Yup.string().nullable().required("Required."),
    phone: Yup.string().required("Required.").max(12, "Invalid"),
    password: Yup.string().when('toggle', {
        is: true,
        then: Yup.string().required("Required.").min(6, "Must be at least 6 characters."),
        otherwise: Yup.string(),
    }),
    confirm_password: Yup.string().when('toggle', {
        is: true,
        then: Yup.string().required("Required."),
        otherwise: Yup.string(),
    }),

});



export default function StaffEdit() {
    const queryClient = useQueryClient();
    const params = useParams();
    const id = parseInt(params.id, 10);
    const navigate = useNavigate();
    const { data: user, isLoading } = useQuery(['user', id], () => getUser(id), { enabled: !!id });
    const { control, handleSubmit, reset, watch, unregister } = useForm({ resolver: yupResolver(validationSchema), mode: "onChange" });
    const { roles, roleLoading } = useRoles();

    const toggle = watch('toggle');

    const update = useMutation(data => updateUser(id, data), {
        onSuccess: (data) => {
            if (data.success) {
                toast.success(data.message);
                queryClient.invalidateQueries('user');
                navigate('/settings/manage-staff');
            } else {
                toast.error(data.message);
            }
        },
        onError: (err) => {
            toast.error(err.message);
        }
    });

    const onSubmit = async (data) => {
        const { password, confirm_password, toggle, ...restData } = data;
        if (toggle) {
            if (password !== confirm_password) return toast.error("Password Not Match!");
        }
        const formData = new FormData();
        if (!toggle) {
            for (const key in { password: undefined, ...restData }) {
                formData.append(key, restData[key]);
            }
        } else {
            const _data = { password, ...restData };
            for (const key in _data) {
                formData.append(key, _data[key]);
            }
        }

        await update.mutateAsync(formData);
    }

    const filteredRoles = roles?.response_data?.filter(role => !/^student$|^teacher$/i.test(role.name));


    return (
        <Page title='Edit Staff'>
            <PageHeader titleEn={`Edit Staff`} titleBn={`কর্মীর তথ্য পরিবর্তন`}
                link={{ to: '/settings/manage-staff', textEn: 'Manage Staff', textBn: 'কর্মী ব্যবস্থাপনা' }} />


            {(id && isLoading) ? <Loading /> : <AddForm handleSubmit={handleSubmit(onSubmit)} loading={update.isLoading} reset={reset}>
                <PhotoAvatarUpload
                    name="photo"
                    control={control}
                    imageUrl={user?.response_data?.photo_url}
                    fileType={PNG_JPG_JPEG_GIF} />

                <TextInput
                    name="name"
                    control={control}
                    value={user?.response_data?.name}
                    label={{ en: "Staff Name", bn: "কর্মীর নাম" }}
                    errorMsg fontBold={false} required />
                <TextInput
                    name="phone"
                    type="tel"
                    value={user?.response_data?.phone}
                    control={control}
                    label={{ en: "Mobile Number", bn: "মোবাইল নাম্বার" }}
                    errorMsg fontBold={false} required />

                <ToggleSwitch
                    name='toggle'
                    control={control}
                    label={{ en: "Update password!", bn: "পাসওয়ার্ড আপডেট করুন!" }}
                    unregister={unregister}
                    unregisterFields={['password']} />


                {toggle &&
                    <Stack gap={2}>
                        {/* <TextInput
                            name="email"
                            type="email"
                            value={user?.response_data?.email}
                            control={control}
                            label={{ en: "Email", bn: "ইমেইল" }}
                            errorMsg fontBold={false} required /> */}

                        <PasswordField
                            name="password"
                            type="password"
                            control={control}
                            label={{ en: "Password", bn: "পাসওয়ার্ড" }}
                            errorMsg fontBold={false} required />
                        <PasswordField
                            name="confirm_password"
                            type="password"
                            control={control}
                            label={{ en: "Confirm Password", bn: "কনফার্ম পাসওয়ার্ড" }}
                            errorMsg fontBold={false} required />

                    </Stack>
                }

                <SelectField
                    name="role_id"
                    control={control}
                    subLabel={false}
                    isLoading={roleLoading}
                    defaultValue={user?.response_data?.role.id}
                    data={filteredRoles}
                    label={{ en: "Select Role", bn: "ভূমিকা নির্বাচন" }}
                    errorMsg fontBold={false} required />

            </AddForm>}


        </Page>
    )
}