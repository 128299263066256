import Page from "../../Layout/Page";
import {
    Paper,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
} from "@mui/material";
import { grey, } from "@mui/material/colors";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as Yup from "yup";
import Translate from "../../components/Translate/Translate";
import PageHeader from "../../Layout/PageHeader";
import TCell from "../../components/Table/TCell";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { VscSaveAs } from "react-icons/vsc";
import { updateCGPA } from "../../api/fetcherFn/cgpaConfig";
import Loading from "../../Layout/Loading";
import { toast } from "react-hot-toast";
import useCGPA from "../../hooks/result/useCGPA";

const validationSchema = Yup.object().shape({
    data: Yup
        .array()
        .of(
            Yup.object().shape({
                point: Yup.number().required("Required."),
            })
        ),
});


const CGPACalculator = () => {
    const queryClient = useQueryClient();
    const [id, setId] = useState();


    const { control, watch } = useForm({ resolver: yupResolver(validationSchema), mode: "onChange", });
    const { data: cgpaData, isLoading } = useCGPA();
    const update = useMutation((data) => updateCGPA(id, data), {
        onSuccess: (data) => {
            if (data.success) {
                toast.success(data.message);
                queryClient.invalidateQueries(['cgpa-configs']);
            } else {
                toast.success(data.message);
            }
        },
        onError: (err) => {
            toast.success(err.message);
        }
    });

    const handleRowUpdate = async (row, i) => {
        setId(row.id);
        const point = watch(`data.${i}.point`);
        await update.mutateAsync({ point });
    };

    return (
        <Page title={"CGPA Configuration"}>
            <PageHeader titleEn="CGPA Configuration" titleBn="সিজিপিএ কনফিগারেশন" />

            <Paper elevation={0} sx={{ px: 3, py: 3, minHeight: '75vh' }} className="rad-grad">
                {isLoading ? <Loading /> :
                    <TableContainer sx={{ mt: 2, borderRadius: 1, border: `1px solid ${grey[100]}` }}>
                        <Table sx={{ minWidth: 650 }} size={"medium"}>
                            <TableHead sx={{ bgcolor: `${grey[100]}` }}>
                                <TableRow>
                                    <TCell bold ><Translate en="Number Range" bn="নাম্বার" /></TCell>
                                    <TCell bold ><Translate en="Grade" bn="গ্রেড" /></TCell>
                                    <TCell bold><Translate en="Point" bn="পয়েন্ট" /></TCell>
                                    <TCell bold align="center"><Translate en="Action" bn="অ্যাকশন" /></TCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {cgpaData?.response_data?.map((row, i) => (
                                    <TableRow hover key={i}>
                                        <TCell >{row.number_range}</TCell>
                                        <TCell >{row.grade}</TCell>
                                        <TCell >
                                            <Controller
                                                name={`data[${i}].point`}
                                                control={control}
                                                defaultValue={"" || row.point}
                                                render={({ field }) => (
                                                    <TextField {...field} type="number" inputProps={{ steps: 0.01 }} size="small" sx={{ width: 80 }} />
                                                )}
                                            />
                                        </TCell>
                                        <TCell bold align="center">
                                            <VscSaveAs onClick={() => handleRowUpdate(row, i)} cursor="pointer" color="blue" size={21} title="Save/Update" />
                                        </TCell>
                                    </TableRow>
                                ))}

                            </TableBody>
                        </Table>
                    </TableContainer>
                }
            </Paper>

        </Page>
    );
}

export default CGPACalculator;