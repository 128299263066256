import { useQuery } from '@tanstack/react-query';
import { getExams, getExamsSelf } from '../../api/fetcherFn/exam';
import usePermissions from '../permission/usePermissions';
import { ROUTES } from '../../Pages/Settings/permission_data';

const useGetExams = (course, batch, exam = null, semester) => {
    const { check } = usePermissions();
    return useQuery(['exams', 1, 0, course, batch, exam, semester],
        check(ROUTES.EXAM.MANAGE.M) ? () => getExams(1, 0, course, batch, exam, semester) : () => getExamsSelf(1, 0, course, batch, exam, semester), {
        keepPreviousData: false, enabled: !!course && !!batch
    });
};

export default useGetExams;