import Page from "../../Layout/Page";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as Yup from "yup";
import PageHeader from "../../Layout/PageHeader";
import SelectField from "../../components/Fields/SelectField";
import AddForm from "../../components/Filter/AddForm";
import MultipleSelectField from "../../components/Fields/MultipleSelectField";
import { useMutation, } from "@tanstack/react-query";
import { NavLink, useNavigate, } from "react-router-dom";
import useGetAllCourse from "../../hooks/course/useGetAllCourse";
import useTeachers from "../../hooks/teacher/useTeachers";
import { assignTeacher, } from "../../api/fetcherFn/teacher";
import { toast } from "react-hot-toast";
import Loading from "../../Layout/Loading";
import { useState } from "react";
import { Paper, Stack } from "@mui/material";
import { MdWarning } from "react-icons/md";



const AssignCourse = () => {
    const navigate = useNavigate();
    const [message, setMessage] = useState(false);

    const allCourse = useGetAllCourse();

    const validationSchema = Yup.object().shape({
        course_id: Yup.number().nullable().required("Required."),
        batch_id: Yup.number().nullable().required("Required."),
        semester_number: Yup.number().when('course_id', (courseId, schema) => {
            const course = allCourse.data?.response_data?.find(c => c.id === courseId);
            return course?.semester_wise_subjects?.length > 0
                ? schema.nullable().transform(v => !isNaN(v) ? v : null).required('Required.')
                : schema.nullable().transform(v => !isNaN(v) ? v : null);
        }),
        teachers: Yup.array()
            .nullable()
            .required()
            .min(1, "Required"),
    });

    const { control, handleSubmit, watch, setValue } = useForm({ resolver: yupResolver(validationSchema), mode: "onChange", });
    const courseId = watch('course_id');


    const { data, isLoading: teacherLoading } = useTeachers();

    const add = useMutation(data => assignTeacher(data), {
        onSuccess: (data) => {
            if (data.success) {
                toast.success(data.message);
                navigate('/teachers/assigned-course-manage');
            } else if (!data.success) {
                setMessage(data.message);
                // toast.error(data.message);
            }
        },
        onError: (err) => {
            toast.error(err.message);
        }
    });



    const onSubmit = async (data) => {
        setMessage(false);
        data.teachers = data.teachers?.map(id => ({ id: id }));
        await add.mutateAsync(data);
        window.scrollTo(0, 0);
    }

    const courses = allCourse?.data?.response_data;
    const semesters = courses && courses?.find(c => c.id === courseId)?.semester_wise_subjects;
    const batches = courses && courses?.find(c => c.id === courseId)?.batches;
    const teachers = data?.response_data?.data.map(d => d.user);


    return (
        <Page title={"Assign Course"}>
            <PageHeader titleEn="Assign Course" titleBn="শিক্ষকদের কোর্স বরাদ্দকরণ"
                link={{ to: '/teachers/assigned-course-manage', textEn: "Assigned Course Management", textBn: "বরাদ্দকৃত কোর্স ব্যবস্থাপনা" }} />


            <Paper elevation={0} sx={{ minHeight: '75vh' }} className="rad-grad">
                {message &&
                    <Stack direction={"row"} py={3} color="orangered" alignItems={"center"} gap={1} justifyContent={"center"} ><MdWarning size={25} /> {message}
                        <NavLink to={`/teachers/assign-course/edit/${add.data?.response_data?.assigned_id}`} style={{ color: 'blue', fontWeight: "bold", textDecoration: "underline" }}>Update Existing</NavLink>
                    </Stack>}

                {teacherLoading ? <Loading /> : <AddForm gradient={false} handleSubmit={handleSubmit(onSubmit)} loading={add.isLoading}>

                    <SelectField
                        name="course_id"
                        control={control}
                        data={courses}
                        handelChange={() => {
                            setValue('batch_id', null);
                            setValue('subject_id', null);
                            setValue('semester_number', null);
                        }}
                        label={{ en: "Select Course", bn: "কোর্স নির্বাচন" }}
                        errorMsg fontBold={false} required
                        link={{ label: "Not found Course? Create one", to: "/courses/add" }} />
                    <SelectField
                        name="batch_id"
                        control={control}
                        uniqueKey={courseId}
                        data={batches}
                        label={{ en: "Select Batch", bn: "ব্যাচ নির্বাচন" }}
                        errorMsg fontBold={false} required
                        link={{ label: "Not found Batch? Create one", to: "/batch/add-batch" }} />

                    {semesters && <SelectField
                        name="semester_number"
                        control={control}
                        subLabel={false}
                        uniqueKey={courseId}
                        data={semesters?.map(option => ({ id: option.semester, name: `Semester ${option.semester}` }))}
                        label={{ en: "Select Semester", bn: "সেমিস্টার নির্বাচন" }}
                        errorMsg fontBold={false} required={semesters?.length > 0} />}


                    {teacherLoading ? <Loading size={30} /> : teachers && <MultipleSelectField
                        name="teachers"
                        control={control}
                        data={teachers}
                        subLabel={false}
                        subValue={"_id"}
                        search={true}
                        selectAllValue={true}
                        label={{ en: "Teachers List", bn: "শিক্ষকবৃন্দ" }}
                        errorMsg fontBold={false} required />}
                </AddForm>}
            </Paper>

        </Page>
    )
}

export default AssignCourse;