import Page from "../../Layout/Page";
import {
    Button, FormControlLabel, Paper, RadioGroup,
    Stack,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from "@mui/material";
import { grey, } from "@mui/material/colors";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as Yup from "yup";
import Translate from "../../components/Translate/Translate";
import PageHeader from "../../Layout/PageHeader";
import FilterForm from "../../components/Filter/FilterForm";
import SelectField from "../../components/Fields/SelectField";
import TextInput from "../../components/Fields/TextInput";
import CreateAttendanceDialog from "../../components/Dialogs/CreateAttendanceDialog";
import TCell from "../../components/Table/TCell";
import { AiOutlineSave } from "react-icons/ai";
import CheckboxRadio from "../../components/Fields/CheckboxRadio";
import useGetAllCourse from "../../hooks/course/useGetAllCourse";
import useClasses from "../../hooks/class/useClasses";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { exportToCSV, exportToExcel, getAttendances, getAttendancesSelf, updateAllAttendance, updateOneAttendance } from "../../api/fetcherFn/attendance";
import Loading from "../../Layout/Loading";
import { toast } from "react-hot-toast";
import usePermissions from "../../hooks/permission/usePermissions";
import { ROUTES } from "../Settings/permission_data";
import { getTodaysDate } from "../../utils/constants";
import AddNew from "../../components/Buttons/AddNew";
import { saveAs } from 'file-saver';

const schema = Yup.object().shape({
    attendance: Yup
        .array()
        .of(
            Yup.object().shape({
                student_id: Yup.number(),
                attendance: Yup.string().required("Required.").oneOf(['present', 'absent']),
                remarks: Yup.string(),
            })
        ),
});

const AttendanceManage = () => {
    const { check } = usePermissions();
    const queryClient = useQueryClient();
    const [openDialog, setOpenDialog] = useState(false);
    const [selectAllPresent, setSelectAllPresent] = useState(false);
    const [selectAllAbsent, setSelectAllAbsent] = useState(false);
    const [updateId, setUpdateId] = useState();

    const { control, handleSubmit, watch, setValue, } = useForm({ resolver: yupResolver(schema), });

    const courseId = watch('course_id');
    const batchId = watch('batch_id');
    const classId = watch('class_id');
    const semesterNum = watch('semester_number');
    const classDate = watch('date');
    const studentId = watch('student_id');

    const allCourse = useGetAllCourse();
    const { data: classesData } = useClasses(0, 0, courseId, batchId);

    const { data: attendanceData, isLoading } = useQuery(['attendances', 1, 0, courseId, batchId, classId, semesterNum, classDate, studentId],
        check(ROUTES.ATTENDANCE.MANAGE.M) ? () => getAttendances(1, 0, courseId, batchId, classId, semesterNum, classDate, studentId) : () => getAttendancesSelf(1, 0, courseId, batchId, classId, semesterNum, classDate, studentId), {
        enabled: !!courseId && !!batchId && !!classId
    })
    const attendances = attendanceData?.response_data?.data;

    const updateOne = useMutation((data) => updateOneAttendance(updateId, data), {
        onSuccess: (data) => {
            if (data.success) {
                toast.success(data.message);
                queryClient.invalidateQueries(['attendances', updateId]);
            } else {
                toast.error(data.message);
            }
        },
        onError: (err) => {
            toast.error(err.message);
        }
    })

    const updateAll = useMutation((data) => updateAllAttendance(data), {
        onSuccess: (data) => {
            if (data.success) {
                toast.success(data.message);
                queryClient.invalidateQueries(['attendances']);
            } else {
                toast.error(data.message);
            }
        },
        onError: (err) => {
            toast.error(err.message);
        }
    })


    const handleUpdateRow = async (attendance, i) => {
        setUpdateId(attendance.id);
        const _data = {
            course_id: attendance.course.id,
            batch_id: attendance.batch.id,
            class_id: attendance.class.id,
            semester_number: attendance.semester_number,
            subject_id: attendance.subject.id,
            attendance: watch(`attendance.${i}.attendance`),
            remarks: watch(`attendance.${i}.remarks`)
        };

        await updateOne.mutateAsync(_data);

    };


    const handleSaveAttendance = async (data) => {
        const _data = {
            course_id: attendances[0]?.course.id,
            batch_id: attendances[0]?.batch.id,
            class_id: attendances[0]?.class.id,
            semester_number: attendances[0]?.semester_number || undefined,
            subject_id: attendances[0]?.subject.id,
            attendances: attendances?.map((att, i) => ({
                student_id: att.student.id,
                ...data.attendance[i],
            })),
        };

        await updateAll.mutateAsync(_data);
    };

    const handleSelectAllStatus = (status) => {
        setValue('attendance', attendances?.map((attendance) => ({
            student_id: attendance.student.id,
            attendance: status,
        })));
        if (status === 'present') {
            setSelectAllPresent(true);
            setSelectAllAbsent(false);
        } else {
            setSelectAllPresent(false);
            setSelectAllAbsent(true);
        }
    };

    const handleRadioChange = (e, i) => {
        const updatedAttendance = [...watch('attendance')];
        updatedAttendance[i].student_id = attendances[i]?.student.id;
        updatedAttendance[i].attendance = e.target.value;
        setValue('attendance', updatedAttendance);
        setSelectAllPresent(false);
        setSelectAllAbsent(false);
    }


    const exportToExcelFormat = async () => {
        try {
            const responseData = await exportToExcel();
            const filename = 'attendance_details.xlsx';
            saveAs(responseData, filename);
        } catch (err) {
            toast.error('Error downloading file:', err.message);
        }
    }
    const exportToCSVFormat = async () => {
        try {
            const responseData = await exportToCSV();
            const filename = 'attendance_details.csv';
            saveAs(responseData, filename);
        } catch (err) {
            toast.error('Error downloading file:', err.message);
        }
    }

    const courses = allCourse?.data?.response_data;
    const batches = courses && courses?.find(c => c.id === courseId)?.batches;
    const semesters = courses && courses?.find(c => c.id === courseId)?.semester_wise_subjects;
    const classes = classesData?.response_data?.data;


    return (
        <Page title={"Attendance Manage"}>
            <PageHeader titleEn="Manage Attendence" titleBn="উপস্থিতি ব্যবস্থাপনা" />

            <Stack direction={"row"} justifyContent={"flex-end"} alignItems={"center"} mb={1} gap={1.5}>
                <Button onClick={exportToExcelFormat} variant="outlined" size="small" sx={{ textTransform: 'unset', fontWeight: "bold" }}>Export to Excel</Button>
                <Button onClick={exportToCSVFormat} variant="outlined" size="small" sx={{ textTransform: 'unset', fontWeight: "bold" }} color="warning">Export to CSV</Button>
                {check(ROUTES.ATTENDANCE.ADD) && <AddNew btnSize="small" variant="contained" btnText={{ en: "Create New", bn: "উপস্থিতি তৈরি" }} handleClick={() => setOpenDialog(true)} />}
            </Stack>

            <Paper elevation={0} sx={{ px: 3, py: 2, mt: 2, minHeight: '75vh' }} className="rad-grad">
                {/* <FilterForm btnText={{ en: "Create New", bn: "উপস্থিতি তৈরি" }} button={check(ROUTES.ATTENDANCE.ADD)} handleBtn={() => setOpenDialog(true)}> */}
                <FilterForm button={false} >
                    <SelectField
                        name="course_id"
                        control={control}
                        data={courses}
                        handelChange={() => {
                            setValue('batch_id', null);
                            setValue('semester_number', null);
                            setValue('class_id', null);
                            setValue('date', "");
                        }}
                        label={{ en: "Select Course", bn: "কোর্স নির্বাচন" }} />
                    <SelectField
                        name="batch_id"
                        control={control}
                        data={batches}
                        uniqueKey={courseId}
                        label={{ en: "Select Batch", bn: "ব্যাচ নির্বাচন" }} />
                    <SelectField
                        name="semester_number"
                        control={control}
                        uniqueKey={courseId}
                        data={semesters?.map(option => ({ id: option.semester, name: `Semester ${option.semester}` }))}
                        label={{ en: "Select Semester", bn: "সেমিস্টার নির্বাচন" }} />
                    <SelectField
                        name="class_id"
                        control={control}
                        data={classes}
                        uniqueKey={courseId}
                        label={{ en: "Select Class", bn: "ক্লাস নির্বাচন" }} />

                    <TextInput
                        name="date"
                        type="date"
                        min={getTodaysDate}
                        control={control}
                        label={{ en: "Class Date", bn: "ক্লাস তারিখ" }} />

                    <SelectField
                        name="student_id"
                        control={control}
                        data={attendances?.map(attendance => (attendance.student))}
                        label={{ en: "Select Student", bn: "শিক্ষার্থী নির্বাচন" }} />

                </FilterForm>


                {!attendances?.length > 0 ?
                    <p style={{ textAlign: 'center' }}>Will show results after filtering.</p> :
                    isLoading ? <Loading /> :

                        <form onSubmit={handleSubmit(handleSaveAttendance)}  >
                            <Typography variant="body1" my={1} fontWeight="bold">Total student: {attendances?.length}</Typography>
                            <TableContainer sx={{ mt: 2, borderRadius: 1, border: `1px solid ${grey[100]}` }}>
                                <Table sx={{ minWidth: 650 }} size={"medium"}>
                                    <TableHead sx={{ bgcolor: grey[100] }}>
                                        <TableRow>
                                            <TCell bold align="center">#</TCell>
                                            <TCell bold><Translate en="Student ID" bn="শিক্ষার্থীর আইডি" /></TCell>
                                            <TCell bold><Translate en="Student Name" bn="শিক্ষার্থীর নাম" /></TCell>
                                            <TCell bold align="center"><Translate en="Attendance Status" bn="উপস্থিতি স্ট্যাটাস" /> <br />
                                                {check(ROUTES.ATTENDANCE.MANAGE.E, ROUTES.ATTENDANCE.UPDATE) && <RadioGroup row sx={{ justifyContent: 'center' }}>
                                                    <FormControlLabel
                                                        value="present"
                                                        control={
                                                            <CheckboxRadio
                                                                checked={selectAllPresent}
                                                                onChange={() => handleSelectAllStatus('present')}
                                                            // disabled={selectAllAbsent}  
                                                            />}
                                                        label="Present"
                                                    />
                                                    <FormControlLabel
                                                        value="absent"
                                                        control={<CheckboxRadio
                                                            checked={selectAllAbsent}
                                                            onChange={() => handleSelectAllStatus('absent')}
                                                        // disabled={selectAllPresent} 
                                                        />} label="Absent" />
                                                </RadioGroup>}
                                            </TCell>
                                            <TCell bold align="center"><Translate en="Remarks" bn="মন্তব্য" /></TCell>
                                            {check(ROUTES.ATTENDANCE.MANAGE.E) && <TCell bold align="center"><Translate en="Action" bn="অ্যাকশন" /></TCell>}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {attendances?.map((attendance, i) => (
                                            <TableRow key={i} hover>
                                                <TCell align="center">{i + 1}</TCell>
                                                <TCell>{attendance?.student?._id}</TCell>
                                                <TCell>{attendance?.student?.name}</TCell>
                                                <TCell align="center">
                                                    <Controller
                                                        name={`attendance[${i}].attendance`}
                                                        control={control}
                                                        defaultValue={"" || attendance.attendance}
                                                        render={({ field }) => (
                                                            <RadioGroup {...field} row sx={{ justifyContent: 'center' }}>
                                                                <FormControlLabel
                                                                    value="present"
                                                                    control={
                                                                        <CheckboxRadio
                                                                            disabled={!check(ROUTES.ATTENDANCE.MANAGE.E, ROUTES.ATTENDANCE.UPDATE)}
                                                                            checked={watch(`attendance.${i}.attendance`) === 'present'}
                                                                            onChange={(e) => handleRadioChange(e, i)}
                                                                        // disabled={selectAllAbsent}
                                                                        />}
                                                                    label="Present"
                                                                />
                                                                <FormControlLabel
                                                                    value="absent"
                                                                    control={
                                                                        <CheckboxRadio
                                                                            disabled={!check(ROUTES.ATTENDANCE.MANAGE.E, ROUTES.ATTENDANCE.UPDATE)}
                                                                            checked={watch(`attendance.${i}.attendance`) === 'absent'}
                                                                            onChange={(e) => handleRadioChange(e, i)}
                                                                        // disabled={selectAllPresent}
                                                                        />}
                                                                    label="Absent"
                                                                />
                                                            </RadioGroup>
                                                        )}
                                                    />
                                                </TCell>
                                                <TCell align="center">
                                                    <Controller
                                                        name={`attendance[${i}].remarks`}
                                                        control={control}
                                                        defaultValue={"" || attendance.remarks}
                                                        render={({ field }) => (
                                                            <TextField
                                                                {...field}
                                                                size="small"
                                                                inputProps={{ readOnly: !check(ROUTES.ATTENDANCE.MANAGE.E, ROUTES.ATTENDANCE.UPDATE) }} />
                                                        )}
                                                    />
                                                </TCell>
                                                {check(ROUTES.ATTENDANCE.MANAGE.E) && <TCell align="center">
                                                    <Button size="small" variant="outlined" sx={{ fontWeight: 'bold' }} onClick={() => handleUpdateRow(attendance, i)}><Translate en="Update" bn="আপডেট" /></Button>
                                                </TCell>}
                                            </TableRow>
                                        ))}

                                    </TableBody>
                                </Table>
                            </TableContainer>

                            {!studentId && check(ROUTES.ATTENDANCE.UPDATE) && <Stack justifyContent="flex-end" direction={{ xs: 'column', md: 'row' }} mt={2}>
                                <Button type="submit" variant={"contained"} color="info" size="small" >
                                    <Stack direction={"row"} gap={1} alignItems={"center"}>
                                        <AiOutlineSave size={18} />
                                        <Typography textTransform={"capitalize"}><Translate en="Update Attendance" bn="আপডেট করুন" /></Typography>
                                    </Stack>
                                </Button>
                            </Stack>}
                        </form>}
            </Paper>


            {openDialog && <CreateAttendanceDialog openDialog={openDialog} setOpenDialog={setOpenDialog} />}
        </Page >
    );
}

export default AttendanceManage;