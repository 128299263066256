import { toast } from "react-hot-toast";
import api from "../setup";

export const createResult = async (data) => {
    try {
        const response = await api.post('/api/result/create', data);
        return response.data;
    } catch (err) {
        toast.error(err.message);
    }
}
export const updateResult = async (id, data) => {
    try {
        const response = await api.patch(`/api/result/update/${id}`, data);
        return response.data;
    } catch (err) {
        toast.error(err.message);
    }
}

export const getResult = async (id) => {
    try {
        const response = await api.get(`/api/result/get/${id}`);
        return response.data;
    } catch (err) {
        toast.error(err.message);
    }
}
export const getResults = async (page, limit, course, batch, semester, exam) => {
    try {
        let response;
        if (course) { response = await api.get(`/api/result/manage?page=${page}&limit=${limit}&course=${course}`); }
        if (batch) { response = await api.get(`/api/result/manage?page=${page}&limit=${limit}&batch=${batch}`); }
        if (exam) { response = await api.get(`/api/result/manage?page=${page}&limit=${limit}&exam=${exam}`); }
        if (course && semester) { response = await api.get(`/api/result/manage?page=${page}&limit=${limit}&course=${course}&semester=${semester}`); }
        if (course && batch) { response = await api.get(`/api/result/manage?page=${page}&limit=${limit}&course=${course}&batch=${batch}`); }
        if (course && batch && semester) { response = await api.get(`/api/result/manage?page=${page}&limit=${limit}&course=${course}&batch=${batch}&semester=${semester}`); }
        if (course && batch && exam) { response = await api.get(`/api/result/manage?page=${page}&limit=${limit}&course=${course}&batch=${batch}&exam=${exam}`); }
        if (!course && !batch && !semester && !exam) { response = await api.get(`/api/result/manage?page=${page}&limit=${limit}`); }

        return response.data;
    } catch (err) {
        toast.error(err.message);
    }
}
export const getResultsSelf = async (page, limit, course, batch, semester, exam) => {
    try {
        let response;
        if (course) { response = await api.get(`/api/result/manage/self?page=${page}&limit=${limit}&course=${course}`); }
        if (batch) { response = await api.get(`/api/result/manage/self?page=${page}&limit=${limit}&batch=${batch}`); }
        if (exam) { response = await api.get(`/api/result/manage/self?page=${page}&limit=${limit}&exam=${exam}`); }
        if (course && semester) { response = await api.get(`/api/result/manage/self?page=${page}&limit=${limit}&course=${course}&semester=${semester}`); }
        if (course && batch) { response = await api.get(`/api/result/manage/self?page=${page}&limit=${limit}&course=${course}&batch=${batch}`); }
        if (course && batch && semester) { response = await api.get(`/api/result/manage/self?page=${page}&limit=${limit}&course=${course}&batch=${batch}&semester=${semester}`); }
        if (course && batch && exam) { response = await api.get(`/api/result/manage/self?page=${page}&limit=${limit}&course=${course}&batch=${batch}&exam=${exam}`); }
        if (!course && !batch && !semester && !exam) { response = await api.get(`/api/result/manage/self?page=${page}&limit=${limit}`); }

        return response.data;
    } catch (err) {
        toast.error(err.message);
    }
}
export const deleteResult = async (id) => {
    try {
        const response = await api.delete(`/api/result/delete/${id}`);
        return response.data;
    } catch (err) {
        toast.error(err.message);
    }
}

export const exportToExcel = async () => {
    try {
        const response = await api.get(`/api/result/export/excel`, {
            responseType: 'blob',
        });
        return response.data;
    } catch (err) {
        toast.error(err.message);
    }
}
export const exportToCSV = async () => {
    try {
        const response = await api.get(`/api/result/export/csv`, {
            responseType: 'blob',
        });
        return response.data;
    } catch (err) {
        toast.error(err.message);
    }
}


// Final Result 
export const publishResult = async (data) => {
    try {
        const response = await api.post('/api/final-result/publish', data);
        return response.data;
    } catch (err) {
        toast.error(err.message);
    }
}

export const getFinalResults = async (page, limit, course, batch, semester) => {
    try {
        let response;
        if (course) { response = await api.get(`/api/final-result/manage?page=${page}&limit=${limit}&course=${course}`); }
        if (batch) { response = await api.get(`/api/final-result/manage?page=${page}&limit=${limit}&batch=${batch}`); }
        if (course && semester) { response = await api.get(`/api/final-result/manage?page=${page}&limit=${limit}&course=${course}&semester=${semester}`); }
        if (course && batch) { response = await api.get(`/api/final-result/manage?page=${page}&limit=${limit}&course=${course}&batch=${batch}`); }
        if (course && batch && semester) { response = await api.get(`/api/final-result/manage?page=${page}&limit=${limit}&course=${course}&batch=${batch}&semester=${semester}`); }
        if (!course && !batch && !semester) { response = await api.get(`/api/final-result/manage?page=${page}&limit=${limit}`); }

        return response.data;
    } catch (err) {
        toast.error(err.message);
    }
}
export const getFinalResultsSelf = async (page, limit, course, batch, semester) => {
    try {
        let response;
        if (course) { response = await api.get(`/api/final-result/manage/self?page=${page}&limit=${limit}&course=${course}`); }
        if (batch) { response = await api.get(`/api/final-result/manage/self?page=${page}&limit=${limit}&batch=${batch}`); }
        if (course && semester) { response = await api.get(`/api/final-result/manage/self?page=${page}&limit=${limit}&course=${course}&semester=${semester}`); }
        if (course && batch) { response = await api.get(`/api/final-result/manage/self?page=${page}&limit=${limit}&course=${course}&batch=${batch}`); }
        if (course && batch && semester) { response = await api.get(`/api/final-result/manage/self?page=${page}&limit=${limit}&course=${course}&batch=${batch}&semester=${semester}`); }
        if (!course && !batch && !semester) { response = await api.get(`/api/final-result/manage/self?page=${page}&limit=${limit}`); }

        return response.data;
    } catch (err) {
        toast.error(err.message);
    }
}

export const getFinalResult = async (id) => {
    try {
        const response = await api.get(`/api/final-result/get/${id}`);
        return response.data;
    } catch (err) {
        toast.error(err.message);
    }
}

export const deleteFinalResult = async (id) => {
    try {
        const response = await api.delete(`/api/final-result/delete/${id}`);
        return response.data;
    } catch (err) {
        toast.error(err.message);
    }
}

export const updateFinalResult = async (id, data) => {
    try {
        const response = await api.patch(`/api/final-result/update/${id}`, data);
        return response.data;
    } catch (err) {
        toast.error(err.message);
    }
}

export const resultCheck = async (data) => {
    try {
        const response = await api.post('/api/final-result/check', data);
        return response.data;
    } catch (err) {
        toast.error(err.message);
    }
}

export const exportSemesterToExcel = async () => {
    try {
        const response = await api.get(`/api/final-result/export/excel`, {
            responseType: 'blob',
        });
        return response.data;
    } catch (err) {
        toast.error(err.message);
    }
}
export const exportSemesterToCSV = async () => {
    try {
        const response = await api.get(`/api/final-result/export/csv`, {
            responseType: 'blob',
        });
        return response.data;
    } catch (err) {
        toast.error(err.message);
    }
}